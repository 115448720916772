var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { styled } from "@mui/material/styles";
import { compose, spacing } from "@mui/system";
import { useGridApiRef } from "@mui/x-data-grid";
import classNames from "classnames";
import React, { memo } from "react";
import { BaseIcon } from "../BaseIcon";
import { Box } from "../Box";
import { Checkbox } from "../Checkbox";
import { NumberInput } from "../NumberInput";
import { Typography } from "../Typography";
import { DataGrid } from "./styled";
const ImplTable = React.forwardRef((_a, ref) => {
    var { page, onPageChange, pagination, totalPages, className } = _a, props = __rest(_a, ["page", "onPageChange", "pagination", "totalPages", "className"]);
    const apiRef = useGridApiRef();
    const columnSortedAscendingIcon = () => React.createElement(BaseIcon, { size: 16, icon: "DropDown", color: "black-1200" });
    const columnSortedDescendingIcon = () => React.createElement(BaseIcon, { size: 16, icon: "DropDown", rotate: 180, color: "black-1200" });
    const columnUnsortedIcon = () => React.createElement(BaseIcon, { size: 16, icon: "DropDown", color: "black-400" });
    const baseCheckbox = (props) => React.createElement(Checkbox, Object.assign({ size: "medium" }, props));
    const noRowsOverlay = () => React.createElement(Box, { height: "100%", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" },
        React.createElement(Typography, { color: "black-400", type: "text14" }, "Your transaction history will appear here"));
    // const loadingOverlay = () => (
    //     <SpinnerWrapper>
    //         <CircularLoader />
    //     </SpinnerWrapper>
    // );
    return (React.createElement(Box, { display: "flex", flexDirection: "column" },
        React.createElement(DataGrid, Object.assign({ apiRef: apiRef, ref: ref, disableColumnMenu: true, disableRowSelectionOnClick: true, disableColumnSelector: true, columnHeaderHeight: 60, rowHeight: 80, hideFooter: true, autoHeight: true, 
            // columnHeaderHeight={getRowHeight(rowSize)}
            // autoHeight
            experimentalFeatures: { columnGrouping: true }, slots: {
                columnSortedAscendingIcon,
                columnSortedDescendingIcon,
                columnUnsortedIcon,
                noRowsOverlay,
                baseCheckbox,
            }, className: classNames(className, {
                "Pagination": pagination && !!totalPages,
                "Selection": props.checkboxSelection,
            }) }, props)),
        !!totalPages && pagination && onPageChange && (React.createElement(Pagination, { totalPages: totalPages || 0, onPageChange: onPageChange, page: page || 0 }))));
});
const Pagination = ({ page, totalPages, onPageChange }) => (React.createElement(Box, { width: "100%", justifyContent: "space-between", display: "flex", padding: "15px 20px", alignItems: "center", bgColor: "general-white", borderRadius: "0 0 24px 24px", borders: ["bottom", "left", "right"], borderWidth: "1px", borderStyle: "solid", borderColor: "black-100", boxSizing: "border-box" },
    React.createElement(Box, { display: "flex", alignItems: "center", gap: "5px" },
        React.createElement(Typography, { marginRight: "5px" }, "Page"),
        React.createElement(NumberInput, { value: page + 1, width: "30px", size: "xs", textAlign: "center", placeholder: "", maxValue: totalPages, onChange: (e) => Number(e.target.value) > 0 && onPageChange(Number(e.target.value) - 1) }),
        React.createElement(Typography, null,
            "of ",
            totalPages)),
    React.createElement(Box, { display: "flex", alignItems: "center", gap: "15px" },
        React.createElement(BaseIcon, { icon: "DropDown", disabled: page === 0, rotate: 90, opacity: page === 0 ? "0.3" : "1", bgColor: "black-50", borderRadius: "100%", padding: "10px", width: 45, height: 45, hoverBgColor: "black-200", color: page === 0 ? "black-500" : undefined, onClick: () => onPageChange(page - 1) }),
        React.createElement(BaseIcon, { width: 45, height: 45, borderRadius: "100%", disabled: !!totalPages && page === totalPages - 1, icon: "DropDown", rotate: -90, bgColor: "black-50", padding: "10px", hoverBgColor: "black-200", color: page === totalPages - 1 ? "black-500" : undefined, onClick: () => onPageChange(page + 1), opacity: totalPages - 1 === page ? "0.3" : "1" }))));
export const Table = memo(styled(ImplTable, {
    shouldForwardProp: (prop) => !spacing.filterProps.includes(prop),
})(compose(spacing)));
