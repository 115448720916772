var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { styled } from "@mui/material/styles";
import { compose, flexbox, grid, spacing } from "@mui/system";
import classNames from "classnames";
import React, { memo } from "react";
import { BaseIcon } from "../BaseIcon";
import { Loader } from "../Loader";
import { Typography } from "../Typography";
import { padding, paddingIcon, paddingWithIcon, sizeIcon, StyledAlert, StyledButton } from "./styled";
const textColor = {
    Default: "general-white",
    Primary: "general-white",
    White: "black-1200",
    Gray: "black-1200",
    Bordered: "black-1200",
    BorderedGray: "black-500",
    BlueBordered: "deep-blue-500",
    LightBlue: "deep-blue-500",
    Black: "general-white",
};
const BaseButton = React.forwardRef((_a, ref) => {
    var { children, icon, variant = "Default", size = "m", className, width = "auto", minWidth, iconSize, active, IconProps, iconPosition = "left", flexDirection, onClick, error, replace, loading } = _a, props = __rest(_a, ["children", "icon", "variant", "size", "className", "width", "minWidth", "iconSize", "active", "IconProps", "iconPosition", "flexDirection", "onClick", "error", "replace", "loading"]);
    const iconE = icon
        ? (React.createElement(BaseIcon, Object.assign({ icon: icon, color: props.disabled ? "black-500" : textColor[variant], size: iconSize || sizeIcon[size] }, IconProps)))
        : undefined;
    return (React.createElement(StyledButton, Object.assign({ replace: props.LinkComponent ? replace : undefined, ref: ref, className: classNames(className, `Button-${variant}`, { ButtonIcon: icon && !children }, { ButtonActive: active }, { "ButtonDisabled Mui-disabled": props.disabled }), "$width": width, "$minWidth": minWidth, "$size": size, "$padding": icon && children
            ? paddingWithIcon[size]
            : icon
                ? paddingIcon[size]
                : padding[size], onClick: props.disabled ? undefined : onClick }, props),
        iconPosition === "left" ? iconE : null,
        children && (React.createElement(Typography, { type: (() => {
                switch (size) {
                    case "s":
                        return "text14";
                    case "xxs":
                        return "text12";
                    default:
                        return "text16";
                }
            })(), fontWeight: 500, color: props.disabled ? "black-500" : textColor[variant] }, children)),
        iconPosition === "right" ? iconE : null,
        loading && (React.createElement(Loader, { size: 20 })),
        error && React.createElement(StyledAlert, { text: error })));
});
export const Button = memo(styled(BaseButton, {
    shouldForwardProp: (prop) => !compose(spacing, grid, flexbox)
        .filterProps.includes(prop),
})(compose(spacing, grid, flexbox)));
