import * as React from "react";
import styles from "./index.module.scss";
import { Typography } from "@/shared/components/Typography";
export const PasswordHint = ({ match = false, showMatch = false, uppercase, lowercase, special, digit, length, }) => (React.createElement("div", { className: styles.hint },
    showMatch && (React.createElement(Typography, { type: "text12", color: match ? "general-green" : "black-500" }, "Passwords must match")),
    React.createElement(Typography, { type: "text12", color: uppercase ? "general-green" : "black-500" }, "Password must contain 1 or more uppercase characters"),
    React.createElement(Typography, { type: "text12", color: lowercase ? "general-green" : "black-500" }, "Password must contain 1 or more lowercase characters"),
    React.createElement(Typography, { type: "text12", color: special ? "general-green" : "black-500" }, "Password must contain 1 or more special characters"),
    React.createElement(Typography, { type: "text12", color: digit ? "general-green" : "black-500" }, "Password must contain 1 or more digit characters"),
    React.createElement(Typography, { type: "text12", color: length ? "general-green" : "black-500" }, "Password must be 8 or more characters in length")));
