var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { ClickAwayListener, FormControl } from "@mui/base";
import { InputAdornment, Popper } from "@mui/material";
import { styled } from "@mui/material/styles";
import { RichTreeView, TreeItem, treeItemClasses } from "@mui/x-tree-view";
import React, { useEffect, useId, useMemo, useRef, useState } from "react";
import { BaseIcon } from "../BaseIcon";
import { FormControlInput } from "../FormControlInput";
function extractChildrenRecursive(data) {
    const childrenObj = {};
    function traverse(node) {
        if (node.children && node.children.length > 0) {
            node.children.forEach((child) => {
                traverse(child);
            });
        }
        else {
            childrenObj[node.id] = node.label;
        }
    }
    data.forEach((item) => {
        traverse(item);
    });
    return childrenObj;
}
const changeLabel = (opts) => opts.map((item) => ({
    id: item.id,
    label: `${item.id} — ${item.label}`,
    children: item.children ? changeLabel(item.children) : [],
}));
export const FormControlTreeSelect = (_a) => {
    var { options, value, onSelectTree } = _a, props = __rest(_a, ["options", "value", "onSelectTree"]);
    const opt = useMemo(() => changeLabel(options), [options]);
    const mapValues = useMemo(() => extractChildrenRecursive(options), [options]);
    const mapOptValues = useMemo(() => extractChildrenRecursive(opt), [opt]);
    const values = useMemo(() => Object.entries(mapOptValues).map(([key, value]) => ({
        id: key,
        label: value,
    })), [mapOptValues]);
    const [expandedItems, setExpandedItems] = React.useState([]);
    const [open, setOpen] = useState(false);
    const [query, setQuery] = useState("");
    const mapValue = value ? mapOptValues[value] : "";
    const filteredValues = useMemo(() => query
        ? values.filter((item) => item.label.toLowerCase().includes(query.toLowerCase()))
        : undefined, [query, values]);
    const id = useId();
    const anchorRef = useRef(null);
    const handleHide = () => {
        setOpen(false);
    };
    const handleExpandedItemsChange = (_, itemIds) => {
        setExpandedItems(itemIds);
    };
    useEffect(() => {
        var _a;
        if (open && value && anchorRef.current) {
            const body = (_a = document.getElementById(id)) === null || _a === void 0 ? void 0 : _a.parentElement;
            if (!body)
                return;
            const selectedElement = document.getElementById(`${id}-${value}`);
            if (!selectedElement) {
                return;
            }
            const elementRect = selectedElement.getBoundingClientRect();
            const listRect = body.getBoundingClientRect();
            const y = body.scrollTop
                + elementRect.top
                - listRect.top
                - listRect.height / 2
                + elementRect.height / 2;
            body.scrollTo({ top: y });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);
    useEffect(() => {
        setQuery(mapValue);
    }, [mapValue]);
    return (React.createElement(ClickAwayListener, { onClickAway: handleHide, mouseEvent: "onMouseDown" },
        React.createElement(FormControl, { ref: anchorRef, style: { position: "relative" } },
            React.createElement(FormControlInput, Object.assign({}, props, { autoComplete: "off", focused: open, 
                // value={mapValues[value as string] || ""}
                value: query, onChange: (e) => setQuery(e.currentTarget.value), onClick: () => setOpen((p) => !p), InputProps: {
                    endAdornment: (React.createElement(InputAdornment, { position: "end" },
                        React.createElement(CustomBaseIcon, { "$isOpen": open, icon: "DropDown" }))),
                } })),
            React.createElement(Popper, { style: {
                    width: "100%",
                    background: "white",
                    borderRadius: "12px",
                    maxHeight: "300px",
                    zIndex: 9999,
                    boxShadow: "0px 4px 20px 0px #00000014",
                    overflow: "auto",
                    marginTop: "8px",
                }, open: open, anchorEl: anchorRef.current, role: undefined, disablePortal: true },
                React.createElement(RichTreeView, { id: id, items: mapValue === query ? opt : filteredValues !== null && filteredValues !== void 0 ? filteredValues : opt, expandedItems: expandedItems, onExpandedItemsChange: handleExpandedItemsChange, selectedItems: value || undefined, onSelectedItemsChange: (_, v) => {
                        if (v && mapValues[v]) {
                            onSelectTree === null || onSelectTree === void 0 ? void 0 : onSelectTree(v, mapValues[v]);
                            setQuery(mapOptValues[v]);
                            setOpen(false);
                        }
                    }, slots: {
                        item: CustomTreeItem,
                    } })))));
};
const CustomBaseIcon = styled(BaseIcon)(({ $isOpen }) => ({
    transition: "unset",
    transform: $isOpen ? "rotate(180deg)" : "unset",
}));
const CustomTreeItem = styled(TreeItem)(({ theme }) => ({
    [`& .${treeItemClasses.content}`]: {
        width: "100%",
        display: "flex",
        gap: "12px",
        alignItems: "center",
        padding: "15px 20px",
        boxSizing: "border-box",
        transition: "background 0.2s ease",
        ".MuiTreeItem-label": {
            color: theme.colors["black-600"],
        },
        "&.Mui-selected, &.Mui-focused, &.Mui-focused.Mui-selected": {
            backgroundColor: "transparent",
        },
        "&.Mui-selected .MuiTreeItem-label": {
            color: theme.colors["black-1200"],
        },
        "&:hover .MuiTreeItem-label": {
            color: theme.colors["black-1200"],
        },
    },
    [`& .${treeItemClasses.content}:hover`]: {
        backgroundColor: "transparent",
        cursor: "pointer",
    },
    [`& .${treeItemClasses.root}:not([aria-expanded=false]):not([aria-expanded=true])`]: {
        [`& .${treeItemClasses.content}:hover`]: {
            backgroundColor: theme.colors["black-50"],
            cursor: "pointer",
            ".MuiTreeItem-label": {
                color: theme.colors["deep-blue-500"],
            },
        },
    },
}));
