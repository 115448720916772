import { GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid";
import React, { useState } from "react";
import { TransactionAmount } from "../TransactionAmount";
import { TransactionInfo } from "../TransactionInfo";
import { useGetHistoryQuery } from "@/api/wallet";
import { getOperationType } from "@/api/wallet/constants";
import { BalanceLogReasonType } from "@/api/wallet/types";
import { Box, Table, Typography } from "@/shared/components";
import { DATE_FORMAT, formatDate } from "@/shared/lib";
export const Transactions = ({ onItems, onSelectedItems, accountIds = [], tableProps }) => {
    const [transaction, setTransaction] = useState(null);
    const [page, setPage] = useState(0);
    const { data: transfers, isLoading, isFetching } = useGetHistoryQuery({ accountIds, page });
    const getBenicifiaryInfo = (type, source) => {
        if (type === BalanceLogReasonType.FIAT_WITHDRAWAL_REJECT
            || type === BalanceLogReasonType.FIAT_WITHDRAWAL_REQUEST) {
            const info = JSON.parse(source).state.details;
            if (info.wType === "BANK_TRANSFER") {
                return (React.createElement(Typography, { whiteSpace: "normal", color: "black-400" },
                    info.beneficiaryName,
                    "/",
                    info.beneficiaryAccountNumber));
            }
        }
        return null;
    };
    React.useEffect(() => {
        var _a;
        onItems === null || onItems === void 0 ? void 0 : onItems((_a = transfers === null || transfers === void 0 ? void 0 : transfers.data) !== null && _a !== void 0 ? _a : []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transfers]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Table, Object.assign({ pagination: true, rows: (transfers === null || transfers === void 0 ? void 0 : transfers.data) || [], loading: isLoading || isFetching, columns: [
                Object.assign(Object.assign({}, GRID_CHECKBOX_SELECTION_COL_DEF), { minWidth: 70 }),
                {
                    field: "createdAt",
                    headerName: "Date",
                    sortable: true,
                    resizable: false,
                    flex: 1,
                    renderCell: ({ value }) => (React.createElement(Typography, { display: "flex", flexDirection: "column" },
                        formatDate(value, DATE_FORMAT.date),
                        React.createElement(Typography, { color: "black-400" }, formatDate(value, DATE_FORMAT.time)))),
                },
                {
                    field: "reasonType",
                    headerName: "Operation",
                    resizable: false,
                    flex: 1.5,
                    renderCell: ({ value, row }) => (React.createElement(Box, { display: "flex", flexDirection: "column" }, row.note
                        ? (React.createElement(Typography, null, row.note))
                        : (React.createElement(React.Fragment, null,
                            React.createElement(Typography, null,
                                getOperationType(value),
                                ", ",
                                row.currency),
                            getBenicifiaryInfo(row.reasonType, row.source))))),
                },
                {
                    field: "change",
                    headerName: "Amount",
                    resizable: false,
                    flex: 1,
                    align: "right",
                    headerAlign: "right",
                    renderCell: ({ row }) => (React.createElement(TransactionAmount, { status: row.status, amount: row.change, currency: row.currency })),
                },
            ], getRowId: (row) => row.id, onRowClick: ({ row }) => setTransaction(row), initialState: {
                pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                },
                sorting: { sortModel: [{ field: "createdAt", sort: "desc" }] },
            }, page: page, paginationMode: "server", totalPages: transfers === null || transfers === void 0 ? void 0 : transfers.totalPages, onPageChange: (page) => setPage(page), onRowSelectionModelChange: (newRowSelectionModel) => {
                var _a;
                if (!onSelectedItems)
                    return;
                const selectedItems = ((_a = transfers === null || transfers === void 0 ? void 0 : transfers.data) === null || _a === void 0 ? void 0 : _a.filter(({ id }) => newRowSelectionModel.includes(id || "")))
                    || [];
                onSelectedItems === null || onSelectedItems === void 0 ? void 0 : onSelectedItems(selectedItems);
            } }, tableProps)),
        React.createElement(TransactionInfo, { transaction: transaction, onClose: () => setTransaction(null) })));
};
