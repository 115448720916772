import { CounterpartyProfileType, RelationType } from "../../../api/wallet/types";
import { countries } from "@/shared/constants/countries";
import lineOfBusinessDict from "@/shared/constants/lineOfBusinessDict.json";
import { hasAscii, isBic, isIban, maxLength } from "@/shared/utils/validation";
const mapEnumToKeyLabel = (enumData) => Object.keys(enumData).map((key) => ({
    key: enumData[key],
    label: key
        .replace(/_/g, " ")
        .split(" ")
        .map((word) => word.charAt(0) + word.slice(1).toLowerCase())
        .join(" "),
}));
export const RECIPIENT_FIELDS_CONFIG = [
    {
        label: "Recipient type",
        field: "profile.profileType",
        grid: "1/-1",
        type: "select",
        options: [
            {
                key: CounterpartyProfileType.CORPORATION,
                label: "Corporation",
            },
            {
                key: CounterpartyProfileType.INDIVIDUAL,
                label: "Individual",
            },
        ],
    },
    {
        label: "Name",
        field: "profile.name",
        grid: "1/-1",
        type: "input",
        validate: {
            hasAscii,
        },
    },
    // {
    //     label: "Name",
    //     field: "profile.email",
    //     grid: "1/-1",
    //     type: "input",
    // },
    // {
    //     label: "Name",
    //     field: "profile.telephoneNumber",
    //     grid: "1/-1",
    //     type: "input",
    // },
    {
        label: "Country",
        field: "profile.address.country",
        grid: "span 1",
        type: "select",
        options: countries.map((item) => ({
            key: item.codeAlpha3,
            label: `${item.flag} ${item.name}`,
        })),
    },
    {
        label: "City",
        field: "profile.address.town",
        grid: "span 1",
        type: "input",
        validate: {
            hasAscii,
            maxLength: maxLength(21),
        },
    },
    {
        label: "State/Province",
        field: "profile.address.state",
        grid: "1/-1",
        type: "select",
        options: "profileState",
    },
    {
        label: "Zip Code/Postal Code",
        field: "profile.address.postCode",
        grid: "1/-1",
        type: "input",
    },
    {
        label: "Address",
        field: "profile.address.street",
        grid: "1/-1",
        type: "input",
        validate: {
            hasAscii,
            maxLength: maxLength(35),
        },
    },
    {
        label: "Line of Business",
        field: "profile.lineOfBusiness",
        grid: "1/-1",
        type: "select",
        options: lineOfBusinessDict,
    },
    {
        label: "Relation to customer",
        field: "profile.relationshipToCustomer",
        grid: "1/-1",
        type: "select",
        options: mapEnumToKeyLabel(RelationType),
    },
    {
        label: "Tax Reference Number (EIN, FEIN, TIN, etc)",
        field: "profile.taxReferenceNumber",
        grid: "1/-1",
        type: "input",
    },
];
export const FedwireBankFields = [
    {
        label: "Account number",
        field: "accountNumber",
        grid: "1/-1",
        type: "input",
    },
    {
        label: "Routing number",
        field: "routingNumber",
        grid: "1/-1",
        type: "input",
    },
    {
        label: "Institution name",
        field: "institutionName",
        grid: "1/-1",
        type: "input",
        validate: {
            hasAscii,
            maxLength: maxLength(35),
        },
    },
    {
        label: "City",
        field: "institutionAddress.town",
        grid: "1/-1",
        type: "input",
        validate: {
            hasAscii,
            maxLength: maxLength(21),
        },
    },
    {
        label: "State/Province",
        field: "institutionAddress.state",
        grid: "1/-1",
        type: "institutionState",
    },
    {
        label: "Address",
        field: "institutionAddress.street",
        grid: "1/-1",
        type: "input",
        validate: {
            hasAscii,
            maxLength: maxLength(35),
        },
    },
    {
        label: "Zip Code/Postal Code",
        field: "institutionAddress.postCode",
        grid: "1/-1",
        type: "input",
    },
];
export const SepaBankFields = [
    {
        label: "IBAN",
        field: "iban",
        grid: "1/-1",
        type: "input",
        validate: {
            isIban,
        },
    },
    {
        label: "SWIFT (BIC) code",
        field: "swiftBic",
        grid: "1/-1",
        type: "input",
        validate: {
            isBic,
        },
    },
    {
        label: "Institution name",
        field: "institutionName",
        grid: "1/-1",
        type: "input",
        validate: {
            hasAscii,
            maxLength: maxLength(35),
        },
    },
    {
        label: "State/Province",
        field: "institutionAddress.state",
        grid: "1/-1",
        type: "institutionState",
    },
    {
        label: "City",
        field: "institutionAddress.town",
        grid: "1/-1",
        type: "input",
        validate: {
            hasAscii,
            maxLength: maxLength(21),
        },
    },
    {
        label: "Address",
        field: "institutionAddress.street",
        grid: "1/-1",
        type: "input",
        validate: {
            hasAscii,
            maxLength: maxLength(35),
        },
    },
    {
        label: "Address 2",
        field: "profile.address.subStreet",
        grid: "1/-1",
        type: "input",
        validate: {
            hasAscii,
            maxLength: maxLength(35),
        },
    },
    {
        label: "Zip Code/Postal Code",
        field: "institutionAddress.postCode",
        grid: "1/-1",
        type: "input",
    },
];
export const ImpsFields = [
    {
        label: "IFSC code",
        field: "ifsc",
        grid: "1/-1",
        type: "input",
    },
    {
        label: "Account number",
        field: "accountNumber",
        grid: "1/-1",
        type: "input",
    },
    {
        label: "Institution name",
        field: "institutionName",
        grid: "1/-1",
        type: "input",
        validate: {
            hasAscii,
            maxLength: maxLength(35),
        },
    },
    {
        label: "City",
        field: "institutionAddress.town",
        grid: "1/-1",
        type: "input",
        validate: {
            hasAscii,
            maxLength: maxLength(21),
        },
    },
    {
        label: "State/Province",
        field: "institutionAddress.state",
        grid: "1/-1",
        type: "institutionState",
    },
    {
        label: "Address",
        field: "institutionAddress.street",
        grid: "1/-1",
        type: "input",
        validate: {
            hasAscii,
            maxLength: maxLength(35),
        },
    },
    {
        label: "Zip Code/Postal Code",
        field: "institutionAddress.postCode",
        grid: "1/-1",
        type: "input",
    },
];
export const AchBankFields = [
    {
        label: "Account number",
        field: "accountNumber",
        grid: "1/-1",
        type: "input",
    },
    {
        label: "Routing number",
        field: "routingNumber",
        grid: "1/-1",
        type: "input",
    },
    {
        label: "Institution name",
        field: "institutionName",
        grid: "1/-1",
        type: "input",
        validate: {
            hasAscii,
            maxLength: maxLength(35),
        },
    },
    {
        label: "State/Province",
        field: "institutionAddress.state",
        grid: "1/-1",
        type: "institutionState",
    },
];
export const SwiftBankFields = [
    {
        label: "Account number",
        field: "accountNumber",
        grid: "1/-1",
        type: "input",
    },
    {
        label: "SWIFT (BIC) code",
        field: "swiftBic",
        grid: "1/-1",
        type: "input",
        validate: {
            isBic,
        },
    },
    {
        label: "Institution name",
        field: "institutionName",
        grid: "1/-1",
        type: "input",
        validate: {
            hasAscii,
            maxLength: maxLength(35),
        },
    },
    {
        label: "State/Province",
        field: "institutionAddress.state",
        grid: "1/-1",
        type: "institutionState",
    },
    {
        label: "City",
        field: "institutionAddress.town",
        grid: "1/-1",
        type: "input",
        validate: {
            hasAscii,
            maxLength: maxLength(21),
        },
    },
    {
        label: "Address",
        field: "institutionAddress.street",
        grid: "1/-1",
        type: "input",
        validate: {
            hasAscii,
            maxLength: maxLength(35),
        },
    },
    {
        label: "Address 2",
        field: "profile.address.subStreet",
        grid: "1/-1",
        type: "input",
        validate: {
            hasAscii,
            maxLength: maxLength(35),
        },
    },
    {
        label: "Zip Code/Postal Code",
        field: "institutionAddress.postCode",
        grid: "1/-1",
        type: "input",
    },
    {
        type: "intermediaryInfo",
    },
];
export const SwiftIbanBankFields = [
    {
        label: "IBAN",
        field: "accountNumber",
        grid: "1/-1",
        type: "input",
        validate: {
            isIban,
        },
    },
    {
        label: "SWIFT (BIC) code",
        field: "swiftBic",
        grid: "1/-1",
        type: "input",
        validate: {
            isBic,
        },
    },
    {
        label: "Institution name",
        field: "institutionName",
        grid: "1/-1",
        type: "input",
        validate: {
            hasAscii,
            maxLength: maxLength(35),
        },
    },
    {
        label: "State/Province",
        field: "institutionAddress.state",
        grid: "1/-1",
        type: "institutionState",
    },
    {
        label: "City",
        field: "institutionAddress.town",
        grid: "1/-1",
        type: "input",
        validate: {
            hasAscii,
            maxLength: maxLength(21),
        },
    },
    {
        label: "Address",
        field: "institutionAddress.street",
        grid: "1/-1",
        type: "input",
        validate: {
            hasAscii,
            maxLength: maxLength(35),
        },
    },
    {
        label: "Address 2",
        field: "profile.address.subStreet",
        grid: "1/-1",
        type: "input",
        validate: {
            hasAscii,
            maxLength: maxLength(35),
        },
    },
    {
        label: "Zip Code/Postal Code",
        field: "institutionAddress.postCode",
        grid: "1/-1",
        type: "input",
    },
    {
        type: "intermediaryInfo",
    },
];
export const bankFields = {
    SWIFT_IBAN: SwiftIbanBankFields,
    SWIFT: SwiftBankFields,
    SEPA: SepaBankFields,
    ACH: AchBankFields,
    FEDWIRE: FedwireBankFields,
    IMPS: ImpsFields,
};
