import React from "react";
import { toast } from "react-toastify";
import { Toast } from "@/shared/components/Alert";
export const useErrorToasts = (errors) => {
    React.useEffect(() => {
        errors.forEach(error => {
            toast((props) => (React.createElement(Toast, Object.assign({}, props, { type: "error", text: error.description }))));
        });
    }, [errors]);
};
