var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { observer } from "mobx-react-lite";
import * as React from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { generatePath, useNavigate } from "react-router-dom";
import { FormLayout } from "@/pages/KYBPage/components/FormLayout";
import { FormRow } from "@/pages/KYBPage/components/FormRow";
import { ROUTES } from "@/routes";
import { FormControlInput } from "@/shared/components";
import { FormControlSelect } from "@/shared/components/FormControlSelect";
import { FormControlTreeSelect } from "@/shared/components/TreeSelect";
import { KYBEntityTypes } from "@/shared/constants/dict";
import naicsOptions from "@/shared/constants/naics.json";
import { useStoreContext } from "@/shared/hooks/useStore";
import { CompanyInfoStore } from "@/shared/stores/CompanyInfoStore";
import { RequiredFieldMessage } from "@/shared/utils/error";
export const Form2 = observer(() => {
    var _a;
    const navigate = useNavigate();
    const companyInfo = useStoreContext(CompanyInfoStore);
    const form = useForm();
    const submit = form.handleSubmit((formData) => __awaiter(void 0, void 0, void 0, function* () {
        companyInfo.update(formData).then((success) => {
            if (success)
                navigate(generatePath(ROUTES.kybFormId, { id: 3 }));
        });
    }));
    const entityType = useWatch({
        control: form.control,
        name: "meta.entityType",
    });
    React.useEffect(() => {
        form.reset(companyInfo.companyInfo);
    }, [form, companyInfo.companyInfo]);
    return (React.createElement(FormLayout, { onSubmit: submit, onBack: () => navigate(generatePath(ROUTES.kybFormId, { id: 1 })), index: 2, error: (_a = companyInfo.errors[0]) === null || _a === void 0 ? void 0 : _a.description, title: "Company information", desc: "Make sure the details match your official documents and use Latin letters only", loading: !companyInfo.ready || companyInfo.loading },
        React.createElement(FormRow, null,
            React.createElement(Controller, { control: form.control, name: "companyName", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: ({ field, fieldState: { error } }) => (React.createElement(FormControlInput, Object.assign({}, field, { disabled: companyInfo.loading || field.disabled, label: "Legal Business Name", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" }))) }),
            React.createElement(Controller, { control: form.control, name: "meta.entityType", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: ({ field, fieldState: { error } }) => (React.createElement(FormControlSelect, { maxHeightList: "200px", name: field.name, onChange: (_, v) => field.onChange(v === null || v === void 0 ? void 0 : v.key), value: field.value, options: KYBEntityTypes, label: "Legal structure", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" })) })),
        entityType === "OTHER" && (React.createElement(Controller, { control: form.control, name: "meta.entityTypeDescription", rules: {
                required: {
                    value: true,
                    message: RequiredFieldMessage.description,
                },
            }, render: ({ field, fieldState: { error } }) => (React.createElement(FormControlInput, Object.assign({}, field, { disabled: companyInfo.loading || field.disabled, label: "Description of legal structure", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" }))) })),
        React.createElement(Controller, { control: form.control, name: "meta.descriptionOfBusinessNature", rules: {
                required: {
                    value: true,
                    message: RequiredFieldMessage.description,
                },
            }, render: ({ field, fieldState: { error } }) => (React.createElement(FormControlInput, Object.assign({}, field, { disabled: companyInfo.loading || field.disabled, label: "Description of business nature", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" }))) }),
        React.createElement(Controller, { control: form.control, name: "meta.naics", 
            // rules={{
            //     required: {
            //         value: true,
            //         message: RequiredFieldMessage.description,
            //     },
            // }}
            render: ({ field, fieldState: { error } }) => (React.createElement(FormControlTreeSelect, Object.assign({}, field, { 
                // disabled={companyInfo.loading || field.disabled}
                label: "NAICS", options: naicsOptions, error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative", onSelectTree: (key, label) => {
                    field.onChange(key);
                    form.setValue("meta.naicsDescription", label);
                } }))
            // <FormControlInput
            //     {...field}
            //     disabled={companyInfo.loading || field.disabled}
            //     label="NAICS"
            //     error={error?.message}
            //     errorPosition="relative"
            // />
            ) })));
});
