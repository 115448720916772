import { PaymentTarget, PaymentType, SubscriptionPlan } from "@/routes";
export const parseType = (value) => {
    switch (value) {
        case PaymentType.Crypto:
        case PaymentType.Card:
            return value;
        default:
            return null;
    }
};
export const parseTarget = (value) => {
    switch (value) {
        case PaymentTarget.Kyb:
        case PaymentTarget.Subscription:
            return value;
        default:
            return null;
    }
};
export const parsePlan = (value) => {
    switch (value) {
        case SubscriptionPlan.Business:
        case SubscriptionPlan.Personal:
        case SubscriptionPlan.Pro:
            return value;
        default:
            return null;
    }
};
