import { buttonClasses } from "@mui/base/Button";
import { Tab as BaseTab, tabClasses } from "@mui/base/Tab";
import { Tabs as BaseTabs } from "@mui/base/Tabs";
import { TabsList as BaseTabsList } from "@mui/base/TabsList";
import { styled } from "@mui/material/styles";
export const Tab = styled(BaseTab)(({ theme }) => `
  background-color: ${theme.colors["deep-blue-500-transparent-5"]};
  color: ${theme.colors["deep-blue-500"]};
  cursor: pointer;
  font-size: ${theme.text.text14.fontSize};
  line-height: ${theme.text.text14.lineHeight};
  font-weight: 500;
  width: 100%;
  height: 36px;
  border: none;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
  min-width: 130px;

  &:hover:not(.${buttonClasses.disabled}):not(.${tabClasses.selected}) {
    background-color: ${theme.colors["deep-blue-500-transparent-10"]};
  }

  &:hover:not(.${buttonClasses.disabled}).${tabClasses.selected}) {
    background-color: #0017E5;
  }


  &.${tabClasses.selected} {
    background-color: ${theme.colors["deep-blue-500"]};
    color: ${theme.colors["general-white"]};
  }

  &.${buttonClasses.disabled} {
    background-color: ${theme.colors["black-100"]};
    color: ${theme.colors["black-500"]};
  }
`);
export const TabsList = styled(BaseTabsList)(() => `
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  `);
export const Tabs = styled(BaseTabs)(() => `
  display: flex;
  `);
