import { styled } from "@mui/material";
export const PopupBody = styled("div") `
    min-height: ${props => { var _a; return (_a = props.minHeight) !== null && _a !== void 0 ? _a : "505px"; }};
    box-sizing: border-box;
    padding: 50px;
    padding-top: ${props => props.padTop ? "50px" : "0"};
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: ${props => props.spaceBetween ? "space-between" : undefined};

    ${props => props.theme.breakpoints.down("sm")} {
        padding: 25px;
        padding-top: ${props => props.padTop ? "25px" : "0"};
    }
`;
