var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { makeAutoObservable, runInAction } from "mobx";
import { registrationApi } from "@/api/registration";
import { VerificationType } from "@/api/registration/types";
import { parseApiError } from "@/shared/utils/error";
export class IndividualVerificationStore {
    constructor(individualId, kycId) {
        this.individualId = individualId;
        this.kycId = kycId;
        this.initialized = false;
        this.loading = false;
        this.errors = [];
        this.data = undefined;
        makeAutoObservable(this, {}, { autoBind: true });
    }
    init() {
        this.sync();
    }
    dispose() {
        this.initialized = false;
        this.loading = false;
        this.data = undefined;
    }
    sync() {
        return __awaiter(this, void 0, void 0, function* () {
            let data;
            runInAction(() => {
                this.loading = true;
            });
            if (this.kycId) {
                try {
                    const resp = yield registrationApi.verification.getVerificationByUuid(this.kycId);
                    data = resp.data;
                }
                catch (e) {
                    console.error(e);
                }
            }
            runInAction(() => {
                this.loading = false;
                this.data = data;
                this.initialized = true;
            });
        });
    }
    createVerification() {
        return __awaiter(this, void 0, void 0, function* () {
            let data;
            let errors = [];
            runInAction(() => {
                this.loading = true;
            });
            try {
                const resp = 
                // TODO: Fix
                // @ts-ignore
                yield registrationApi.verification.createIndividualVerification(this.individualId, {
                    type: VerificationType.KYBLAYER2,
                });
                data = resp.data;
                this.kycId = data.id;
            }
            catch (e) {
                console.error(e);
                errors = parseApiError(e);
            }
            runInAction(() => {
                this.data = data;
                this.errors = errors;
                this.loading = false;
            });
        });
    }
}
