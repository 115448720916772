import { styled } from "@mui/material/styles";
export const SwitchThumb = styled("span")(({ theme }) => ({
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.colors["black-400"],
    width: "20px",
    height: "20px",
    borderRadius: "100%",
    left: 0,
    top: 0,
    transition: "transform 0.2s ease",
    "&.checked": {
        transform: "translateX(20px)",
        backgroundColor: theme.colors["general-white"],
    },
}));
export const SwitchRoot = styled("span")(({ theme, $isHover }) => (Object.assign({ display: "inline-block", backgroundColor: theme.colors["black-200"], borderRadius: "16px", position: "relative", width: "48px", height: "28px", padding: "4px", boxSizing: "border-box", flexShrink: "0", transition: "backgroundColor 0.2s ease", "&.checked:not(.disabled)": {
        backgroundColor: theme.colors["deep-blue-500"],
    }, "&.disabled:before": {
        opacity: 1,
    }, "&:hover:before": {
        opacity: 1,
    }, "&.disabled input": {
        cursor: "not-allowed",
    }, "&:hover:not(.checked,.disabled) .SwitchThumb": {
        transform: "translateX(2px)",
    }, "&.checked:hover:not(.disabled) .SwitchThumb": {
        transform: "translateX(18px)",
    } }, ($isHover && {
    "&.checked:not(.disabled) .SwitchThumb": {
        transform: "translateX(18px)",
    },
    "&:not(.checked,.disabled) .SwitchThumb": {
        transform: "translateX(2px)",
    },
}))));
export const SwitchInput = styled("input")(() => ({
    position: "absolute",
    width: "100%",
    height: "100%",
    top: "0",
    left: "0",
    opacity: "0",
    zIndex: "1",
    margin: "0",
    cursor: "pointer",
}));
export const SwitchTrack = styled("span")(() => ({
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
    boxSizing: "border-box",
    padding: "0 3px",
}));
