import { keyframes, styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { generatePath, NavLink } from "react-router-dom";
import { MenuLinks } from "../../constants";
import { SignOutModal } from "@/features/signout";
import { ROUTES } from "@/routes";
import { BaseIcon, Box, Typography } from "@/shared/components";
import { Overlay } from "@/shared/components/Overlay";
import privacyPolicy from "@/shared/files/privacy_policy.pdf";
import termsOfUse from "@/shared/files/terms_of_use.pdf";
import { useStoreContext } from "@/shared/hooks/useStore";
import { MenuStore } from "@/shared/stores/MenuStore";
export const SideMenu = observer(() => {
    const [isOpenModal, setOpenModal] = React.useState(false);
    const menu = useStoreContext(MenuStore);
    const handleCloseModal = () => {
        setOpenModal(false);
        menu.close();
    };
    return (React.createElement(Overlay, null,
        React.createElement(Wrapper, null,
            React.createElement(Box, { display: "flex", flexDirection: "column" },
                React.createElement(BaseIcon, { icon: "Cross", size: 12, containerSize: 24, onClick: menu.close, alignSelf: "flex-end", marginBottom: "30px" }),
                React.createElement(User, { to: generatePath(ROUTES.profile) },
                    "Profile Settings",
                    React.createElement(BaseIcon, { icon: "Navigation", size: 24, color: "black-500" })),
                React.createElement(Box, { gap: "5px", display: "flex", flexDirection: "column", alignItems: "flex-start" }, MenuLinks.map(({ link, icon, label }) => (React.createElement(Item, { to: generatePath(link) },
                    React.createElement(BaseIcon, { icon: icon, size: 22, containerSize: 46 }),
                    label))))),
            React.createElement(Box, null,
                React.createElement(Box, { display: "flex", flexDirection: "column", gap: "20px", marginBottom: "60px", alignItems: "flex-start" },
                    React.createElement(StyledLink, { href: termsOfUse, target: "_blank", rel: "noreferrer" }, "Terms of Use"),
                    React.createElement(StyledLink, { href: privacyPolicy, target: "_blank", rel: "noreferrer" }, "Privacy Policy")),
                React.createElement(Typography, { color: "general-red", type: "text16Bold", onClick: () => setOpenModal(true) }, "Sign out"))),
        React.createElement(SignOutModal, { isOpen: isOpenModal, onClose: handleCloseModal })));
});
const slide = keyframes `
    from {
        opacity: 0;
        transform: translateX(50px);
    }
    to {
        opacity: 1;
        transform: none;
    }
`;
const Wrapper = styled("div") `
    gap: 60px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    box-sizing: border-box;
    padding: 20px;
    position: relative;
    background: ${(props) => props.theme.colors["black-50"]};
    min-height: 100vh;
    animation: ${slide} 0.25s;
    flex-grow: 1;
`;
const Link = styled(NavLink) `
    color: ${(props) => props.theme.colors["black-400"]};

    &:hover,
    &.active {
        color: ${(props) => props.theme.colors["black-1200"]};
    }
`;
const StyledLink = styled("a") `
    color: ${(props) => props.theme.colors["black-400"]};

    &:hover,
    &.active {
        color: ${(props) => props.theme.colors["black-1200"]};
    }
`;
const Item = styled(Link) `
    display: flex;
    align-items: center;
    gap: 10px;
    height: 46px;
    font-size: ${(props) => props.theme.text.text20.fontSize};
`;
const User = styled(Link) `
    padding: 18px 15px;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: ${(props) => props.theme.colors["general-white"]};
    margin-bottom: 40px;
    color: ${(props) => props.theme.colors["black-1200"]};
`;
