export const countries = [
    {
        name: "Afghanistan",
        flag: "🇦🇫",
        codeAlpha2: "AF",
        codeAlpha3: "AFG",
        dialCode: "93",
        minLength: 9,
        maxLength: 9,
    },
    {
        name: "Åland Islands",
        flag: "🇦🇽",
        codeAlpha2: "AX",
        codeAlpha3: "ALA",
        dialCode: "358",
        minLength: 15,
        maxLength: 15,
    },
    {
        name: "Albania",
        flag: "🇦🇱",
        codeAlpha2: "AL",
        codeAlpha3: "ALB",
        dialCode: "355",
        minLength: 9,
        maxLength: 9,
    },
    {
        name: "Algeria",
        flag: "🇩🇿",
        codeAlpha2: "DZ",
        codeAlpha3: "DZA",
        dialCode: "213",
        minLength: 9,
        maxLength: 9,
    },
    {
        name: "American Samoa",
        flag: "🇦🇸",
        codeAlpha2: "AS",
        codeAlpha3: "ASM",
        dialCode: "1684",
        minLength: 7,
        maxLength: 7,
    },
    {
        name: "Andorra",
        flag: "🇦🇩",
        codeAlpha2: "AD",
        codeAlpha3: "AND",
        dialCode: "376",
        minLength: 9,
        maxLength: 9,
    },
    {
        name: "Angola",
        flag: "🇦🇴",
        codeAlpha2: "AO",
        codeAlpha3: "AGO",
        dialCode: "244",
        minLength: 9,
        maxLength: 9,
    },
    {
        name: "Anguilla",
        flag: "🇦🇮",
        codeAlpha2: "AI",
        codeAlpha3: "AIA",
        dialCode: "1264",
        minLength: 7,
        maxLength: 7,
    },
    {
        name: "Antarctica",
        flag: "🇦🇶",
        codeAlpha2: "AQ",
        codeAlpha3: "ATA",
        dialCode: "672",
        minLength: 6,
        maxLength: 6,
    },
    {
        name: "Antigua and Barbuda",
        flag: "🇦🇬",
        codeAlpha2: "AG",
        codeAlpha3: "ATG",
        dialCode: "1268",
        minLength: 7,
        maxLength: 7,
    },
    {
        name: "Argentina",
        flag: "🇦🇷",
        codeAlpha2: "AR",
        codeAlpha3: "ARG",
        dialCode: "54",
        minLength: 12,
        maxLength: 12,
    },
    {
        name: "Armenia",
        flag: "🇦🇲",
        codeAlpha2: "AM",
        codeAlpha3: "ARM",
        dialCode: "374",
        minLength: 8,
        maxLength: 8,
    },
    {
        name: "Aruba",
        flag: "🇦🇼",
        codeAlpha2: "AW",
        codeAlpha3: "ABW",
        dialCode: "297",
        minLength: 7,
        maxLength: 7,
    },
    {
        name: "Australia",
        flag: "🇦🇺",
        codeAlpha2: "AU",
        codeAlpha3: "AUS",
        dialCode: "61",
        minLength: 9,
        maxLength: 9,
    },
    {
        name: "Austria",
        flag: "🇦🇹",
        codeAlpha2: "AT",
        codeAlpha3: "AUT",
        dialCode: "43",
        minLength: 13,
        maxLength: 13,
    },
    {
        name: "Azerbaijan",
        flag: "🇦🇿",
        codeAlpha2: "AZ",
        codeAlpha3: "AZE",
        dialCode: "994",
        minLength: 9,
        maxLength: 9,
    },
    {
        name: "Bahamas",
        flag: "🇧🇸",
        codeAlpha2: "BS",
        codeAlpha3: "BHS",
        dialCode: "1242",
        minLength: 7,
        maxLength: 7,
    },
    {
        name: "Bahrain",
        flag: "🇧🇭",
        codeAlpha2: "BH",
        codeAlpha3: "BHR",
        dialCode: "973",
        minLength: 8,
        maxLength: 8,
    },
    {
        name: "Bangladesh",
        flag: "🇧🇩",
        codeAlpha2: "BD",
        codeAlpha3: "BGD",
        dialCode: "880",
        minLength: 10,
        maxLength: 10,
    },
    {
        name: "Barbados",
        flag: "🇧🇧",
        codeAlpha2: "BB",
        codeAlpha3: "BRB",
        dialCode: "1246",
        minLength: 7,
        maxLength: 7,
    },
    {
        name: "Belarus",
        flag: "🇧🇾",
        codeAlpha2: "BY",
        codeAlpha3: "BLR",
        dialCode: "375",
        minLength: 10,
        maxLength: 10,
    },
    {
        name: "Belgium",
        flag: "🇧🇪",
        codeAlpha2: "BE",
        codeAlpha3: "BEL",
        dialCode: "32",
        minLength: 9,
        maxLength: 9,
    },
    {
        name: "Belize",
        flag: "🇧🇿",
        codeAlpha2: "BZ",
        codeAlpha3: "BLZ",
        dialCode: "501",
        minLength: 7,
        maxLength: 7,
    },
    {
        name: "Benin",
        flag: "🇧🇯",
        codeAlpha2: "BJ",
        codeAlpha3: "BEN",
        dialCode: "229",
        minLength: 8,
        maxLength: 8,
    },
    {
        name: "Bermuda",
        flag: "🇧🇲",
        codeAlpha2: "BM",
        codeAlpha3: "BMU",
        dialCode: "1441",
        minLength: 7,
        maxLength: 7,
    },
    {
        name: "Bhutan",
        flag: "🇧🇹",
        codeAlpha2: "BT",
        codeAlpha3: "BTN",
        dialCode: "975",
        minLength: 8,
        maxLength: 8,
    },
    {
        name: "Bolivia, Plurinational State of bolivia",
        flag: "🇧🇴",
        codeAlpha2: "BO",
        codeAlpha3: "BOL",
        dialCode: "591",
        minLength: 8,
        maxLength: 8,
    },
    {
        name: "Bosnia and Herzegovina",
        flag: "🇧🇦",
        codeAlpha2: "BA",
        codeAlpha3: "BIH",
        dialCode: "387",
        minLength: 9,
        maxLength: 9,
    },
    {
        name: "Botswana",
        flag: "🇧🇼",
        codeAlpha2: "BW",
        codeAlpha3: "BWA",
        dialCode: "267",
        minLength: 8,
        maxLength: 8,
    },
    {
        name: "Bouvet Island",
        flag: "🇧🇻",
        codeAlpha2: "BV",
        codeAlpha3: "BVT",
        dialCode: "47",
        minLength: 15,
        maxLength: 15,
    },
    {
        name: "Brazil",
        flag: "🇧🇷",
        codeAlpha2: "BR",
        codeAlpha3: "BRA",
        dialCode: "55",
        minLength: 11,
        maxLength: 11,
    },
    {
        name: "British Indian Ocean Territory",
        flag: "🇮🇴",
        codeAlpha2: "IO",
        codeAlpha3: "IOT",
        dialCode: "246",
        minLength: 7,
        maxLength: 7,
    },
    {
        name: "Brunei Darussalam",
        flag: "🇧🇳",
        codeAlpha2: "BN",
        codeAlpha3: "BRN",
        dialCode: "673",
        minLength: 7,
        maxLength: 7,
    },
    {
        name: "Bulgaria",
        flag: "🇧🇬",
        codeAlpha2: "BG",
        codeAlpha3: "BGR",
        dialCode: "359",
        minLength: 9,
        maxLength: 9,
    },
    {
        name: "Burkina Faso",
        flag: "🇧🇫",
        codeAlpha2: "BF",
        codeAlpha3: "BFA",
        dialCode: "226",
        minLength: 8,
        maxLength: 8,
    },
    {
        name: "Burundi",
        flag: "🇧🇮",
        codeAlpha2: "BI",
        codeAlpha3: "BDI",
        dialCode: "257",
        minLength: 8,
        maxLength: 8,
    },
    {
        name: "Cambodia",
        flag: "🇰🇭",
        codeAlpha2: "KH",
        codeAlpha3: "KHM",
        dialCode: "855",
        minLength: 9,
        maxLength: 9,
    },
    {
        name: "Cameroon",
        flag: "🇨🇲",
        codeAlpha2: "CM",
        codeAlpha3: "CMR",
        dialCode: "237",
        minLength: 9,
        maxLength: 9,
    },
    {
        name: "Canada",
        flag: "🇨🇦",
        codeAlpha2: "CA",
        codeAlpha3: "CAN",
        dialCode: "1",
        minLength: 10,
        maxLength: 10,
    },
    {
        name: "Cape Verde",
        flag: "🇨🇻",
        codeAlpha2: "CV",
        codeAlpha3: "CPV",
        dialCode: "238",
        minLength: 7,
        maxLength: 7,
    },
    {
        name: "Cayman Islands",
        flag: "🇰🇾",
        codeAlpha2: "KY",
        codeAlpha3: "CYM",
        dialCode: "345",
        minLength: 7,
        maxLength: 7,
    },
    {
        name: "Central African Republic",
        flag: "🇨🇫",
        codeAlpha2: "CF",
        codeAlpha3: "CAF",
        dialCode: "236",
        minLength: 8,
        maxLength: 8,
    },
    {
        name: "Chad",
        flag: "🇹🇩",
        codeAlpha2: "TD",
        codeAlpha3: "TCD",
        dialCode: "235",
        minLength: 7,
        maxLength: 7,
    },
    {
        name: "Chile",
        flag: "🇨🇱",
        codeAlpha2: "CL",
        codeAlpha3: "CHL",
        dialCode: "56",
        minLength: 9,
        maxLength: 9,
    },
    {
        name: "China",
        flag: "🇨🇳",
        codeAlpha2: "CN",
        codeAlpha3: "CHN",
        dialCode: "86",
        minLength: 12,
        maxLength: 12,
    },
    {
        name: "Christmas Island",
        flag: "🇨🇽",
        codeAlpha2: "CX",
        codeAlpha3: "CXR",
        dialCode: "61",
        minLength: 15,
        maxLength: 15,
    },
    {
        name: "Cocos (Keeling) Islands",
        flag: "🇨🇨",
        codeAlpha2: "CC",
        codeAlpha3: "CCK",
        dialCode: "61",
        minLength: 15,
        maxLength: 15,
    },
    {
        name: "Colombia",
        flag: "🇨🇴",
        codeAlpha2: "CO",
        codeAlpha3: "COL",
        dialCode: "57",
        minLength: 10,
        maxLength: 10,
    },
    {
        name: "Comoros",
        flag: "🇰🇲",
        codeAlpha2: "KM",
        codeAlpha3: "COM",
        dialCode: "269",
        minLength: 7,
        maxLength: 7,
    },
    {
        name: "Congo",
        flag: "🇨🇬",
        codeAlpha2: "CG",
        codeAlpha3: "COG",
        dialCode: "242",
        minLength: 7,
        maxLength: 7,
    },
    {
        name: "Congo, The Democratic Republic of the Congo",
        flag: "🇨🇩",
        codeAlpha2: "CD",
        codeAlpha3: "COD",
        dialCode: "243",
        minLength: 9,
        maxLength: 9,
    },
    {
        name: "Cook Islands",
        flag: "🇨🇰",
        codeAlpha2: "CK",
        codeAlpha3: "COK",
        dialCode: "682",
        minLength: 5,
        maxLength: 5,
    },
    {
        name: "Costa Rica",
        flag: "🇨🇷",
        codeAlpha2: "CR",
        codeAlpha3: "CRI",
        dialCode: "506",
        minLength: 8,
        maxLength: 8,
    },
    {
        name: "Côte d'Ivoire",
        flag: "🇨🇮",
        codeAlpha2: "CI",
        codeAlpha3: "CIV",
        dialCode: "225",
        minLength: 10,
        maxLength: 10,
    },
    {
        name: "Croatia",
        flag: "🇭🇷",
        codeAlpha2: "HR",
        codeAlpha3: "HRV",
        dialCode: "385",
        minLength: 12,
        maxLength: 12,
    },
    {
        name: "Cuba",
        flag: "🇨🇺",
        codeAlpha2: "CU",
        codeAlpha3: "CUB",
        dialCode: "53",
        minLength: 8,
        maxLength: 8,
    },
    {
        name: "Cyprus",
        flag: "🇨🇾",
        codeAlpha2: "CY",
        codeAlpha3: "CYP",
        dialCode: "357",
        minLength: 8,
        maxLength: 8,
    },
    {
        name: "Czech Republic",
        flag: "🇨🇿",
        codeAlpha2: "CZ",
        codeAlpha3: "CZE",
        dialCode: "420",
        minLength: 9,
        maxLength: 9,
    },
    {
        name: "Denmark",
        flag: "🇩🇰",
        codeAlpha2: "DK",
        codeAlpha3: "DNK",
        dialCode: "45",
        minLength: 8,
        maxLength: 8,
    },
    {
        name: "Djibouti",
        flag: "🇩🇯",
        codeAlpha2: "DJ",
        codeAlpha3: "DJI",
        dialCode: "253",
        minLength: 6,
        maxLength: 6,
    },
    {
        name: "Dominica",
        flag: "🇩🇲",
        codeAlpha2: "DM",
        codeAlpha3: "DMA",
        dialCode: "1767",
        minLength: 7,
        maxLength: 7,
    },
    {
        name: "Dominican Republic",
        flag: "🇩🇴",
        codeAlpha2: "DO",
        codeAlpha3: "DOM",
        dialCode: "1849",
        minLength: 12,
        maxLength: 12,
    },
    {
        name: "Ecuador",
        flag: "🇪🇨",
        codeAlpha2: "EC",
        codeAlpha3: "ECU",
        dialCode: "593",
        minLength: 8,
        maxLength: 9,
    },
    {
        name: "Egypt",
        flag: "🇪🇬",
        codeAlpha2: "EG",
        codeAlpha3: "EGY",
        dialCode: "20",
        minLength: 10,
        maxLength: 10,
    },
    {
        name: "El Salvador",
        flag: "🇸🇻",
        codeAlpha2: "SV",
        codeAlpha3: "SLV",
        dialCode: "503",
        minLength: 11,
        maxLength: 11,
    },
    {
        name: "Equatorial Guinea",
        flag: "🇬🇶",
        codeAlpha2: "GQ",
        codeAlpha3: "GNQ",
        dialCode: "240",
        minLength: 6,
        maxLength: 6,
    },
    {
        name: "Eritrea",
        flag: "🇪🇷",
        codeAlpha2: "ER",
        codeAlpha3: "ERI",
        dialCode: "291",
        minLength: 7,
        maxLength: 7,
    },
    {
        name: "Estonia",
        flag: "🇪🇪",
        codeAlpha2: "EE",
        codeAlpha3: "EST",
        dialCode: "372",
        minLength: 10,
        maxLength: 10,
    },
    {
        name: "Ethiopia",
        flag: "🇪🇹",
        codeAlpha2: "ET",
        codeAlpha3: "ETH",
        dialCode: "251",
        minLength: 9,
        maxLength: 9,
    },
    {
        name: "Falkland Islands (Malvinas)",
        flag: "🇫🇰",
        codeAlpha2: "FK",
        codeAlpha3: "FLK",
        dialCode: "500",
        minLength: 5,
        maxLength: 5,
    },
    {
        name: "Faroe Islands",
        flag: "🇫🇴",
        codeAlpha2: "FO",
        codeAlpha3: "FRO",
        dialCode: "298",
        minLength: 6,
        maxLength: 6,
    },
    {
        name: "Fiji",
        flag: "🇫🇯",
        codeAlpha2: "FJ",
        codeAlpha3: "FJI",
        dialCode: "679",
        minLength: 7,
        maxLength: 7,
    },
    {
        name: "Finland",
        flag: "🇫🇮",
        codeAlpha2: "FI",
        codeAlpha3: "FIN",
        dialCode: "358",
        minLength: 12,
        maxLength: 12,
    },
    {
        name: "France",
        flag: "🇫🇷",
        codeAlpha2: "FR",
        codeAlpha3: "FRA",
        dialCode: "33",
        minLength: 9,
        maxLength: 9,
    },
    {
        name: "French Guiana",
        flag: "🇬🇫",
        codeAlpha2: "GF",
        codeAlpha3: "GUF",
        dialCode: "594",
        minLength: 15,
        maxLength: 15,
    },
    {
        name: "French Polynesia",
        flag: "🇵🇫",
        codeAlpha2: "PF",
        codeAlpha3: "PYF",
        dialCode: "689",
        minLength: 6,
        maxLength: 6,
    },
    {
        name: "French Southern Territories",
        flag: "🇹🇫",
        codeAlpha2: "TF",
        codeAlpha3: "ATF",
        dialCode: "262",
        minLength: 15,
        maxLength: 15,
    },
    {
        name: "Gabon",
        flag: "🇬🇦",
        codeAlpha2: "GA",
        codeAlpha3: "GAB",
        dialCode: "241",
        minLength: 9,
        maxLength: 9,
    },
    {
        name: "Gambia",
        flag: "🇬🇲",
        codeAlpha2: "GM",
        codeAlpha3: "GMB",
        dialCode: "220",
        minLength: 7,
        maxLength: 7,
    },
    {
        name: "Georgia",
        flag: "🇬🇪",
        codeAlpha2: "GE",
        codeAlpha3: "GEO",
        dialCode: "995",
        minLength: 8,
        maxLength: 9,
    },
    {
        name: "Germany",
        flag: "🇩🇪",
        codeAlpha2: "DE",
        codeAlpha3: "DEU",
        dialCode: "49",
        minLength: 9,
        maxLength: 13,
    },
    {
        name: "Ghana",
        flag: "🇬🇭",
        codeAlpha2: "GH",
        codeAlpha3: "GHA",
        dialCode: "233",
        minLength: 10,
        maxLength: 10,
    },
    {
        name: "Gibraltar",
        flag: "🇬🇮",
        codeAlpha2: "GI",
        codeAlpha3: "GIB",
        dialCode: "350",
        minLength: 8,
        maxLength: 8,
    },
    {
        name: "Greece",
        flag: "🇬🇷",
        codeAlpha2: "GR",
        codeAlpha3: "GRC",
        dialCode: "30",
        minLength: 10,
        maxLength: 10,
    },
    {
        name: "Greenland",
        flag: "🇬🇱",
        codeAlpha2: "GL",
        codeAlpha3: "GRL",
        dialCode: "299",
        minLength: 6,
        maxLength: 6,
    },
    {
        name: "Grenada",
        flag: "🇬🇩",
        codeAlpha2: "GD",
        codeAlpha3: "GRD",
        dialCode: "1473",
        minLength: 7,
        maxLength: 7,
    },
    {
        name: "Guadeloupe",
        flag: "🇬🇵",
        codeAlpha2: "GP",
        codeAlpha3: "GLP",
        dialCode: "590",
        minLength: 15,
        maxLength: 15,
    },
    {
        name: "Guam",
        flag: "🇬🇺",
        codeAlpha2: "GU",
        codeAlpha3: "GUM",
        dialCode: "1671",
        minLength: 7,
        maxLength: 7,
    },
    {
        name: "Guatemala",
        flag: "🇬🇹",
        codeAlpha2: "GT",
        codeAlpha3: "GTM",
        dialCode: "502",
        minLength: 8,
        maxLength: 8,
    },
    {
        name: "Guernsey",
        flag: "🇬🇬",
        codeAlpha2: "GG",
        codeAlpha3: "GGY",
        dialCode: "44",
        minLength: 6,
        maxLength: 6,
    },
    {
        name: "Guinea",
        flag: "🇬🇳",
        codeAlpha2: "GN",
        codeAlpha3: "GIN",
        dialCode: "224",
        minLength: 8,
        maxLength: 9,
    },
    {
        name: "Guinea-Bissau",
        flag: "🇬🇼",
        codeAlpha2: "GW",
        codeAlpha3: "GNB",
        dialCode: "245",
        minLength: 7,
        maxLength: 7,
    },
    {
        name: "Guyana",
        flag: "🇬🇾",
        codeAlpha2: "GY",
        codeAlpha3: "GUY",
        dialCode: "592",
        minLength: 7,
        maxLength: 7,
    },
    {
        name: "Haiti",
        flag: "🇭🇹",
        codeAlpha2: "HT",
        codeAlpha3: "HTI",
        dialCode: "509",
        minLength: 8,
        maxLength: 8,
    },
    {
        name: "Heard Island and Mcdonald Islands",
        flag: "🇭🇲",
        codeAlpha2: "HM",
        codeAlpha3: "HMD",
        dialCode: "672",
        minLength: 15,
        maxLength: 15,
    },
    {
        name: "Holy See (Vatican City State)",
        flag: "🇻🇦",
        codeAlpha2: "VA",
        codeAlpha3: "VAT",
        dialCode: "379",
        minLength: 10,
        maxLength: 10,
    },
    {
        name: "Honduras",
        flag: "🇭🇳",
        codeAlpha2: "HN",
        codeAlpha3: "HND",
        dialCode: "504",
        minLength: 8,
        maxLength: 8,
    },
    {
        name: "Hong Kong",
        flag: "🇭🇰",
        codeAlpha2: "HK",
        codeAlpha3: "HKG",
        dialCode: "852",
        minLength: 8,
        maxLength: 8,
    },
    {
        name: "Hungary",
        flag: "🇭🇺",
        codeAlpha2: "HU",
        codeAlpha3: "HUN",
        dialCode: "36",
        minLength: 9,
        maxLength: 9,
    },
    {
        name: "Iceland",
        flag: "🇮🇸",
        codeAlpha2: "IS",
        codeAlpha3: "ISL",
        dialCode: "354",
        minLength: 7,
        maxLength: 9,
    },
    {
        name: "India",
        flag: "🇮🇳",
        codeAlpha2: "IN",
        codeAlpha3: "IND",
        dialCode: "91",
        minLength: 10,
        maxLength: 10,
    },
    {
        name: "Indonesia",
        flag: "🇮🇩",
        codeAlpha2: "ID",
        codeAlpha3: "IDN",
        dialCode: "62",
        minLength: 10,
        maxLength: 13,
    },
    {
        name: "Iran, Islamic Republic of Persian Gulf",
        flag: "🇮🇷",
        codeAlpha2: "IR",
        codeAlpha3: "IRN",
        dialCode: "98",
        minLength: 10,
        maxLength: 10,
    },
    {
        name: "Iraq",
        flag: "🇮🇶",
        codeAlpha2: "IQ",
        codeAlpha3: "IRQ",
        dialCode: "964",
        minLength: 10,
        maxLength: 10,
    },
    {
        name: "Ireland",
        flag: "🇮🇪",
        codeAlpha2: "IE",
        codeAlpha3: "IRL",
        dialCode: "353",
        minLength: 7,
        maxLength: 9,
    },
    {
        name: "Isle of Man",
        flag: "🇮🇲",
        codeAlpha2: "IM",
        codeAlpha3: "IMN",
        dialCode: "44",
        minLength: 6,
        maxLength: 6,
    },
    {
        name: "Israel",
        flag: "🇮🇱",
        codeAlpha2: "IL",
        codeAlpha3: "ISR",
        dialCode: "972",
        minLength: 9,
        maxLength: 9,
    },
    {
        name: "Italy",
        flag: "🇮🇹",
        codeAlpha2: "IT",
        codeAlpha3: "ITA",
        dialCode: "39",
        minLength: 13,
        maxLength: 13,
    },
    {
        name: "Jamaica",
        flag: "🇯🇲",
        codeAlpha2: "JM",
        codeAlpha3: "JAM",
        dialCode: "1876",
        minLength: 7,
        maxLength: 7,
    },
    {
        name: "Japan",
        flag: "🇯🇵",
        codeAlpha2: "JP",
        codeAlpha3: "JPN",
        dialCode: "81",
        minLength: 10,
        maxLength: 10,
    },
    {
        name: "Jersey",
        flag: "🇯🇪",
        codeAlpha2: "JE",
        codeAlpha3: "JEY",
        dialCode: "44",
        minLength: 6,
        maxLength: 6,
    },
    {
        name: "Jordan",
        flag: "🇯🇴",
        codeAlpha2: "JO",
        codeAlpha3: "JOR",
        dialCode: "962",
        minLength: 9,
        maxLength: 9,
    },
    {
        name: "Kazakhstan",
        flag: "🇰🇿",
        codeAlpha2: "KZ",
        codeAlpha3: "KAZ",
        dialCode: "7",
        minLength: 10,
        maxLength: 10,
    },
    {
        name: "Kenya",
        flag: "🇰🇪",
        codeAlpha2: "KE",
        codeAlpha3: "KEN",
        dialCode: "254",
        minLength: 10,
        maxLength: 10,
    },
    {
        name: "Kiribati",
        flag: "🇰🇮",
        codeAlpha2: "KI",
        codeAlpha3: "KIR",
        dialCode: "686",
        minLength: 5,
        maxLength: 5,
    },
    {
        name: "Korea, Democratic People's Republic of Korea",
        flag: "🇰🇵",
        codeAlpha2: "KP",
        codeAlpha3: "PRK",
        dialCode: "850",
        minLength: 10,
        maxLength: 10,
    },
    {
        name: "Korea, Republic of South Korea",
        flag: "🇰🇷",
        codeAlpha2: "KR",
        codeAlpha3: "KOR",
        dialCode: "82",
        minLength: 11,
        maxLength: 11,
    },
    {
        name: "Kosovo",
        flag: "🇽🇰",
        codeAlpha2: "XK",
        codeAlpha3: "UNK",
        dialCode: "383",
        minLength: 8,
        maxLength: 8,
    },
    {
        name: "Kuwait",
        flag: "🇰🇼",
        codeAlpha2: "KW",
        codeAlpha3: "KWT",
        dialCode: "965",
        minLength: 8,
        maxLength: 8,
    },
    {
        name: "Kyrgyzstan",
        flag: "🇰🇬",
        codeAlpha2: "KG",
        codeAlpha3: "KGZ",
        dialCode: "996",
        minLength: 9,
        maxLength: 9,
    },
    {
        name: "Laos",
        flag: "🇱🇦",
        codeAlpha2: "LA",
        codeAlpha3: "LAO",
        dialCode: "856",
        minLength: 10,
        maxLength: 10,
    },
    {
        name: "Latvia",
        flag: "🇱🇻",
        codeAlpha2: "LV",
        codeAlpha3: "LVA",
        dialCode: "371",
        minLength: 8,
        maxLength: 8,
    },
    {
        name: "Lebanon",
        flag: "🇱🇧",
        codeAlpha2: "LB",
        codeAlpha3: "LBN",
        dialCode: "961",
        minLength: 8,
        maxLength: 8,
    },
    {
        name: "Lesotho",
        flag: "🇱🇸",
        codeAlpha2: "LS",
        codeAlpha3: "LSO",
        dialCode: "266",
        minLength: 8,
        maxLength: 8,
    },
    {
        name: "Liberia",
        flag: "🇱🇷",
        codeAlpha2: "LR",
        codeAlpha3: "LBR",
        dialCode: "231",
        minLength: 8,
        maxLength: 8,
    },
    {
        name: "Libyan Arab Jamahiriya",
        flag: "🇱🇾",
        codeAlpha2: "LY",
        codeAlpha3: "LBY",
        dialCode: "218",
        minLength: 9,
        maxLength: 9,
    },
    {
        name: "Liechtenstein",
        flag: "🇱🇮",
        codeAlpha2: "LI",
        codeAlpha3: "LIE",
        dialCode: "423",
        minLength: 9,
        maxLength: 9,
    },
    {
        name: "Lithuania",
        flag: "🇱🇹",
        codeAlpha2: "LT",
        codeAlpha3: "LTU",
        dialCode: "370",
        minLength: 8,
        maxLength: 8,
    },
    {
        name: "Luxembourg",
        flag: "🇱🇺",
        codeAlpha2: "LU",
        codeAlpha3: "LUX",
        dialCode: "352",
        minLength: 11,
        maxLength: 11,
    },
    {
        name: "Macao",
        flag: "🇲🇴",
        codeAlpha2: "MO",
        codeAlpha3: "MAC",
        dialCode: "853",
        minLength: 8,
        maxLength: 8,
    },
    {
        name: "Macedonia",
        flag: "🇲🇰",
        codeAlpha2: "MK",
        codeAlpha3: "MKD",
        dialCode: "389",
        minLength: 8,
        maxLength: 8,
    },
    {
        name: "Madagascar",
        flag: "🇲🇬",
        codeAlpha2: "MG",
        codeAlpha3: "MDG",
        dialCode: "261",
        minLength: 10,
        maxLength: 10,
    },
    {
        name: "Malawi",
        flag: "🇲🇼",
        codeAlpha2: "MW",
        codeAlpha3: "MWI",
        dialCode: "265",
        minLength: 7,
        maxLength: 9,
    },
    {
        name: "Malaysia",
        flag: "🇲🇾",
        codeAlpha2: "MY",
        codeAlpha3: "MYS",
        dialCode: "60",
        minLength: 11,
        maxLength: 11,
    },
    {
        name: "Maldives",
        flag: "🇲🇻",
        codeAlpha2: "MV",
        codeAlpha3: "MDV",
        dialCode: "960",
        minLength: 7,
        maxLength: 7,
    },
    {
        name: "Mali",
        flag: "🇲🇱",
        codeAlpha2: "ML",
        codeAlpha3: "MLI",
        dialCode: "223",
        minLength: 8,
        maxLength: 8,
    },
    {
        name: "Malta",
        flag: "🇲🇹",
        codeAlpha2: "MT",
        codeAlpha3: "MLT",
        dialCode: "356",
        minLength: 8,
        maxLength: 8,
    },
    {
        name: "Marshall Islands",
        flag: "🇲🇭",
        codeAlpha2: "MH",
        codeAlpha3: "MHL",
        dialCode: "692",
        minLength: 7,
        maxLength: 7,
    },
    {
        name: "Martinique",
        flag: "🇲🇶",
        codeAlpha2: "MQ",
        codeAlpha3: "MTQ",
        dialCode: "596",
        minLength: 15,
        maxLength: 15,
    },
    {
        name: "Mauritania",
        flag: "🇲🇷",
        codeAlpha2: "MR",
        codeAlpha3: "MRT",
        dialCode: "222",
        minLength: 8,
        maxLength: 8,
    },
    {
        name: "Mauritius",
        flag: "🇲🇺",
        codeAlpha2: "MU",
        codeAlpha3: "MUS",
        dialCode: "230",
        minLength: 7,
        maxLength: 8,
    },
    {
        name: "Mayotte",
        flag: "🇾🇹",
        codeAlpha2: "YT",
        codeAlpha3: "MYT",
        dialCode: "262",
        minLength: 9,
        maxLength: 9,
    },
    {
        name: "Mexico",
        flag: "🇲🇽",
        codeAlpha2: "MX",
        codeAlpha3: "MEX",
        dialCode: "52",
        minLength: 10,
        maxLength: 10,
    },
    {
        name: "Micronesia, Federated States of Micronesia",
        flag: "🇫🇲",
        codeAlpha2: "FM",
        codeAlpha3: "FSM",
        dialCode: "691",
        minLength: 7,
        maxLength: 7,
    },
    {
        name: "Moldova",
        flag: "🇲🇩",
        codeAlpha2: "MD",
        codeAlpha3: "MDA",
        dialCode: "373",
        minLength: 8,
        maxLength: 8,
    },
    {
        name: "Monaco",
        flag: "🇲🇨",
        codeAlpha2: "MC",
        codeAlpha3: "MCO",
        dialCode: "377",
        minLength: 9,
        maxLength: 9,
    },
    {
        name: "Mongolia",
        flag: "🇲🇳",
        codeAlpha2: "MN",
        codeAlpha3: "MNG",
        dialCode: "976",
        minLength: 8,
        maxLength: 8,
    },
    {
        name: "Montenegro",
        flag: "🇲🇪",
        codeAlpha2: "ME",
        codeAlpha3: "MNE",
        dialCode: "382",
        minLength: 12,
        maxLength: 12,
    },
    {
        name: "Montserrat",
        flag: "🇲🇸",
        codeAlpha2: "MS",
        codeAlpha3: "MSR",
        dialCode: "1664",
        minLength: 7,
        maxLength: 7,
    },
    {
        name: "Morocco",
        flag: "🇲🇦",
        codeAlpha2: "MA",
        codeAlpha3: "MAR",
        dialCode: "212",
        minLength: 9,
        maxLength: 9,
    },
    {
        name: "Mozambique",
        flag: "🇲🇿",
        codeAlpha2: "MZ",
        codeAlpha3: "MOZ",
        dialCode: "258",
        minLength: 9,
        maxLength: 9,
    },
    {
        name: "Myanmar",
        flag: "🇲🇲",
        codeAlpha2: "MM",
        codeAlpha3: "MMR",
        dialCode: "95",
        minLength: 9,
        maxLength: 9,
    },
    {
        name: "Namibia",
        flag: "🇳🇦",
        codeAlpha2: "NA",
        codeAlpha3: "NAM",
        dialCode: "264",
        minLength: 10,
        maxLength: 10,
    },
    {
        name: "Nauru",
        flag: "🇳🇷",
        codeAlpha2: "NR",
        codeAlpha3: "NRU",
        dialCode: "674",
        minLength: 7,
        maxLength: 7,
    },
    {
        name: "Nepal",
        flag: "🇳🇵",
        codeAlpha2: "NP",
        codeAlpha3: "NPL",
        dialCode: "977",
        minLength: 10,
        maxLength: 10,
    },
    {
        name: "Netherlands",
        flag: "🇳🇱",
        codeAlpha2: "NL",
        codeAlpha3: "NLD",
        dialCode: "31",
        minLength: 9,
        maxLength: 9,
    },
    {
        name: "New Caledonia",
        flag: "🇳🇨",
        codeAlpha2: "NC",
        codeAlpha3: "NCL",
        dialCode: "687",
        minLength: 6,
        maxLength: 6,
    },
    {
        name: "New Zealand",
        flag: "🇳🇿",
        codeAlpha2: "NZ",
        codeAlpha3: "NZL",
        dialCode: "64",
        minLength: 10,
        maxLength: 10,
    },
    {
        name: "Nicaragua",
        flag: "🇳🇮",
        codeAlpha2: "NI",
        codeAlpha3: "NIC",
        dialCode: "505",
        minLength: 8,
        maxLength: 8,
    },
    {
        name: "Niger",
        flag: "🇳🇪",
        codeAlpha2: "NE",
        codeAlpha3: "NER",
        dialCode: "227",
        minLength: 8,
        maxLength: 8,
    },
    {
        name: "Nigeria",
        flag: "🇳🇬",
        codeAlpha2: "NG",
        codeAlpha3: "NGA",
        dialCode: "234",
        minLength: 10,
        maxLength: 11,
    },
    {
        name: "Niue",
        flag: "🇳🇺",
        codeAlpha2: "NU",
        codeAlpha3: "NIU",
        dialCode: "683",
        minLength: 4,
        maxLength: 4,
    },
    {
        name: "Norfolk Island",
        flag: "🇳🇫",
        codeAlpha2: "NF",
        codeAlpha3: "NFK",
        dialCode: "672",
        minLength: 15,
        maxLength: 15,
    },
    {
        name: "Northern Mariana Islands",
        flag: "🇲🇵",
        codeAlpha2: "MP",
        codeAlpha3: "MNP",
        dialCode: "1670",
        minLength: 7,
        maxLength: 7,
    },
    {
        name: "Norway",
        flag: "🇳🇴",
        codeAlpha2: "NO",
        codeAlpha3: "NOR",
        dialCode: "47",
        minLength: 8,
        maxLength: 8,
    },
    {
        name: "Oman",
        flag: "🇴🇲",
        codeAlpha2: "OM",
        codeAlpha3: "OMN",
        dialCode: "968",
        minLength: 8,
        maxLength: 8,
    },
    {
        name: "Pakistan",
        flag: "🇵🇰",
        codeAlpha2: "PK",
        codeAlpha3: "PAK",
        dialCode: "92",
        minLength: 10,
        maxLength: 10,
    },
    {
        name: "Palau",
        flag: "🇵🇼",
        codeAlpha2: "PW",
        codeAlpha3: "PLW",
        dialCode: "680",
        minLength: 7,
        maxLength: 7,
    },
    {
        name: "Palestinian Territory, Occupied",
        flag: "🇵🇸",
        codeAlpha2: "PS",
        codeAlpha3: "PSE",
        dialCode: "970",
        minLength: 9,
        maxLength: 9,
    },
    {
        name: "Panama",
        flag: "🇵🇦",
        codeAlpha2: "PA",
        codeAlpha3: "PAN",
        dialCode: "507",
        minLength: 8,
        maxLength: 8,
    },
    {
        name: "Papua New Guinea",
        flag: "🇵🇬",
        codeAlpha2: "PG",
        codeAlpha3: "PNG",
        dialCode: "675",
        minLength: 11,
        maxLength: 11,
    },
    {
        name: "Paraguay",
        flag: "🇵🇾",
        codeAlpha2: "PY",
        codeAlpha3: "PRY",
        dialCode: "595",
        minLength: 10,
        maxLength: 10,
    },
    {
        name: "Peru",
        flag: "🇵🇪",
        codeAlpha2: "PE",
        codeAlpha3: "PER",
        dialCode: "51",
        minLength: 9,
        maxLength: 9,
    },
    {
        name: "Philippines",
        flag: "🇵🇭",
        codeAlpha2: "PH",
        codeAlpha3: "PHL",
        dialCode: "63",
        minLength: 10,
        maxLength: 10,
    },
    {
        name: "Pitcairn",
        flag: "🇵🇳",
        codeAlpha2: "PN",
        codeAlpha3: "PCN",
        dialCode: "64",
        minLength: 10,
        maxLength: 10,
    },
    {
        name: "Poland",
        flag: "🇵🇱",
        codeAlpha2: "PL",
        codeAlpha3: "POL",
        dialCode: "48",
        minLength: 9,
        maxLength: 9,
    },
    {
        name: "Portugal",
        flag: "🇵🇹",
        codeAlpha2: "PT",
        codeAlpha3: "PRT",
        dialCode: "351",
        minLength: 9,
        maxLength: 9,
    },
    {
        name: "Puerto Rico",
        flag: "🇵🇷",
        codeAlpha2: "PR",
        codeAlpha3: "PRI",
        dialCode: "1939",
        minLength: 15,
        maxLength: 15,
    },
    {
        name: "Qatar",
        flag: "🇶🇦",
        codeAlpha2: "QA",
        codeAlpha3: "QAT",
        dialCode: "974",
        minLength: 8,
        maxLength: 8,
    },
    {
        name: "Romania",
        flag: "🇷🇴",
        codeAlpha2: "RO",
        codeAlpha3: "ROU",
        dialCode: "40",
        minLength: 9,
        maxLength: 9,
    },
    {
        name: "Russia",
        flag: "🇷🇺",
        codeAlpha2: "RU",
        codeAlpha3: "RUS",
        dialCode: "7",
        minLength: 10,
        maxLength: 10,
    },
    {
        name: "Rwanda",
        flag: "🇷🇼",
        codeAlpha2: "RW",
        codeAlpha3: "RWA",
        dialCode: "250",
        minLength: 9,
        maxLength: 9,
    },
    {
        name: "Reunion",
        flag: "🇷🇪",
        codeAlpha2: "RE",
        codeAlpha3: "REU",
        dialCode: "262",
        minLength: 9,
        maxLength: 9,
    },
    {
        name: "Saint Barthelemy",
        flag: "🇧🇱",
        codeAlpha2: "BL",
        codeAlpha3: "BLM",
        dialCode: "590",
        minLength: 9,
        maxLength: 9,
    },
    {
        name: "Saint Helena, Ascension and Tristan Da Cunha",
        flag: "🇸🇭",
        codeAlpha2: "SH",
        codeAlpha3: "SHN",
        dialCode: "290",
        minLength: 4,
        maxLength: 4,
    },
    {
        name: "Saint Kitts and Nevis",
        flag: "🇰🇳",
        codeAlpha2: "KN",
        codeAlpha3: "KNA",
        dialCode: "1869",
        minLength: 7,
        maxLength: 7,
    },
    {
        name: "Saint Lucia",
        flag: "🇱🇨",
        codeAlpha2: "LC",
        codeAlpha3: "LCA",
        dialCode: "1758",
        minLength: 7,
        maxLength: 7,
    },
    {
        name: "Saint Martin",
        flag: "🇲🇫",
        codeAlpha2: "MF",
        codeAlpha3: "MAF",
        dialCode: "590",
        minLength: 9,
        maxLength: 9,
    },
    {
        name: "Saint Pierre and Miquelon",
        flag: "🇵🇲",
        codeAlpha2: "PM",
        codeAlpha3: "SPM",
        dialCode: "508",
        minLength: 6,
        maxLength: 6,
    },
    {
        name: "Saint Vincent and the Grenadines",
        flag: "🇻🇨",
        codeAlpha2: "VC",
        codeAlpha3: "VCT",
        dialCode: "1784",
        minLength: 7,
        maxLength: 7,
    },
    {
        name: "Samoa",
        flag: "🇼🇸",
        codeAlpha2: "WS",
        codeAlpha3: "WSM",
        dialCode: "685",
        minLength: 7,
        maxLength: 7,
    },
    {
        name: "San Marino",
        flag: "🇸🇲",
        codeAlpha2: "SM",
        codeAlpha3: "SMR",
        dialCode: "378",
        minLength: 10,
        maxLength: 10,
    },
    {
        name: "Sao Tome and Principe",
        flag: "🇸🇹",
        codeAlpha2: "ST",
        codeAlpha3: "STP",
        dialCode: "239",
        minLength: 7,
        maxLength: 7,
    },
    {
        name: "Saudi Arabia",
        flag: "🇸🇦",
        codeAlpha2: "SA",
        codeAlpha3: "SAU",
        dialCode: "966",
        minLength: 9,
        maxLength: 9,
    },
    {
        name: "Senegal",
        flag: "🇸🇳",
        codeAlpha2: "SN",
        codeAlpha3: "SEN",
        dialCode: "221",
        minLength: 9,
        maxLength: 9,
    },
    {
        name: "Serbia",
        flag: "🇷🇸",
        codeAlpha2: "RS",
        codeAlpha3: "SRB",
        dialCode: "381",
        minLength: 12,
        maxLength: 12,
    },
    {
        name: "Seychelles",
        flag: "🇸🇨",
        codeAlpha2: "SC",
        codeAlpha3: "SYC",
        dialCode: "248",
        minLength: 6,
        maxLength: 6,
    },
    {
        name: "Sierra Leone",
        flag: "🇸🇱",
        codeAlpha2: "SL",
        codeAlpha3: "SLE",
        dialCode: "232",
        minLength: 8,
        maxLength: 8,
    },
    {
        name: "Singapore",
        flag: "🇸🇬",
        codeAlpha2: "SG",
        codeAlpha3: "SGP",
        dialCode: "65",
        minLength: 8,
        maxLength: 8,
    },
    {
        name: "Slovakia",
        flag: "🇸🇰",
        codeAlpha2: "SK",
        codeAlpha3: "SVK",
        dialCode: "421",
        minLength: 9,
        maxLength: 9,
    },
    {
        name: "Slovenia",
        flag: "🇸🇮",
        codeAlpha2: "SI",
        codeAlpha3: "SVN",
        dialCode: "386",
        minLength: 8,
        maxLength: 8,
    },
    {
        name: "Solomon Islands",
        flag: "🇸🇧",
        codeAlpha2: "SB",
        codeAlpha3: "SLB",
        dialCode: "677",
        minLength: 5,
        maxLength: 5,
    },
    {
        name: "Somalia",
        flag: "🇸🇴",
        codeAlpha2: "SO",
        codeAlpha3: "SOM",
        dialCode: "252",
        minLength: 8,
        maxLength: 8,
    },
    {
        name: "South Africa",
        flag: "🇿🇦",
        codeAlpha2: "ZA",
        codeAlpha3: "ZAF",
        dialCode: "27",
        minLength: 9,
        maxLength: 9,
    },
    {
        name: "South Sudan",
        flag: "🇸🇸",
        codeAlpha2: "SS",
        codeAlpha3: "SSD",
        dialCode: "211",
        minLength: 9,
        maxLength: 9,
    },
    {
        name: "South Georgia and the South Sandwich Islands",
        flag: "🇬🇸",
        codeAlpha2: "GS",
        codeAlpha3: "SGS",
        dialCode: "500",
        minLength: 15,
        maxLength: 15,
    },
    {
        name: "Spain",
        flag: "🇪🇸",
        codeAlpha2: "ES",
        codeAlpha3: "ESP",
        dialCode: "34",
        minLength: 9,
        maxLength: 9,
    },
    {
        name: "Sri Lanka",
        flag: "🇱🇰",
        codeAlpha2: "LK",
        codeAlpha3: "LKA",
        dialCode: "94",
        minLength: 9,
        maxLength: 9,
    },
    {
        name: "Sudan",
        flag: "🇸🇩",
        codeAlpha2: "SD",
        codeAlpha3: "SDN",
        dialCode: "249",
        minLength: 9,
        maxLength: 9,
    },
    {
        name: "Suriname",
        flag: "🇸🇷",
        codeAlpha2: "SR",
        codeAlpha3: "SUR",
        dialCode: "597",
        minLength: 7,
        maxLength: 7,
    },
    {
        name: "Svalbard and Jan Mayen",
        flag: "🇸🇯",
        codeAlpha2: "SJ",
        codeAlpha3: "SJM",
        dialCode: "47",
        minLength: 8,
        maxLength: 8,
    },
    {
        name: "Eswatini",
        flag: "🇸🇿",
        codeAlpha2: "SZ",
        codeAlpha3: "SWZ",
        dialCode: "268",
        minLength: 8,
        maxLength: 8,
    },
    {
        name: "Sweden",
        flag: "🇸🇪",
        codeAlpha2: "SE",
        codeAlpha3: "SWE",
        dialCode: "46",
        minLength: 7,
        maxLength: 13,
    },
    {
        name: "Switzerland",
        flag: "🇨🇭",
        codeAlpha2: "CH",
        codeAlpha3: "CHE",
        dialCode: "41",
        minLength: 12,
        maxLength: 12,
    },
    {
        name: "Syrian Arab Republic",
        flag: "🇸🇾",
        codeAlpha2: "SY",
        codeAlpha3: "SYR",
        dialCode: "963",
        minLength: 10,
        maxLength: 10,
    },
    {
        name: "Taiwan",
        flag: "🇹🇼",
        codeAlpha2: "TW",
        codeAlpha3: "TWN",
        dialCode: "886",
        minLength: 9,
        maxLength: 9,
    },
    {
        name: "Tajikistan",
        flag: "🇹🇯",
        codeAlpha2: "TJ",
        codeAlpha3: "TJK",
        dialCode: "992",
        minLength: 9,
        maxLength: 9,
    },
    {
        name: "Tanzania, United Republic of Tanzania",
        flag: "🇹🇿",
        codeAlpha2: "TZ",
        codeAlpha3: "TZA",
        dialCode: "255",
        minLength: 9,
        maxLength: 9,
    },
    {
        name: "Thailand",
        flag: "🇹🇭",
        codeAlpha2: "TH",
        codeAlpha3: "THA",
        dialCode: "66",
        minLength: 9,
        maxLength: 9,
    },
    {
        name: "Timor-Leste",
        flag: "🇹🇱",
        codeAlpha2: "TL",
        codeAlpha3: "TLS",
        dialCode: "670",
        minLength: 7,
        maxLength: 7,
    },
    {
        name: "Togo",
        flag: "🇹🇬",
        codeAlpha2: "TG",
        codeAlpha3: "TGO",
        dialCode: "228",
        minLength: 8,
        maxLength: 8,
    },
    {
        name: "Tokelau",
        flag: "🇹🇰",
        codeAlpha2: "TK",
        codeAlpha3: "TKL",
        dialCode: "690",
        minLength: 4,
        maxLength: 4,
    },
    {
        name: "Tonga",
        flag: "🇹🇴",
        codeAlpha2: "TO",
        codeAlpha3: "TON",
        dialCode: "676",
        minLength: 7,
        maxLength: 7,
    },
    {
        name: "Trinidad and Tobago",
        flag: "🇹🇹",
        codeAlpha2: "TT",
        codeAlpha3: "TTO",
        dialCode: "1868",
        minLength: 7,
        maxLength: 7,
    },
    {
        name: "Tunisia",
        flag: "🇹🇳",
        codeAlpha2: "TN",
        codeAlpha3: "TUN",
        dialCode: "216",
        minLength: 8,
        maxLength: 8,
    },
    {
        name: "Turkey",
        flag: "🇹🇷",
        codeAlpha2: "TR",
        codeAlpha3: "TUR",
        dialCode: "90",
        minLength: 10,
        maxLength: 10,
    },
    {
        name: "Turkmenistan",
        flag: "🇹🇲",
        codeAlpha2: "TM",
        codeAlpha3: "TKM",
        dialCode: "993",
        minLength: 8,
        maxLength: 8,
    },
    {
        name: "Turks and Caicos Islands",
        flag: "🇹🇨",
        codeAlpha2: "TC",
        codeAlpha3: "TCA",
        dialCode: "1649",
        minLength: 7,
        maxLength: 7,
    },
    {
        name: "Tuvalu",
        flag: "🇹🇻",
        codeAlpha2: "TV",
        codeAlpha3: "TUV",
        dialCode: "688",
        minLength: 6,
        maxLength: 6,
    },
    {
        name: "Uganda",
        flag: "🇺🇬",
        codeAlpha2: "UG",
        codeAlpha3: "UGA",
        dialCode: "256",
        minLength: 9,
        maxLength: 9,
    },
    {
        name: "Ukraine",
        flag: "🇺🇦",
        codeAlpha2: "UA",
        codeAlpha3: "UKR",
        dialCode: "380",
        minLength: 9,
        maxLength: 9,
    },
    {
        name: "United Arab Emirates",
        flag: "🇦🇪",
        codeAlpha2: "AE",
        codeAlpha3: "ARE",
        dialCode: "971",
        minLength: 9,
        maxLength: 9,
    },
    {
        name: "United Kingdom",
        flag: "🇬🇧",
        codeAlpha2: "GB",
        codeAlpha3: "GBR",
        dialCode: "44",
        minLength: 10,
        maxLength: 10,
    },
    {
        name: "United States",
        flag: "🇺🇸",
        codeAlpha2: "US",
        codeAlpha3: "USA",
        dialCode: "1",
        minLength: 10,
        maxLength: 10,
    },
    {
        name: "Uruguay",
        flag: "🇺🇾",
        codeAlpha2: "UY",
        codeAlpha3: "URY",
        dialCode: "598",
        minLength: 9,
        maxLength: 9,
    },
    {
        name: "Uzbekistan",
        flag: "🇺🇿",
        codeAlpha2: "UZ",
        codeAlpha3: "UZB",
        dialCode: "998",
        minLength: 9,
        maxLength: 9,
    },
    {
        name: "Vanuatu",
        flag: "🇻🇺",
        codeAlpha2: "VU",
        codeAlpha3: "VUT",
        dialCode: "678",
        minLength: 7,
        maxLength: 7,
    },
    {
        name: "Venezuela, Bolivarian Republic of Venezuela",
        flag: "🇻🇪",
        codeAlpha2: "VE",
        codeAlpha3: "VEN",
        dialCode: "58",
        minLength: 10,
        maxLength: 10,
    },
    {
        name: "Vietnam",
        flag: "🇻🇳",
        codeAlpha2: "VN",
        codeAlpha3: "VNM",
        dialCode: "84",
        minLength: 11,
        maxLength: 11,
    },
    {
        name: "Virgin Islands, British",
        flag: "🇻🇬",
        codeAlpha2: "VG",
        codeAlpha3: "VGB",
        dialCode: "1284",
        minLength: 7,
        maxLength: 7,
    },
    {
        name: "Virgin Islands, U.S.",
        flag: "🇻🇮",
        codeAlpha2: "VI",
        codeAlpha3: "VIR",
        dialCode: "1340",
        minLength: 7,
        maxLength: 7,
    },
    {
        name: "Wallis and Futuna",
        flag: "🇼🇫",
        codeAlpha2: "WF",
        codeAlpha3: "WLF",
        dialCode: "681",
        minLength: 6,
        maxLength: 6,
    },
    {
        name: "Yemen",
        flag: "🇾🇪",
        codeAlpha2: "YE",
        codeAlpha3: "YEM",
        dialCode: "967",
        minLength: 9,
        maxLength: 9,
    },
    {
        name: "Zambia",
        flag: "🇿🇲",
        codeAlpha2: "ZM",
        codeAlpha3: "ZMB",
        dialCode: "260",
        minLength: 9,
        maxLength: 9,
    },
    {
        name: "Zimbabwe",
        flag: "🇿🇼",
        codeAlpha2: "ZW",
        codeAlpha3: "ZWE",
        dialCode: "263",
        minLength: 9,
        maxLength: 9,
    },
    {
        name: "Curacao",
        flag: "🇨🇼",
        codeAlpha2: "CW",
        codeAlpha3: "CUW",
        dialCode: "5999",
        minLength: 0,
        maxLength: 20,
    },
    {
        name: "Sint Maarten",
        flag: "🇸🇽",
        codeAlpha2: "SX",
        codeAlpha3: "SXM",
        dialCode: "1721",
        minLength: 0,
        maxLength: 20,
    },
    {
        name: "Bonaire",
        flag: "🇧🇶",
        codeAlpha2: "BQ",
        codeAlpha3: "BES",
        dialCode: "5997",
        minLength: 0,
        maxLength: 20,
    },
];
