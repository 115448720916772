import { makeAutoObservable } from "mobx";
export class AuthPageStore {
    constructor() {
        this.formVisible = false;
        makeAutoObservable(this, {}, { autoBind: true });
    }
    toggleForm() {
        this.formVisible = !this.formVisible;
    }
}
