import { ButtonBase } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FormAlert } from "../FormAlert";
const sizeToHeight = {
    xxs: "16px",
    xs: "32px",
    s: "40px",
    icon: "46px",
    m: "52px",
    l: "60px",
};
export const sizeIcon = {
    xxs: 12,
    xs: 16,
    s: 24,
    icon: 24,
    m: 24,
    l: 24,
};
export const padding = {
    xxs: "0 6px",
    xs: "0 12px",
    s: "0 25px",
    icon: "0 10px",
    m: "0 40px",
    l: "0 40px",
};
export const paddingWithIcon = {
    xxs: "0 6px",
    xs: "0 8px",
    s: "0 25px",
    icon: "0 10px",
    m: "0 25px",
    l: "0 16px",
};
export const paddingIcon = {
    xxs: "0 2px",
    xs: "0 8px",
    s: "0 8px",
    icon: "0 10px",
    m: "0 12px",
    l: "0 16px",
};
export const StyledButton = styled(ButtonBase, {
    shouldForwardProp: (prop) => !prop.startsWith("$"),
})(({ $size, $width, $minWidth, $padding, theme, }) => ({
    borderRadius: $size === "icon" ? "14px" : "100px",
    height: sizeToHeight[$size],
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
    transition: "background 0.2s ease, opacity 0.2s ease",
    width: $width || "100%",
    minWidth: $minWidth,
    padding: $padding,
    zIndex: 0,
    flexShrink: 0,
    position: "relative",
    "&.ButtonIcon": {
        height: $size === "xxs" ? "16px" : sizeToHeight[$size],
    },
    "&.Button-Default": {
        background: theme.colors["deep-blue-500"],
        "&:hover:not(.Mui-disabled)": {
            background: "#0017E5",
        },
    },
    "&.Button-Primary": {
        background: theme.colors["black-1000"],
        "&:hover:not(.Mui-disabled)": {
            background: "rgba(25, 27, 32, 1)",
        },
    },
    "&.Button-Transparent": {
        background: "transparent",
        "&:hover:not(.Mui-disabled)": {
            background: "#F2F2F2",
        },
    },
    "&.Button-White": {
        background: theme.colors["general-white"],
        "&:hover:not(.Mui-disabled)": {
            background: theme.colors["general-white"],
        },
    },
    "&.Button-Black": {
        background: theme.colors["black-1000"],
        "&:hover:not(.Mui-disabled)": {
            background: theme.colors["black-1200"],
        },
    },
    "&.Button-Gray": {
        background: theme.colors["black-100"],
        "&:hover:not(.Mui-disabled)": {
            background: theme.colors["black-200"],
        },
    },
    "&.Button-Bordered": {
        background: theme.colors["general-white"],
        boxShadow: `inset 0 0 0 1px ${theme.colors["black-200"]}`,
        "&:hover:not(.Mui-disabled)": {
            background: theme.colors["general-white"],
        },
    },
    "&.Button-BorderedGray": {
        background: theme.colors["general-white"],
        boxShadow: `inset 0 0 0 1px ${theme.colors["black-200"]}`,
        "&:hover:not(.Mui-disabled)": {
            background: theme.colors["general-white"],
        },
    },
    "&.Button-BlueBordered": {
        background: theme.colors["general-white"],
        boxShadow: `inset 0 0 0 1px ${theme.colors["deep-blue-500"]}`,
        "&:hover:not(.Mui-disabled)": {
            background: theme.colors["general-white"],
        },
    },
    "&.Button-LightBlue": {
        background: theme.colors["light-blue"],
        "&:hover:not(.Mui-disabled)": {
            background: theme.colors["light-blue"],
        },
    },
    "&.ButtonDisabled": {
        cursor: "not-allowed",
        pointerEvents: "auto",
        background: theme.colors["black-100"],
    },
}));
export const StyledAlert = styled(FormAlert)(() => ({
    position: "absolute",
    bottom: "calc(100% + 10px)",
    boxSizing: "border-box",
    width: "100%",
    zIndex: 2,
    pointerEvents: "none",
}));
