import { styled } from "@mui/material";
import * as React from "react";
import eurSvg from "@/shared/img/iban/eur.svg";
import usdSvg from "@/shared/img/iban/usd.svg";
export const BankIcon = ({ symbol, size = 20, iconUrl, }) => {
    const url = iconUrl !== null && iconUrl !== void 0 ? iconUrl : (() => {
        switch (symbol === null || symbol === void 0 ? void 0 : symbol.toLowerCase()) {
            case "eur":
                return eurSvg;
            case "usd":
                return usdSvg;
            default:
                return null;
        }
    })();
    return (React.createElement(Img, { size: size, url: url }));
};
const Img = styled("div") `
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    border-radius: 50%;
    background-image: ${props => props.url ? `url(${props.url})` : undefined};
    background-size: cover;
    background-color: ${props => props.theme.colors["black-100"]};
    background-repeat: no-repeat;
    flex-shrink: 0;
`;
