import * as React from "react";
import { generatePath, Link } from "react-router-dom";
import { useGetVerificationInfo } from "@/api/registration";
import { VerificationStatus } from "@/api/registration/types";
import { ROUTES } from "@/routes";
import { Box, Typography } from "@/shared/components";
import { Button } from "@/shared/components/Button";
import { useMedia } from "@/shared/hooks/useMedia";
import { useStore } from "@/shared/hooks/useStore";
import verification from "@/shared/img/verification.png";
import { SubscriptionStore } from "@/shared/stores/SubscriptionStore";
export const VerificationBanner = () => {
    const { data } = useGetVerificationInfo();
    const subStore = useStore(SubscriptionStore);
    const status = data === null || data === void 0 ? void 0 : data.status;
    const media = useMedia();
    let title = "Pass verification";
    let description = "Provide your personal and company information";
    let button = "Verify now";
    if (status === VerificationStatus.INIT) {
        button = "Verify";
        title = "Pass verification";
        description = "Provide your personal and company information";
    }
    if (status === VerificationStatus.PENDING) {
        title = "Verification is under review";
        description = "We’ve successfully received your request. Our team will contact you";
    }
    if (status === VerificationStatus.REJECT
        || status === VerificationStatus.RETRY) {
        button = "Resubmit";
        title = "Action needed";
        description = "Some of the documents don't meet our requirements";
    }
    return (React.createElement(Box, { bgColor: "black-1200", borderRadius: "22px", display: "flex", gap: media.phone ? "30px" : "20px", alignItems: "center", justifyContent: "space-between", boxSizing: "border-box", position: "relative", overflow: "hidden", flexDirection: media.phone ? "column" : "row", padding: !media.phone ? "0 40px" : "30px 30px 0" },
        React.createElement(Box, { padding: media.phone ? "0" : "0px", flexDirection: "column", display: "flex", width: "100%", maxWidth: media.phone ? "auto" : "510px" },
            React.createElement(Typography, { type: "text24", color: "general-white" }, title),
            React.createElement(Typography, { color: "black-500", type: "text14", mt: "5px" }, description)),
        React.createElement(Box, { order: media.phone ? 2 : 1, display: "flex" },
            React.createElement("img", { src: verification, width: 180, height: 180, alt: "banner" })),
        !subStore.isPaid && (React.createElement(Button, { order: media.phone ? 1 : 2, variant: "White", width: media.phone ? "100%" : "150px", LinkComponent: Link, to: generatePath(ROUTES.subscription) }, button))));
};
