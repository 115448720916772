/* eslint-disable class-methods-use-this */
const ACCESS_TOKEN_STORAGE_KEY = "accessToken";
const REFRESH_TOKEN_STORAGE_KEY = "refreshToken";
export class TokenStorage {
    constructor() {
        this.callbacks = [];
        window.addEventListener("storage", () => {
            this.callbacks.forEach(cb => cb());
        });
    }
    getAccessToken() {
        try {
            return localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);
        }
        catch (e) {
            console.error(e);
            return null;
        }
    }
    getRefreshToken() {
        try {
            return localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY);
        }
        catch (e) {
            console.error(e);
            return null;
        }
    }
    setTokens(accessToken, refreshToken) {
        if (accessToken || refreshToken) {
            try {
                if (accessToken) {
                    localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, accessToken);
                }
                if (refreshToken) {
                    localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, refreshToken);
                }
                this.callbacks.forEach(cb => cb());
            }
            catch (e) {
                console.error(e);
            }
        }
    }
    clean() {
        try {
            localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
            localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);
            this.callbacks.forEach(cb => cb());
        }
        catch (e) {
            console.error(e);
        }
    }
    subscribe(cb) {
        this.callbacks.push(cb);
        return () => {
            this.callbacks = this.callbacks.filter(item => item !== cb);
        };
    }
}
export const tokenStorage = new TokenStorage();
