var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { makeAutoObservable, runInAction } from "mobx";
import { registrationApi } from "@/api/registration";
export class IndividualListStore {
    constructor() {
        this.ready = false;
        this.loading = false;
        this.list = [];
        makeAutoObservable(this, {}, { autoBind: true });
    }
    init() {
        this.sync();
    }
    dispose() {
        this.ready = false;
        this.loading = false;
        this.list = [];
    }
    sync() {
        return __awaiter(this, void 0, void 0, function* () {
            let list = [];
            let ready = true;
            runInAction(() => {
                this.loading = true;
            });
            try {
                const resp = yield registrationApi.user.getCompanyInfoIndividuals();
                list = resp.data || [];
            }
            catch (e) {
                console.error(e);
                ready = false;
            }
            runInAction(() => {
                this.list = list;
                this.loading = false;
                this.ready = ready;
            });
        });
    }
}
