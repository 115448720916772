var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { makeAutoObservable, runInAction } from "mobx";
import { registrationApi } from "@/api/registration";
import { AuthScope } from "@/shared/stores/AuthStore";
import { TimerStore } from "@/shared/stores/TimerStore";
import { parseApiError, UnknownError } from "@/shared/utils/error";
export var PhoneFormStep;
(function (PhoneFormStep) {
    PhoneFormStep[PhoneFormStep["Form"] = 0] = "Form";
    PhoneFormStep[PhoneFormStep["Code"] = 1] = "Code";
})(PhoneFormStep || (PhoneFormStep = {}));
export class PhoneFormStore {
    constructor(auth) {
        this.auth = auth;
        this.step = PhoneFormStep.Form;
        this.loading = false;
        this.visible = false;
        this.errors = [];
        this.timer = new TimerStore();
        makeAutoObservable(this, {}, { autoBind: true });
    }
    open() {
        return __awaiter(this, void 0, void 0, function* () {
            let visible = false;
            runInAction(() => {
                this.loading = true;
            });
            try {
                yield this.auth.requestScope(AuthScope.ChangePhone);
                visible = true;
            }
            catch (e) {
                console.error(e);
            }
            runInAction(() => {
                this.loading = false;
                this.visible = visible;
            });
        });
    }
    reset() {
        this.step = PhoneFormStep.Form;
        this.loading = false;
        this.visible = false;
        this.errors = [];
    }
    submitPhone(phone) {
        return __awaiter(this, void 0, void 0, function* () {
            let step = PhoneFormStep.Form;
            let errors = [];
            runInAction(() => {
                this.loading = true;
            });
            try {
                yield registrationApi.user.updatePhone({ phone });
                step = PhoneFormStep.Code;
                this.timer.start();
            }
            catch (e) {
                console.error(e);
                errors = parseApiError(e);
            }
            runInAction(() => {
                this.loading = false;
                this.step = step;
                this.errors = errors;
            });
        });
    }
    submitPhoneCode(phone, code) {
        return __awaiter(this, void 0, void 0, function* () {
            let errors = [];
            let visible = true;
            let success = false;
            runInAction(() => {
                this.loading = true;
            });
            try {
                const resp = yield registrationApi.user.updatePhone({ phone, code });
                if (resp.data.confirmed) {
                    yield this.auth.sync();
                    visible = false;
                    success = true;
                }
                else {
                    errors = [UnknownError];
                }
            }
            catch (e) {
                console.error(e);
                errors = parseApiError(e);
            }
            runInAction(() => {
                this.visible = visible;
                this.loading = false;
                this.errors = errors;
            });
            return success;
        });
    }
}
