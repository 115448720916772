import { formattedTokenAmount } from "@broxus/js-utils";
import BigNumber from "bignumber.js";
import { observer } from "mobx-react-lite";
import { useStoreContext } from "@/shared/hooks/useStore";
import { CurrencyListStore } from "@/shared/stores/CurrencyListStore";
import { UserCurrencyStore } from "@/shared/stores/UserCurrencyStore";
export const Amount = observer(({ from, to, amount }) => {
    const { rates } = useStoreContext(CurrencyListStore);
    const userCurrency = useStoreContext(UserCurrencyStore);
    if (from && amount) {
        const toCurrency = (to || userCurrency.selected || "usd").toLowerCase();
        const fromRate = rates[from.toLowerCase()];
        const toRate = rates[toCurrency];
        if ((fromRate === null || fromRate === void 0 ? void 0 : fromRate.eurPrice) && (toRate === null || toRate === void 0 ? void 0 : toRate.eurPrice)) {
            const value = BigNumber(fromRate.eurPrice).multipliedBy(amount).dividedBy(toRate.eurPrice).toFixed();
            return `${formattedTokenAmount(value)} ${toCurrency.toUpperCase()}`;
        }
    }
    return "\u200b";
});
