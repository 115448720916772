import { styled } from "@mui/material";
import * as React from "react";
import { Link } from "react-router-dom";
import { BaseIcon, Typography } from "@/shared/components";
export const SquareBtn = ({ icon, to, onClick, type = "gray", children, }) => {
    const content = (React.createElement(React.Fragment, null,
        React.createElement(Inner, { bgColor: type === "blue" ? "deep-blue-500" : "black-100" },
            React.createElement(BaseIcon, { icon: icon, color: type === "blue" ? "general-white" : "black-1200" })),
        React.createElement(Typography, { type: "text14", color: type === "blue" ? "deep-blue-500" : "black-1200", whiteSpace: "nowrap" }, children)));
    return to
        ? (React.createElement(LinkWrapper, { to: to }, content))
        : (React.createElement(Wrapper, { onClick: onClick }, content));
};
const LinkWrapper = styled(Link) `
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
`;
const Wrapper = styled("button") `
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
`;
const Inner = styled("div") `
    width: 46px;
    height: 46px;
    background: ${props => props.theme.colors[props.bgColor]};
    border-radius: 14px;
    align-items: center;
    justify-content: center;
    display: flex;
`;
