var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { styled } from "@mui/material/styles";
import { borders, compose, display, flexbox, positions, shadows, spacing, } from "@mui/system";
import classNames from "classnames";
import React, { memo } from "react";
import { ContainerIcon } from "./styled";
import { Icons } from "@/shared/icons";
export const ImplBaseIcon = React.forwardRef((_a, ref) => {
    var _b, _c, _d, _e;
    var { icon, size = 20, onClick, disabled = false, color, width, height, containerSize, containerWidth, containerHeight, strokeWidth, hoverColor, hoverOpacity, opacity, rotate, hoverBgColor, borderRadius, bgColor, className } = _a, props = __rest(_a, ["icon", "size", "onClick", "disabled", "color", "width", "height", "containerSize", "containerWidth", "containerHeight", "strokeWidth", "hoverColor", "hoverOpacity", "opacity", "rotate", "hoverBgColor", "borderRadius", "bgColor", "className"]);
    const IconComponent = Icons[icon];
    const children = (React.createElement(IconComponent, { width: `${width || size}px`, height: `${height || size}px` }));
    return onClick
        ? (React.createElement(ContainerIcon, Object.assign({ "data-active": "true", onClick: disabled ? undefined : onClick, "$color": color, "$hoverColor": hoverColor, "$hoverOpacity": hoverOpacity, "$strokeWidth": strokeWidth, "$opacity": opacity, "$hoverBgColor": hoverBgColor, "$rotate": rotate, "$bgColor": bgColor, tabIndex: 0, role: "button", size: containerSize !== null && containerSize !== void 0 ? containerSize : size, "$borderRadius": borderRadius, height: (_b = containerHeight !== null && containerHeight !== void 0 ? containerHeight : containerSize) !== null && _b !== void 0 ? _b : height, width: (_c = containerWidth !== null && containerWidth !== void 0 ? containerWidth : containerSize) !== null && _c !== void 0 ? _c : width, className: classNames(className, "Icon", {
                "Icon-disabled": disabled,
            }), "data-testid": icon }, props, { ref: ref }), children))
        : (React.createElement(ContainerIcon, Object.assign({ "data-testid": icon }, props, { "$borderRadius": borderRadius, className: classNames(className, "Icon"), width: (_d = containerWidth !== null && containerWidth !== void 0 ? containerWidth : containerSize) !== null && _d !== void 0 ? _d : width, "$rotate": rotate, "$hoverColor": hoverColor, "$color": color, "$opacity": opacity, "$strokeWidth": strokeWidth, "$bgColor": bgColor, height: (_e = containerHeight !== null && containerHeight !== void 0 ? containerHeight : containerSize) !== null && _e !== void 0 ? _e : height, size: containerSize !== null && containerSize !== void 0 ? containerSize : size, ref: ref }), children));
});
export const BaseIcon = memo(styled(ImplBaseIcon, {
    shouldForwardProp: (prop) => !compose(spacing, positions, flexbox, display)
        .filterProps.includes(prop),
})(compose(spacing, positions, flexbox, display, borders, shadows)));
