import { observer } from "mobx-react-lite";
import * as React from "react";
import { generatePath, Navigate, Route, Routes } from "react-router-dom";
import { TwoFaModal } from "@/features/2fa-modal";
import { CreateRecipientModal } from "@/features/create-recipient";
import { SignTermsModal } from "@/features/sign-terms-modal";
import { AuthPage } from "@/pages/AuthPage";
import { BankAccountsPage } from "@/pages/BankAccountsPage";
import { BankAccountPage } from "@/pages/BankAccountsPage/BankAccountPage";
import { FailedCardPage } from "@/pages/CheckoutCardPage/FailedPage";
import { SuccessCardPage } from "@/pages/CheckoutCardPage/SuccessPage";
import { CompanyInfoPage } from "@/pages/CompanyInfoPage";
import { DepositPage } from "@/pages/DepositPage";
import { ExchangePage } from "@/pages/ExchangePage";
import { FeesPage } from "@/pages/FeesPage";
import { HomePage } from "@/pages/HomePage";
import { KYBPage } from "@/pages/KYBPage";
import { KYBFormIdPage } from "@/pages/KYBPage/KYBFormIdPage";
import { KYBFormPage } from "@/pages/KYBPage/KYBFormPage";
import { PasswordResetPage } from "@/pages/PasswordResetPage";
import { PaymentPage } from "@/pages/PaymentPage";
import { PhonePage } from "@/pages/PhonePage";
import { ProfilePage } from "@/pages/ProfilePage";
import { RecipientsPage } from "@/pages/RecipientsPage";
import { SigninPage } from "@/pages/SigninPage";
import { SignupPage } from "@/pages/SignupPage";
import { SubscriptionPage } from "@/pages/SubscriptionPage";
import { TransactionPage } from "@/pages/TransactionPage";
import { TransferPage } from "@/pages/TransferPage";
import { UserPage } from "@/pages/UserPage";
import { WalletsPage } from "@/pages/WalletsPage";
import { WalletPage } from "@/pages/WalletsPage/WalletPage";
import { ROUTES } from "@/routes";
import { useStoreContext } from "@/shared/hooks/useStore";
import { AuthStore } from "@/shared/stores/AuthStore";
export const RootInner = observer(() => {
    const auth = useStoreContext(AuthStore);
    return (React.createElement(React.Fragment, null,
        auth.initialized
            && (auth.authorized
                ? (React.createElement(Routes, null,
                    React.createElement(Route, { path: ROUTES.root, element: React.createElement(UserPage, null) },
                        React.createElement(Route, { path: ROUTES.root, element: React.createElement(HomePage, null) }),
                        React.createElement(Route, { path: ROUTES.transactions, element: React.createElement(TransactionPage, null) }),
                        React.createElement(Route, { path: ROUTES.company, element: React.createElement(CompanyInfoPage, null) }),
                        React.createElement(Route, { path: ROUTES.profile, element: React.createElement(ProfilePage, null) }),
                        React.createElement(Route, { path: ROUTES.checkoutSuccessCard, element: React.createElement(SuccessCardPage, null) }),
                        React.createElement(Route, { path: ROUTES.checkoutFailedCard, element: React.createElement(FailedCardPage, null) }),
                        React.createElement(Route, { path: ROUTES.walletList, element: React.createElement(WalletsPage, null) }),
                        React.createElement(Route, { path: ROUTES.walletItem, element: React.createElement(WalletPage, null) }),
                        React.createElement(Route, { path: ROUTES.recipients, element: React.createElement(RecipientsPage, null) }),
                        React.createElement(Route, { path: ROUTES.bankAccountsList, element: React.createElement(BankAccountsPage, null) }),
                        React.createElement(Route, { path: ROUTES.bankAccountsItem, element: React.createElement(BankAccountPage, null) }),
                        React.createElement(Route, { path: ROUTES.subscription, element: React.createElement(SubscriptionPage, null) })),
                    React.createElement(Route, { path: ROUTES.kyb, element: React.createElement(KYBPage, null) },
                        React.createElement(Route, { path: ROUTES.kyb, element: React.createElement(Navigate, { replace: true, to: generatePath(ROUTES.kybForm) }) }),
                        React.createElement(Route, { path: ROUTES.kybForm, element: React.createElement(KYBFormPage, null) }),
                        React.createElement(Route, { path: ROUTES.kybFormId, element: React.createElement(KYBFormIdPage, null) })),
                    React.createElement(Route, { path: ROUTES.payment, element: React.createElement(PaymentPage, null) }),
                    React.createElement(Route, { path: ROUTES.deposit, element: React.createElement(DepositPage, null) }),
                    React.createElement(Route, { path: ROUTES.transfer, element: React.createElement(TransferPage, null) }),
                    React.createElement(Route, { path: ROUTES.exchange, element: React.createElement(ExchangePage, null) }),
                    React.createElement(Route, { path: "*", element: React.createElement(Navigate, { to: generatePath(ROUTES.root) }) })))
                : (React.createElement(Routes, null,
                    React.createElement(Route, { path: ROUTES.root, element: React.createElement(AuthPage, null) },
                        React.createElement(Route, { path: ROUTES.root, element: auth.userInfo && !auth.userInfo.phone ? React.createElement(PhonePage, null) : React.createElement(SignupPage, null) }),
                        React.createElement(Route, { path: ROUTES.signin, element: auth.userInfo && !auth.userInfo.phone ? React.createElement(PhonePage, null) : React.createElement(SigninPage, null) }),
                        React.createElement(Route, { path: ROUTES.passwordReset, element: React.createElement(PasswordResetPage, null) }),
                        React.createElement(Route, { path: "*", element: React.createElement(Navigate, { to: generatePath(ROUTES.root) }) })),
                    React.createElement(Route, { path: ROUTES.feesPolicy, element: React.createElement(FeesPage, null) })))),
        React.createElement(TwoFaModal, null),
        React.createElement(CreateRecipientModal, null),
        React.createElement(SignTermsModal, null)));
});
