var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { styled } from "@mui/material/styles";
import { compose, flexbox, spacing } from "@mui/system";
import React, { memo } from "react";
import { Checkbox } from "../Checkbox";
import { Typography } from "../Typography";
import { StyledContainer } from "./styled";
export const ImplFormControlCheckbox = React.forwardRef((_a, ref) => {
    var { label, className, colorText, text, lineClamp, disabled, marginBottom } = _a, props = __rest(_a, ["label", "className", "colorText", "text", "lineClamp", "disabled", "marginBottom"]);
    return (React.createElement(StyledContainer, { className: className, disabled: disabled },
        React.createElement(Checkbox, Object.assign({ inputRef: ref }, props, { disabled: disabled })),
        typeof label === "string"
            ? (React.createElement(Typography, { type: text || "text16", color: colorText || "black-1200", lineClamp: lineClamp }, label))
            : label));
});
export const FormControlCheckbox = memo(styled(ImplFormControlCheckbox)(compose(spacing, flexbox)));
