import { observer } from "mobx-react-lite";
import React from "react";
import { SettingsEmail } from "./components/Email";
import { SettingsNumber } from "./components/Number";
import { SettingsPassword } from "./components/Password";
import { Settings2FA } from "./components/TwoFA";
import { EmailFormStore } from "@/pages/ProfilePage/stores/EmailFormStore";
import { PasswordFormStore } from "@/pages/ProfilePage/stores/PasswordFormStore";
import { PhoneFormStore } from "@/pages/ProfilePage/stores/PhoneFormStore";
import { Box, Typography } from "@/shared/components";
import { useMedia } from "@/shared/hooks/useMedia";
import { useProvider, useStore, useStoreContext } from "@/shared/hooks/useStore";
import { AuthStore } from "@/shared/stores/AuthStore";
export const ProfilePage = observer(() => {
    const auth = useStoreContext(AuthStore);
    const phoneForm = useStore(PhoneFormStore, auth);
    const PhoneFormProvider = useProvider(PhoneFormStore);
    const emailForm = useStore(EmailFormStore, auth);
    const EmailFormProvider = useProvider(EmailFormStore);
    const passwordForm = useStore(PasswordFormStore, auth);
    const PasswordFormProvider = useProvider(PasswordFormStore);
    const { tablet } = useMedia();
    return (React.createElement(Box, { display: "flex", gap: "40px", mt: "20px", flexDirection: "column" },
        React.createElement(Typography, { type: "text32Bold" }, "Profile settings"),
        React.createElement(Box, { mb: "20px", bgColor: "general-white", borderRadius: "22px", padding: tablet ? "20px" : "60px", gap: "30px", display: "flex", flexDirection: "column" },
            React.createElement(EmailFormProvider, { value: emailForm },
                React.createElement(SettingsEmail, null)),
            React.createElement(Box, { width: "100%", height: "1px", bgColor: "black-100" }),
            React.createElement(PhoneFormProvider, { value: phoneForm },
                React.createElement(SettingsNumber, null)),
            React.createElement(Box, { width: "100%", height: "1px", bgColor: "black-100" }),
            React.createElement(PasswordFormProvider, { value: passwordForm },
                React.createElement(SettingsPassword, null)),
            React.createElement(Box, { width: "100%", height: "1px", bgColor: "black-100" }),
            React.createElement(Settings2FA, null))));
});
