var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { BaseIcon } from "../BaseIcon";
import { StyledDialog } from "./styled";
export const Modal = (_a) => {
    var { children, isMobileFull, showCloseButton = true, bgColor = "general-white", disablePadding } = _a, props = __rest(_a, ["children", "isMobileFull", "showCloseButton", "bgColor", "disablePadding"]);
    return (React.createElement(StyledDialog, Object.assign({ container: document.getElementById("root"), "$bgColor": bgColor, "$disablePadding": disablePadding, "$isMobileFull": isMobileFull, transitionDuration: props.open ? undefined : 0 }, props),
        showCloseButton && (React.createElement(BaseIcon, { icon: "Close", position: "absolute", top: "25px", right: "25px", bgColor: "black-50", padding: "0", size: 45, borderRadius: "12px", onClick: (e) => { var _a; return (_a = props.onClose) === null || _a === void 0 ? void 0 : _a.call(props, e, "backdropClick"); } })),
        children));
};
