var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { makeAutoObservable, runInAction } from "mobx";
import { registrationApi } from "@/api/registration";
import { download } from "@/shared/utils/download";
import { parseApiError } from "@/shared/utils/error";
export class UploadStore {
    constructor(verification) {
        this.verification = verification;
        this.loading = false;
        this.errors = [];
        makeAutoObservable(this, {}, { autoBind: true });
    }
    setErrors(value) {
        this.errors = value;
    }
    upload(kycId, file, type, subType) {
        return __awaiter(this, void 0, void 0, function* () {
            let errors = [];
            runInAction(() => {
                this.loading = true;
            });
            try {
                const request = {
                    type,
                    subType,
                };
                yield registrationApi.verification.uploadDocument(kycId, {
                    file,
                    request: new Blob([JSON.stringify(request)], {
                        type: "application/json",
                    }),
                });
                yield this.verification.sync();
            }
            catch (e) {
                console.error(e);
                errors = parseApiError(e);
            }
            runInAction(() => {
                this.errors = errors;
                this.loading = false;
            });
        });
    }
    download(kycId, docId, filename) {
        return __awaiter(this, void 0, void 0, function* () {
            let errors = [];
            runInAction(() => {
                this.loading = true;
            });
            try {
                const resp = yield registrationApi.verification.getDocument(kycId, docId, {
                    format: "blob",
                });
                download(resp.data, filename !== null && filename !== void 0 ? filename : docId);
            }
            catch (e) {
                console.error(e);
                errors = parseApiError(e);
            }
            runInAction(() => {
                this.loading = false;
                this.errors = errors;
            });
        });
    }
    delete(kycId, docId) {
        return __awaiter(this, void 0, void 0, function* () {
            let errors = [];
            runInAction(() => {
                this.loading = true;
            });
            try {
                yield registrationApi.verification.deleteDocument(kycId, docId);
                yield this.verification.sync();
            }
            catch (e) {
                console.error(e);
                errors = parseApiError(e);
            }
            runInAction(() => {
                this.loading = false;
                this.errors = errors;
            });
        });
    }
}
