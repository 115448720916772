import { observer } from "mobx-react-lite";
import * as React from "react";
import { generatePath, Outlet, useNavigate } from "react-router-dom";
import { VerificationStatus } from "@/api/registration/types";
import { BackBtn } from "@/pages/KYBPage/components/BackBtn";
import { ExitPopup } from "@/pages/KYBPage/components/ExitPopup";
import { CompanyVerificationStore } from "@/pages/KYBPage/stores/CompanyVerificationStore";
import { IndividualListStore } from "@/pages/KYBPage/stores/IndividualListStore";
import { ROUTES } from "@/routes";
import { Box } from "@/shared/components";
import { useMedia } from "@/shared/hooks/useMedia";
import { useProvider, useStore } from "@/shared/hooks/useStore";
import { useToggler } from "@/shared/hooks/useToggler";
import { CompanyInfoStore } from "@/shared/stores/CompanyInfoStore";
import { SubscriptionStore } from "@/shared/stores/SubscriptionStore";
import { UnderReviewPopup } from "@/widgets/UnderReviewPopup";
export const KYBPage = observer(() => {
    var _a;
    const media = useMedia();
    const companyInfo = useStore(CompanyInfoStore);
    const verification = useStore(CompanyVerificationStore);
    const individualList = useStore(IndividualListStore);
    const CompanyInfoProvider = useProvider(CompanyInfoStore);
    const CompanyVerificationProvider = useProvider(CompanyVerificationStore);
    const IndividualListProvider = useProvider(IndividualListStore);
    const subscription = useStore(SubscriptionStore);
    const SubscriptionProvider = useProvider(SubscriptionStore);
    const navigate = useNavigate();
    const backPopup = useToggler();
    const back = () => navigate(generatePath(ROUTES.root));
    React.useEffect(() => {
        if (subscription.ready) {
            if (!subscription.id) {
                navigate(generatePath(ROUTES.company));
            }
        }
    }, [subscription.ready, subscription.id, navigate]);
    return (React.createElement(SubscriptionProvider, { value: subscription },
        React.createElement(CompanyInfoProvider, { value: companyInfo },
            React.createElement(IndividualListProvider, { value: individualList },
                React.createElement(CompanyVerificationProvider, { value: verification },
                    backPopup.enabled && (React.createElement(ExitPopup, { onBack: back, onClose: backPopup.off })),
                    ((_a = verification.data) === null || _a === void 0 ? void 0 : _a.status) === VerificationStatus.PENDING && React.createElement(UnderReviewPopup, null),
                    React.createElement(Box, { minHeight: "100vh", bgColor: "general-white", position: "relative" },
                        React.createElement(Box, { paddingLeft: "max(20px, calc((100% - 1240px) / 2))", paddingRight: "max(20px, calc((100% - 1240px) / 2))", paddingTop: media.desktop ? "50px" : "20px", paddingBottom: media.desktop ? "50px" : "20px", margin: "0 auto" },
                            React.createElement(Box, { position: "relative", zIndex: "0" },
                                React.createElement(BackBtn, { onClick: backPopup.on }),
                                React.createElement(Outlet, null)))))))));
});
