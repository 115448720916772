var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useQueryClient } from "@tanstack/react-query";
import React, { useLayoutEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { bankFields, RECIPIENT_FIELDS_CONFIG } from "../../constants/fields";
import { CreateRecipientStore } from "../../store/CreateRecipientStore";
import { CounterpartyRailType, CounterpartyType } from "@/api/wallet/types";
import { useCreateRecipient } from "@/api/wallet/useCreateRecipient";
import { IntermediaryInfo } from "@/features/create-recipient/components/Form/IntermediaryInfo";
import { Box, Button, FormControlInput, Typography } from "@/shared/components";
import { FormControlSelect } from "@/shared/components/FormControlSelect";
import { Tab, Tabs, TabsList } from "@/shared/components/Tabs";
import counterpartyCountries from "@/shared/constants/counterpartyCountries.json";
import { countries } from "@/shared/constants/countries";
import ibanCountries from "@/shared/constants/countriesIBAN.json";
import { useMedia } from "@/shared/hooks/useMedia";
import { useStoreContext } from "@/shared/hooks/useStore";
import { CurrencyListStore } from "@/shared/stores/CurrencyListStore";
import { RecipientListStore } from "@/shared/stores/RecipientListStore";
import { getCountryStates } from "@/shared/utils/country";
import { RequiredFieldMessage } from "@/shared/utils/error";
export const CreateRecipientForm = () => {
    var _a, _b, _c, _d, _e, _f;
    const recipientList = useStoreContext(RecipientListStore);
    const { setIsOpen } = useStoreContext(CreateRecipientStore);
    const { control, handleSubmit } = useForm();
    const [tab, setTab] = useState(null);
    const [type, setType] = useState(CounterpartyType.FIAT);
    const currencyList = useStoreContext(CurrencyListStore);
    const media = useMedia();
    const queryClient = useQueryClient();
    const { mutateAsync, isPending } = useCreateRecipient({
        onSuccess: () => __awaiter(void 0, void 0, void 0, function* () {
            yield recipientList.sync();
            setIsOpen(false);
            queryClient.invalidateQueries({
                queryKey: ["walletApi.counterparty.getCounterparties"],
            });
        }),
    });
    const countryCurrencies = React.useMemo(() => (Object.entries(counterpartyCountries)
        .map(([key, value]) => {
        var _a;
        return ({
            codeAlpha2: key,
            codeAlpha3: (_a = countries.find(item => item.codeAlpha2 === key)) === null || _a === void 0 ? void 0 : _a.codeAlpha3,
            currencies: Object.entries(value).map(([currKey, currVal]) => ({
                slug: currKey,
                type: currVal,
            })),
        });
    })
        .filter(item => !!item.codeAlpha3)), []);
    const profileCountry = useWatch({ control, name: "profile.address.country" });
    const selectedCountry = useWatch({ control, name: "institutionAddress.country" });
    const profileStates = React.useMemo(() => (profileCountry ? getCountryStates(profileCountry) : []), [profileCountry]);
    const institutionStates = React.useMemo(() => (selectedCountry ? getCountryStates(selectedCountry) : []), [selectedCountry]);
    const profileType = useWatch({ control, name: "profile.profileType" });
    const visibleCountry = selectedCountry !== null && selectedCountry !== void 0 ? selectedCountry : "";
    const currentCountryCurrencies = countryCurrencies.find(item => item.codeAlpha3 === visibleCountry);
    const avaliableCurrencies = currentCountryCurrencies === null || currentCountryCurrencies === void 0 ? void 0 : currentCountryCurrencies.currencies.map(item => item.slug.toLowerCase());
    const currencies = (_a = currencyList.list) === null || _a === void 0 ? void 0 : _a.filter(item => item.type === "FIAT" && item.slug && avaliableCurrencies
        && avaliableCurrencies.includes(item.slug.toLowerCase()));
    const countryAlpha2 = (_b = countries.find(item => item.codeAlpha3 === selectedCountry)) === null || _b === void 0 ? void 0 : _b.codeAlpha2;
    const isIbanCountry = !!ibanCountries.find(item => item === countryAlpha2);
    const blockchain = useWatch({ control, name: "blockchain" });
    const selectedCurrency = useWatch({ control, name: "currency" });
    const visibleCurrency = selectedCurrency;
    const paymentTypes = currentCountryCurrencies
        ? (_c = currentCountryCurrencies.currencies.find(item => item.slug.toLowerCase() === (visibleCurrency === null || visibleCurrency === void 0 ? void 0 : visibleCurrency.toLowerCase()))) === null || _c === void 0 ? void 0 : _c.type
        : undefined;
    console.log(paymentTypes, '__paymentTypes');
    const blockchainList = selectedCurrency
        ? (_f = ((_e = (_d = currencyList.bySlug[selectedCurrency]) === null || _d === void 0 ? void 0 : _d.tokens) === null || _e === void 0 ? void 0 : _e.filter(item => item.blockchain).map(item => item.blockchain))) !== null && _f !== void 0 ? _f : []
        : [];
    const selectedTab = tab !== null && tab !== void 0 ? tab : paymentTypes === null || paymentTypes === void 0 ? void 0 : paymentTypes.at(0);
    const recipientFields = RECIPIENT_FIELDS_CONFIG;
    const bankAccountFields = selectedTab ? bankFields[selectedTab] : [];
    const onSubmit = (data) => __awaiter(void 0, void 0, void 0, function* () {
        if (type === CounterpartyType.CRYPTO) {
            mutateAsync(Object.assign(Object.assign({}, data), { type: CounterpartyType.CRYPTO, rail: CounterpartyRailType.CRYPTO, disabled: false }));
        }
        else {
            mutateAsync(Object.assign(Object.assign({}, data), { type, rail: selectedTab !== "SWIFT_IBAN" ? selectedTab : "SWIFT", disabled: false }));
        }
    });
    useLayoutEffect(() => {
        setTab(null);
    }, [selectedCountry]);
    useLayoutEffect(() => {
        setTab(null);
    }, [selectedCountry, selectedCurrency]);
    useLayoutEffect(() => {
        setType(CounterpartyType.FIAT);
    }, []);
    return (React.createElement(Box, { width: "100%", mb: media.tablet ? "40px" : "100px" },
        React.createElement(Box, { display: "grid", gridTemplateColumns: media.tablet ? "1fr" : "1fr 1fr", gap: "40px" },
            React.createElement(Typography, { type: "text32Bold", gridColumn: "1/-1" }, "Enter recipient's details"), recipientFields === null || recipientFields === void 0 ? void 0 :
            recipientFields.map((item) => {
                let field = null;
                const isProfileFieldHidden = profileType === "INDIVIDUAL" && item.field === "profile.lineOfBusiness";
                if (isProfileFieldHidden)
                    return null;
                if (item.type === "input") {
                    const isRequired = item.field !== "profile.address.subStreet"
                        && item.field !== "profile.taxReferenceNumber";
                    field = (React.createElement(Controller, { rules: {
                            required: isRequired
                                ? {
                                    value: true,
                                    message: RequiredFieldMessage.description,
                                }
                                : undefined,
                            // pattern: item.regex,
                            validate: item.validate,
                        }, control: control, name: item.field, render: ({ field, fieldState: { error } }) => (React.createElement(FormControlInput, Object.assign({ disabled: isPending, error: error === null || error === void 0 ? void 0 : error.message, label: item.label, InputProps: !isRequired
                                ? {
                                    endAdornment: (React.createElement(Typography, { type: "text12", color: "black-500" }, "Optional")),
                                }
                                : undefined }, field))) }));
                }
                if (item.type === "select") {
                    field = (React.createElement(Controller, { rules: {
                            required: {
                                value: true,
                                message: RequiredFieldMessage.description,
                            },
                        }, control: control, name: item.field, render: ({ field, fieldState: { error } }) => {
                            var _a;
                            return (React.createElement(FormControlSelect, Object.assign({}, field, { disabled: isPending, error: error === null || error === void 0 ? void 0 : error.message, onChange: (_, v) => field.onChange(v === null || v === void 0 ? void 0 : v.key), label: item.label, options: (_a = (item.options === "profileState" ? profileStates : item.options)) !== null && _a !== void 0 ? _a : [] })));
                        } }));
                }
                return React.createElement(Box, { key: item.label, gridColumn: item.grid }, field);
            })),
        React.createElement(Box, { mt: "60px", mb: "60px", width: "100%", height: "1px", bgColor: "black-100" }),
        React.createElement(Box, { display: "flex", flexDirection: "column", gap: "40px" },
            React.createElement(Typography, { type: "text32Bold", gridColumn: "1/-1" }, "Select recipient type"),
            React.createElement(FormControlSelect, { value: type, options: [
                    {
                        key: CounterpartyType.FIAT,
                        label: "Fiat",
                    },
                    {
                        key: CounterpartyType.CRYPTO,
                        label: "Crypto",
                    },
                ], disabled: isPending, onChange: (_, v) => {
                    setType(v
                        ? v.key
                        : CounterpartyType.FIAT);
                }, label: "Recipient type" })),
        React.createElement(Box, { mt: "60px", mb: "60px", width: "100%", height: "1px", bgColor: "black-100" }),
        type === CounterpartyType.CRYPTO
            ? (React.createElement(Box, { display: "flex", flexDirection: "column", gap: "40px" },
                React.createElement(Typography, { type: "text32Bold", gridColumn: "1/-1" }, "Enter blockchain details"),
                React.createElement(Controller, { control: control, rules: {
                        required: {
                            value: true,
                            message: RequiredFieldMessage.description,
                        },
                    }, name: "currency", render: ({ field, fieldState: { error } }) => (React.createElement(FormControlSelect, Object.assign({}, field, { options: currencyList.list
                            .filter(item => item.type === "CRYPTO")
                            .map((item) => ({
                            key: item.slug.toLowerCase(),
                            label: item.shortName.toUpperCase(),
                        })), disabled: isPending, error: error === null || error === void 0 ? void 0 : error.message, onChange: (_, v) => {
                            field.onChange(v === null || v === void 0 ? void 0 : v.key);
                        }, label: "Currency" }))) }),
                React.createElement(Controller, { control: control, rules: {
                        required: {
                            value: true,
                            message: RequiredFieldMessage.description,
                        },
                    }, name: "blockchain", render: ({ field, fieldState: { error } }) => (React.createElement(FormControlSelect, Object.assign({}, field, { options: blockchainList.map(item => ({
                            key: item.slug.toLowerCase(),
                            label: item.name.toUpperCase(),
                        })), disabled: isPending || !selectedCurrency, error: error === null || error === void 0 ? void 0 : error.message, onChange: (_, v) => {
                            field.onChange(v === null || v === void 0 ? void 0 : v.key);
                        }, label: "Blockchain" }))) }),
                React.createElement(Controller, { rules: {
                        required: {
                            value: true,
                            message: RequiredFieldMessage.description,
                        },
                    }, control: control, name: "blockchainAddress", render: ({ field, fieldState: { error } }) => (React.createElement(FormControlInput, Object.assign({ disabled: isPending, error: error === null || error === void 0 ? void 0 : error.message, label: "Blockchain address" }, field))) }),
                React.createElement(Controller, { control: control, rules: {
                        required: {
                            value: true,
                            message: RequiredFieldMessage.description,
                        },
                    }, name: "walletType", render: ({ field, fieldState: { error } }) => (React.createElement(FormControlSelect, Object.assign({}, field, { options: [
                            {
                                key: "INSTITUTION",
                                label: "Institution",
                            },
                            {
                                key: "OTHER",
                                label: "Other",
                            },
                            {
                                key: "UNKNOWN",
                                label: "Unknown",
                            },
                        ], disabled: isPending || !blockchain, error: error === null || error === void 0 ? void 0 : error.message, onChange: (_, v) => {
                            field.onChange(v === null || v === void 0 ? void 0 : v.key);
                        }, label: "Wallet type" }))) }),
                React.createElement(Controller, { rules: {
                        required: {
                            value: true,
                            message: RequiredFieldMessage.description,
                        },
                    }, control: control, name: "institutionName", render: ({ field, fieldState: { error } }) => (React.createElement(FormControlInput, Object.assign({ disabled: isPending, error: error === null || error === void 0 ? void 0 : error.message, label: "Institution name" }, field))) }),
                React.createElement(Controller, { control: control, name: "description", render: ({ field, fieldState: { error } }) => (React.createElement(FormControlInput, Object.assign({ disabled: isPending, error: error === null || error === void 0 ? void 0 : error.message, label: "Description" }, field))) })))
            : (React.createElement(React.Fragment, null,
                React.createElement(Box, { display: "flex", flexDirection: "column", gap: "40px" },
                    React.createElement(Typography, { type: "text32Bold", gridColumn: "1/-1" }, "Select bank account country"),
                    React.createElement(Controller, { control: control, rules: {
                            required: {
                                value: true,
                                message: RequiredFieldMessage.description,
                            },
                        }, name: "institutionAddress.country", render: ({ field, fieldState: { error } }) => (React.createElement(FormControlSelect, Object.assign({}, field, { options: countries
                                .filter(item => Object.keys(counterpartyCountries).includes(item.codeAlpha2))
                                .map((item) => ({
                                key: item.codeAlpha3,
                                label: `${item.flag} ${item.name}`,
                            })), disabled: isPending, error: error === null || error === void 0 ? void 0 : error.message, onChange: (_, v) => {
                                field.onChange(v === null || v === void 0 ? void 0 : v.key);
                            }, label: "Country" }))) }),
                    React.createElement(Controller, { rules: {
                            required: {
                                value: true,
                                message: RequiredFieldMessage.description,
                            },
                        }, control: control, name: "currency", render: ({ field, fieldState: { error } }) => (React.createElement(FormControlSelect, Object.assign({}, field, { error: error === null || error === void 0 ? void 0 : error.message, disabled: isPending || !selectedCountry, options: (currencies === null || currencies === void 0 ? void 0 : currencies.map((item) => {
                                var _a;
                                return ({
                                    key: item.slug,
                                    label: (_a = item.slug) === null || _a === void 0 ? void 0 : _a.toUpperCase(),
                                });
                            })) || [], onChange: (_, v) => field.onChange(v === null || v === void 0 ? void 0 : v.key), label: "Currency" }))) })),
                React.createElement(Box, { mt: "60px", mb: "60px", width: "100%", height: "1px", bgColor: "black-100" }),
                React.createElement(Box, { display: "grid", gridTemplateColumns: "1fr 1fr", gap: "40px" }, paymentTypes
                    ? (React.createElement(React.Fragment, null,
                        React.createElement(Typography, { type: "text32Bold", gridColumn: "1/-1" }, "Enter bank account details"),
                        React.createElement(Box, null,
                            React.createElement(Tabs, { value: selectedTab, onChange: (_, value) => setTab(value) },
                                React.createElement(TabsList, null, paymentTypes === null || paymentTypes === void 0 ? void 0 : paymentTypes.map(item => (React.createElement(Tab, { value: item, key: item }, (() => {
                                    switch (item) {
                                        case "SWIFT_IBAN":
                                            return "SWIFT";
                                        case "FEDWIRE":
                                            return "Fedwire";
                                        default:
                                            return item;
                                    }
                                })())))))),
                        bankAccountFields.map((item) => {
                            let field = null;
                            if (item.type === "input") {
                                const isRequired = item.field !== "profile.address.subStreet";
                                const isAccountNumber = item.field === "accountNumber";
                                const label = isAccountNumber && isIbanCountry
                                    && tab === CounterpartyRailType.SWIFT
                                    ? "IBAN"
                                    : item.label;
                                field = (React.createElement(Controller, { rules: {
                                        required: isRequired
                                            ? {
                                                value: true,
                                                message: RequiredFieldMessage.description,
                                            }
                                            : undefined,
                                        validate: "validate" in item ? item.validate : undefined,
                                    }, control: control, name: item.field, render: ({ field, fieldState: { error }, }) => (React.createElement(FormControlInput, Object.assign({ disabled: isPending, error: error === null || error === void 0 ? void 0 : error.message, label: label }, field))) }));
                                return React.createElement(Box, { key: item.label, gridColumn: item.grid }, field);
                            }
                            if (item.type === "institutionState") {
                                return (React.createElement(Box, { key: item.label, gridColumn: item.grid },
                                    React.createElement(Controller, { rules: {
                                            required: {
                                                value: true,
                                                message: RequiredFieldMessage.description,
                                            },
                                        }, control: control, name: item.field, render: ({ field, fieldState: { error } }) => (React.createElement(FormControlSelect, Object.assign({}, field, { disabled: isPending, error: error === null || error === void 0 ? void 0 : error.message, onChange: (_, v) => field.onChange(v === null || v === void 0 ? void 0 : v.key), label: item.label, options: institutionStates }))) })));
                            }
                            if (item.type === "intermediaryInfo") {
                                return (React.createElement(IntermediaryInfo, { key: "intermediaryInfo", control: control }));
                            }
                            return null;
                        })))
                    : null))),
        React.createElement(Button, { width: "250px", mt: "100px", onClick: handleSubmit(onSubmit), disabled: isPending }, "Save")));
};
