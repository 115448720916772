import React from "react";
import { BaseIcon } from "../BaseIcon";
import { Box } from "../Box";
import { Typography } from "../Typography";
const color = {
    "error": "general-red",
    "info": "black-600",
    "success": "general-green",
    "warning": "general-yellow",
    "default": "black-600",
};
export const Toast = ({ text, type, closeToast }) => (React.createElement(Box, { position: "absolute", top: "0", left: "0", bgColor: color[type], width: "100%", display: "grid", gridTemplateColumns: "20px 1fr 20px", gap: "20px", padding: "16px 20px", boxSizing: "border-box" },
    React.createElement("span", null),
    React.createElement(Typography, { color: "general-white", textAlign: "center" }, text),
    React.createElement(BaseIcon, { icon: "AlertClose", size: 28, onClick: closeToast, color: "general-white-transparent-15" })));
