var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { action, runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { WalletFormStore } from "../../store/CreateWalletStore";
import { Button } from "@/shared/components";
import { FormAlert } from "@/shared/components/FormAlert";
import { FormControlSelect } from "@/shared/components/FormControlSelect";
import { Popup } from "@/shared/components/Popup";
import { PopupBody } from "@/shared/components/Popup/Body";
import { PopupContent } from "@/shared/components/Popup/Content";
import { PopupHead } from "@/shared/components/Popup/Head";
import { PopupTitle } from "@/shared/components/Popup/Title";
import { TokenSearch } from "@/shared/components/TokenSearch";
import { useProvider, useStore, useStoreContext } from "@/shared/hooks/useStore";
import { CurrencyListStore } from "@/shared/stores/CurrencyListStore";
import { EventsStore } from "@/shared/stores/EventsStore";
import { preventDefault } from "@/shared/utils/prevent";
// TODO: Show errors
export const CreateWalletPopup = observer(({ onClose, onSuccess }) => {
    var _a, _b;
    const events = useStoreContext(EventsStore);
    const form = useStore(WalletFormStore, events);
    const WalletFormProvider = useProvider(WalletFormStore);
    const currencyList = useStoreContext(CurrencyListStore);
    const list = currencyList.list.filter((item) => { var _a; return item.shortName && ((_a = item.type) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === "crypto"; });
    const networks = (_b = (_a = currencyList.list.find((item) => { var _a; return ((_a = item.shortName) === null || _a === void 0 ? void 0 : _a.toString()) === form.tokenId; })) === null || _a === void 0 ? void 0 : _a.tokens) !== null && _b !== void 0 ? _b : [];
    const onSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        const id = yield form.submit();
        if (id)
            onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(id);
    });
    return (React.createElement(WalletFormProvider, { value: form },
        React.createElement(Popup, { width: "450px", onClose: onClose },
            React.createElement(PopupHead, { onClose: onClose }, "\u200b"),
            React.createElement("form", { onSubmit: preventDefault(onSubmit) },
                React.createElement(PopupBody, null,
                    React.createElement(PopupTitle, null, "Create digital wallet"),
                    React.createElement(PopupContent, null,
                        React.createElement(TokenSearch, { label: "Coin", disabled: form.loading || !!form.walletId, value: form.tokenId, onChange: action((key) => {
                                form.tokenId = key;
                            }), tokens: list.map((item) => ({
                                id: item.shortName,
                                name: item.description,
                                symbol: item.shortName,
                                iconUrl: item.iconUrl,
                            })) }),
                        React.createElement(FormControlSelect, { label: "Network", disabled: !form.tokenId || form.loading || !!form.walletId, value: form.networkId, onChange: (_, o) => {
                                runInAction(() => {
                                    form.networkId = o === null || o === void 0 ? void 0 : o.key;
                                });
                            }, options: networks.map((item) => {
                                var _a, _b, _c, _d;
                                return ({
                                    key: (_b = (_a = item.blockchain) === null || _a === void 0 ? void 0 : _a.slug) === null || _b === void 0 ? void 0 : _b.toString(),
                                    label: (_d = (_c = item.blockchain) === null || _c === void 0 ? void 0 : _c.name) !== null && _d !== void 0 ? _d : "Unknown",
                                });
                            }) }),
                        form.errors.at(0) && React.createElement(FormAlert, { text: form.errors.at(0).description })),
                    React.createElement(Button, { type: "submit", disabled: form.loading || !form.valid || !!form.walletId }, "Create"))))));
});
