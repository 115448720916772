import { styled } from "@mui/material/styles";
import * as React from "react";
import { Controller } from "react-hook-form";
import { Box, Checkbox, FormControlInput, Typography } from "@/shared/components";
import { FormControlSelect } from "@/shared/components/FormControlSelect";
import { countries } from "@/shared/constants/countries";
import { RequiredFieldMessage } from "@/shared/utils/error";
export const IntermediaryInfo = ({ control, }) => {
    const [enabled, setEnabled] = React.useState(false);
    return (React.createElement(Box, { display: "flex", flexDirection: "column", gap: "40px", gridColumn: "1/-1" },
        React.createElement(CheckboxPanel, { key: "intermediaryInfo", onClick: () => setEnabled(!enabled) },
            React.createElement(Typography, { type: "text16", color: "black-500" }, "Add intermediary bank information"),
            React.createElement(Checkbox, { size: "medium", checked: enabled })),
        enabled && (React.createElement(React.Fragment, null,
            React.createElement(Controller, { rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, control: control, name: "intermediary.institutionAddress.country", render: ({ field, fieldState: { error } }) => (React.createElement(FormControlSelect, { name: field.name, onChange: (_, v) => field.onChange(v === null || v === void 0 ? void 0 : v.key), value: field.value, options: countries.map((item) => ({
                        key: item.codeAlpha3,
                        label: `${item.flag} ${item.name}`,
                    })), label: "Country", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" })) }),
            React.createElement(Controller, { rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, control: control, name: "intermediary.institutionName", render: ({ field, fieldState: { error } }) => (React.createElement(FormControlInput, Object.assign({ error: error === null || error === void 0 ? void 0 : error.message, label: "Intermediary institution / Correspondent bank" }, field))) }),
            React.createElement(Controller, { rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, control: control, name: "intermediary.swiftBic", render: ({ field, fieldState: { error } }) => (React.createElement(FormControlInput, Object.assign({ error: error === null || error === void 0 ? void 0 : error.message, label: "SWIFT / BIC" }, field))) })))));
};
const CheckboxPanel = styled("div") `
    display: flex;
    background: ${props => props.theme.colors["black-50"]};
    padding: 30px;
    justify-content: space-between;
    align-items: center;
    border-radius: 30px;
    cursor: pointer;
`;
