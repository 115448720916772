var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { observer } from "mobx-react-lite";
import React from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { toast } from "react-toastify";
import { PasswordFormStore } from "@/pages/ProfilePage/stores/PasswordFormStore";
import { Box, Button, FormControlInput, Typography } from "@/shared/components";
import { Toast } from "@/shared/components/Alert";
import { PasswordHint } from "@/shared/components/PasswordHint";
import { PopupBody } from "@/shared/components/Popup/Body";
import { PopupContent } from "@/shared/components/Popup/Content";
import { useStoreContext } from "@/shared/hooks/useStore";
import { validatePassword } from "@/shared/utils/password";
import { preventDefault } from "@/shared/utils/prevent";
export const ChangePasswordForm = observer(() => {
    const store = useStoreContext(PasswordFormStore);
    const { control, handleSubmit } = useForm({
        defaultValues: {
            password: "",
            passwordRepeat: "",
        },
    });
    const [password, passwordRepeat] = useWatch({
        control,
        name: ["password", "passwordRepeat"],
    });
    const passwordValidation = validatePassword(password);
    const isPasswordValid = passwordValidation.length
        && passwordValidation.lowercase
        && passwordValidation.uppercase
        && passwordValidation.special
        && passwordValidation.digit
        && password === passwordRepeat;
    const onSubmit = (values) => __awaiter(void 0, void 0, void 0, function* () {
        const success = yield store.submitPassword(values.password);
        if (success) {
            toast((props) => (React.createElement(Toast, Object.assign({ type: "success", text: "Your password is successfully changed" }, props))));
        }
    });
    return (React.createElement(PopupBody, { as: "form", onSubmit: preventDefault(handleSubmit(onSubmit)) },
        React.createElement(PopupContent, { gap: "30px" },
            React.createElement(Box, { display: "flex", flexDirection: "column", gap: "10px", alignItems: "center" },
                React.createElement(Typography, { type: "text28Bold" }, "Reset password"),
                React.createElement(Typography, { color: "black-500" }, "Create a new password")),
            React.createElement(Controller, { control: control, name: "password", render: ({ field }) => {
                    var _a;
                    return (React.createElement(FormControlInput, { type: "password", label: "Password", value: field.value, onChange: field.onChange, disabled: store.loading, variantError: "alert", error: (_a = store.errors[0]) === null || _a === void 0 ? void 0 : _a.description }));
                } }),
            React.createElement(Controller, { control: control, name: "passwordRepeat", render: ({ field }) => (React.createElement(FormControlInput, { type: "password", label: "Repeat password", value: field.value, onChange: field.onChange, disabled: store.loading, variantError: "alert" })) }),
            React.createElement(PasswordHint, { showMatch: true, match: !!password && password === passwordRepeat, digit: passwordValidation.digit, length: passwordValidation.length, lowercase: passwordValidation.lowercase, special: passwordValidation.special, uppercase: passwordValidation.uppercase })),
        React.createElement(Button, { width: "100%", type: "submit", onClick: handleSubmit(onSubmit), disabled: store.loading || !isPasswordValid }, "Change password")));
});
