import { styled } from "@mui/material";
import * as React from "react";
import { BaseIcon } from "@/shared/components/BaseIcon";
import { Box } from "@/shared/components/Box";
import { Typography } from "@/shared/components/Typography";
export const PopupOption = ({ selected, desc, title, onClick, }) => (React.createElement(Wrapper, { selected: selected, onClick: onClick },
    React.createElement(Box, { display: "flex", flexDirection: "column" },
        React.createElement(Typography, { type: "text18", fontWeight: 500 }, title),
        React.createElement(Typography, { type: "text14", color: "black-500" }, desc)),
    selected && (React.createElement(BaseIcon, { icon: "Check", size: 8, containerSize: 20, borderRadius: "20px", bgColor: "deep-blue-500", color: "general-white" }))));
const Wrapper = styled("button") `
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    border-radius: 20px;
    padding: 18px 20px;
    box-shadow: ${props => props.selected
    ? `inset 0 0 0 2px ${props.theme.colors["deep-blue-500"]}`
    : `inset 0 0 0 1px ${props.theme.colors["black-100"]}`};
`;
