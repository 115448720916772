var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { styled } from "@mui/material/styles";
import { compose, spacing } from "@mui/system";
import React, { memo } from "react";
import { FormAlert } from "../FormAlert";
import { Autocomplete } from "../Select";
import { ListOptionDefault } from "../Select/Option";
import { Typography } from "../Typography";
import { StyledContainer } from "./styled";
export const ImplFormControlMultiSelect = (_a) => {
    var _b;
    var { error, errorPosition = "relative", variantError = "text", value } = _a, props = __rest(_a, ["error", "errorPosition", "variantError", "value"]);
    const selected = ((_b = props.options) === null || _b === void 0 ? void 0 : _b.filter((option) => value === null || value === void 0 ? void 0 : value.find((v) => v === option.key))) || [];
    return (React.createElement(StyledContainer, { "$errorPosittion": errorPosition },
        React.createElement(Autocomplete
        // TODO: Fix this ref
        , Object.assign({ 
            // TODO: Fix this ref
            classes: { inputRoot: selected.length ? "MuiAutocomplete-hasValue" : "" }, ref: undefined, renderOption: (p, option, state) => (React.createElement(ListOptionDefault, Object.assign({ text: option.label }, state, p, { key: option.key }))) }, props, { value: selected, FormControlProps: { error, errorPosition, variantError } })),
        error
            && typeof error === "string"
            && variantError === "alert" && React.createElement(FormAlert, { text: error }),
        error
            && typeof error === "string"
            && variantError === "text" && (React.createElement(Typography, { type: "text14", color: "general-red" }, error))));
};
export const FormControlMultiSelect = memo(styled(ImplFormControlMultiSelect)(compose(spacing)));
