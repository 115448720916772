var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import BigNumber from "bignumber.js";
import { makeAutoObservable, reaction, runInAction } from "mobx";
import { registrationApi } from "@/api/registration";
import kybPaymentWallets from "@/shared/constants/kybPaymentWallets.json";
import { parseApiError } from "@/shared/utils/error";
import { Reactions } from "@/shared/utils/reactions";
export const wallets = kybPaymentWallets.map((wallet, index) => (Object.assign(Object.assign({}, wallet), { key: index.toString(), label: wallet.symbol, network: wallet.network.map((item, index) => (Object.assign(Object.assign({}, item), { key: index.toString(), label: item.name }))) })));
export class PaymentCryptoFormStore {
    constructor(verification, companyInfo, promo) {
        this.verification = verification;
        this.companyInfo = companyInfo;
        this.promo = promo;
        this.success = false;
        this.errors = [];
        this.walletKey = undefined;
        this.networkKey = undefined;
        this.trxHash = undefined;
        this.loader = 0;
        this.reactions = new Reactions();
        makeAutoObservable(this, {}, { autoBind: true });
    }
    init() {
        this.reactions.create(reaction(() => this.walletKey, () => {
            this.networkKey = undefined;
        }, {
            fireImmediately: true,
        }));
    }
    dispose() {
        this.reactions.destroy();
    }
    submit() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            let success = false;
            let errors = [];
            runInAction(() => {
                this.loader += 1;
            });
            try {
                if (!((_a = this.verification.verification) === null || _a === void 0 ? void 0 : _a.id)) {
                    throw new Error("Verification must be defined");
                }
                if (this.needTxHash) {
                    if (!this.trxHash) {
                        throw new Error("trxHash must be defined");
                    }
                    yield this.companyInfo.update({
                        meta: {
                            paymentTrxHash: this.trxHash,
                        },
                    });
                }
                yield registrationApi.verification.updateVerificationStatus(this.verification.verification.id);
                yield this.verification.sync();
                success = true;
            }
            catch (e) {
                console.error(e);
                errors = parseApiError(e);
            }
            runInAction(() => {
                this.errors = errors;
                this.loader -= 1;
                this.success = success;
            });
        });
    }
    get loading() {
        return this.loader > 0;
    }
    get walletAddress() {
        var _a, _b;
        return this.walletKey && this.networkKey
            ? (_b = (_a = wallets
                .find((item) => item.key === this.walletKey)) === null || _a === void 0 ? void 0 : _a.network.find((item) => item.key === this.networkKey)) === null || _b === void 0 ? void 0 : _b.account
            : undefined;
    }
    get symbol() {
        var _a;
        if (this.walletKey && this.networkKey) {
            return (_a = wallets.find((item) => item.key === this.walletKey)) === null || _a === void 0 ? void 0 : _a.symbol;
        }
        return undefined;
    }
    get needTxHash() {
        var _a;
        return ((_a = this.promo.discountHighest) === null || _a === void 0 ? void 0 : _a.discount_percent) === undefined
            || new BigNumber(this.promo.discountHighest.discount_percent).lt(100);
    }
}
