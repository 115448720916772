var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { makeAutoObservable, runInAction } from "mobx";
import { registrationApi } from "@/api/registration";
import { IdDocSubType, IdDocType } from "@/api/registration/types";
export class CompanyVerificationStore {
    constructor() {
        this.ready = false;
        this.loading = false;
        this.data = undefined;
        makeAutoObservable(this, {}, { autoBind: true });
    }
    init() {
        this.sync();
    }
    dispose() {
        this.ready = false;
        this.loading = false;
        this.data = undefined;
    }
    sync() {
        return __awaiter(this, void 0, void 0, function* () {
            let data;
            let ready = true;
            runInAction(() => {
                this.loading = true;
            });
            try {
                const statusResp = yield registrationApi.verification.getVerificationStatus();
                data = statusResp.data;
            }
            catch (e) {
                console.error(e);
                ready = false;
            }
            runInAction(() => {
                this.loading = false;
                this.data = data;
                this.ready = ready;
            });
        });
    }
    get success() {
        var _a;
        return !!((_a = this.data) === null || _a === void 0 ? void 0 : _a.docs)
            && !!this.data.docs.find(item => item.type === IdDocType.COMPANY_DOC
                && item.sub_type === IdDocSubType.INCORPORATION_ARTICLES)
            && !!this.data.docs.find(item => item.type === IdDocType.COMPANY_DOC
                && item.sub_type === IdDocSubType.INCORPORATION_CERT)
            && !!this.data.docs.find(item => item.type === IdDocType.COMPANY_DOC
                && item.sub_type === IdDocSubType.OTHER);
    }
}
