var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { makeAutoObservable, reaction } from "mobx";
import { walletApi } from "@/api/wallet/api";
import { Reactions } from "@/shared/utils/reactions";
export class NameFormStore {
    constructor(accountList, accountId) {
        this.accountList = accountList;
        this.accountId = accountId;
        this.visible = false;
        this.loading = false;
        this.errors = [];
        this.name = undefined;
        this.id = undefined;
        this.reactions = new Reactions();
        makeAutoObservable(this, {}, { autoBind: true });
        this.id = accountId;
    }
    init() {
        this.reactions.create(reaction(() => this.account, () => {
            var _a, _b;
            this.name = (_b = (_a = this.account) === null || _a === void 0 ? void 0 : _a.shortName) !== null && _b !== void 0 ? _b : "";
        }, {
            fireImmediately: true,
        }));
    }
    submit() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.id)
                return;
            if (!this.name)
                return;
            yield walletApi.account.updateAccount(this.id, {
                account: this.id,
                accountType: "LAYER2",
                shortName: this.name,
            });
            this.accountList.sync();
            this.reset();
        });
    }
    reset() {
        var _a;
        this.visible = false;
        this.loading = false;
        this.errors = [];
        this.name = (_a = this.account) === null || _a === void 0 ? void 0 : _a.shortName;
    }
    get account() {
        return this.accountId
            ? this.accountList.fiatById[this.accountId]
            : undefined;
    }
    get valid() {
        return this.name ? this.name.length > 0 : undefined;
    }
}
