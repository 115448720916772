var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { styled } from "@mui/material/styles";
import * as React from "react";
import { BaseIcon } from "@/shared/components/BaseIcon";
export const Loader = (_a) => {
    var { size, containerSize, color = "black-1200", opacity = 0.1 } = _a, props = __rest(_a, ["size", "containerSize", "color", "opacity"]);
    return (React.createElement(Wrapper, Object.assign({}, props, { icon: "Spinner", width: size, height: size, containerHeight: containerSize !== null && containerSize !== void 0 ? containerSize : size, containerWidth: containerSize !== null && containerSize !== void 0 ? containerSize : size, opacity: opacity, color: color })));
};
export const Wrapper = styled(BaseIcon)(() => ({
    animation: "rotate 1s linear infinite",
    "@keyframes rotate": {
        "from": {
            transform: "rotate(0)",
        },
        "to": {
            transform: "rotate(360deg)",
        },
    },
}));
