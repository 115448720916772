var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable react-hooks/exhaustive-deps */
import { formattedTokenAmount } from "@broxus/js-utils";
import BN, { BigNumber } from "bignumber.js";
import debounce from "lodash.debounce";
import { action, runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useCreateDirectExchange } from "@/api/wallet";
import { ExchangeModal } from "@/pages/ExchangePage/components/Modal";
import { ExchangeFormStore } from "@/pages/ExchangePage/stores/ExchangeFormStore";
import { ExchangeType, ROUTES } from "@/routes";
import { BaseIcon, Box, Button, Typography } from "@/shared/components";
import { AccountSelect } from "@/shared/components/AccountSelect";
import { BankAccountPopup } from "@/shared/components/BankAccountPopup";
import { BankIcon } from "@/shared/components/BankIcon";
import { ExchangeField } from "@/shared/components/ExchangeField";
import { ExchangeInput } from "@/shared/components/ExchangeInput";
import { HintBtn } from "@/shared/components/HintBtn";
import { Info } from "@/shared/components/Info";
import { OperationLayout } from "@/shared/components/OperationLayout";
import { SectionAmount } from "@/shared/components/SectionAmount";
import { TokenIcon } from "@/shared/components/TokenIcon";
import { TokenPopup } from "@/shared/components/TokenPopup";
import { useMedia } from "@/shared/hooks/useMedia";
import { useStore, useStoreContext } from "@/shared/hooks/useStore";
import { useToggler } from "@/shared/hooks/useToggler";
import { AccountListStore } from "@/shared/stores/AccountListStore";
import { BalanceListStore } from "@/shared/stores/BalanceListStore";
import { CurrencyListStore } from "@/shared/stores/CurrencyListStore";
import { generateQueryPath } from "@/shared/utils/path";
import { sliceAddress } from "@/shared/utils/slice-address";
export const Exchange = observer(({ type }) => {
    var _a, _b, _c, _d, _e, _f;
    const media = useMedia();
    const account1Popup = useToggler();
    const account2Popup = useToggler();
    const params = useParams();
    const tokenPopup = useToggler();
    const navigate = useNavigate();
    const accounts = useStoreContext(AccountListStore);
    const [search] = useSearchParams();
    const from = search.get("from");
    const to = search.get("to");
    const balances = useStoreContext(BalanceListStore);
    const form = useStore(ExchangeFormStore, balances);
    const currencyList = useStoreContext(CurrencyListStore);
    const [isOpenModal, setOpenModal] = React.useState(false);
    const [preexchangeErr, setPreexchangeErr] = React.useState("");
    const [exchangeLoad, setExchangeLoad] = React.useState(false);
    const fiat1Id = params.type === ExchangeType.Crypto ? to : from;
    const fiat2Id = params.type === ExchangeType.FiatFiat ? to : undefined;
    const cryptoId = params.type === ExchangeType.Crypto ? from : to;
    const fiat1Acc = fiat1Id ? accounts.byId[fiat1Id] : undefined;
    const fiat2Acc = fiat2Id ? accounts.byId[fiat2Id] : undefined;
    const cryptoAcc = cryptoId ? accounts.byId[cryptoId] : undefined;
    const fromAcc = params.type === ExchangeType.Crypto ? cryptoAcc : fiat1Acc;
    const toAcc = params.type === ExchangeType.FiatFiat
        ? fiat2Acc
        : ExchangeType.Crypto
            ? fiat1Acc
            : cryptoAcc;
    const fiat1Error = params.type !== ExchangeType.Crypto && form.fiat1Enough === false
        ? "Enter a smaller amount"
        : undefined;
    const cryptoError = params.type === ExchangeType.Crypto && form.cryptoEnough === false
        ? "Enter a smaller amount"
        : undefined;
    React.useEffect(() => {
        if (from || to) {
            balances.sync(from, to);
        }
    }, [balances, from, to]);
    React.useEffect(() => {
        runInAction(() => {
            form.cryptoId = cryptoId !== null && cryptoId !== void 0 ? cryptoId : undefined;
            form.fiat1Id = fiat1Id !== null && fiat1Id !== void 0 ? fiat1Id : undefined;
            form.fiat2Id = fiat2Id !== null && fiat2Id !== void 0 ? fiat2Id : undefined;
        });
    }, [cryptoId, fiat1Id, fiat2Id, form]);
    const { mutateAsync, data, isPending: isPendingPreExchange, reset, } = useCreateDirectExchange({});
    const preExchangeInfo = data === null || data === void 0 ? void 0 : data.data;
    const preExchange = React.useCallback((value) => __awaiter(void 0, void 0, void 0, function* () {
        var _g, _h, _j;
        const toAccLabel = getCurrLabel(true);
        if (exchangeLoad)
            return;
        if (!value || new BN(value).eq(0))
            return;
        if (!toAccLabel)
            return;
        try {
            setExchangeLoad(true);
            const isFiat = params.type === ExchangeType.Fiat;
            const isFiatFiat = params.type === ExchangeType.FiatFiat;
            const field = isFiatFiat
                ? "fiat2Amount"
                : isFiat
                    ? "cryptoAmount"
                    : "fiat1Amount";
            let amount = "";
            if (from && to && (fromAcc === null || fromAcc === void 0 ? void 0 : fromAcc.currency) && !!Number(value)) {
                const data = yield mutateAsync({
                    dryRun: true,
                    data: {
                        fromAccount: from,
                        toAccount: to,
                        fromCurrency: fromAcc.currency,
                        toCurrency: toAccLabel,
                        fromAmount: value,
                    },
                });
                amount = data.data.toAmount;
                if (data.data.feeAmount && data.data.feeCurrency) {
                    const fee = yield currencyList.convert(data.data.fromCurrency, data.data.toCurrency, data.data.feeAmount);
                    amount = new BN(amount).minus(fee !== null && fee !== void 0 ? fee : "0").toFixed();
                }
            }
            runInAction(() => {
                form[field] = amount;
            });
            setExchangeLoad(false);
        }
        catch (e) {
            setPreexchangeErr((_j = (_h = (_g = e === null || e === void 0 ? void 0 : e.response) === null || _g === void 0 ? void 0 : _g.data) === null || _h === void 0 ? void 0 : _h.message) !== null && _j !== void 0 ? _j : "");
            setExchangeLoad(false);
        }
    }), [from, to, fromAcc === null || fromAcc === void 0 ? void 0 : fromAcc.currency, toAcc === null || toAcc === void 0 ? void 0 : toAcc.currency, mutateAsync]);
    const onSuccess = () => {
        reset();
        form.reset();
        if (from || to) {
            balances.sync(from, to);
        }
    };
    const onCloseModal = () => {
        setOpenModal(false);
        setPreexchangeErr("");
    };
    const debounceExchange = React.useCallback(debounce(preExchange, 1000), [
        preExchange,
    ]);
    const defaultAmount = params.type === ExchangeType.Crypto
        ? form.cryptoAmount
        : form.fiat1Amount;
    React.useEffect(() => {
        debounceExchange(defaultAmount);
    }, [from, defaultAmount]);
    const isDisabledExchange = type === ExchangeType.FiatFiat
        ? !!fiat1Error
            || !Number(form.fiat1Amount)
            || !Number(form.fiat2Amount)
        : !!fiat1Error
            || !!cryptoError
            || !Number(form.fiat1Amount)
            || !Number(form.cryptoAmount);
    const fiat1Field = (React.createElement(ExchangeField, { error: fiat1Error, input: React.createElement(ExchangeInput, { decimals: (fiat1Acc === null || fiat1Acc === void 0 ? void 0 : fiat1Acc.currency)
                ? (_a = currencyList.bySlug[fiat1Acc === null || fiat1Acc === void 0 ? void 0 : fiat1Acc.currency.toLowerCase()]) === null || _a === void 0 ? void 0 : _a.decimals
                : undefined, disabled: params.type === ExchangeType.Crypto, invalid: !!fiat1Error, postFix: fiat1Acc === null || fiat1Acc === void 0 ? void 0 : fiat1Acc.currency.toUpperCase(), value: params.type === ExchangeType.Crypto
                ? preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.toAmount
                : form.fiat1Amount, onChange: action((value) => {
                form.fiat1Amount = value;
            }) }), label: params.type === ExchangeType.FiatFiat
            ? "You send from bank"
            : params.type === ExchangeType.Fiat
                ? "You send from bank"
                : "You get to bank", select: React.createElement(AccountSelect, { name: fiat1Acc ? sliceAddress(fiat1Acc.account) : undefined, descLoading: !form.fiat1Balance, desc: form.fiat1Balance
                ? `${formattedTokenAmount(form.fiat1Balance.balance)} ${form.fiat1Balance.currency.toUpperCase()}`
                : undefined, icon: fiat1Acc ? React.createElement(BankIcon, { symbol: fiat1Acc.currency, size: 24 }) : undefined, onClick: account1Popup.on }) }));
    const fiat2Field = (React.createElement(ExchangeField, { input: React.createElement(ExchangeInput, { decimals: (fiat2Acc === null || fiat2Acc === void 0 ? void 0 : fiat2Acc.currency)
                ? (_b = currencyList.bySlug[fiat2Acc === null || fiat2Acc === void 0 ? void 0 : fiat2Acc.currency.toLowerCase()]) === null || _b === void 0 ? void 0 : _b.decimals
                : undefined, disabled: true, postFix: fiat2Acc === null || fiat2Acc === void 0 ? void 0 : fiat2Acc.currency.toUpperCase(), value: params.type === ExchangeType.FiatFiat
                ? preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.toAmount
                : form.fiat2Amount }), label: "You get to bank", select: React.createElement(AccountSelect, { name: fiat2Acc ? sliceAddress(fiat2Acc.account) : undefined, descLoading: !form.fiat2Balance, desc: form.fiat2Balance
                ? `${formattedTokenAmount(form.fiat2Balance.balance)} ${form.fiat2Balance.currency.toUpperCase()}`
                : undefined, icon: fiat2Acc ? React.createElement(BankIcon, { symbol: fiat2Acc.currency, size: 24 }) : undefined, onClick: account2Popup.on }) }));
    const cryptoField = (React.createElement(ExchangeField, { error: cryptoError, input: React.createElement(ExchangeInput, { decimals: (cryptoAcc === null || cryptoAcc === void 0 ? void 0 : cryptoAcc.currency)
                ? (_c = currencyList.bySlug[cryptoAcc === null || cryptoAcc === void 0 ? void 0 : cryptoAcc.currency.toLowerCase()]) === null || _c === void 0 ? void 0 : _c.decimals
                : undefined, disabled: params.type === ExchangeType.Fiat, invalid: !!cryptoError, postFix: cryptoAcc === null || cryptoAcc === void 0 ? void 0 : cryptoAcc.currency.toUpperCase(), value: params.type === ExchangeType.Crypto
                ? form === null || form === void 0 ? void 0 : form.cryptoAmount
                : preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.toAmount, onChange: action((value) => {
                form.cryptoAmount = value;
            }) }), label: params.type === ExchangeType.Crypto
            ? "You send crypto"
            : "You get crypto", select: React.createElement(AccountSelect, { name: cryptoAcc ? sliceAddress(cryptoAcc.account) : undefined, descLoading: !form.cryptoBalance, desc: form.cryptoBalance
                ? `${formattedTokenAmount(form.cryptoBalance.balance)} ${form.cryptoBalance.currency.toUpperCase()}`
                : undefined, icon: cryptoAcc ? React.createElement(TokenIcon, { symbol: cryptoAcc.currency, size: 24 }) : undefined, onClick: tokenPopup.on }) }));
    const rate = (preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.toAmount) && (preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.fromAmount)
        ? BN(preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.fromAmount)
            .div(preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.toAmount)
            .toFixed(6, BigNumber.ROUND_DOWN)
        : undefined;
    const totalExchangeAmount = preExchangeInfo
        ? formattedTokenAmount(new BN((_d = preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.fromAmount) !== null && _d !== void 0 ? _d : 0)
            .plus((_e = preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.feeAmount) !== null && _e !== void 0 ? _e : 0)
            .toFixed(2))
        : "0";
    const getCurrLabel = (reverseOn) => {
        if (!(cryptoAcc === null || cryptoAcc === void 0 ? void 0 : cryptoAcc.currency) && !(fromAcc === null || fromAcc === void 0 ? void 0 : fromAcc.currency))
            return "Currency";
        if (params.type === ExchangeType.FiatFiat) {
            return reverseOn && toAcc ? toAcc.currency : fromAcc === null || fromAcc === void 0 ? void 0 : fromAcc.currency;
        }
        if (params.type === ExchangeType.Fiat) {
            return reverseOn && cryptoAcc
                ? cryptoAcc.currency
                : fromAcc === null || fromAcc === void 0 ? void 0 : fromAcc.currency;
        }
        if (params.type === ExchangeType.Crypto) {
            return reverseOn ? toAcc === null || toAcc === void 0 ? void 0 : toAcc.currency : fromAcc === null || fromAcc === void 0 ? void 0 : fromAcc.currency;
        }
        return reverseOn ? fromAcc === null || fromAcc === void 0 ? void 0 : fromAcc.currency : toAcc === null || toAcc === void 0 ? void 0 : toAcc.currency;
    };
    const info = [
        {
            label: "Amount",
            value: (preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.fromAmount)
                ? `${formattedTokenAmount(preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.fromAmount)} ${fromAcc === null || fromAcc === void 0 ? void 0 : fromAcc.currency.toUpperCase()}`
                : "–",
        },
        {
            label: "Transaction fee",
            value: (preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.feeAmount) && preExchangeInfo.feeCurrency
                ? `${formattedTokenAmount(preExchangeInfo.feeAmount)} ${preExchangeInfo.feeCurrency.toUpperCase()}`
                : "–",
        },
        {
            label: `${getCurrLabel(true)} rate`,
            value: rate ? `${rate} ${getCurrLabel()}` : "–",
        },
        {
            label: "Processing time",
            value: "Defined by bank",
        },
    ];
    const showOnlyCurrencyBank1 = (() => {
        if (params.type === ExchangeType.Crypto)
            return "USD";
        if (params.type === ExchangeType.Fiat)
            return "USD";
        if (params.type !== ExchangeType.FiatFiat)
            return undefined;
        if (fiat2Acc && (fiat2Acc === null || fiat2Acc === void 0 ? void 0 : fiat2Acc.currency) !== "USD")
            return "USD";
        return undefined;
    })();
    const hideCurrencyBank1 = (() => {
        if (params.type !== ExchangeType.FiatFiat)
            return undefined;
        if (fiat2Acc && (fiat2Acc === null || fiat2Acc === void 0 ? void 0 : fiat2Acc.currency) === "USD")
            return "USD";
        return undefined;
    })();
    const showOnlyCurrencyBank2 = (() => {
        if (params.type === ExchangeType.Crypto)
            return "USD";
        if (params.type === ExchangeType.Fiat)
            return "USD";
        if (params.type !== ExchangeType.FiatFiat)
            return undefined;
        if (fiat1Acc && (fiat1Acc === null || fiat1Acc === void 0 ? void 0 : fiat1Acc.currency) !== "USD")
            return "USD";
        return undefined;
    })();
    const hideCurrencyBank2 = (() => {
        if (params.type !== ExchangeType.FiatFiat)
            return undefined;
        if (fiat1Acc && (fiat1Acc === null || fiat1Acc === void 0 ? void 0 : fiat1Acc.currency) === "USD")
            return "USD";
        return undefined;
    })();
    const handleRouteId = (accId, type) => {
        if (type === ExchangeType.Crypto) {
            navigate(generateQueryPath(ROUTES.exchange, {
                type: params.type,
            }, params.type === ExchangeType.Crypto
                ? {
                    from: (fromAcc === null || fromAcc === void 0 ? void 0 : fromAcc.account) === accId ? "" : accId,
                    to,
                }
                : {
                    from,
                    to: (toAcc === null || toAcc === void 0 ? void 0 : toAcc.account) === accId ? "" : accId,
                }), { replace: true });
        }
        if (type === ExchangeType.Fiat) {
            navigate(generateQueryPath(ROUTES.exchange, { type: params.type }, params.type === ExchangeType.Crypto
                ? {
                    from,
                    to: (toAcc === null || toAcc === void 0 ? void 0 : toAcc.account) === accId ? "" : accId,
                }
                : {
                    from: (fromAcc === null || fromAcc === void 0 ? void 0 : fromAcc.account) === accId ? "" : accId,
                    to,
                }), { replace: true });
        }
        if (type === ExchangeType.FiatFiat) {
            navigate(generateQueryPath(ROUTES.exchange, { type: params.type }, {
                from,
                to: (toAcc === null || toAcc === void 0 ? void 0 : toAcc.account) === accId ? "" : accId,
            }), { replace: true });
        }
    };
    return (React.createElement(React.Fragment, null,
        tokenPopup.enabled && (React.createElement(TokenPopup, { selectedId: cryptoAcc === null || cryptoAcc === void 0 ? void 0 : cryptoAcc.account, onClose: tokenPopup.off, onSelect: (id) => {
                tokenPopup.off();
                form.reset();
                handleRouteId(id, ExchangeType.Crypto);
            } })),
        account1Popup.enabled && (React.createElement(BankAccountPopup, { hideAccount: fiat2Acc === null || fiat2Acc === void 0 ? void 0 : fiat2Acc.account, selectedId: fiat1Acc === null || fiat1Acc === void 0 ? void 0 : fiat1Acc.account, onClose: account1Popup.off, hideCurrency: hideCurrencyBank1, showOnlyCurrency: showOnlyCurrencyBank1, onSelect: (id) => {
                account1Popup.off();
                form.reset();
                handleRouteId(id, ExchangeType.Fiat);
            } })),
        account2Popup.enabled && (React.createElement(BankAccountPopup, { hideAccount: fiat1Acc === null || fiat1Acc === void 0 ? void 0 : fiat1Acc.account, selectedId: fiat2Acc === null || fiat2Acc === void 0 ? void 0 : fiat2Acc.account, onClose: account2Popup.off, hideCurrency: hideCurrencyBank2, showOnlyCurrency: showOnlyCurrencyBank2, onSelect: (id) => {
                account2Popup.off();
                form.reset();
                handleRouteId(id, ExchangeType.FiatFiat);
            } })),
        React.createElement(OperationLayout, { main: React.createElement(Box, null,
                React.createElement(Typography, { type: "text32Bold", marginBottom: "9px", variant: "h2" }, "Exchange funds"),
                React.createElement(Box, { gap: "15px", display: "flex", alignItems: "center", marginBottom: "26px", flexWrap: "wrap" },
                    React.createElement(Button, { variant: type === ExchangeType.FiatFiat
                            ? "BorderedGray"
                            : "Bordered", size: "xs", minWidth: "130px", LinkComponent: Link, to: generateQueryPath(ROUTES.exchange, {
                            type: ExchangeType.FiatFiat,
                        }, type === ExchangeType.Fiat
                            ? {
                                from,
                                to: undefined,
                            }
                            : type === ExchangeType.Crypto
                                ? {
                                    from: to,
                                    to: undefined,
                                }
                                : {}) }, "Fiat to Fiat"),
                    React.createElement(Button, { variant: type === ExchangeType.Crypto
                            ? "BorderedGray"
                            : "Bordered", size: "xs", minWidth: "130px", LinkComponent: Link, to: generateQueryPath(ROUTES.exchange, {
                            type: ExchangeType.Crypto,
                        }, type === ExchangeType.Fiat
                            ? {
                                from: to,
                                to: from,
                            }
                            : type === ExchangeType.FiatFiat
                                ? {
                                    from: undefined,
                                    to: from,
                                }
                                : {}) }, "Crypto to USD"),
                    React.createElement(Button, { variant: type === ExchangeType.Fiat
                            ? "BorderedGray"
                            : "Bordered", size: "xs", minWidth: "130px", LinkComponent: Link, to: generateQueryPath(ROUTES.exchange, {
                            type: ExchangeType.Fiat,
                        }, type === ExchangeType.Crypto
                            ? {
                                from: to,
                                to: from,
                            }
                            : type === ExchangeType.FiatFiat
                                ? {
                                    from,
                                    to: undefined,
                                }
                                : {}) }, "USD to Crypto")),
                React.createElement(Box, { display: "flex", flexDirection: "column", gap: "30px" },
                    React.createElement(Box, { display: "flex", flexDirection: "column", gap: "10px" },
                        params.type === ExchangeType.Crypto
                            ? cryptoField
                            : fiat1Field,
                        React.createElement(Button, { replace: true, variant: "Black", size: "xs", width: "50px", alignSelf: "center", LinkComponent: Link, tabIndex: -1, to: generateQueryPath(ROUTES.exchange, {
                                type: type === ExchangeType.FiatFiat
                                    ? ExchangeType.FiatFiat
                                    : type
                                        === ExchangeType.Crypto
                                        ? ExchangeType.Fiat
                                        : ExchangeType.Crypto,
                            }, {
                                from: to,
                                to: from,
                            }) },
                            React.createElement(BaseIcon, { icon: "Swap", size: 14 })),
                        params.type === ExchangeType.FiatFiat
                            ? fiat2Field
                            : params.type === ExchangeType.Crypto
                                ? fiat1Field
                                : cryptoField),
                    false && (React.createElement(Box, { display: "grid", gridAutoFlow: "column", gridAutoColumns: "minmax(0,1fr)", gap: "5px" },
                        React.createElement(HintBtn, { value: "0.0000105", label: "Min EUR" }),
                        React.createElement(HintBtn, { value: "All funds", disabled: !Number((_f = form.cryptoBalance) === null || _f === void 0 ? void 0 : _f.balance), onClick: () => {
                                runInAction(() => {
                                    var _a;
                                    form.cryptoAmount = (_a = form.cryptoBalance) === null || _a === void 0 ? void 0 : _a.balance;
                                });
                            } }))))), card: media.desktop && (React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "center", gap: "50px" },
                React.createElement(Info, { data: info }),
                React.createElement(Box, { height: "1px", bgColor: "black-1000", width: "100%" }),
                React.createElement(SectionAmount, { amount: totalExchangeAmount, currency: fromAcc === null || fromAcc === void 0 ? void 0 : fromAcc.currency }),
                React.createElement(Button, { width: "100%", onClick: () => setOpenModal(true), disabled: isPendingPreExchange || isDisabledExchange, loading: isPendingPreExchange }, "Exchange"))), side: !media.desktop && (React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "center", gap: "30px", width: "100%" },
                React.createElement(Box, { width: "100%", height: "1px", bgColor: "black-100" }),
                React.createElement(Info, { dark: false, data: [
                        ...info,
                        {
                            label: "Total + Fees",
                            value: totalExchangeAmount
                                ? `${totalExchangeAmount} ${fromAcc === null || fromAcc === void 0 ? void 0 : fromAcc.currency}`
                                : "–",
                        },
                    ] }),
                React.createElement(Button, { width: "100%", onClick: () => setOpenModal(true), disabled: isPendingPreExchange || isDisabledExchange, loading: isPendingPreExchange }, "Exchange"))) }),
        React.createElement(ExchangeModal, { onSuccess: onSuccess, onClose: onCloseModal, isOpen: isOpenModal, data: {
                fromAccount: from,
                toAccount: to,
                fromCurrency: fromAcc === null || fromAcc === void 0 ? void 0 : fromAcc.currency,
                toCurrency: getCurrLabel(true),
                fromAmount: params.type === ExchangeType.Crypto
                    ? form.cryptoAmount
                    : form.fiat1Amount,
                id: preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.id,
                feeAmount: preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.feeAmount,
                feeCurrency: preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.feeCurrency,
            }, rateInfo: {
                label: `${getCurrLabel(true)} rate`,
                value: rate ? `${rate} ${fromAcc === null || fromAcc === void 0 ? void 0 : fromAcc.currency}` : "–",
            }, showError: preexchangeErr, toAmount: params.type === ExchangeType.FiatFiat
                ? form.fiat2Amount
                : params.type === ExchangeType.Fiat
                    ? form.cryptoAmount
                    : form.fiat1Amount })));
});
