/**
 * Retrieves the label or key of an option.
 *
 * @param {T | AutocompleteFreeSoloValueMapping<FreeSolo>} option - The option for which to retrieve the label.
 * @returns {string | T} - The label of the option if it is an object with a label property, the key of the option if it is an object without a label property, or the option itself if it is a string.
 */
export const getOptionLabel = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === "string") {
        return option;
    }
    // Regular option
    return (option === null || option === void 0 ? void 0 : option.label) || option.key;
};
