var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useSwitch } from "@mui/base/useSwitch";
import classNames from "classnames";
import React, { memo } from "react";
import { SwitchInput, SwitchRoot, SwitchThumb, SwitchTrack } from "./styled";
const ImplSwitch = React.forwardRef((_a, ref) => {
    var { isHover, name, id } = _a, props = __rest(_a, ["isHover", "name", "id"]);
    const { getInputProps, checked, disabled, focusVisible } = useSwitch(props);
    const stateClasses = {
        checked,
        disabled,
        focusVisible,
    };
    return (React.createElement(SwitchRoot, { className: classNames(stateClasses), "$isHover": isHover },
        React.createElement(SwitchTrack, null,
            React.createElement(SwitchThumb, { className: classNames(stateClasses, "SwitchThumb") })),
        React.createElement(SwitchInput, Object.assign({}, getInputProps(), { name: name, id: id, ref: ref }))));
});
export const Switch = memo(ImplSwitch);
