var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { observer } from "mobx-react-lite";
import * as React from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { generatePath, Link, useNavigate, useParams } from "react-router-dom";
import MaskedInput from "react-text-mask";
import { FormLayout } from "@/pages/KYBPage/components/FormLayout";
import { IndividualFormStore } from "@/pages/KYBPage/stores/IndividualFormStore";
import { IndividualListStore } from "@/pages/KYBPage/stores/IndividualListStore";
import { ROUTES } from "@/routes";
import { Box, FormControlInput, Typography } from "@/shared/components";
import { Button } from "@/shared/components/Button";
import { FormAlert } from "@/shared/components/FormAlert";
import { PhoneInput } from "@/shared/components/FormControlInput/PhoneInput";
import { FormControlSelect } from "@/shared/components/FormControlSelect";
import { countries } from "@/shared/constants/countries";
import { IndividualTypes, USResidencyStatus } from "@/shared/constants/dict";
import { useProvider, useStore, useStoreContext } from "@/shared/hooks/useStore";
import { getCountryStates } from "@/shared/utils/country";
import { RequiredFieldMessage, WrongFormatMessage } from "@/shared/utils/error";
import { EMAIL_REGEXP } from "@/shared/utils/is-email";
export const IndividualForm = observer(() => {
    var _a, _b;
    const params = useParams();
    const navigate = useNavigate();
    const individualList = useStoreContext(IndividualListStore);
    const individualForm = useStore(IndividualFormStore, individualList);
    const IndividualFormProvider = useProvider(IndividualFormStore);
    const form = useForm();
    const submit = form.handleSubmit((formData) => __awaiter(void 0, void 0, void 0, function* () {
        const uuid = params.param === "create" ? undefined : params.param;
        individualForm.submitForm(formData, uuid).then((success) => {
            if (success)
                navigate(generatePath(ROUTES.kybFormId, { id: 5 }));
        });
    }));
    React.useEffect(() => {
        const data = individualList.list.find((item) => item.id === params.param);
        form.reset(data);
    }, [params.param, individualList.list, form]);
    const countryCode = useWatch({
        control: form.control,
        name: "mailingAddress.country",
    });
    const states = React.useMemo(() => (countryCode ? getCountryStates(countryCode) : []), [countryCode]);
    return (React.createElement(IndividualFormProvider, { value: individualForm },
        React.createElement(FormLayout, { index: 5, showSubmitBtns: false, onSubmit: submit, onBack: () => navigate(generatePath(ROUTES.kybFormId, { id: 4 })), title: "Company Roles and Structure", desc: "Please provide all the persons who play role in the company. You need to enter information about all shareholders. If there are more than 10 of them, then you need to enter data on the 10 largest shareholders.", loading: individualList.loading || individualForm.loading },
            React.createElement(Controller, { control: form.control, name: "individualType", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: ({ field, fieldState: { error } }) => (React.createElement(FormControlSelect, { name: field.name, onChange: (_, v) => field.onChange(v === null || v === void 0 ? void 0 : v.key), value: field.value, options: IndividualTypes, label: "Role", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative", disabled: individualForm.loading || field.disabled })) }),
            React.createElement(Controller, { control: form.control, name: "percentageOwnership", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                    pattern: {
                        value: /^\d+$/,
                        message: WrongFormatMessage.description,
                    },
                }, render: ({ field, fieldState: { error } }) => (React.createElement(FormControlInput, Object.assign({}, field, { disabled: individualForm.loading || field.disabled, label: "Percentage ownership", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" }))) }),
            React.createElement(Controller, { control: form.control, name: "firstName", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: ({ field, fieldState: { error } }) => (React.createElement(FormControlInput, Object.assign({}, field, { disabled: individualForm.loading || field.disabled, label: "First name", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" }))) }),
            React.createElement(Controller, { control: form.control, name: "middleName", render: ({ field, fieldState: { error } }) => (React.createElement(FormControlInput, Object.assign({}, field, { disabled: individualForm.loading || field.disabled, label: "Middle name", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative", InputProps: {
                        endAdornment: (React.createElement(Typography, { type: "text12", color: "black-500" }, "Optional")),
                    } }))) }),
            React.createElement(Controller, { control: form.control, name: "lastName", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: ({ field, fieldState: { error } }) => (React.createElement(FormControlInput, Object.assign({}, field, { disabled: individualForm.loading || field.disabled, label: "Last name", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" }))) }),
            React.createElement(Controller, { control: form.control, name: "emailAddress", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                    pattern: {
                        value: EMAIL_REGEXP,
                        message: WrongFormatMessage.description,
                    },
                }, render: ({ field, fieldState: { error } }) => (React.createElement(FormControlInput, Object.assign({}, field, { disabled: individualForm.loading || field.disabled, label: "Email", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" }))) }),
            React.createElement(Controller, { control: form.control, name: "telephoneNumber", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: ({ field, fieldState: { error } }) => (React.createElement(PhoneInput, { onChange: field.onChange, value: field.value, error: error === null || error === void 0 ? void 0 : error.message, disabled: individualForm.loading || field.disabled })) }),
            React.createElement(Controller, { control: form.control, name: "mailingAddress.flatNumber", render: ({ field, fieldState: { error } }) => (React.createElement(FormControlInput, Object.assign({}, field, { disabled: individualForm.loading || field.disabled, label: "Unit number", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative", InputProps: {
                        endAdornment: (React.createElement(Typography, { type: "text12", color: "black-500" }, "Optional")),
                    } }))) }),
            React.createElement(Controller, { control: form.control, name: "mailingAddress.street", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: ({ field, fieldState: { error } }) => (React.createElement(FormControlInput, Object.assign({}, field, { disabled: individualForm.loading || field.disabled, label: "Address line 1", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" }))) }),
            React.createElement(Controller, { control: form.control, name: "mailingAddress.buildingNumber", render: ({ field, fieldState: { error } }) => (React.createElement(FormControlInput, Object.assign({}, field, { disabled: individualForm.loading || field.disabled, label: "Address line 2", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative", InputProps: {
                        endAdornment: (React.createElement(Typography, { type: "text12", color: "black-500" }, "Optional")),
                    } }))) }),
            React.createElement(Controller, { control: form.control, name: "mailingAddress.subStreet", render: ({ field, fieldState: { error } }) => (React.createElement(FormControlInput, Object.assign({}, field, { disabled: individualForm.loading || field.disabled, label: "Address line 3", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative", InputProps: {
                        endAdornment: (React.createElement(Typography, { type: "text12", color: "black-500" }, "Optional")),
                    } }))) }),
            React.createElement(Controller, { control: form.control, name: "mailingAddress.town", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: ({ field, fieldState: { error } }) => (React.createElement(FormControlInput, Object.assign({}, field, { disabled: individualForm.loading || field.disabled, label: "City", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" }))) }),
            React.createElement(Controller, { control: form.control, name: "mailingAddress.postCode", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: ({ field, fieldState: { error } }) => (React.createElement(FormControlInput, Object.assign({}, field, { disabled: individualForm.loading || field.disabled, label: "Postal / Zip code", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" }))) }),
            React.createElement(Controller, { control: form.control, name: "mailingAddress.country", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: ({ field, fieldState: { error } }) => (React.createElement(FormControlSelect, { name: field.name, onChange: (_, v) => field.onChange(v === null || v === void 0 ? void 0 : v.key), value: field.value, options: countries.map((item) => ({
                        key: item.codeAlpha3,
                        label: `${item.flag} ${item.name}`,
                    })), label: "Country", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative", disabled: individualForm.loading || field.disabled })) }),
            React.createElement(Controller, { control: form.control, name: "mailingAddress.state", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: ({ field, fieldState: { error } }) => (React.createElement(FormControlSelect, { name: field.name, onChange: (_, v) => field.onChange(v === null || v === void 0 ? void 0 : v.key), value: field.value, options: states, label: "State", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative", disabled: individualForm.loading || field.disabled })) }),
            React.createElement(Controller, { control: form.control, name: "taxReferenceNumber", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: ({ field, fieldState: { error } }) => (React.createElement(FormControlInput, Object.assign({}, field, { disabled: individualForm.loading || field.disabled, label: "Tax reference number of the country", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" }))) }),
            React.createElement(Controller, { control: form.control, name: "passportNumber", render: ({ field, fieldState: { error } }) => (React.createElement(FormControlInput, Object.assign({}, field, { disabled: individualForm.loading || field.disabled, label: "Passport number", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative", InputProps: {
                        endAdornment: (React.createElement(Typography, { type: "text12", color: "black-500" }, "Optional")),
                    } }))) }),
            React.createElement(Controller, { control: form.control, name: "nationality", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: ({ field, fieldState: { error } }) => (React.createElement(FormControlSelect, { name: field.name, onChange: (_, v) => field.onChange(v === null || v === void 0 ? void 0 : v.key), value: field.value, options: countries.map((item) => ({
                        key: item.codeAlpha2,
                        label: `${item.flag} ${item.name}`,
                    })), label: "Nationality", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative", disabled: individualForm.loading || field.disabled })) }),
            React.createElement(Controller, { control: form.control, name: "dateOfBirth", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: ({ field, fieldState: { error } }) => (React.createElement(MaskedInput, Object.assign({}, field, { mask: [
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        "-",
                        /\d/,
                        /\d/,
                        "-",
                        /\d/,
                        /\d/,
                    ], render: (innerRef, props) => (React.createElement(FormControlInput, Object.assign({}, props, { ref: innerRef, value: props.defaultValue, disabled: individualForm.loading || field.disabled, label: "Date of birth", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative", placeholder: "YYYY-MM-DD" }))) }))) }),
            React.createElement(Controller, { control: form.control, name: "title", render: ({ field, fieldState: { error } }) => (React.createElement(FormControlInput, Object.assign({}, field, { disabled: individualForm.loading || field.disabled, label: "Job title", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative", InputProps: {
                        endAdornment: (React.createElement(Typography, { type: "text12", color: "black-500" }, "Optional")),
                    } }))) }),
            React.createElement(Controller, { control: form.control, name: "usResidencyStatus", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: ({ field, fieldState: { error } }) => (React.createElement(FormControlSelect, { name: field.name, onChange: (_, v) => field.onChange(v === null || v === void 0 ? void 0 : v.key), value: field.value, options: USResidencyStatus, label: "US Residency status", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative", disabled: individualForm.loading || field.disabled })) }),
            ((_a = individualForm.errors[0]) === null || _a === void 0 ? void 0 : _a.description) && React.createElement(FormAlert, { text: (_b = individualForm.errors[0]) === null || _b === void 0 ? void 0 : _b.description }),
            React.createElement(Box, { display: "flex", justifyContent: "end", gap: "10px" },
                React.createElement(Button, { type: "submit", paddingLeft: "25px", paddingRight: "25px", disabled: individualList.loading || individualForm.loading }, "Save"),
                React.createElement(Button, { variant: "Bordered", paddingLeft: "25px", paddingRight: "25px", LinkComponent: Link, to: generatePath(ROUTES.kybFormId, {
                        id: 5,
                    }) }, "Decline")))));
});
