import { formattedTokenAmount } from "@broxus/js-utils";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { DepositType, ROUTES } from "@/routes";
import { BankIcon } from "@/shared/components/BankIcon";
import { BaseIcon } from "@/shared/components/BaseIcon";
import { Box } from "@/shared/components/Box";
import { Button } from "@/shared/components/Button";
import { Loader } from "@/shared/components/Loader";
import { PopupItem } from "@/shared/components/Popup/Item";
import { Typography } from "@/shared/components/Typography";
export const AccountItem = ({ id, selected, amount, symbol, desc, accNum, depositBtnVisible, isEmpty, onSelect, }) => {
    const navigate = useNavigate();
    return (React.createElement(PopupItem, { selected: selected, onClick: onSelect },
        React.createElement(BankIcon, { symbol: symbol, size: 36 }),
        React.createElement(Box, { display: "flex", flexDirection: "column", flexGrow: "1" },
            React.createElement(Title, { type: "text14" },
                amount
                    ? (`${formattedTokenAmount(amount)} ${symbol}`)
                    : React.createElement(Loader, { size: 16, containerSize: 21 }),
                selected && React.createElement(BaseIcon, { size: 16, icon: "Success" })),
            desc && (React.createElement(Typography, { type: "text14", color: "black-400" }, desc)),
            React.createElement(Typography, { type: "text14", color: "black-400" }, isEmpty ? "No account" : accNum !== null && accNum !== void 0 ? accNum : "\u200b")),
        depositBtnVisible && (React.createElement(Button, { size: "s", variant: "Bordered", onClick: (e) => {
                e.preventDefault();
                e.stopPropagation();
                navigate(generatePath(ROUTES.deposit, {
                    type: DepositType.Fiat,
                    id,
                }));
            } }, "Add funds"))));
};
const Title = styled(Typography) `
    display: flex;
    align-items: center;
    gap: 5px;
`;
