var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { makeAutoObservable, runInAction } from "mobx";
import { registrationApi } from "@/api/registration";
import { TimerStore } from "@/shared/stores/TimerStore";
import { parseApiError } from "@/shared/utils/error";
export var PasswordFormStep;
(function (PasswordFormStep) {
    PasswordFormStep[PasswordFormStep["Form"] = 0] = "Form";
    PasswordFormStep[PasswordFormStep["Code"] = 1] = "Code";
})(PasswordFormStep || (PasswordFormStep = {}));
export class PasswordFormStore {
    constructor(auth) {
        this.auth = auth;
        this.step = PasswordFormStep.Code;
        this.loading = false;
        this.visible = false;
        this.errors = [];
        this.timer = new TimerStore();
        this.payload = undefined;
        makeAutoObservable(this, {}, { autoBind: true });
    }
    reset() {
        this.step = PasswordFormStep.Code;
        this.loading = false;
        this.visible = false;
        this.errors = [];
        this.payload = undefined;
    }
    open() {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            let errors = [];
            runInAction(() => {
                this.step = PasswordFormStep.Code;
                this.loading = true;
                this.visible = true;
            });
            try {
                if (!((_b = (_a = this.auth.userInfo) === null || _a === void 0 ? void 0 : _a.primaryEmail) === null || _b === void 0 ? void 0 : _b.email)) {
                    throw new Error("primaryEmail must be defined");
                }
                yield registrationApi.user.resetPasswordConfirm({
                    username: this.auth.userInfo.primaryEmail.email,
                });
                this.timer.start();
            }
            catch (e) {
                console.error(e);
                errors = parseApiError(e);
            }
            runInAction(() => {
                this.loading = false;
                this.errors = errors;
            });
        });
    }
    submitEmailCode(code) {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function* () {
            let errors = [];
            let payload = "";
            let step = PasswordFormStep.Code;
            runInAction(() => {
                this.loading = true;
            });
            try {
                if (!((_b = (_a = this.auth.userInfo) === null || _a === void 0 ? void 0 : _a.primaryEmail) === null || _b === void 0 ? void 0 : _b.email)) {
                    throw new Error("primaryEmail must be defined");
                }
                const resp = yield registrationApi.user.resetPasswordConfirm({
                    username: (_d = (_c = this.auth.userInfo) === null || _c === void 0 ? void 0 : _c.primaryEmail) === null || _d === void 0 ? void 0 : _d.email,
                    code,
                });
                step = PasswordFormStep.Form;
                payload = resp.data;
            }
            catch (e) {
                console.error(e);
                errors = parseApiError(e);
            }
            runInAction(() => {
                this.step = step;
                this.payload = payload;
                this.loading = false;
                this.errors = errors;
            });
        });
    }
    submitPassword(password) {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function* () {
            let errors = [];
            let success = false;
            runInAction(() => {
                this.loading = true;
            });
            try {
                if (!((_b = (_a = this.auth.userInfo) === null || _a === void 0 ? void 0 : _a.primaryEmail) === null || _b === void 0 ? void 0 : _b.email)) {
                    throw new Error("primaryEmail must be defined");
                }
                if (!this.payload) {
                    throw new Error("payload must be defined");
                }
                yield registrationApi.user.resetPassword({
                    newPassword: password,
                    signedPayload: this.payload,
                    username: (_d = (_c = this.auth.userInfo) === null || _c === void 0 ? void 0 : _c.primaryEmail) === null || _d === void 0 ? void 0 : _d.email,
                });
                success = true;
            }
            catch (e) {
                console.error(e);
                errors = parseApiError(e);
            }
            runInAction(() => {
                this.loading = false;
                this.errors = errors;
                this.visible = !success;
            });
            return success;
        });
    }
}
