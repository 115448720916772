import { makeAutoObservable, reaction } from "mobx";
export var EventType;
(function (EventType) {
    EventType[EventType["AccountCreated"] = 0] = "AccountCreated";
})(EventType || (EventType = {}));
export class EventsStore {
    constructor() {
        this.list = [];
        makeAutoObservable(this, {}, { autoBind: true });
    }
    reaction(eventType, callback) {
        return reaction(() => this.last, (e) => {
            if ((e === null || e === void 0 ? void 0 : e.type) === eventType) {
                callback(e);
            }
        });
    }
    push(event) {
        this.list = [...this.list, event];
    }
    get last() {
        return this.list.at(this.list.length - 1);
    }
}
