import { action } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { PasswordResetPageStore } from "@/pages/PasswordResetPage/stores/PasswordResetPageStore";
import { FormControlInput } from "@/shared/components";
import { AuthForm } from "@/shared/components/AuthForm";
import { Button } from "@/shared/components/Button";
import { ResendCodeBtn } from "@/shared/components/ResendCodeBtn";
import { useStoreContext } from "@/shared/hooks/useStore";
import { onChange } from "@/shared/utils/input";
export const ResetCodeForm = observer(() => {
    var _a;
    const page = useStoreContext(PasswordResetPageStore);
    return (React.createElement(AuthForm, { onBack: page.reset, onSubmit: page.submitCode, title: "Reset password", desc: React.createElement(React.Fragment, null,
            "Enter the code we've sent you at ",
            React.createElement("a", { href: "/" }, page.email),
            ". Please enter it below."), content: React.createElement(React.Fragment, null,
            React.createElement(FormControlInput, { disabled: true, type: "text", label: "Email", value: page.email }),
            React.createElement(FormControlInput, { type: "text", label: "Verification code", value: page.code, onChange: onChange(action(value => {
                    page.code = value;
                })), disabled: page.loading, variantError: "alert", error: (_a = page.errors[0]) === null || _a === void 0 ? void 0 : _a.description, InputProps: {
                    endAdornment: (React.createElement(ResendCodeBtn, { loading: page.loading, onSubmit: page.submitEmail, count: page.timer.count })),
                } })), footer: React.createElement(Button, { type: "submit", width: "100%", disabled: !page.codeIsValid || page.loading }, "Continue") }));
});
