import { styled } from "@mui/material/styles";
export const Panel = styled("form") `
    border-radius: 22px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    background: ${(props) => props.theme.colors["general-white"]};

    ${(props) => props.theme.breakpoints.down("md")} {
        border: 1px solid ${(props) => props.theme.colors["black-200"]};
        border-radius: 12px;
        padding: 20px;
        gap: 20px;
    }
`;
