import { action } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { PhonePageStore } from "@/pages/PhonePage/stores/PhonePageStore";
import { FormControlInput, Typography } from "@/shared/components";
import { AuthForm } from "@/shared/components/AuthForm";
import { Button } from "@/shared/components/Button";
import { Input } from "@/shared/components/Input";
import { ResendCodeBtn } from "@/shared/components/ResendCodeBtn";
import { useStoreContext } from "@/shared/hooks/useStore";
import { onChange } from "@/shared/utils/input";
export const SmsCodeForm = observer(() => {
    var _a;
    const page = useStoreContext(PhonePageStore);
    return (React.createElement(AuthForm, { onBack: page.resetPhone, onSubmit: page.submitSmsCode, title: "Confirm your phone", desc: React.createElement(Typography, { type: "text16", color: "black-500" }, "We've sent you an SMS verification code. Please enter it below."), content: React.createElement(React.Fragment, null,
            React.createElement(Input, { disabled: true, value: page.phone, label: "Phone number" }),
            React.createElement(FormControlInput, { label: "SMS code", type: "text", value: page.smsCode, onChange: onChange(action(value => {
                    page.smsCode = value;
                })), disabled: page.loading, variantError: "alert", error: (_a = page.errors[0]) === null || _a === void 0 ? void 0 : _a.description, InputProps: {
                    endAdornment: (React.createElement(ResendCodeBtn, { loading: page.loading, onSubmit: page.submitPhone, count: page.timer.count })),
                } })), footer: React.createElement(Button, { type: "submit", width: "100%", disabled: !page.isSmsCodeValid || page.loading }, "Continue") }));
});
