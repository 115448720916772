import { observer } from "mobx-react-lite";
import * as React from "react";
import { CompanyVerificationStore } from "@/pages/KYBPage/stores/CompanyVerificationStore";
import { UploadStore } from "@/pages/KYBPage/stores/UploadStore";
import { Upload } from "@/shared/components/Upload";
import { useStore, useStoreContext } from "@/shared/hooks/useStore";
export const CompanyDocument = observer(({ label, subType, type, }) => {
    var _a, _b, _c, _d, _e;
    const verification = useStoreContext(CompanyVerificationStore);
    const upload = useStore(UploadStore, verification);
    const doc = (_b = (_a = verification.data) === null || _a === void 0 ? void 0 : _a.docs) === null || _b === void 0 ? void 0 : _b.find(item => item.type === type && item.sub_type === subType);
    return (React.createElement(Upload, { size: doc === null || doc === void 0 ? void 0 : doc.size, label: label, onFiles: e => upload.upload(verification.data.id, e[0], type, subType), loading: upload.loading || !verification.ready, error: (_c = upload.errors[0]) === null || _c === void 0 ? void 0 : _c.description, fileName: doc ? label : undefined, disabled: !verification.data || verification.loading, onDownload: ((_d = verification.data) === null || _d === void 0 ? void 0 : _d.id) && (doc === null || doc === void 0 ? void 0 : doc.id)
            ? () => upload.download(verification.data.id, doc === null || doc === void 0 ? void 0 : doc.id, doc.filename)
            : undefined, onRemove: doc && ((_e = verification.data) === null || _e === void 0 ? void 0 : _e.id)
            ? () => upload.delete(verification.data.id, doc.id)
            : undefined }));
});
