var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { styled } from "@mui/material/styles";
import { compose, spacing } from "@mui/system";
import React, { memo, useRef } from "react";
import { StyledTypography } from "./styled";
export const ImplTypography = React.forwardRef((_a, ref) => {
    var { children, className, variant = "span", color = "black-1200", lineClamp, type = "text16", bgColor, textIndent, wordBreak, textDecoration } = _a, props = __rest(_a, ["children", "className", "variant", "color", "lineClamp", "type", "bgColor", "textIndent", "wordBreak", "textDecoration"]);
    const isButton = !!props.onClick;
    const containerRef = useRef(null);
    return (React.createElement(StyledTypography, Object.assign({ className: className, tabIndex: isButton ? 0 : undefined, role: isButton ? "button" : undefined, "$textColor": color, "$bgColor": bgColor, "$text": type, "$lineClamp": lineClamp, "$wordBreak": wordBreak, "$textIndent": textIndent, "$textDecoration": textDecoration, variant: variant }, props, { ref: ref || containerRef }), children));
});
export const Typography = memo(styled(ImplTypography, {
    shouldForwardProp: (prop) => !spacing.filterProps.includes(prop),
})(compose(spacing)));
