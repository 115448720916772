var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { styled } from "@mui/material/styles";
import { borders as MUBorders, compose, display, flexbox, grid, positions, sizing, spacing, typography, } from "@mui/system";
import React, { memo } from "react";
import { StyledBox } from "./styled";
export const ImplBox = React.forwardRef((_a, ref) => {
    var { children, variant = "unstyled", bgColor, borderColor, borderWidth, borderStyle, backdropFilter, borders, borderRadius } = _a, props = __rest(_a, ["children", "variant", "bgColor", "borderColor", "borderWidth", "borderStyle", "backdropFilter", "borders", "borderRadius"]);
    return (React.createElement(StyledBox, Object.assign({ "$bgColor": bgColor, "$borders": borders, "$borderColor": borderColor, "$borderWidth": borderWidth, "$borderStyle": borderStyle, "$backdropFilter": backdropFilter, "$variant": variant }, props, { ref: ref }), children));
});
export const Box = memo(styled(ImplBox, {
    shouldForwardProp: (prop) => !compose(spacing, sizing, flexbox, grid, display, positions, typography)
        .filterProps.includes(prop),
})(compose(spacing, sizing, flexbox, grid, display, MUBorders, positions, typography)));
