var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { action, runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { AccountFormStore } from "@/features/create-account/stores/AccountFormStore";
import { Box, Button, FormControlInput, Typography } from "@/shared/components";
import { FormAlert } from "@/shared/components/FormAlert";
import { FormControlSelect } from "@/shared/components/FormControlSelect";
import { Popup } from "@/shared/components/Popup";
import { PopupHead } from "@/shared/components/Popup/Head";
import { useMedia } from "@/shared/hooks/useMedia";
import { useProvider, useStore, useStoreContext } from "@/shared/hooks/useStore";
import { CurrencyListStore } from "@/shared/stores/CurrencyListStore";
import { EventsStore } from "@/shared/stores/EventsStore";
import { onChange } from "@/shared/utils/input";
import { preventDefault } from "@/shared/utils/prevent";
export const CreateAccountPopup = observer(({ onClose, onSuccess, }) => {
    const media = useMedia();
    const events = useStoreContext(EventsStore);
    const form = useStore(AccountFormStore, events);
    const AccountFormProvider = useProvider(AccountFormStore);
    const currencyList = useStoreContext(CurrencyListStore);
    const list = currencyList.list.filter(item => { var _a; return item.shortName && ((_a = item.type) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === "fiat"; });
    const onSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        const id = yield form.submit();
        if (id)
            onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(id);
    });
    return (React.createElement(AccountFormProvider, { value: form },
        React.createElement(Popup, { width: "450px", onClose: onClose },
            React.createElement(PopupHead, { onClose: onClose }, "\u200b"),
            React.createElement("form", { onSubmit: preventDefault(onSubmit) },
                React.createElement(Box, { minHeight: "505px", boxSizing: "border-box", padding: media.phone ? "0 25px 25px" : "0 50px 50px", display: "flex", flexDirection: "column", gap: "30px" },
                    React.createElement(Typography, { type: "text28", fontWeight: 500, textAlign: "center", variant: "h2" }, "Open bank account"),
                    React.createElement(Box, { display: "flex", flexDirection: "column", flexGrow: "1", gap: "20px" },
                        React.createElement(FormControlInput, { label: "Name", disabled: form.loading || !!form.accountId, value: form.name, onChange: onChange(action(value => {
                                form.name = value;
                            })), InputProps: {
                                endAdornment: (React.createElement(Typography, { type: "text12", color: "black-500" }, "Optional")),
                            } }),
                        React.createElement(FormControlSelect, { label: "Currency", disabled: form.loading || !!form.accountId, value: form.currency, onChange: (_, option) => {
                                runInAction(() => {
                                    form.currency = option === null || option === void 0 ? void 0 : option.key;
                                });
                            }, options: list.map(item => ({
                                key: item.shortName,
                                label: item.description
                                    ? `${item.shortName} (${item.description})`
                                    : item.shortName,
                            })) }),
                        form.errors.length > 0 && React.createElement(FormAlert, { text: form.errors[0].description })),
                    React.createElement(Button, { type: "submit", disabled: form.loading || !form.valid || !!form.accountId }, "Open"))))));
});
