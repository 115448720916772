import { styled } from "@mui/material";
import { action } from "mobx";
import { observer } from "mobx-react-lite";
import React from "react";
import { Box, FormControlInput, Modal, Typography } from "@/shared/components";
import { Button } from "@/shared/components/Button";
import { ResendCodeBtn } from "@/shared/components/ResendCodeBtn";
import { useStoreContext } from "@/shared/hooks/useStore";
import { AuthStore } from "@/shared/stores/AuthStore";
import { MfaStore } from "@/shared/stores/MfaStore";
import { onChange } from "@/shared/utils/input";
import { preventDefault } from "@/shared/utils/prevent";
export const TwoFaModal = observer(() => {
    var _a, _b, _c;
    const mfa = useStoreContext(MfaStore);
    const auth = useStoreContext(AuthStore);
    return (React.createElement(Modal, { open: mfa.visible, onClose: mfa.reset, isMobileFull: true },
        React.createElement(Form, { onSubmit: preventDefault(mfa.submitCode) },
            React.createElement(Box, { display: "flex", flexDirection: "column", gap: "30px" },
                React.createElement(Box, { display: "flex", flexDirection: "column", gap: "10px", alignItems: "center" },
                    React.createElement(Typography, { type: "text28Bold", textAlign: "center" }, "2-Step Authentication"),
                    React.createElement(Typography, { color: "black-500", textAlign: "center" },
                        "Enter the code we've sent you at",
                        " ", (_b = (_a = auth.userInfo) === null || _a === void 0 ? void 0 : _a.primaryEmail) === null || _b === void 0 ? void 0 :
                        _b.email,
                        ". Please enter it below.")),
                React.createElement(FormControlInput, { label: "Verification code", type: "text", value: mfa.code, onChange: onChange(action(val => {
                        mfa.code = val;
                    })), disabled: mfa.loading && !mfa.code, variantError: "alert", error: (_c = mfa.errors[0]) === null || _c === void 0 ? void 0 : _c.description, InputProps: {
                        endAdornment: (React.createElement(ResendCodeBtn, { loading: mfa.loading, onSubmit: mfa.createChallenge, count: mfa.timer.count })),
                    } })),
            React.createElement(Button, { type: "submit", width: "100%", onClick: mfa.submitCode, disabled: !mfa.code || mfa.loading }, "Continue"))));
});
const Form = styled("form") `
    display: flex;
    flex-direction: column;
    height: 450px;
    justify-content: space-between;
    margin-top: 50px;
    width: 100%;
`;
