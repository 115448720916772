var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { styled } from "@mui/material/styles";
import { compose, flexbox } from "@mui/system";
import classNames from "classnames";
import React from "react";
import { Box } from "../Box";
import { Typography } from "../Typography";
import { StyledContainer } from "./styled";
const ImplListOption = React.forwardRef((_a, ref) => {
    var { text, description, selected, lineClamp, children } = _a, props = __rest(_a, ["text", "description", "selected", "lineClamp", "children"]);
    return (React.createElement(StyledContainer, Object.assign({ ref: ref }, props, { className: classNames("SW-ListOptionDefault", props.className), id: "ListOptionDefault", "aria-selected": selected }), children !== null && children !== void 0 ? children : (React.createElement(Box, null,
        React.createElement(Typography, { lineClamp: lineClamp, color: selected ? "black-1200" : "black-500", className: "option-text" }, text)))));
});
export const ListOptionDefault = styled(ImplListOption, {
    shouldForwardProp: (prop) => !flexbox.filterProps.includes(prop),
})(compose(flexbox));
