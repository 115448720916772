import { observer } from "mobx-react-lite";
import * as React from "react";
import { generatePath, useNavigate } from "react-router-dom";
import styles from "./index.module.scss";
import { AuthPageStore } from "@/pages/AuthPage/stores/AuthPageStore";
import { ROUTES } from "@/routes";
import { BaseIcon } from "@/shared/components";
import { Button } from "@/shared/components/Button";
import { Typography } from "@/shared/components/Typography";
import { useMedia } from "@/shared/hooks/useMedia";
import { useStoreContext } from "@/shared/hooks/useStore";
import welcomeMainImg from "@/shared/img/welcome-main.png";
export const Welcome = observer(() => {
    const page = useStoreContext(AuthPageStore);
    const navigate = useNavigate();
    const media = useMedia();
    const signup = () => {
        page.toggleForm();
        navigate(generatePath(ROUTES.root));
    };
    const signin = () => {
        page.toggleForm();
        navigate(generatePath(ROUTES.signin));
    };
    return (React.createElement("div", { className: styles.root },
        React.createElement("div", { className: styles.head },
            React.createElement(BaseIcon, { icon: "Logo", width: 100, height: 30 })),
        React.createElement("div", { className: styles.main },
            React.createElement("img", { srcSet: welcomeMainImg, width: media.tablet ? "200" : " 318", height: media.tablet ? "200" : "318", alt: "" }),
            React.createElement(Typography, { type: media.tablet ? "text24Bold" : "text30Bold", textAlign: "center" }, "Open a business account")),
        media.tablet && (React.createElement("div", { className: styles.actions },
            React.createElement(Button, { onClick: signup, width: "100%" }, "Create an account"),
            React.createElement(Button, { onClick: signin, variant: "Primary", width: "100%" }, "Already have? Sign in")))));
});
