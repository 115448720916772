import { styled } from "@mui/material/styles";
import { capitalizeFirstLetter } from "@/shared/lib";
export const StyledBox = styled("div", {
    shouldForwardProp: (prop) => !prop.startsWith("$"),
})(({ theme, $bgColor, $borderColor, $borderWidth, $borderStyle, $backdropFilter, $borders, $variant, }) => (Object.assign(Object.assign({ backgroundColor: $bgColor ? theme.colors[$bgColor] : undefined, backdropFilter: $backdropFilter }, ($borders
    ? $borders.reduce((acc, name) => {
        const value = capitalizeFirstLetter(name);
        acc[`border${value}Color`] = theme.colors[$borderColor || "black-1200"];
        acc[`border${value}Width`] = $borderWidth;
        acc[`border${value}Style`] = $borderStyle;
        return acc;
    }, {})
    : {
        borderColor: $borderColor
            ? theme.colors[$borderColor]
            : undefined,
        borderWidth: $borderWidth,
        borderStyle: $borderStyle,
    })), ($variant === "styled"
    ? {
        position: "relative",
        borderRadius: "8px",
        overflow: "hidden",
        boxSizing: "border-box",
    }
    : {}))));
