var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// eslint-disable-next-line max-classes-per-file
import { makeAutoObservable, runInAction } from "mobx";
import { walletApi } from "@/api/wallet/api";
export var EAddressTypes;
(function (EAddressTypes) {
    EAddressTypes["FEDWIRE"] = "FEDWIRE";
    EAddressTypes["ACH"] = "ACH";
    EAddressTypes["SWIFT"] = "SWIFT";
    EAddressTypes["EFT"] = "EFT";
    EAddressTypes["EMPTY"] = "";
})(EAddressTypes || (EAddressTypes = {}));
class AddressItem {
    constructor(data) {
        this.data = data;
    }
    get isEmpty() {
        return "unavailable" in this.data;
    }
    get isCrypto() {
        return "type" in this.data && this.data.type === "CRYPTO";
    }
    get isBank() {
        return ("type" in this.data
            && (this.data.type === "ACH"
                || this.data.type === "SWIFT"
                || this.data.type === "FEDWIRE"));
    }
    get type() {
        return "type" in this.data
            ? this.data.type
            : undefined;
    }
    get accountHolderName() {
        return "accountHolderName" in this.data
            ? this.data.accountHolderName
            : undefined;
    }
    get accountNumber() {
        return "accountNumber" in this.data
            ? this.data.accountNumber
            : undefined;
    }
    get accountHolderAddress() {
        return "accountHolderAddress" in this.data
            ? this.data.accountHolderAddress
            : undefined;
    }
    get bankName() {
        return "bankName" in this.data ? this.data.bankName : undefined;
    }
    get bankAddress() {
        return "bankAddress" in this.data ? this.data.bankAddress : undefined;
    }
    get address() {
        return "address" in this.data ? this.data.address : undefined;
    }
    get accountId() {
        var _a;
        return "accountId" in this.data ? (_a = this.data) === null || _a === void 0 ? void 0 : _a.accountId : undefined;
    }
    get institutionNumber() {
        return "institutionNumber" in this.data
            ? this.data.institutionNumber
            : undefined;
    }
    get transitNumber() {
        return "transitNumber" in this.data
            ? this.data.transitNumber
            : undefined;
    }
    get accountRoutingNumber() {
        return "accountRoutingNumber" in this.data
            ? this.data.accountRoutingNumber
            : undefined;
    }
    get memo() {
        return "memo" in this.data ? this.data.memo : undefined;
    }
    get swiftBic() {
        return "swiftBic" in this.data ? this.data.swiftBic : undefined;
    }
}
class AddressList {
    constructor(data) {
        this.list = data.map((item) => new AddressItem(item));
    }
    get first() {
        return this.list.at(0);
    }
}
export class AddressListStore {
    constructor() {
        this.data = {};
        this.loading = {};
        makeAutoObservable(this, {}, { autoBind: true });
    }
    sync(...list) {
        return __awaiter(this, void 0, void 0, function* () {
            const filtered = list
                .filter((item) => !!item)
                .filter((item) => { var _a; return !((_a = this.data[item.account]) === null || _a === void 0 ? void 0 : _a[item.currency]); })
                .filter((item) => { var _a; return !((_a = this.loading[item.account]) === null || _a === void 0 ? void 0 : _a[item.currency]); });
            this.loading = filtered.reduce((acc, item) => (Object.assign(Object.assign({}, acc), { [item.account]: Object.assign(Object.assign({}, acc[item.account]), { [item.currency]: true }) })), Object.assign({}, this.loading));
            filtered.forEach((item) => {
                var _a;
                walletApi.v2
                    .getAddresses({
                    accountId: item.account,
                    currency: (_a = item.currency) !== null && _a !== void 0 ? _a : "",
                })
                    .then((resp) => {
                    runInAction(() => {
                        this.data = Object.assign(Object.assign({}, this.data), { [item.account]: Object.assign(Object.assign({}, this.data[item.account]), { [item.currency]: new AddressList(resp.data) }) });
                    });
                })
                    .catch((e) => {
                    console.error(e);
                    runInAction(() => {
                        this.data = Object.assign(Object.assign({}, this.data), { [item.account]: Object.assign(Object.assign({}, this.data[item.account]), { [item.currency]: new AddressList([
                                    {
                                        accountId: item.account,
                                        unavailable: true,
                                    },
                                ]) }) });
                    });
                })
                    .finally(() => {
                    runInAction(() => {
                        this.loading = Object.assign(Object.assign({}, this.loading), { [item.account]: Object.assign(Object.assign({}, this.loading[item.account]), { [item.currency]: false }) });
                    });
                });
            });
        });
    }
}
