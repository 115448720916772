import { observer } from "mobx-react-lite";
import * as React from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { NewPasswordForm } from "@/pages/PasswordResetPage/components/NewPasswordForm";
import { PasswordResetForm } from "@/pages/PasswordResetPage/components/PasswordResetForm";
import { ResetCodeForm } from "@/pages/PasswordResetPage/components/ResetCodeForm";
import { PasswordResetPageStore, PasswordResetStep } from "@/pages/PasswordResetPage/stores/PasswordResetPageStore";
import { ROUTES } from "@/routes";
import { useProvider, useStore } from "@/shared/hooks/useStore";
export const PasswordResetPage = observer(() => {
    const navigate = useNavigate();
    const page = useStore(PasswordResetPageStore);
    const PageProvider = useProvider(PasswordResetPageStore);
    React.useEffect(() => {
        if (page.success) {
            navigate(generatePath(ROUTES.signin));
        }
    }, [page.success, navigate]);
    return (React.createElement(PageProvider, { value: page }, page.step === PasswordResetStep.Email
        ? React.createElement(PasswordResetForm, null)
        : page.step === PasswordResetStep.Code
            ? React.createElement(ResetCodeForm, null)
            : page.step === PasswordResetStep.Password
                ? React.createElement(NewPasswordForm, null)
                : null));
});
