var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { styled } from "@mui/material/styles";
import { borders as MUBorders, compose, display, flexbox, grid, positions, sizing, spacing, } from "@mui/system";
import classNames from "classnames";
import React, { memo } from "react";
import { BaseIcon } from "../BaseIcon";
import { Box } from "../Box";
import { Typography } from "../Typography";
export const ImplAlert = (_a) => {
    var { text, className } = _a, props = __rest(_a, ["text", "className"]);
    return (React.createElement(Box, Object.assign({ bgColor: "black-alert", borderRadius: "12px", padding: "20px", display: "flex", alignItems: "center", gap: "15px", boxSizing: "border-box", className: classNames(className, "Alert") }, props),
        React.createElement(BaseIcon, { icon: "AlertClose", size: 28, color: "general-red" }),
        React.createElement(Typography, { type: "text12", color: "general-white", textAlign: "left", wordBreak: "break-word" }, text)));
};
export const FormAlert = memo(styled(ImplAlert, {
    shouldForwardProp: (prop) => !compose(spacing, sizing, flexbox, grid, display, positions)
        .filterProps.includes(prop),
})(compose(spacing, sizing, flexbox, grid, display, MUBorders, positions)));
