import React from "react";
import { EMAIL_HELP } from "@/config";
import { Box, Button, Typography } from "@/shared/components";
import { Popup } from "@/shared/components/Popup";
import { PopupBody } from "@/shared/components/Popup/Body";
import { DATE_FORMAT, formatDate } from "@/shared/lib";
export const FailPopup = ({ message, onClose, onSubmit }) => (React.createElement(Popup, { width: "450px", bgColor: "black-1200", onClose: onClose },
    React.createElement(PopupBody, { spaceBetween: true, padTop: true },
        React.createElement(Box, { gap: "50px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between" },
            React.createElement(Typography, { color: "general-white", sx: { opacity: 0.6 } }, formatDate(new Date().toISOString(), DATE_FORMAT.date_with_time)),
            React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "center" },
                React.createElement(Typography, { type: "text28Bold", variant: "h3", mb: "10px", textAlign: "center", color: "general-white" }, "Something went wrong"),
                message
                    ? (React.createElement(Typography, { color: "black-500", wordBreak: "break-word" }, message))
                    : (React.createElement(React.Fragment, null,
                        React.createElement(Typography, { color: "black-500" }, "We couldn't process your request."),
                        React.createElement(Typography, { color: "black-500" }, "Please try again."))))),
        React.createElement(Box, { display: "flex", flexDirection: "column", width: "100%", alignItems: "center", gap: "20px" },
            React.createElement(Button, { width: "100%", variant: "White", onClick: onSubmit }, "Try again"),
            React.createElement(Typography, { onClick: () => window.open(`mailto:${EMAIL_HELP}`), color: "general-white" }, "Contact support")))));
