import { styled } from "@mui/material/styles";
import { Dialog } from "../Dialog";
export const StyledDialog = styled(Dialog, {
    shouldForwardProp: (prop) => !prop.startsWith("$"),
})(({ $bgColor, $disablePadding, $isMobileFull, theme }) => ({
    "& .MuiDialog-paper": {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: $disablePadding ? "0" : "50px",
        boxSizing: "border-box",
        boxShadow: "none",
        borderRadius: "20px",
        background: theme.colors[$bgColor],
        width: "100%",
        maxWidth: "450px",
        [theme.breakpoints.down("md")]: Object.assign({}, ($isMobileFull && {
            borderRadius: "0",
            margin: "0",
            width: "100vw",
            maxHeight: "100vh",
            height: "100vh",
            maxWidth: "100vw",
        })),
    },
}));
