var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Autocomplete as MUIAutocomplete, } from "@mui/material";
import { styled } from "@mui/material/styles";
import { compose, spacing } from "@mui/system";
import classNames from "classnames";
import React, { useState } from "react";
import { BaseIcon } from "../BaseIcon";
import { BaseInput } from "../Input";
import { Typography } from "../Typography";
import { getOptionLabel } from "./lib";
import { StyledAutocomplete } from "./styled";
const ImplAutocomplete = (_a, ref) => {
    var { multiple, placeholder, freeSolo, groupBy, showChips = true, onChange, size, width, maxHeightList, FormControlProps, inputProps, renderInput, isVirtualized, onBlur, options, InputProps: AutocompleteInputProps, maxItemChecked, value, isLoading, disableClearable, label, ListboxProps = {} } = _a, props = __rest(_a, ["multiple", "placeholder", "freeSolo", "groupBy", "showChips", "onChange", "size", "width", "maxHeightList", "FormControlProps", "inputProps", "renderInput", "isVirtualized", "onBlur", "options", "InputProps", "maxItemChecked", "value", "isLoading", "disableClearable", "label", "ListboxProps"]);
    const [inputValue, setInputValue] = useState("");
    const hasNoSelected = Array.isArray(value) ? !value.length : !value;
    const isEmptyAutocomplete = !inputValue && !options.length && hasNoSelected;
    // const listboxProps: object = {
    //     inputValue,
    //     maxHeightList,
    //     isVirtualized,
    //     isGroup: !!groupBy,
    //     role: "listbox",
    //     ...ListboxProps,
    // }
    const handleChange = (event, changedValue, reason, details) => {
        // To clear input value without changing selected options
        if (multiple && inputValue && reason === "clear")
            return;
        // To prevent selection changing on click Backspace button
        if (multiple && (event === null || event === void 0 ? void 0 : event.key) === "Backspace")
            return;
        // To prevent selection on out of max selected items count
        if (multiple && Array.isArray(changedValue) && maxItemChecked
            && changedValue.length > maxItemChecked) {
            return;
        }
        onChange === null || onChange === void 0 ? void 0 : onChange(event, changedValue, reason, details);
    };
    return (React.createElement(StyledAutocomplete, { "$smallList": multiple && showChips, "$width": width, "$maxHeightList": maxHeightList },
        React.createElement(MUIAutocomplete, Object.assign({ options: showChips && !options.length ? [{ key: "empty" }] : options, id: "SW-Autocomplete", inputValue: inputValue, onInputChange: (_, value) => setInputValue(value), groupBy: groupBy, disableClearable: disableClearable !== null && disableClearable !== void 0 ? disableClearable : true, onChange: handleChange, freeSolo: freeSolo, clearOnBlur: freeSolo && multiple, openOnFocus: true, multiple: multiple, disableCloseOnSelect: multiple, 
            // ListboxComponent={ListBox}
            componentsProps: isEmptyAutocomplete
                ? { paper: { sx: { display: "none" } } }
                : undefined, value: value, 
            // clearIcon={<BaseIcon icon="X" size={10} p="4px" opacity="0.4" data-testid="button-clear" />}
            popupIcon: React.createElement(BaseIcon, { icon: "DropDown", size: 24, p: "0", color: "black-500" }), renderInput: renderInput
                || ((_a) => {
                    var _b = _a.InputProps, { ref: containerRef } = _b, InputProps = __rest(_b, ["ref"]), { InputLabelProps } = _a, params = __rest(_a, ["InputProps", "InputLabelProps"]);
                    return (React.createElement(BaseInput, Object.assign({}, params, { className: classNames({ "hasOptionsValue": !!(value === null || value === void 0 ? void 0 : value.length) }), containerRef: containerRef, inputProps: Object.assign(Object.assign({ placeholder }, inputProps), params.inputProps), size: size, width: width, inputRef: ref, onBlur: onBlur, label: label, InputProps: Object.assign(Object.assign({}, InputProps), AutocompleteInputProps), error: !!(FormControlProps === null || FormControlProps === void 0 ? void 0 : FormControlProps.error) })));
                }), noOptionsText: React.createElement(Typography, null, "No Options"), getOptionLabel: getOptionLabel, 
            // ListboxProps={listboxProps}
            loading: isLoading }, props))));
};
const BaseAutocomplete = React.forwardRef(ImplAutocomplete);
export const Autocomplete = styled(BaseAutocomplete, {
    shouldForwardProp: (prop) => !spacing.filterProps.includes(prop),
})(compose(spacing));
