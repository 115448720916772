import { observer } from "mobx-react-lite";
import * as React from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { CreateAccountPopup } from "@/features/create-account/components/CreateAccountPopup";
import { BankItem } from "@/pages/BankAccountsPage/components/Item";
import { ROUTES } from "@/routes";
import { BaseIcon, Box, Button, Typography } from "@/shared/components";
import { AccountTable } from "@/shared/components/AccountTable";
import { EmptyTable } from "@/shared/components/EmptyTable";
import { useMedia } from "@/shared/hooks/useMedia";
import { useStoreContext } from "@/shared/hooks/useStore";
import { useToggler } from "@/shared/hooks/useToggler";
import { AccountListStore } from "@/shared/stores/AccountListStore";
import { AddressListStore } from "@/shared/stores/AddressListStore";
import { BalanceListStore } from "@/shared/stores/BalanceListStore";
export const BankAccountsPage = observer(() => {
    const media = useMedia();
    const popup = useToggler();
    const navigate = useNavigate();
    const accounts = useStoreContext(AccountListStore);
    const balanceList = useStoreContext(BalanceListStore);
    const addressList = useStoreContext(AddressListStore);
    const loaded = accounts.fiat.every(item => {
        var _a, _b;
        return (((_a = balanceList.data[item.account]) === null || _a === void 0 ? void 0 : _a.balance)
            && !!((_b = addressList.data[item.account]) === null || _b === void 0 ? void 0 : _b[item.currency]));
    });
    React.useEffect(() => {
        balanceList.sync(...accounts.fiat.map(acc => acc.account));
    }, [balanceList, accounts.fiat]);
    React.useEffect(() => {
        addressList.sync(...accounts.fiat);
    }, [accounts.fiat, addressList]);
    return (React.createElement(React.Fragment, null,
        popup.enabled && (React.createElement(CreateAccountPopup, { onClose: popup.off, onSuccess: id => {
                navigate(generatePath(ROUTES.bankAccountsItem, { id }));
                accounts.sync();
            } })),
        React.createElement(Box, { display: "flex", flexDirection: "column", gap: "40px" },
            React.createElement(Box, { display: "flex", alignItems: "center", justifyContent: "space-between" },
                React.createElement(Typography, { type: media.desktop ? "text32Bold" : "text24Bold" }, "Bank accounts"),
                React.createElement(Button, { size: "s", variant: "Black", onClick: popup.on },
                    React.createElement(Box, { display: "flex", alignItems: "center", gap: "5px" },
                        React.createElement(BaseIcon, { size: 13, icon: "Plus", containerSize: 24 }),
                        media.desktop ? "Open bank account" : "Open"))),
            accounts.fiat.length
                ? (React.createElement(AccountTable, { loaded: loaded }, accounts.fiat.map(account => {
                    var _a, _b, _c, _d;
                    const item = (_a = addressList.data[account.account]) === null || _a === void 0 ? void 0 : _a[account.currency];
                    return (React.createElement(BankItem, { isEmpty: (_b = item === null || item === void 0 ? void 0 : item.first) === null || _b === void 0 ? void 0 : _b.isEmpty, key: account.account, amount: (_c = balanceList.data[account.account]) === null || _c === void 0 ? void 0 : _c.balance, id: account.account, symbol: account.currency, accNumber: (_d = item === null || item === void 0 ? void 0 : item.first) === null || _d === void 0 ? void 0 : _d.accountNumber, desc: account.shortName }));
                })))
                : React.createElement(EmptyTable, { message: "You\u2019re only one step away from opening a bank account" }))));
});
