var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { makeAutoObservable, runInAction, toJS } from "mobx";
import { registrationApi } from "@/api/registration";
import { TimerStore } from "@/shared/stores/TimerStore";
import { groupErrorsByKey, parseApiError } from "@/shared/utils/error";
import { isEmail } from "@/shared/utils/is-email";
import { validatePassword } from "@/shared/utils/password";
export var PasswordResetStep;
(function (PasswordResetStep) {
    PasswordResetStep[PasswordResetStep["Email"] = 0] = "Email";
    PasswordResetStep[PasswordResetStep["Code"] = 1] = "Code";
    PasswordResetStep[PasswordResetStep["Password"] = 2] = "Password";
})(PasswordResetStep || (PasswordResetStep = {}));
export class PasswordResetPageStore {
    constructor() {
        this.email = "";
        this.code = "";
        this.password = "";
        this.passwordRepeat = "";
        this.payload = "";
        this.step = PasswordResetStep.Email;
        this.loading = false;
        this.errors = [];
        this.timer = new TimerStore();
        this.success = false;
        makeAutoObservable(this, {}, { autoBind: true });
    }
    dispose() {
        this.timer.dispose();
    }
    submitEmail() {
        return __awaiter(this, void 0, void 0, function* () {
            let errors = [];
            let { step } = this;
            runInAction(() => {
                this.loading = true;
            });
            try {
                yield registrationApi.user.resetPasswordConfirm({
                    username: this.email,
                });
                this.timer.start();
                step = PasswordResetStep.Code;
            }
            catch (e) {
                console.error(e);
                errors = parseApiError(e);
            }
            runInAction(() => {
                this.loading = false;
                this.errors = errors;
                this.step = step;
            });
        });
    }
    submitCode() {
        return __awaiter(this, void 0, void 0, function* () {
            let errors = [];
            let { step } = this;
            let payload = "";
            runInAction(() => {
                this.loading = true;
            });
            try {
                const resp = yield registrationApi.user.resetPasswordConfirm({
                    username: this.email,
                    code: this.code,
                });
                step = PasswordResetStep.Password;
                payload = resp.data;
            }
            catch (e) {
                console.error(e);
                errors = parseApiError(e);
            }
            runInAction(() => {
                this.loading = false;
                this.errors = errors;
                this.step = step;
                this.payload = payload;
            });
        });
    }
    submitPassword() {
        return __awaiter(this, void 0, void 0, function* () {
            let errors = [];
            let success = false;
            runInAction(() => {
                this.loading = true;
            });
            try {
                yield registrationApi.user.resetPassword({
                    newPassword: this.password,
                    signedPayload: this.payload,
                    username: this.email,
                });
                success = true;
            }
            catch (e) {
                console.error(e);
                errors = parseApiError(e);
            }
            runInAction(() => {
                this.loading = false;
                this.errors = errors;
                this.success = success;
            });
        });
    }
    reset() {
        this.code = "";
        this.password = "";
        this.passwordRepeat = "";
        this.step = PasswordResetStep.Email;
        this.errors = [];
    }
    get passwordValidation() {
        return validatePassword(this.password);
    }
    get errorsByKey() {
        return groupErrorsByKey(toJS(this.errors));
    }
    get isEmailValid() {
        return isEmail(this.email);
    }
    get codeIsValid() {
        return this.code.length > 0;
    }
    get isPasswordValid() {
        return this.passwordValidation.length
            && this.passwordValidation.lowercase
            && this.passwordValidation.uppercase
            && this.passwordValidation.special
            && this.passwordValidation.digit
            && this.password === this.passwordRepeat;
    }
}
