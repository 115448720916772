import { styled } from "@mui/material/styles";
import * as React from "react";
import { Overlay } from "@/shared/components/Overlay";
export const Popup = ({ width = "545px", children, bgColor, onClose, }) => (React.createElement(Overlay, null,
    React.createElement(Bg, { onClick: onClose }),
    React.createElement(Container, { width: width, bgColor: bgColor }, children)));
const Bg = styled("div")(() => ({
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: "-1",
    background: "rgba(0,0,0,0.2)",
    animation: "open 0.25s",
    "@keyframes open": {
        "from": {
            opacity: 0,
        },
        "to": {
            opacity: 1,
        },
    },
}));
const Container = styled("div")(({ width, theme, bgColor }) => ({
    width: "100%",
    maxWidth: width,
    position: "relative",
    margin: "20px",
    borderRadius: "22px",
    backgroundColor: theme.colors["general-white"],
    animation: "show 0.25s",
    background: bgColor ? theme.colors[bgColor] : undefined,
    "@keyframes show": {
        "from": {
            opacity: 0,
            transform: "translateY(10px)",
        },
        "to": {
            opacity: 1,
            transform: "none",
        },
    },
}));
