import { styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import * as React from "react";
export const UserPick = observer(({ firstName, lastName, name, size = 36, }) => {
    var _a;
    return (React.createElement(Wrapper, { size: size }, firstName && lastName
        ? (React.createElement(React.Fragment, null,
            firstName[0],
            lastName[0]))
        : name
            ? (React.createElement(React.Fragment, null,
                name.split(" ")[0][0], (_a = name.split(" ")[1]) === null || _a === void 0 ? void 0 :
                _a[0]))
            : null));
});
const Wrapper = styled("div") `
    width: ${props => `${props.size}px`};
    height: ${props => `${props.size}px`};
    border-radius: ${props => `${props.size}px`};
    background: ${props => props.theme.colors["light-blue"]};
    font-size: ${props => props.size > 24 ? "15px" : "10px"};
    color: ${props => props.theme.colors["deep-blue-500"]};
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
`;
