import { makeAutoObservable, runInAction } from "mobx";
export var UserCurrency;
(function (UserCurrency) {
    UserCurrency["Usd"] = "usd";
    UserCurrency["Eur"] = "eur";
})(UserCurrency || (UserCurrency = {}));
const STORAGE_KEY = "currency";
export class UserCurrencyStore {
    constructor() {
        this.selected = undefined;
        makeAutoObservable(this, {}, { autoBind: true });
    }
    init() {
        window.addEventListener("storage", this.sync);
        this.sync();
    }
    dispose() {
        window.removeEventListener("storage", this.sync);
    }
    sync() {
        let selected = UserCurrency.Usd;
        try {
            const test = localStorage.getItem(STORAGE_KEY);
            if (test === UserCurrency.Eur) {
                selected = UserCurrency.Eur;
            }
        }
        catch (e) {
            console.error(e);
        }
        runInAction(() => {
            this.selected = selected;
        });
    }
    setSelect(value) {
        try {
            this.selected = value;
            localStorage.setItem(STORAGE_KEY, value);
        }
        catch (e) {
            console.error(e);
        }
    }
}
