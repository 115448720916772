import { observer } from "mobx-react-lite";
import * as React from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { ROUTES } from "@/routes";
import { Box, Button, Typography } from "@/shared/components";
import { Popup } from "@/shared/components/Popup";
export const ReconcilingTransfer = observer(() => {
    const navigate = useNavigate();
    return (React.createElement(Popup, { width: "450px" },
        React.createElement(Box, { bgColor: "general-green", borderRadius: "20px", padding: "50px", minHeight: "400px", maxHeight: "600px", height: "100vh", boxSizing: "border-box", display: "flex", flexDirection: "column", justifyContent: "space-between", gap: "74px" },
            React.createElement(Box, { flexGrow: "4", display: "flex", flexDirection: "column", justifyContent: "center" },
                React.createElement(Typography, { type: "text28", fontWeight: 500, variant: "h2", textAlign: "center", margin: "0 0 10px", color: "general-white" }, "Almost there!"),
                React.createElement(Typography, { type: "text14", fontWeight: 500, textAlign: "center", color: "general-white" }, "We\u2019re reconciling your crypto transfer and will email you once your application and docs are off to the bank. Approval updates coming soon!")),
            React.createElement(Button, { variant: "White", width: "100%", onClick: () => {
                    navigate(generatePath(ROUTES.root));
                } }, "Got it!"))));
});
