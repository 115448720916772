export class Reactions {
    constructor() {
        this.reactions = [];
    }
    create(...reactions) {
        this.destroy();
        this.reactions = this.reactions.concat(reactions);
    }
    destroy() {
        this.reactions.forEach(item => item());
        this.reactions = [];
    }
}
