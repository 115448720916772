var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { observer } from "mobx-react-lite";
import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import { generatePath, useNavigate } from "react-router-dom";
import { FormLayout } from "@/pages/KYBPage/components/FormLayout";
import { FormRow } from "@/pages/KYBPage/components/FormRow";
import { ROUTES } from "@/routes";
import { FormControlInput } from "@/shared/components";
import { PhoneInput } from "@/shared/components/FormControlInput/PhoneInput";
import { useStoreContext } from "@/shared/hooks/useStore";
import { CompanyInfoStore } from "@/shared/stores/CompanyInfoStore";
import { RequiredFieldMessage, WrongFormatMessage } from "@/shared/utils/error";
import { EMAIL_REGEXP } from "@/shared/utils/is-email";
export const Form1 = observer(() => {
    var _a;
    const navigate = useNavigate();
    const companyInfo = useStoreContext(CompanyInfoStore);
    const form = useForm();
    const submit = form.handleSubmit((formData) => __awaiter(void 0, void 0, void 0, function* () {
        companyInfo.update(formData).then((success) => {
            if (success)
                navigate(generatePath(ROUTES.kybFormId, { id: 2 }));
        });
    }));
    React.useEffect(() => {
        form.reset(companyInfo.companyInfo);
    }, [form, companyInfo.companyInfo]);
    return (React.createElement(FormLayout, { onSubmit: submit, onBack: () => navigate(generatePath(ROUTES.kybForm)), index: 1, error: (_a = companyInfo.errors[0]) === null || _a === void 0 ? void 0 : _a.description, title: "Personal details", desc: "Please provide the details of the company\u2019s representative. Latin letters only, please", loading: !companyInfo.ready || companyInfo.loading },
        React.createElement(FormRow, null,
            React.createElement(Controller, { control: form.control, name: "meta.firstName", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: ({ field, fieldState: { error } }) => (React.createElement(FormControlInput, Object.assign({}, field, { disabled: companyInfo.loading || field.disabled, label: "First name", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" }))) }),
            React.createElement(Controller, { control: form.control, name: "meta.lastName", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: ({ field, fieldState: { error } }) => (React.createElement(FormControlInput, Object.assign({}, field, { disabled: companyInfo.loading || field.disabled, label: "Last name", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" }))) })),
        React.createElement(FormRow, null,
            React.createElement(Controller, { control: form.control, name: "phone", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: ({ field, fieldState: { error } }) => (React.createElement(PhoneInput, { onChange: field.onChange, value: field.value, error: error === null || error === void 0 ? void 0 : error.message, disabled: companyInfo.loading || field.disabled })) }),
            React.createElement(Controller, { control: form.control, name: "email", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                    pattern: {
                        value: EMAIL_REGEXP,
                        message: WrongFormatMessage.description,
                    },
                }, render: ({ field, fieldState: { error } }) => (React.createElement(FormControlInput, Object.assign({}, field, { disabled: companyInfo.loading || field.disabled, label: "Email", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" }))) }))));
});
