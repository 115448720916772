import { observer } from "mobx-react-lite";
import * as React from "react";
import { generatePath, Link, useNavigate } from "react-router-dom";
import { IndividualItem } from "@/pages/KYBPage/components/Form5/IndividualItem";
import { FormLayout } from "@/pages/KYBPage/components/FormLayout";
import { IndividualListStore } from "@/pages/KYBPage/stores/IndividualListStore";
import { ROUTES } from "@/routes";
import { Box } from "@/shared/components";
import { Button } from "@/shared/components/Button";
import { Loader } from "@/shared/components/Loader";
import { useStoreContext } from "@/shared/hooks/useStore";
export const IndividualList = observer(() => {
    const navigate = useNavigate();
    const individualList = useStoreContext(IndividualListStore);
    return (React.createElement(FormLayout, { index: 5, showSubmitBtns: individualList.list.length > 0, onSubmit: () => navigate(generatePath(ROUTES.kybFormId, { id: 6 })), onBack: () => navigate(generatePath(ROUTES.kybFormId, { id: 4 })), title: "Company Roles and Structure", desc: "Please provide all the persons who play role in the company. You need to enter information about all shareholders. If there are more than 10 of them, then you need to enter data on the 10 largest shareholders.", loading: !individualList.ready || individualList.loading },
        individualList.ready
            ? (individualList.list.length > 0 && (React.createElement(React.Fragment, null,
                React.createElement(Box, { display: "flex", gap: "20px", flexDirection: "column" }, individualList.list.map(item => (React.createElement(IndividualItem, { key: item.id, uuid: item.id })))),
                React.createElement(Box, { bgColor: "black-100", height: "1px" }))))
            : (React.createElement(Box, { bgColor: "black-50", padding: "20px 30px", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "22px" },
                React.createElement(Loader, { size: 32, containerSize: 51 }))),
        React.createElement(Box, { display: "flex", alignItems: "center", justifyContent: "center", bgColor: "deep-blue-500-transparent-2", borderRadius: "22px", borderWidth: "1px", borderColor: "deep-blue-500", borderStyle: "dashed", height: "80px" },
            React.createElement(Button, { width: "auto", size: "s", variant: "LightBlue", paddingRight: "25px", paddingLeft: "15px", icon: "Plus", iconSize: 14, IconProps: {
                    containerSize: 24,
                }, LinkComponent: Link, to: generatePath(ROUTES.kybFormId, {
                    id: 5,
                    param: "create",
                }) }, "Add individual"))));
});
