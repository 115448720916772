import { action } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { PhonePageStore } from "@/pages/PhonePage/stores/PhonePageStore";
import { AuthForm } from "@/shared/components/AuthForm";
import { Button } from "@/shared/components/Button";
import { PhoneInput } from "@/shared/components/FormControlInput/PhoneInput";
import { useStoreContext } from "@/shared/hooks/useStore";
export const PhoneForm = observer(() => {
    var _a;
    const page = useStoreContext(PhonePageStore);
    return (React.createElement(AuthForm, { onSubmit: page.submitPhone, title: "Enter your phone number", 
        // desc="We will send you a SMS code. Verify your phone number to even better protect your account."
        content: React.createElement(PhoneInput, { value: page.phone, error: (_a = page.errors[0]) === null || _a === void 0 ? void 0 : _a.description, disabled: page.loading, onChange: action(e => {
                page.phone = e;
            }) }), footer: React.createElement(Button, { type: "submit", width: "100%", disabled: page.loading || !page.isPhoneValid }, "Continue") }));
});
