var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { makeAutoObservable, reaction, runInAction } from "mobx";
import { walletApi } from "@/api/wallet/api";
import { EventType } from "@/shared/stores/EventsStore";
import { Reactions } from "@/shared/utils/reactions";
export class AccountListStore {
    constructor(auth, events) {
        this.auth = auth;
        this.events = events;
        this.loading = false;
        this.initialized = false;
        this.list = [];
        this.reactions = new Reactions();
        makeAutoObservable(this, {}, { autoBind: true });
    }
    init() {
        this.reactions.create(this.events.reaction(EventType.AccountCreated, this.sync), reaction(() => [this.auth.authorized, this.auth.initialized], this.sync, {
            fireImmediately: true,
        }));
    }
    dispose() {
        this.list = [];
        this.loading = false;
        this.reactions.destroy();
    }
    sync() {
        return __awaiter(this, void 0, void 0, function* () {
            let list = [];
            let initialized = false;
            runInAction(() => {
                this.loading = true;
            });
            if (this.auth.initialized) {
                if (this.auth.authorized) {
                    try {
                        const resp = yield walletApi.account.listAccount();
                        list = resp.data.filter((item) => {
                            if (item.accountType === "LAYER2"
                                && "currency" in item
                                && !!item.currency) {
                                return true;
                            }
                            return false;
                        });
                    }
                    catch (e) {
                        console.error(e);
                    }
                }
                initialized = true;
            }
            runInAction(() => {
                this.loading = false;
                this.list = list;
                this.initialized = initialized;
            });
        });
    }
    get fiat() {
        return this.list.filter((item) => { var _a; return ((_a = item.blockchain) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === "fiat" && item.currency; });
    }
    get crypto() {
        return this.list.filter((item) => { var _a; return item.blockchain && ((_a = item.blockchain) === null || _a === void 0 ? void 0 : _a.toLowerCase()) !== "fiat"; });
    }
    get byId() {
        return this.list.reduce((acc, item) => (Object.assign(Object.assign({}, acc), { [item.account]: item })), {});
    }
    get fiatById() {
        return this.fiat.reduce((acc, item) => (Object.assign(Object.assign({}, acc), { [item.account]: item })), {});
    }
    get cryptoById() {
        return this.crypto.reduce((acc, item) => (Object.assign(Object.assign({}, acc), { [item.account]: item })), {});
    }
}
