import BigNumber from "bignumber.js";
import { makeAutoObservable } from "mobx";
export class ExchangeFormStore {
    constructor(balances) {
        this.balances = balances;
        this.fiat1Id = undefined;
        this.fiat2Id = undefined;
        this.cryptoId = undefined;
        this.fiat1Amount = undefined;
        this.fiat2Amount = undefined;
        this.cryptoAmount = undefined;
        makeAutoObservable(this, {}, { autoBind: true });
    }
    get cryptoBalance() {
        return this.cryptoId ? this.balances.data[this.cryptoId] : undefined;
    }
    get fiat1Balance() {
        return this.fiat1Id ? this.balances.data[this.fiat1Id] : undefined;
    }
    get fiat2Balance() {
        return this.fiat2Id ? this.balances.data[this.fiat2Id] : undefined;
    }
    get cryptoEnough() {
        return this.cryptoAmount && this.cryptoBalance
            ? new BigNumber(this.cryptoAmount).lte(this.cryptoBalance.balance)
            : undefined;
    }
    get fiat1Enough() {
        return this.fiat1Amount && this.fiat1Balance
            ? new BigNumber(this.fiat1Amount).lte(this.fiat1Balance.balance)
            : undefined;
    }
    reset() {
        this.fiat1Amount = undefined;
        this.fiat2Amount = undefined;
        this.cryptoAmount = undefined;
    }
}
