import { makeAutoObservable } from "mobx";
export class MenuStore {
    constructor() {
        this.visible = false;
        makeAutoObservable(this, {}, { autoBind: true });
    }
    toggle() {
        this.visible = !this.visible;
    }
    open() {
        this.visible = true;
    }
    close() {
        this.visible = false;
    }
}
