let counter = 0;
export const blockPage = () => {
    const root = document.getElementById("root");
    counter += 1;
    if (root) {
        root.style.top = `-${window.scrollY}px`;
        root.style.left = "0";
        root.style.right = "0";
        root.style.position = "fixed";
        root.style.pointerEvents = "none";
        window.scrollTo({
            // @ts-ignore
            behavior: "instant",
            top: 0,
        });
    }
};
export const unblockPage = () => {
    const root = document.getElementById("root");
    counter -= 1;
    if (root && counter === 0) {
        const scrollTop = Math.abs(parseInt(root.style.top, 10));
        root.style.position = "";
        root.style.left = "";
        root.style.right = "";
        root.style.top = "";
        root.style.pointerEvents = "";
        window.scrollTo({
            // @ts-ignore
            behavior: "instant",
            top: scrollTop,
        });
    }
};
