var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { makeAutoObservable, runInAction } from "mobx";
import { registrationApi } from "@/api/registration";
import { parseApiError } from "@/shared/utils/error";
export class IndividualFormStore {
    constructor(individualList) {
        this.individualList = individualList;
        this.loading = false;
        this.errors = [];
        makeAutoObservable(this, {}, { autoBind: true });
    }
    dispose() {
        this.loading = false;
        this.errors = [];
    }
    submitForm(data, uuid) {
        return __awaiter(this, void 0, void 0, function* () {
            let success = false;
            let errors = [];
            runInAction(() => {
                this.loading = true;
            });
            try {
                if (uuid) {
                    yield registrationApi.user.updateCompanyInfoIndividual(uuid, data);
                }
                else {
                    yield registrationApi.user.createCompanyInfoIndividual(data);
                }
                yield this.individualList.sync();
                success = true;
            }
            catch (e) {
                console.error(e);
                errors = parseApiError(e);
            }
            runInAction(() => {
                this.errors = errors;
                this.loading = false;
            });
            return success;
        });
    }
    delete(uuid) {
        return __awaiter(this, void 0, void 0, function* () {
            let success = false;
            let errors = [];
            runInAction(() => {
                this.loading = true;
            });
            try {
                yield registrationApi.user.deleteCompanyInfoIndividual(uuid);
                yield this.individualList.sync();
                success = true;
            }
            catch (e) {
                console.error(e);
                errors = parseApiError(e);
            }
            runInAction(() => {
                this.errors = errors;
                this.loading = false;
            });
            return success;
        });
    }
}
