import { styled } from "@mui/material/styles";
import { Box } from "../Box";
const height = {
    s: "26px",
    m: "50px",
    l: "50px",
};
export const StyledBox = styled(Box, {
    shouldForwardProp: (prop) => !prop.startsWith("$"),
})(({ $height }) => ({
    height: height[$height],
}));
