var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { styled } from "@mui/material/styles";
import { compose, spacing } from "@mui/system";
import React, { memo } from "react";
import { FormAlert } from "../FormAlert";
import { Select } from "../Select/Select";
import { Typography } from "../Typography";
import { StyledContainer } from "./styled";
export const ImplFormControlSelect = React
    .forwardRef((_a, ref) => {
    var { error, errorPosition = "relative", variantError = "text" } = _a, props = __rest(_a, ["error", "errorPosition", "variantError"]);
    return (React.createElement(StyledContainer, { "$errorPosittion": errorPosition },
        React.createElement(Select, Object.assign({ ref: ref }, props, { FormControlProps: { error, errorPosition, variantError } })),
        error && typeof error === "string" && variantError === "alert" && React.createElement(FormAlert, { text: error }),
        error && typeof error === "string" && variantError === "text" && (React.createElement(Typography, { type: "text14", color: "general-red" }, error))));
});
export const FormControlSelect = memo(styled(ImplFormControlSelect)(compose(spacing)));
