import { observer } from "mobx-react-lite";
import * as React from "react";
import { generatePath, Link } from "react-router-dom";
import { Menu } from "@/pages/UserPage/components/Menu";
import { SideMenu } from "@/pages/UserPage/components/SideMenu";
import { ROUTES } from "@/routes";
import { BaseIcon } from "@/shared/components/BaseIcon";
import { Box } from "@/shared/components/Box";
import { Loader } from "@/shared/components/Loader";
import { useBack } from "@/shared/hooks/useBack";
import { useMedia } from "@/shared/hooks/useMedia";
import { useStoreContext } from "@/shared/hooks/useStore";
import { MenuStore } from "@/shared/stores/MenuStore";
export const OperationLayout = observer(({ main, card, side, loading }) => {
    const media = useMedia();
    const menu = useStoreContext(MenuStore);
    const back = useBack();
    return (React.createElement(React.Fragment, null,
        menu.visible && !media.desktop && React.createElement(SideMenu, null),
        React.createElement(Box, null,
            media.desktop && React.createElement(Menu, null),
            media.desktop && (React.createElement(BaseIcon, { icon: "Cross", containerSize: 45, size: 12, position: "absolute", top: "50px", right: "50px", borderRadius: "12px", bgColor: "general-white", onClick: back })),
            React.createElement(Box, { gap: "30px", boxSizing: "border-box", display: media.desktop ? "grid" : "flex", flexDirection: "column", bgColor: "general-white", padding: media.desktop ? "0 0 0 76px" : "0 20px 20px", minHeight: "100vh", gridTemplateColumns: media.desktop
                    ? "minmax(0, 60%) minmax(510px, 40%)"
                    : "minmax(0, 1fr)" },
                !media.desktop && (React.createElement(Box, { display: "flex", height: "72px", alignItems: "center", justifyContent: "space-between" },
                    React.createElement(Link, { to: generatePath(ROUTES.root) },
                        React.createElement(BaseIcon, { icon: "LogoSmall", size: 36 })),
                    React.createElement(BaseIcon, { icon: "Burger", size: 24, onClick: menu.open }))),
                React.createElement(Box, { display: "flex", alignItems: "flex-start", justifyContent: "center", paddingTop: media.desktop ? "155px" : undefined },
                    React.createElement(Box, { width: "100%", maxWidth: media.desktop ? "580px" : undefined }, main)),
                React.createElement(Box, { display: "flex", alignItems: "center", justifyContent: "center", bgColor: media.desktop ? "black-1200" : undefined }, loading
                    ? (React.createElement(Loader, { size: 24, color: media.desktop
                            ? "general-white"
                            : "black-1200" }))
                    : card
                        ? (React.createElement(Box, { borderColor: media.desktop
                                ? "black-1000"
                                : "black-200", borderStyle: "solid", borderWidth: "1px", borderRadius: media.desktop ? "22px" : "12px", padding: media.desktop ? "40px" : "30px", maxWidth: media.desktop ? "440px" : undefined, width: "100%", boxSizing: "border-box" }, card))
                        : side)))));
});
