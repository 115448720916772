import { makeAutoObservable, runInAction } from "mobx";
export var BalanceType;
(function (BalanceType) {
    BalanceType["All"] = "all";
    BalanceType["Crypto"] = "crypto";
    BalanceType["Fiat"] = "fiat";
})(BalanceType || (BalanceType = {}));
const STORAGE_KEY = "balance_type";
export class BalanceTypeStore {
    constructor() {
        this.type = undefined;
        makeAutoObservable(this, {}, { autoBind: true });
    }
    init() {
        window.addEventListener("storage", this.sync);
        this.sync();
    }
    dispose() {
        window.removeEventListener("storage", this.sync);
    }
    sync() {
        let type = BalanceType.All;
        try {
            const test = localStorage.getItem(STORAGE_KEY);
            if (test === BalanceType.Crypto) {
                type = BalanceType.Crypto;
            }
            else if (test === BalanceType.Fiat) {
                type = BalanceType.Fiat;
            }
        }
        catch (e) {
            console.error(e);
        }
        runInAction(() => {
            this.type = type;
        });
    }
    setType(value) {
        try {
            this.type = value;
            localStorage.setItem(STORAGE_KEY, value);
        }
        catch (e) {
            console.error(e);
        }
    }
}
