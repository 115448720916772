import { styled } from "@mui/material";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { Box } from "@/shared/components";
import { Loader } from "@/shared/components/Loader";
import { useMedia } from "@/shared/hooks/useMedia";
export const AccountTable = observer(({ loaded, children, }) => {
    const media = useMedia();
    return (React.createElement(Box, { bgColor: "general-white", borderRadius: "22px", padding: media.desktop ? "40px" : "20px 0" },
        React.createElement(Box, { position: "relative" },
            !loaded && (React.createElement(Loader, { position: "absolute", top: "50%", left: "50%", margin: "-12px 0 0 -12px", size: 24 })),
            React.createElement(List, { loaded: !!loaded, desktop: media.desktop }, children))));
});
const List = styled("div") `
    gap: 20px;
    display: grid;
    opacity: ${props => props.loaded ? 1 : 0};
    transition: opacity 0.2s ease;
    pointer-events: ${props => props.loaded ? "unset" : "none"};
    grid-template-columns: ${props => props.desktop ? "1fr 1fr" : "minmax(0, 1fr)"};
`;
