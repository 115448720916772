import { RegistrationApi } from "@/api/registration/types";
import { tokenStorage } from "@/api/tokenStorage";
import { REGISTRATION_API_BASE_URL } from "@/config";
const regApi = new RegistrationApi({
    baseURL: REGISTRATION_API_BASE_URL,
});
const queue = [];
let isRefreshing = false;
const applyAuthReqInterceptor = (instance) => {
    instance.interceptors.request.use((config) => {
        var _a;
        const accessToken = tokenStorage.getAccessToken();
        if (accessToken && !config.headers.Authorization) {
            // eslint-disable-next-line no-param-reassign
            config.headers = (_a = config.headers) !== null && _a !== void 0 ? _a : {};
            // eslint-disable-next-line no-param-reassign
            config.headers.Authorization = `Bearer ${accessToken}`;
        }
        return config;
    });
};
const applyAuthRespInterceptor = (instance) => {
    const respInterceptorId = instance.interceptors.response.use(resp => resp, err => {
        if (err.response
            && (err.response.status === 401 || err.response.status === 403)
            && err.config.url !== "/auth/token") {
            if (!isRefreshing) {
                isRefreshing = true;
                regApi.auth.getToken({
                    grant_type: "refresh_token",
                    refresh_token: tokenStorage.getRefreshToken(),
                }).then(resp => {
                    if (resp.data.access_token && resp.data.refresh_token) {
                        tokenStorage.setTokens(resp.data.access_token, resp.data.refresh_token);
                    }
                    else {
                        throw new Error("Tokens not founded");
                    }
                }).catch(err => {
                    tokenStorage.clean();
                    throw err;
                }).finally(() => {
                    isRefreshing = false;
                    instance.interceptors.response.eject(respInterceptorId);
                    queue.forEach(item => {
                        var _a;
                        const accessToken = tokenStorage.getAccessToken();
                        if (accessToken) {
                            item.config.headers = (_a = item.config.headers) !== null && _a !== void 0 ? _a : {};
                            item.config.headers.Authorization = `Bearer ${accessToken}`;
                        }
                        instance
                            .request(item.config)
                            .then(response => item.resolve(response))
                            .catch(err => item.reject(err));
                    });
                    queue.length = 0;
                    applyAuthRespInterceptor(instance);
                });
            }
            return new Promise((resolve, reject) => {
                queue.push({ config: err.config, resolve, reject });
            });
        }
        return Promise.reject(err);
    });
};
export const applyAuthInterceptor = (instance) => {
    applyAuthReqInterceptor(instance);
    applyAuthRespInterceptor(instance);
};
