import { observer } from "mobx-react-lite";
import * as React from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { PaymentFormCard } from "@/pages/PaymentPage/components/PaymentFormCard";
import { PaymentFormCrypto } from "@/pages/PaymentPage/components/PaymentFormCrypto";
import { PaymentParam, PaymentType, ROUTES } from "@/routes";
import { generateQueryPath } from "@/shared/utils/path";
export const PaymentFormPage = observer(() => {
    const [search] = useSearchParams();
    const type = search.get(PaymentParam.Type);
    const target = search.get(PaymentParam.Target);
    const plan = search.get(PaymentParam.Plan);
    switch (type) {
        case PaymentType.Crypto:
            return React.createElement(PaymentFormCrypto, null);
        case PaymentType.Card:
            return React.createElement(PaymentFormCard, null);
        default:
            return (React.createElement(Navigate, { to: generateQueryPath(ROUTES.payment, {}, {
                    [PaymentParam.Target]: target,
                    [PaymentParam.Plan]: plan,
                }), replace: true }));
    }
});
