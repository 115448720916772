var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { makeAutoObservable, runInAction, toJS } from "mobx";
import { registrationApi } from "@/api/registration";
import { TimerStore } from "@/shared/stores/TimerStore";
import { groupErrorsByKey, parseApiError, UnknownError } from "@/shared/utils/error";
export var PhonePageStep;
(function (PhonePageStep) {
    PhonePageStep[PhonePageStep["Phone"] = 0] = "Phone";
    PhonePageStep[PhonePageStep["SmsCode"] = 1] = "SmsCode";
})(PhonePageStep || (PhonePageStep = {}));
export class PhonePageStore {
    constructor(auth) {
        this.auth = auth;
        this.step = PhonePageStep.Phone;
        this.loading = false;
        this.phone = "";
        this.smsCode = "";
        this.errors = [];
        this.timer = new TimerStore();
        makeAutoObservable(this, {}, {
            autoBind: true,
        });
    }
    dispose() {
        this.timer.dispose();
    }
    submitPhone() {
        return __awaiter(this, void 0, void 0, function* () {
            let errors = [];
            // let { step } = this
            runInAction(() => {
                this.loading = true;
            });
            try {
                yield this.auth.requestScope("user_phone:write");
                yield registrationApi.user.createPhone({
                    phone: this.phone,
                });
                yield this.auth.sync();
                // this.timer.start()
                // step = PhonePageStep.SmsCode
            }
            catch (e) {
                console.error(e);
                errors = parseApiError(e);
            }
            runInAction(() => {
                this.errors = errors;
                this.loading = false;
                // this.step = step
            });
        });
    }
    submitSmsCode() {
        return __awaiter(this, void 0, void 0, function* () {
            let errors = [];
            runInAction(() => {
                this.loading = true;
            });
            try {
                const { data } = yield registrationApi.user.createPhone({
                    phone: this.phone,
                    code: this.smsCode,
                });
                if (data.confirmed) {
                    yield this.auth.sync();
                }
                else {
                    errors = [UnknownError];
                }
            }
            catch (e) {
                console.error(e);
                errors = parseApiError(e);
            }
            runInAction(() => {
                this.loading = false;
                this.errors = errors;
            });
        });
    }
    resetPhone() {
        this.step = PhonePageStep.Phone;
        this.smsCode = "";
        this.errors = [];
    }
    get isPhoneValid() {
        return this.phone.length > 0;
    }
    get isSmsCodeValid() {
        return this.smsCode.length > 0;
    }
    get errorsByKey() {
        return groupErrorsByKey(toJS(this.errors));
    }
}
