import { countries } from "@/shared/constants/countries";
import subdivisionDict from "@/shared/constants/subdivision.json";
export const getCountryCodeAlpha2 = (code) => {
    var _a;
    return (code.length === 3
        ? (_a = countries.find(item => item.codeAlpha3.toUpperCase() === code.toUpperCase())) === null || _a === void 0 ? void 0 : _a.codeAlpha2.toUpperCase()
        : code.length === 2
            ? code.toUpperCase()
            : undefined);
};
export const getCountryStates = (code) => {
    var _a;
    const countryCodeAlpha2 = getCountryCodeAlpha2(code);
    const subdivisions = subdivisionDict;
    return (countryCodeAlpha2 ? ((_a = subdivisions[countryCodeAlpha2]) !== null && _a !== void 0 ? _a : []) : [])
        .map(key => ({ key, label: key }));
};
