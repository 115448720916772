var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { styled } from "@mui/material/styles";
import * as React from "react";
import { NumericFormat } from "react-number-format";
export const ExchangeInput = (_a) => {
    var { autoFocus, postFix, value, invalid, placeholder = "0", disabled, readOnly, decimals } = _a, props = __rest(_a, ["autoFocus", "postFix", "value", "invalid", "placeholder", "disabled", "readOnly", "decimals"]);
    const inputRef = React.useRef(null);
    const onChange = (values) => {
        var _a;
        (_a = props.onChange) === null || _a === void 0 ? void 0 : _a.call(props, values.value);
    };
    return (React.createElement(NumericFormat, { readOnly: readOnly, disabled: disabled, autoFocus: autoFocus, getInputRef: inputRef, customInput: Input, decimalScale: decimals, decimalSeparator: ".", type: "text", suffix: postFix ? ` ${postFix}` : undefined, value: value !== null && value !== void 0 ? value : "", onValueChange: onChange, placeholder: placeholder, invalid: invalid }));
};
const Input = styled("input") `
    margin: 0;
    padding: 0;
    width: 100%;
    border: none;
    font-size: 18px;
    background-color: transparent;
    outline: none;
    font-weight: 500;
    height: 24px;
    color: ${props => props.invalid ? props.theme.colors["general-red"] : props.theme.colors["black-1200"]};
    transition: color 0.2s ease;

    &::placeholder {
        color: ${props => props.invalid ? props.theme.colors["general-red"] : props.theme.colors["black-300"]};
        opacity: 1;
    }
`;
