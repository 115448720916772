/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/** country code for invoice billing */
export var CountryCode;
(function (CountryCode) {
    CountryCode["AF"] = "AF";
    CountryCode["AX"] = "AX";
    CountryCode["AL"] = "AL";
    CountryCode["DZ"] = "DZ";
    CountryCode["AS"] = "AS";
    CountryCode["AD"] = "AD";
    CountryCode["AO"] = "AO";
    CountryCode["AI"] = "AI";
    CountryCode["AG"] = "AG";
    CountryCode["AR"] = "AR";
    CountryCode["AM"] = "AM";
    CountryCode["AW"] = "AW";
    CountryCode["AC"] = "AC";
    CountryCode["AU"] = "AU";
    CountryCode["AQ"] = "AQ";
    CountryCode["AT"] = "AT";
    CountryCode["AZ"] = "AZ";
    CountryCode["BS"] = "BS";
    CountryCode["BH"] = "BH";
    CountryCode["BD"] = "BD";
    CountryCode["BB"] = "BB";
    CountryCode["BY"] = "BY";
    CountryCode["BE"] = "BE";
    CountryCode["BZ"] = "BZ";
    CountryCode["BJ"] = "BJ";
    CountryCode["BM"] = "BM";
    CountryCode["BT"] = "BT";
    CountryCode["BO"] = "BO";
    CountryCode["BQ"] = "BQ";
    CountryCode["BA"] = "BA";
    CountryCode["BW"] = "BW";
    CountryCode["BV"] = "BV";
    CountryCode["BR"] = "BR";
    CountryCode["IO"] = "IO";
    CountryCode["VG"] = "VG";
    CountryCode["BN"] = "BN";
    CountryCode["BG"] = "BG";
    CountryCode["BF"] = "BF";
    CountryCode["BI"] = "BI";
    CountryCode["KH"] = "KH";
    CountryCode["CM"] = "CM";
    CountryCode["CA"] = "CA";
    CountryCode["CV"] = "CV";
    CountryCode["KY"] = "KY";
    CountryCode["CF"] = "CF";
    CountryCode["TD"] = "TD";
    CountryCode["CL"] = "CL";
    CountryCode["CN"] = "CN";
    CountryCode["TW"] = "TW";
    CountryCode["CX"] = "CX";
    CountryCode["PF"] = "PF";
    CountryCode["CC"] = "CC";
    CountryCode["CO"] = "CO";
    CountryCode["KM"] = "KM";
    CountryCode["CG"] = "CG";
    CountryCode["CD"] = "CD";
    CountryCode["CK"] = "CK";
    CountryCode["CR"] = "CR";
    CountryCode["CI"] = "CI";
    CountryCode["HR"] = "HR";
    CountryCode["CU"] = "CU";
    CountryCode["CW"] = "CW";
    CountryCode["CY"] = "CY";
    CountryCode["CZ"] = "CZ";
    CountryCode["DK"] = "DK";
    CountryCode["DJ"] = "DJ";
    CountryCode["DM"] = "DM";
    CountryCode["DO"] = "DO";
    CountryCode["EC"] = "EC";
    CountryCode["EG"] = "EG";
    CountryCode["SV"] = "SV";
    CountryCode["GQ"] = "GQ";
    CountryCode["ER"] = "ER";
    CountryCode["EE"] = "EE";
    CountryCode["SZ"] = "SZ";
    CountryCode["ET"] = "ET";
    CountryCode["FK"] = "FK";
    CountryCode["FO"] = "FO";
    CountryCode["FJ"] = "FJ";
    CountryCode["FI"] = "FI";
    CountryCode["FR"] = "FR";
    CountryCode["GF"] = "GF";
    CountryCode["TF"] = "TF";
    CountryCode["GA"] = "GA";
    CountryCode["GM"] = "GM";
    CountryCode["GE"] = "GE";
    CountryCode["DE"] = "DE";
    CountryCode["GH"] = "GH";
    CountryCode["GI"] = "GI";
    CountryCode["GR"] = "GR";
    CountryCode["GL"] = "GL";
    CountryCode["GD"] = "GD";
    CountryCode["GP"] = "GP";
    CountryCode["GU"] = "GU";
    CountryCode["GT"] = "GT";
    CountryCode["GG"] = "GG";
    CountryCode["GN"] = "GN";
    CountryCode["GW"] = "GW";
    CountryCode["GY"] = "GY";
    CountryCode["HT"] = "HT";
    CountryCode["HM"] = "HM";
    CountryCode["HN"] = "HN";
    CountryCode["HK"] = "HK";
    CountryCode["HU"] = "HU";
    CountryCode["IS"] = "IS";
    CountryCode["IN"] = "IN";
    CountryCode["ID"] = "ID";
    CountryCode["IR"] = "IR";
    CountryCode["IQ"] = "IQ";
    CountryCode["IE"] = "IE";
    CountryCode["IM"] = "IM";
    CountryCode["IL"] = "IL";
    CountryCode["IT"] = "IT";
    CountryCode["JM"] = "JM";
    CountryCode["JP"] = "JP";
    CountryCode["JE"] = "JE";
    CountryCode["JO"] = "JO";
    CountryCode["KZ"] = "KZ";
    CountryCode["KE"] = "KE";
    CountryCode["KI"] = "KI";
    CountryCode["KP"] = "KP";
    CountryCode["KR"] = "KR";
    CountryCode["KW"] = "KW";
    CountryCode["KG"] = "KG";
    CountryCode["LA"] = "LA";
    CountryCode["LV"] = "LV";
    CountryCode["LB"] = "LB";
    CountryCode["LS"] = "LS";
    CountryCode["LR"] = "LR";
    CountryCode["LY"] = "LY";
    CountryCode["LI"] = "LI";
    CountryCode["LT"] = "LT";
    CountryCode["LU"] = "LU";
    CountryCode["MO"] = "MO";
    CountryCode["MK"] = "MK";
    CountryCode["MG"] = "MG";
    CountryCode["MW"] = "MW";
    CountryCode["MY"] = "MY";
    CountryCode["MV"] = "MV";
    CountryCode["ML"] = "ML";
    CountryCode["MT"] = "MT";
    CountryCode["MH"] = "MH";
    CountryCode["MQ"] = "MQ";
    CountryCode["MR"] = "MR";
    CountryCode["MU"] = "MU";
    CountryCode["YT"] = "YT";
    CountryCode["MX"] = "MX";
    CountryCode["FM"] = "FM";
    CountryCode["MD"] = "MD";
    CountryCode["MC"] = "MC";
    CountryCode["MN"] = "MN";
    CountryCode["ME"] = "ME";
    CountryCode["MS"] = "MS";
    CountryCode["MA"] = "MA";
    CountryCode["MZ"] = "MZ";
    CountryCode["MM"] = "MM";
    CountryCode["NA"] = "NA";
    CountryCode["NR"] = "NR";
    CountryCode["NP"] = "NP";
    CountryCode["NL"] = "NL";
    CountryCode["AN"] = "AN";
    CountryCode["NC"] = "NC";
    CountryCode["NZ"] = "NZ";
    CountryCode["NI"] = "NI";
    CountryCode["NE"] = "NE";
    CountryCode["NG"] = "NG";
    CountryCode["NU"] = "NU";
    CountryCode["NF"] = "NF";
    CountryCode["MP"] = "MP";
    CountryCode["NO"] = "NO";
    CountryCode["OM"] = "OM";
    CountryCode["PK"] = "PK";
    CountryCode["PW"] = "PW";
    CountryCode["PA"] = "PA";
    CountryCode["PG"] = "PG";
    CountryCode["PY"] = "PY";
    CountryCode["PE"] = "PE";
    CountryCode["PH"] = "PH";
    CountryCode["PN"] = "PN";
    CountryCode["PL"] = "PL";
    CountryCode["PT"] = "PT";
    CountryCode["PR"] = "PR";
    CountryCode["QA"] = "QA";
    CountryCode["RE"] = "RE";
    CountryCode["RO"] = "RO";
    CountryCode["RU"] = "RU";
    CountryCode["RW"] = "RW";
    CountryCode["BL"] = "BL";
    CountryCode["SH"] = "SH";
    CountryCode["KN"] = "KN";
    CountryCode["LC"] = "LC";
    CountryCode["MF"] = "MF";
    CountryCode["PM"] = "PM";
    CountryCode["VC"] = "VC";
    CountryCode["WS"] = "WS";
    CountryCode["SM"] = "SM";
    CountryCode["ST"] = "ST";
    CountryCode["SA"] = "SA";
    CountryCode["SN"] = "SN";
    CountryCode["RS"] = "RS";
    CountryCode["SC"] = "SC";
    CountryCode["SL"] = "SL";
    CountryCode["SG"] = "SG";
    CountryCode["SX"] = "SX";
    CountryCode["SK"] = "SK";
    CountryCode["SI"] = "SI";
    CountryCode["SB"] = "SB";
    CountryCode["SO"] = "SO";
    CountryCode["ZA"] = "ZA";
    CountryCode["GS"] = "GS";
    CountryCode["SS"] = "SS";
    CountryCode["ES"] = "ES";
    CountryCode["LK"] = "LK";
    CountryCode["SD"] = "SD";
    CountryCode["SR"] = "SR";
    CountryCode["SJ"] = "SJ";
    CountryCode["SE"] = "SE";
    CountryCode["CH"] = "CH";
    CountryCode["SY"] = "SY";
    CountryCode["TJ"] = "TJ";
    CountryCode["TZ"] = "TZ";
    CountryCode["TH"] = "TH";
    CountryCode["TL"] = "TL";
    CountryCode["TG"] = "TG";
    CountryCode["TK"] = "TK";
    CountryCode["TO"] = "TO";
    CountryCode["TT"] = "TT";
    CountryCode["TA"] = "TA";
    CountryCode["TN"] = "TN";
    CountryCode["TR"] = "TR";
    CountryCode["TM"] = "TM";
    CountryCode["TC"] = "TC";
    CountryCode["TV"] = "TV";
    CountryCode["VI"] = "VI";
    CountryCode["UG"] = "UG";
    CountryCode["UA"] = "UA";
    CountryCode["AE"] = "AE";
    CountryCode["GB"] = "GB";
    CountryCode["US"] = "US";
    CountryCode["UY"] = "UY";
    CountryCode["UZ"] = "UZ";
    CountryCode["VU"] = "VU";
    CountryCode["VA"] = "VA";
    CountryCode["VE"] = "VE";
    CountryCode["VN"] = "VN";
    CountryCode["UM"] = "UM";
    CountryCode["WF"] = "WF";
    CountryCode["EH"] = "EH";
    CountryCode["YE"] = "YE";
    CountryCode["ZM"] = "ZM";
    CountryCode["ZW"] = "ZW";
    CountryCode["PS"] = "PS";
    CountryCode["QZ"] = "QZ";
    CountryCode["XK"] = "XK";
})(CountryCode || (CountryCode = {}));
export var HttpStatus;
(function (HttpStatus) {
    HttpStatus["Value100CONTINUE"] = "100 CONTINUE";
    HttpStatus["Value101SWITCHINGPROTOCOLS"] = "101 SWITCHING_PROTOCOLS";
    HttpStatus["Value102PROCESSING"] = "102 PROCESSING";
    HttpStatus["Value103EARLYHINTS"] = "103 EARLY_HINTS";
    HttpStatus["Value103CHECKPOINT"] = "103 CHECKPOINT";
    HttpStatus["Value200OK"] = "200 OK";
    HttpStatus["Value201CREATED"] = "201 CREATED";
    HttpStatus["Value202ACCEPTED"] = "202 ACCEPTED";
    HttpStatus["Value203NONAUTHORITATIVEINFORMATION"] = "203 NON_AUTHORITATIVE_INFORMATION";
    HttpStatus["Value204NOCONTENT"] = "204 NO_CONTENT";
    HttpStatus["Value205RESETCONTENT"] = "205 RESET_CONTENT";
    HttpStatus["Value206PARTIALCONTENT"] = "206 PARTIAL_CONTENT";
    HttpStatus["Value207MULTISTATUS"] = "207 MULTI_STATUS";
    HttpStatus["Value208ALREADYREPORTED"] = "208 ALREADY_REPORTED";
    HttpStatus["Value226IMUSED"] = "226 IM_USED";
    HttpStatus["Value300MULTIPLECHOICES"] = "300 MULTIPLE_CHOICES";
    HttpStatus["Value301MOVEDPERMANENTLY"] = "301 MOVED_PERMANENTLY";
    HttpStatus["Value302FOUND"] = "302 FOUND";
    HttpStatus["Value302MOVEDTEMPORARILY"] = "302 MOVED_TEMPORARILY";
    HttpStatus["Value303SEEOTHER"] = "303 SEE_OTHER";
    HttpStatus["Value304NOTMODIFIED"] = "304 NOT_MODIFIED";
    HttpStatus["Value305USEPROXY"] = "305 USE_PROXY";
    HttpStatus["Value307TEMPORARYREDIRECT"] = "307 TEMPORARY_REDIRECT";
    HttpStatus["Value308PERMANENTREDIRECT"] = "308 PERMANENT_REDIRECT";
    HttpStatus["Value400BADREQUEST"] = "400 BAD_REQUEST";
    HttpStatus["Value401UNAUTHORIZED"] = "401 UNAUTHORIZED";
    HttpStatus["Value402PAYMENTREQUIRED"] = "402 PAYMENT_REQUIRED";
    HttpStatus["Value403FORBIDDEN"] = "403 FORBIDDEN";
    HttpStatus["Value404NOTFOUND"] = "404 NOT_FOUND";
    HttpStatus["Value405METHODNOTALLOWED"] = "405 METHOD_NOT_ALLOWED";
    HttpStatus["Value406NOTACCEPTABLE"] = "406 NOT_ACCEPTABLE";
    HttpStatus["Value407PROXYAUTHENTICATIONREQUIRED"] = "407 PROXY_AUTHENTICATION_REQUIRED";
    HttpStatus["Value408REQUESTTIMEOUT"] = "408 REQUEST_TIMEOUT";
    HttpStatus["Value409CONFLICT"] = "409 CONFLICT";
    HttpStatus["Value410GONE"] = "410 GONE";
    HttpStatus["Value411LENGTHREQUIRED"] = "411 LENGTH_REQUIRED";
    HttpStatus["Value412PRECONDITIONFAILED"] = "412 PRECONDITION_FAILED";
    HttpStatus["Value413PAYLOADTOOLARGE"] = "413 PAYLOAD_TOO_LARGE";
    HttpStatus["Value413REQUESTENTITYTOOLARGE"] = "413 REQUEST_ENTITY_TOO_LARGE";
    HttpStatus["Value414URITOOLONG"] = "414 URI_TOO_LONG";
    HttpStatus["Value414REQUESTURITOOLONG"] = "414 REQUEST_URI_TOO_LONG";
    HttpStatus["Value415UNSUPPORTEDMEDIATYPE"] = "415 UNSUPPORTED_MEDIA_TYPE";
    HttpStatus["Value416REQUESTEDRANGENOTSATISFIABLE"] = "416 REQUESTED_RANGE_NOT_SATISFIABLE";
    HttpStatus["Value417EXPECTATIONFAILED"] = "417 EXPECTATION_FAILED";
    HttpStatus["Value418IAMATEAPOT"] = "418 I_AM_A_TEAPOT";
    HttpStatus["Value419INSUFFICIENTSPACEONRESOURCE"] = "419 INSUFFICIENT_SPACE_ON_RESOURCE";
    HttpStatus["Value420METHODFAILURE"] = "420 METHOD_FAILURE";
    HttpStatus["Value421DESTINATIONLOCKED"] = "421 DESTINATION_LOCKED";
    HttpStatus["Value422UNPROCESSABLEENTITY"] = "422 UNPROCESSABLE_ENTITY";
    HttpStatus["Value423LOCKED"] = "423 LOCKED";
    HttpStatus["Value424FAILEDDEPENDENCY"] = "424 FAILED_DEPENDENCY";
    HttpStatus["Value425TOOEARLY"] = "425 TOO_EARLY";
    HttpStatus["Value426UPGRADEREQUIRED"] = "426 UPGRADE_REQUIRED";
    HttpStatus["Value428PRECONDITIONREQUIRED"] = "428 PRECONDITION_REQUIRED";
    HttpStatus["Value429TOOMANYREQUESTS"] = "429 TOO_MANY_REQUESTS";
    HttpStatus["Value431REQUESTHEADERFIELDSTOOLARGE"] = "431 REQUEST_HEADER_FIELDS_TOO_LARGE";
    HttpStatus["Value451UNAVAILABLEFORLEGALREASONS"] = "451 UNAVAILABLE_FOR_LEGAL_REASONS";
    HttpStatus["Value500INTERNALSERVERERROR"] = "500 INTERNAL_SERVER_ERROR";
    HttpStatus["Value501NOTIMPLEMENTED"] = "501 NOT_IMPLEMENTED";
    HttpStatus["Value502BADGATEWAY"] = "502 BAD_GATEWAY";
    HttpStatus["Value503SERVICEUNAVAILABLE"] = "503 SERVICE_UNAVAILABLE";
    HttpStatus["Value504GATEWAYTIMEOUT"] = "504 GATEWAY_TIMEOUT";
    HttpStatus["Value505HTTPVERSIONNOTSUPPORTED"] = "505 HTTP_VERSION_NOT_SUPPORTED";
    HttpStatus["Value506VARIANTALSONEGOTIATES"] = "506 VARIANT_ALSO_NEGOTIATES";
    HttpStatus["Value507INSUFFICIENTSTORAGE"] = "507 INSUFFICIENT_STORAGE";
    HttpStatus["Value508LOOPDETECTED"] = "508 LOOP_DETECTED";
    HttpStatus["Value509BANDWIDTHLIMITEXCEEDED"] = "509 BANDWIDTH_LIMIT_EXCEEDED";
    HttpStatus["Value510NOTEXTENDED"] = "510 NOT_EXTENDED";
    HttpStatus["Value511NETWORKAUTHENTICATIONREQUIRED"] = "511 NETWORK_AUTHENTICATION_REQUIRED";
})(HttpStatus || (HttpStatus = {}));
/** Invoice payment provider type */
export var InvoicePaymentProviderType;
(function (InvoicePaymentProviderType) {
    InvoicePaymentProviderType["PLATFORM"] = "PLATFORM";
    InvoicePaymentProviderType["CHECKOUT"] = "CHECKOUT";
    InvoicePaymentProviderType["INWIZO"] = "INWIZO";
    InvoicePaymentProviderType["ITEZ"] = "ITEZ";
    InvoicePaymentProviderType["STRIPE"] = "STRIPE";
    InvoicePaymentProviderType["EXTERNAL"] = "EXTERNAL";
})(InvoicePaymentProviderType || (InvoicePaymentProviderType = {}));
/** Status of current invoice different for sub types */
export var InvoiceStatus;
(function (InvoiceStatus) {
    InvoiceStatus["INIT"] = "INIT";
    InvoiceStatus["PENDING"] = "PENDING";
    InvoiceStatus["PROCESSING"] = "PROCESSING";
    InvoiceStatus["COMPLETED"] = "COMPLETED";
    InvoiceStatus["CANCELED"] = "CANCELED";
    InvoiceStatus["FAILED"] = "FAILED";
    InvoiceStatus["REJECTED"] = "REJECTED";
})(InvoiceStatus || (InvoiceStatus = {}));
export var InvoiceType;
(function (InvoiceType) {
    InvoiceType["ORIGINAL"] = "ORIGINAL";
    InvoiceType["RECURRENT"] = "RECURRENT";
})(InvoiceType || (InvoiceType = {}));
/** payment status */
export var PaymentStatus;
(function (PaymentStatus) {
    PaymentStatus["INIT"] = "INIT";
    PaymentStatus["PROCESSING"] = "PROCESSING";
    PaymentStatus["COMPLETED"] = "COMPLETED";
    PaymentStatus["COMPLETED_WITHOUT_FEE"] = "COMPLETED_WITHOUT_FEE";
    PaymentStatus["FAILED"] = "FAILED";
})(PaymentStatus || (PaymentStatus = {}));
/** interval of recurrent invoice */
export var RecurrentInvoiceInterval;
(function (RecurrentInvoiceInterval) {
    RecurrentInvoiceInterval["DAYS30"] = "DAYS30";
    RecurrentInvoiceInterval["DAYS365"] = "DAYS365";
})(RecurrentInvoiceInterval || (RecurrentInvoiceInterval = {}));
/** status of recurrent invoice */
export var RecurrentInvoiceStatus;
(function (RecurrentInvoiceStatus) {
    RecurrentInvoiceStatus["INIT"] = "INIT";
    RecurrentInvoiceStatus["TRIAL"] = "TRIAL";
    RecurrentInvoiceStatus["ACTIVE"] = "ACTIVE";
    RecurrentInvoiceStatus["PAYMENT_FAILED"] = "PAYMENT_FAILED";
    RecurrentInvoiceStatus["PAYMENT_REJECTED"] = "PAYMENT_REJECTED";
    RecurrentInvoiceStatus["CANCELLED"] = "CANCELLED";
    RecurrentInvoiceStatus["DEACTIVATING"] = "DEACTIVATING";
})(RecurrentInvoiceStatus || (RecurrentInvoiceStatus = {}));
import axios from "axios";
export var ContentType;
(function (ContentType) {
    ContentType["Json"] = "application/json";
    ContentType["FormData"] = "multipart/form-data";
    ContentType["UrlEncoded"] = "application/x-www-form-urlencoded";
    ContentType["Text"] = "text/plain";
})(ContentType || (ContentType = {}));
export class HttpClient {
    constructor(_a = {}) {
        var { securityWorker, secure, format } = _a, axiosConfig = __rest(_a, ["securityWorker", "secure", "format"]);
        this.securityData = null;
        this.setSecurityData = (data) => {
            this.securityData = data;
        };
        this.request = (_b) => __awaiter(this, void 0, void 0, function* () {
            var { secure, path, type, query, format, body } = _b, params = __rest(_b, ["secure", "path", "type", "query", "format", "body"]);
            const secureParams = ((typeof secure === "boolean" ? secure : this.secure)
                && this.securityWorker
                && (yield this.securityWorker(this.securityData)))
                || {};
            const requestParams = this.mergeRequestParams(params, secureParams);
            const responseFormat = format || this.format || undefined;
            if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
                body = this.createFormData(body);
            }
            if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
                body = JSON.stringify(body);
            }
            return this.instance.request(Object.assign(Object.assign({}, requestParams), { headers: Object.assign(Object.assign({}, (requestParams.headers || {})), (type ? { "Content-Type": type } : {})), params: query, responseType: responseFormat, data: body, url: path }));
        });
        this.instance = axios.create(Object.assign(Object.assign({}, axiosConfig), { baseURL: axiosConfig.baseURL || "https://gw.api-stg.evercash.eu/acquiring" }));
        this.secure = secure;
        this.format = format;
        this.securityWorker = securityWorker;
    }
    mergeRequestParams(params1, params2) {
        const method = params1.method || (params2 && params2.method);
        return Object.assign(Object.assign(Object.assign(Object.assign({}, this.instance.defaults), params1), (params2 || {})), { headers: Object.assign(Object.assign(Object.assign({}, ((method && this.instance.defaults.headers[method.toLowerCase()]) || {})), (params1.headers || {})), ((params2 && params2.headers) || {})) });
    }
    stringifyFormItem(formItem) {
        if (typeof formItem === "object" && formItem !== null) {
            return JSON.stringify(formItem);
        }
        else {
            return `${formItem}`;
        }
    }
    createFormData(input) {
        if (input instanceof FormData) {
            return input;
        }
        return Object.keys(input || {}).reduce((formData, key) => {
            const property = input[key];
            const propertyContent = property instanceof Array ? property : [property];
            for (const formItem of propertyContent) {
                const isFileType = formItem instanceof Blob || formItem instanceof File;
                formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
            }
            return formData;
        }, new FormData());
    }
}
/**
 * @title Acquiring
 * @version 0.3.0-9a9d7e1a
 * @baseUrl https://gw.api-stg.evercash.eu/acquiring
 */
export class AcquiringApi extends HttpClient {
    constructor() {
        super(...arguments);
        this.merchant = {
            /**
             * @description Set payment account, available for merchant with
             *
             * @tags merchant
             * @name UpdateRecurrentInvoicePaymentAccount
             * @summary Update payment account of recurrent invoice
             * @request PUT:/merchant/invoice/recurrent/{invoiceId}/payment/account
             * @secure
             */
            updateRecurrentInvoicePaymentAccount: (invoiceId, query, params = {}) => this.request(Object.assign({ path: `/merchant/invoice/recurrent/${invoiceId}/payment/account`, method: "PUT", query: query, secure: true }, params)),
            /**
             * @description If Success returns Invoice id and pay url
             *
             * @tags merchant
             * @name Invoice
             * @summary Allow merchant to create new invoices
             * @request POST:/merchant/invoice
             * @secure
             */
            invoice: (data, params = {}) => this.request(Object.assign({ path: `/merchant/invoice`, method: "POST", body: data, secure: true, type: ContentType.Json, format: "json" }, params)),
            /**
             * @description invoice info for merchant
             *
             * @tags merchant
             * @name GetInvoice
             * @summary Get invoice object
             * @request GET:/merchant/invoice/{invoiceId}
             * @secure
             */
            getInvoice: (invoiceId, params = {}) => this.request(Object.assign({ path: `/merchant/invoice/${invoiceId}`, method: "GET", secure: true }, params)),
            /**
             * @description If Success returns 200
             *
             * @tags merchant
             * @name RevokeInvoice
             * @summary Allow merchant to revoke invoice
             * @request DELETE:/merchant/invoice/{invoiceId}
             * @secure
             */
            revokeInvoice: (invoiceId, params = {}) => this.request(Object.assign({ path: `/merchant/invoice/${invoiceId}`, method: "DELETE", secure: true }, params)),
            /**
             * @description If Success return InvoiceStatus object
             *
             * @tags merchant
             * @name Status
             * @summary Allow merchant to get info about invoice by Id
             * @request GET:/merchant/invoice/{invoiceId}/status
             * @secure
             */
            status: (invoiceId, params = {}) => this.request(Object.assign({ path: `/merchant/invoice/${invoiceId}/status`, method: "GET", secure: true, format: "json" }, params)),
            /**
             * @description If Success returns InvoiceStatus object and sends callback
             *
             * @tags merchant
             * @name SendCallback
             * @summary Allow merchant to force send callback
             * @request GET:/merchant/invoice/{invoiceId}/sendCallback
             * @secure
             */
            sendCallback: (invoiceId, params = {}) => this.request(Object.assign({ path: `/merchant/invoice/${invoiceId}/sendCallback`, method: "GET", secure: true, format: "json" }, params)),
        };
        this.invoice = {
            /**
             * No description
             *
             * @tags invoice
             * @name ApplyDiscount
             * @request PUT:/invoice/{invoiceId}/discount
             */
            applyDiscount: (invoiceId, query, params = {}) => this.request(Object.assign({ path: `/invoice/${invoiceId}/discount`, method: "PUT", query: query }, params)),
            /**
             * No description
             *
             * @tags InvoicePaymentController
             * @name UpdatePayment
             * @summary Invoice payment update
             * @request PUT:/invoice/payment
             * @secure
             */
            updatePayment: (data, params = {}) => this.request(Object.assign({ path: `/invoice/payment`, method: "PUT", body: data, secure: true, type: ContentType.Json }, params)),
            /**
             * No description
             *
             * @tags InvoicePaymentController
             * @name PaymentGetOrCreate
             * @summary Invoice payment get or create
             * @request POST:/invoice/payment
             * @secure
             */
            paymentGetOrCreate: (data, params = {}) => this.request(Object.assign({ path: `/invoice/payment`, method: "POST", body: data, secure: true, type: ContentType.Json }, params)),
            /**
             * No description
             *
             * @tags InvoicePaymentController
             * @name Pay
             * @summary Invoice payment confirm
             * @request POST:/invoice/payment/pay
             * @secure
             */
            pay: (data, params = {}) => this.request(Object.assign({ path: `/invoice/payment/pay`, method: "POST", body: data, secure: true, type: ContentType.Json, format: "json" }, params)),
            /**
             * @description return invoices linked to user
             *
             * @tags invoice
             * @name GetInvoices
             * @summary Get User Invoices
             * @request GET:/invoice
             * @secure
             */
            getInvoices: (query, params = {}) => this.request(Object.assign({ path: `/invoice`, method: "GET", query: query, secure: true }, params)),
            /**
             * @description return invoice if it's available to user
             *
             * @tags invoice
             * @name GetInvoiceById
             * @summary Get Invoice by id
             * @request GET:/invoice/{id}
             * @secure
             */
            getInvoiceById: (id, params = {}) => this.request(Object.assign({ path: `/invoice/${id}`, method: "GET", secure: true }, params)),
        };
        this.admin = {
            /**
             * No description
             *
             * @tags admin-merchant-controller
             * @name UpdateMerchant
             * @summary admin update merchant
             * @request PUT:/admin/merchant/{merchantId}/
             * @secure
             */
            updateMerchant: (merchantId, data, params = {}) => this.request(Object.assign({ path: `/admin/merchant/${merchantId}/`, method: "PUT", body: data, secure: true, type: ContentType.Json }, params)),
            /**
             * No description
             *
             * @tags admin-merchant-controller
             * @name DeleteMerchant
             * @summary admin delete merchant
             * @request DELETE:/admin/merchant/{merchantId}/
             * @secure
             */
            deleteMerchant: (merchantId, params = {}) => this.request(Object.assign({ path: `/admin/merchant/${merchantId}/`, method: "DELETE", secure: true }, params)),
            /**
             * @description return list of actual partners in acquiring service
             *
             * @tags admin-partner-controller
             * @name ListMerchant
             * @summary admin get partners controller
             * @request GET:/admin/partner
             * @secure
             */
            listMerchant: (params = {}) => this.request(Object.assign({ path: `/admin/partner`, method: "GET", secure: true }, params)),
            /**
             * @description return list of actual partners in acquiring service
             *
             * @tags admin-partner-controller
             * @name CreatePartner
             * @summary admin create partner controller
             * @request POST:/admin/partner
             * @secure
             */
            createPartner: (data, params = {}) => this.request(Object.assign({ path: `/admin/partner`, method: "POST", body: data, secure: true, type: ContentType.Json }, params)),
            /**
             * No description
             *
             * @tags admin-merchant-controller
             * @name ListMerchant1
             * @summary admin get merchant
             * @request GET:/admin/merchant
             * @secure
             */
            listMerchant1: (params = {}) => this.request(Object.assign({ path: `/admin/merchant`, method: "GET", secure: true }, params)),
            /**
             * No description
             *
             * @tags admin-merchant-controller
             * @name CreateMerchant
             * @summary admin create merchant
             * @request POST:/admin/merchant
             * @secure
             */
            createMerchant: (data, params = {}) => this.request(Object.assign({ path: `/admin/merchant`, method: "POST", body: data, secure: true, type: ContentType.Json }, params)),
            /**
             * @description Create payment and pay invoice by admin with direct transfer
             *
             * @tags admin invoices
             * @name AdminPayInvoice
             * @summary admin pay invoice
             * @request POST:/admin/invoice/pay
             * @secure
             */
            adminPayInvoice: (data, params = {}) => this.request(Object.assign({ path: `/admin/invoice/pay`, method: "POST", body: data, secure: true, type: ContentType.Json }, params)),
            /**
             * @description Get pageable invoices.
             *
             * @tags admin invoices
             * @name AdminGetInvoices
             * @summary admin get invoices
             * @request GET:/admin/invoice
             * @secure
             */
            adminGetInvoices: (query, params = {}) => this.request(Object.assign({ path: `/admin/invoice`, method: "GET", query: query, secure: true }, params)),
        };
        this.payment = {
            /**
             * No description
             *
             * @tags PaymentWebhookController
             * @name PlatformWebhook
             * @summary Invoice platform payment webhook
             * @request POST:/payment/webhook/platform
             * @secure
             */
            platformWebhook: (data, params = {}) => this.request(Object.assign({ path: `/payment/webhook/platform`, method: "POST", body: data, secure: true, type: ContentType.Json }, params)),
            /**
             * No description
             *
             * @tags PaymentWebhookController
             * @name ExternalWebhook
             * @summary Invoice external payment webhook
             * @request POST:/payment/webhook/external
             * @secure
             */
            externalWebhook: (data, params = {}) => this.request(Object.assign({ path: `/payment/webhook/external`, method: "POST", body: data, secure: true, type: ContentType.Json }, params)),
            /**
             * No description
             *
             * @tags PaymentWebhookController
             * @name CheckoutSuccessRedirect
             * @summary Checkout successful redirect
             * @request POST:/payment/checkout/redirect/success
             */
            checkoutSuccessRedirect: (query, params = {}) => this.request(Object.assign({ path: `/payment/checkout/redirect/success`, method: "POST", query: query }, params)),
            /**
             * No description
             *
             * @tags PaymentWebhookController
             * @name CheckoutFailedRedirect
             * @summary Checkout failed redirect
             * @request POST:/payment/checkout/redirect/failed
             */
            checkoutFailedRedirect: (query, params = {}) => this.request(Object.assign({ path: `/payment/checkout/redirect/failed`, method: "POST", query: query }, params)),
        };
        this.itez = {
            /**
             * No description
             *
             * @tags itez-controller
             * @name CreateWidget
             * @request GET:/itez/
             * @deprecated
             */
            createWidget: (query, params = {}) => this.request(Object.assign({ path: `/itez/`, method: "GET", query: query }, params)),
        };
    }
}
