import { observer } from "mobx-react-lite";
import React from "react";
import { ChangePasswordForm } from "@/pages/ProfilePage/components/Password/ChangePasswordForm";
import { VerificationStep } from "@/pages/ProfilePage/components/VerificationStep";
import { PasswordFormStep, PasswordFormStore } from "@/pages/ProfilePage/stores/PasswordFormStore";
import { Box, Button, Typography } from "@/shared/components";
import { Popup } from "@/shared/components/Popup";
import { PopupHead } from "@/shared/components/Popup/Head";
import { useStoreContext } from "@/shared/hooks/useStore";
import { AuthStore } from "@/shared/stores/AuthStore";
export const SettingsPassword = observer(() => {
    var _a, _b, _c;
    const auth = useStoreContext(AuthStore);
    const store = useStoreContext(PasswordFormStore);
    const onSubmitCode = (code) => {
        store.submitEmailCode(code);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { display: "flex", justifyContent: "space-between", alignItems: "center" },
            React.createElement(Box, { display: "flex", flexDirection: "column", gap: "10px" },
                React.createElement(Typography, { type: "text20" }, "Password"),
                React.createElement(Typography, { color: "black-500" }, "***********")),
            React.createElement(Button, { size: "s", disabled: store.loading, onClick: store.open }, "Change")),
        store.visible && (React.createElement(Popup, { width: "450px", onClose: store.reset },
            React.createElement(PopupHead, { onClose: store.reset }),
            store.step === PasswordFormStep.Code && (React.createElement(VerificationStep, { count: store.timer.count, error: (_a = store.errors.at(0)) === null || _a === void 0 ? void 0 : _a.description, loading: store.loading, onResendCode: store.open, onSubmitCode: onSubmitCode, textButton: "Next", title: "Confirm your password", description: `Enter the code we've sent you at ${(_c = (_b = auth.userInfo) === null || _b === void 0 ? void 0 : _b.primaryEmail) === null || _c === void 0 ? void 0 : _c.email}` })),
            store.step === PasswordFormStep.Form && React.createElement(ChangePasswordForm, null)))));
});
