import AlertClose from "./alert-close.svg";
import Back from "./back.svg";
import Bank from "./bank.svg";
import Burger from "./burger.svg";
import Check from "./check.svg";
import Checked from "./checked.svg";
import Close from "./close.svg";
import ClosedEye from "./closed-eye.svg";
import Cross from "./cross.svg";
import Crypto from "./crypto.svg";
import Deposit from "./deposit.svg";
import Doc from "./doc.svg";
import Download from "./download.svg";
import DropDown from "./drop-down.svg";
import Exchange from "./exchange.svg";
import Eye from "./eye.svg";
import Failed from "./failed.svg";
import Help from "./help.svg";
import Info from "./info.svg";
import Lock from "./lock.svg";
import LogoSmall from "./logo-small.svg";
import Logo from "./logo.svg";
import MenuCompany from "./menu-company.svg";
import MenuDashboard from "./menu-dashboard.svg";
import MenuTransactions from "./menu-transactions.svg";
import More from "./more.svg";
import Navigation from "./navigation.svg";
import PaymentTypeCard from "./payment-type-card.svg";
import PaymentTypeCrypto from "./payment-type-crypto.svg";
import PaymentTypeOther from "./payment-type-other.svg";
import Pending from "./pending.svg";
import Plus from "./plus.svg";
import Qr from "./qr.svg";
import Radio from "./radio.svg";
import Recipient from "./recipient.svg";
import Right from "./right.svg";
import Search from "./search.svg";
import Spinner from "./spinner.svg";
import Success from "./success.svg";
import Swap from "./swap.svg";
import Transfer from "./transfer.svg";
import UnChecked from "./unchecked.svg";
export const Icons = {
    Eye,
    ClosedEye,
    Back,
    Logo,
    UnChecked,
    Checked,
    AlertClose,
    DropDown,
    Close,
    Cross,
    Doc,
    Pending,
    Failed,
    LogoSmall,
    MenuCompany,
    MenuDashboard,
    MenuTransactions,
    Help,
    Burger,
    Lock,
    Navigation,
    Deposit,
    Transfer,
    Exchange,
    Info,
    Plus,
    Spinner,
    Right,
    Radio,
    PaymentTypeCard,
    PaymentTypeCrypto,
    PaymentTypeOther,
    Download,
    Success,
    Search,
    Qr,
    Swap,
    Crypto,
    Recipient,
    Bank,
    Check,
    More,
};
