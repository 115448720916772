var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { makeAutoObservable, runInAction } from "mobx";
import { registrationApi } from "@/api/registration";
import { UnknownError } from "@/shared/utils/error";
export var AuthScope;
(function (AuthScope) {
    AuthScope["ChangeEmail"] = "user_email:write";
    AuthScope["ChangePhone"] = "user_phone:write";
})(AuthScope || (AuthScope = {}));
export class AuthStore {
    constructor(tokenStorage, mfa) {
        this.tokenStorage = tokenStorage;
        this.mfa = mfa;
        this.initialized = false;
        this.userInfo = undefined;
        makeAutoObservable(this, {}, { autoBind: true });
    }
    init() {
        this.sync();
    }
    sync() {
        return __awaiter(this, void 0, void 0, function* () {
            let userInfo;
            const accessToken = this.tokenStorage.getAccessToken();
            const refreshToken = this.tokenStorage.getRefreshToken();
            if (accessToken && refreshToken) {
                try {
                    const userInfoResp = yield registrationApi.user.getUserInfo();
                    userInfo = userInfoResp.data;
                }
                catch (e) {
                    console.error(e);
                }
            }
            runInAction(() => {
                this.initialized = true;
                this.userInfo = userInfo;
            });
        });
    }
    requestScope(scope) {
        return __awaiter(this, void 0, void 0, function* () {
            const resp = yield registrationApi.auth.getToken({
                grant_type: "refresh_token",
                refresh_token: this.tokenStorage.getRefreshToken(),
                scope,
            });
            if (resp.data.error === "mfa_required" && resp.data.mfa_token) {
                const tokens = yield this.mfa.start(resp.data.mfa_token, scope);
                this.tokenStorage.setTokens(tokens.accessToken, tokens.refreshToken);
            }
            else if (resp.data.access_token && resp.data.refresh_token) {
                this.tokenStorage.setTokens(resp.data.access_token, resp.data.refresh_token);
            }
            else {
                throw new Error(UnknownError.description);
            }
        });
    }
    login(accessToken, refreshToken) {
        this.initialized = false;
        this.tokenStorage.setTokens(accessToken, refreshToken);
        this.sync();
    }
    logout() {
        this.initialized = false;
        this.tokenStorage.clean();
        this.sync();
    }
    get authorized() {
        var _a;
        return !!((_a = this.userInfo) === null || _a === void 0 ? void 0 : _a.phone) && !!this.userInfo.primaryEmail;
    }
}
