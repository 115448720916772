import { styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { CreateRecipientStore } from "@/features/create-recipient/store/CreateRecipientStore";
import { BaseIcon } from "@/shared/components/BaseIcon";
import { Box } from "@/shared/components/Box";
import { Popup } from "@/shared/components/Popup";
import { PopupHead } from "@/shared/components/Popup/Head";
import { PopupItem } from "@/shared/components/Popup/Item";
import { RecipientItem } from "@/shared/components/RecipientPopup/RecipientItem";
import { Typography } from "@/shared/components/Typography";
import { useStoreContext } from "@/shared/hooks/useStore";
import { RecipientListStore } from "@/shared/stores/RecipientListStore";
export const RecipientPopup = observer(({ type, onClose, selectedId, onSelect, currency, }) => {
    var _a;
    const recipientList = useStoreContext(RecipientListStore);
    const recipients = (_a = recipientList.list) === null || _a === void 0 ? void 0 : _a.filter((item) => {
        var _a;
        return currency
            ? ((_a = item.data.currency) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === currency.toLowerCase()
            : true;
    }).filter(item => type ? item.data.type === type : true);
    const store = useStoreContext(CreateRecipientStore);
    const openModal = () => store.setIsOpen(true);
    return (React.createElement(Popup, { onClose: onClose },
        React.createElement(PopupHead, { onClose: onClose }, "Select recipient"),
        React.createElement(Box, { height: "1px", bgColor: "black-50" }),
        React.createElement(Box, { overflow: "hidden", borderRadius: "0 0 22px 22px" },
            React.createElement(List, null,
                React.createElement(PopupItem, { onClick: () => openModal() },
                    React.createElement(BaseIcon, { icon: "Plus", size: 14, containerSize: 36, borderRadius: "36px", bgColor: "light-blue", color: "deep-blue-500" }),
                    React.createElement(Typography, { type: "text14", color: "deep-blue-500" }, "Add new recipient")), recipients === null || recipients === void 0 ? void 0 :
                recipients.map((item) => (React.createElement(RecipientItem, { key: item.data.id, selected: item.data.id === selectedId, name: item.data.profile.name, address: item.accountNumber, status: "status" in item.data ? item.data.status : "", onSelect: () => onSelect(item.data.id) })))))));
});
const List = styled("div") `
    overflow: auto;
    max-height: 505px;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
`;
