var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
export const StyledTypography = styled(Typography, {
    shouldForwardProp: (prop) => !prop.startsWith("$"),
})((_a) => {
    var _b, _c;
    var { theme, $textColor = "black-1200", $text, $wordBreak, $textDecoration, $textIndent, $lineClamp, $bgColor } = _a, props = __rest(_a, ["theme", "$textColor", "$text", "$wordBreak", "$textDecoration", "$textIndent", "$lineClamp", "$bgColor"]);
    return (Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ color: $textColor
            ? theme.colors[$textColor]
            : "var(--default-font-color)", fontSize: $text
            ? theme.text[$text].fontSize
            : "var(--default-font-size)" }, ($wordBreak && { wordBreak: $wordBreak })), ($textDecoration && { textDecoration: $textDecoration })), ($textIndent && { textIndent: $textIndent })), { fontWeight: (_c = (_b = props.fontWeight) === null || _b === void 0 ? void 0 : _b.toString()) !== null && _c !== void 0 ? _c : ($text
            ? theme.text[$text].fontWeight
            : "var(--default-font-weight)"), lineHeight: $text
            ? theme.text[$text].lineHeight
            : "var(--default-line-height)", transition: "color 0.2s ease", fontFamily: theme.fontFamily }), ($lineClamp && {
        overflow: "hidden",
        whiteSpace: "initial",
        textOverflow: "ellipsis",
        WebkitLineClamp: $lineClamp,
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        wordBreak: $wordBreak || "break-word",
        maxWidth: "max-content",
    })), ($bgColor && { backgroundColor: theme.colors[$bgColor] })));
});
