var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { makeAutoObservable, runInAction, toJS } from "mobx";
import { registrationApi } from "@/api/registration";
import { CLIENT_ID } from "@/config";
import { TimerStore } from "@/shared/stores/TimerStore";
import { groupErrorsByKey, parseApiError, UnknownError } from "@/shared/utils/error";
import { isEmail } from "@/shared/utils/is-email";
export var SigninStep;
(function (SigninStep) {
    SigninStep[SigninStep["Email"] = 0] = "Email";
    SigninStep[SigninStep["Verification"] = 1] = "Verification";
})(SigninStep || (SigninStep = {}));
export class SigninPageStore {
    constructor(auth) {
        this.auth = auth;
        this.step = SigninStep.Email;
        this.email = "";
        this.password = "";
        this.errors = [];
        this.loading = false;
        this.mfaToken = undefined;
        this.code = "";
        this.timer = new TimerStore();
        this.authentication = undefined;
        this.challenge = undefined;
        makeAutoObservable(this, {}, { autoBind: true });
    }
    submit() {
        return __awaiter(this, void 0, void 0, function* () {
            let errors = [];
            let loading = true;
            let mfaToken;
            let step = SigninStep.Email;
            let authentication;
            let challenge;
            runInAction(() => {
                this.loading = loading;
            });
            try {
                const resp = yield registrationApi.auth.getToken({
                    grant_type: "password",
                    password: this.password,
                    username: this.email,
                    client_id: CLIENT_ID,
                });
                if (resp.data.error === "mfa_required" && resp.data.mfa_token) {
                    const authenticationListResp = yield registrationApi.mfa.getListOfTwoFactorAuthentications({
                        onlyActive: true,
                    }, {
                        headers: {
                            Authorization: `Bearer ${resp.data.mfa_token}`,
                        },
                    });
                    const authenticationItem = authenticationListResp.data.find((item) => "oob_channel" in item && item.oob_channel === "EMAIL");
                    if (!authenticationItem) {
                        throw new Error("OOB auth type not founded");
                    }
                    const challengeResp = yield registrationApi.mfa.createChallenge({
                        authenticator_id: authenticationItem === null || authenticationItem === void 0 ? void 0 : authenticationItem.id,
                        challenge_type: authenticationItem.authenticator_type,
                        client_id: CLIENT_ID,
                        mfa_token: resp.data.mfa_token,
                    });
                    mfaToken = resp.data.mfa_token;
                    authentication = authenticationItem;
                    challenge = challengeResp.data;
                    step = SigninStep.Verification;
                    loading = false;
                    this.timer.start();
                }
                else if (resp.data.access_token && resp.data.refresh_token) {
                    this.auth.login(resp.data.access_token, resp.data.refresh_token);
                }
            }
            catch (e) {
                console.error(e);
                errors = parseApiError(e);
                loading = false;
            }
            runInAction(() => {
                this.mfaToken = mfaToken;
                this.authentication = authentication;
                this.challenge = challenge;
                this.loading = loading;
                this.errors = errors;
                this.mfaToken = mfaToken;
                this.step = step;
            });
        });
    }
    submitCode() {
        return __awaiter(this, void 0, void 0, function* () {
            let errors = [];
            let loading = true;
            runInAction(() => {
                this.loading = loading;
            });
            try {
                if (!this.mfaToken) {
                    throw new Error("mfaToken must be defined");
                }
                if (!this.challenge) {
                    throw new Error("challenge must be defined");
                }
                const resp = yield registrationApi.auth.getToken({
                    grant_type: "mfa-oob", // TODO: Other mfa types
                    binding_code: this.code,
                    client_id: CLIENT_ID,
                    mfaToken: this.mfaToken,
                    oobCode: this.challenge.oob_code,
                });
                if (resp.data.access_token && resp.data.refresh_token) {
                    this.auth.login(resp.data.access_token, resp.data.refresh_token);
                }
                else {
                    throw new Error(UnknownError.description);
                }
            }
            catch (e) {
                console.error(e);
                errors = parseApiError(e);
                loading = false;
            }
            runInAction(() => {
                this.errors = errors;
                this.loading = loading;
            });
        });
    }
    reset() {
        this.step = SigninStep.Email;
        this.password = "";
        this.errors = [];
        this.authentication = undefined;
        this.challenge = undefined;
        this.mfaToken = undefined;
        this.code = "";
    }
    get isEmailValid() {
        return isEmail(this.email);
    }
    get isPasswordValid() {
        return !!this.password;
    }
    get errorsByKey() {
        return groupErrorsByKey(toJS(this.errors));
    }
}
