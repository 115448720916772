import { observer } from "mobx-react-lite";
import * as React from "react";
import { PhoneForm } from "@/pages/PhonePage/components/PhoneForm";
import { SmsCodeForm } from "@/pages/PhonePage/components/SmsCodeForm";
import { PhonePageStep, PhonePageStore } from "@/pages/PhonePage/stores/PhonePageStore";
import { useProvider, useStore, useStoreContext } from "@/shared/hooks/useStore";
import { AuthStore } from "@/shared/stores/AuthStore";
export const PhonePage = observer(() => {
    const auth = useStoreContext(AuthStore);
    const page = useStore(PhonePageStore, auth);
    const PageProvider = useProvider(PhonePageStore);
    return (React.createElement(PageProvider, { value: page }, page.step === PhonePageStep.Phone
        ? React.createElement(PhoneForm, null)
        : page.step === PhonePageStep.SmsCode
            ? React.createElement(SmsCodeForm, null)
            : null));
});
