import { styled } from "@mui/material/styles";
import * as React from "react";
import { BaseIcon } from "@/shared/components/BaseIcon";
import { Box } from "@/shared/components/Box";
import { PopupItem } from "@/shared/components/Popup/Item";
import { Typography } from "@/shared/components/Typography";
import { UserPick } from "@/shared/components/UserPick";
import { sliceAddress } from "@/shared/utils/slice-address";
export const RecipientItem = ({ selected, address, name, status, onSelect, }) => (React.createElement(PopupRow, { onClick: onSelect, "$status": status },
    React.createElement(UserPick, { name: name }),
    React.createElement(Box, { display: "flex", flexDirection: "column", position: "relative" },
        React.createElement(Title, { type: "text14" },
            name,
            selected && React.createElement(BaseIcon, { size: 16, icon: "Success" })),
        React.createElement(WrapperStatus, null, status),
        address && (React.createElement(Typography, { type: "text14", color: "black-400" }, sliceAddress(address))))));
const PopupRow = styled(PopupItem)(({ $status }) => ({
    pointerEvents: $status === "error" ? "unset" : "all",
    opacity: $status === "error" ? "0.7" : "1",
}));
export const WrapperStatus = styled(Box)(({ theme }) => ({
    position: "absolute",
    right: "-65px",
    top: "-26px",
    textTransform: "lowercase",
    background: theme.colors["black-1000"],
    color: theme.colors["general-white"],
    borderRadius: "8px",
    padding: "6px 12px",
}));
const Title = styled(Typography) `
    display: flex;
    align-items: center;
    gap: 5px;
`;
