import { action } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { Panel } from "@/pages/PaymentPage/components/Panel";
import { PaymentPromoStore } from "@/pages/PaymentPage/stores/PaymentPromoStore";
import { BaseIcon, Box, Button, FormControlInput, Typography } from "@/shared/components";
import { FormAlert } from "@/shared/components/FormAlert";
import { useStoreContext } from "@/shared/hooks/useStore";
import { preventDefault } from "@/shared/utils/prevent";
export const PaymentPromo = observer(() => {
    var _a, _b;
    const form = useStoreContext(PaymentPromoStore);
    return (React.createElement(Panel, { onSubmit: preventDefault(form.submitDiscount) },
        React.createElement(Box, { display: "flex", flexDirection: "column", gap: "5px" },
            React.createElement(Typography, { type: "text18", fontWeight: 600, display: "flex", alignItems: "center", gap: "5px" },
                "Promo",
                ((_a = form.discount) === null || _a === void 0 ? void 0 : _a.code) && (React.createElement(Typography, { type: "text12", bgColor: "general-green-transparent-5", color: "general-green", fontWeight: 500, padding: "6px 10px", borderRadius: "30px", display: "flex", alignItems: "center", gap: "5px" },
                    "Applied",
                    React.createElement(BaseIcon, { icon: "Check", size: 8, containerSize: 16 })))),
            React.createElement(Typography, { type: "text16", color: "black-500" }, "Enter a promo code and get a discount")),
        React.createElement(Box, { display: "flex", gap: "15px" },
            React.createElement(FormControlInput, { label: "Code", width: "100%", disabled: form.loading, value: form.code, onChange: action((e) => {
                    form.code = e.currentTarget.value;
                }) }),
            React.createElement(Button, { type: "submit", disabled: form.loading, size: "l" }, "Apply")),
        ((_b = form.error.at(0)) === null || _b === void 0 ? void 0 : _b.description) && React.createElement(FormAlert, { text: form.error.at(0).description })));
});
