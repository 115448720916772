var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function lastOfCalls(fn, delay) {
    let requestId = 0;
    return {
        skip: () => {
            requestId += 1;
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        call(...args) {
            return __awaiter(this, void 0, void 0, function* () {
                // eslint-disable-next-line no-plusplus
                const id = ++requestId;
                if (delay) {
                    yield new Promise(r => {
                        setTimeout(r, delay);
                    });
                    if (id !== requestId) {
                        return {
                            skip: true,
                        };
                    }
                }
                try {
                    const result = yield fn(...args);
                    if (id === requestId) {
                        return {
                            result,
                            skip: false,
                        };
                    }
                }
                catch (e) {
                    if (id === requestId) {
                        throw e;
                    }
                }
                return {
                    skip: true,
                };
            });
        },
    };
}
