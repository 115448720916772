var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { makeAutoObservable, runInAction, toJS } from "mobx";
import { registrationApi } from "@/api/registration";
import { UserType } from "@/api/registration/types";
import { TimerStore } from "@/shared/stores/TimerStore";
import { groupErrorsByKey, NoTokensError, parseApiError } from "@/shared/utils/error";
import { isEmail } from "@/shared/utils/is-email";
import { validatePassword } from "@/shared/utils/password";
export var SignupStep;
(function (SignupStep) {
    SignupStep[SignupStep["Email"] = 0] = "Email";
    SignupStep[SignupStep["EmailCode"] = 1] = "EmailCode";
})(SignupStep || (SignupStep = {}));
export class SignupPageStore {
    constructor(auth) {
        this.auth = auth;
        this.step = SignupStep.Email;
        this.email = "";
        this.password = "";
        this.terms = false;
        this.loading = false;
        this.emailCode = "";
        this.referral = "";
        this.errors = [];
        this.timer = new TimerStore();
        makeAutoObservable(this, {}, {
            autoBind: true,
        });
    }
    dispose() {
        this.timer.dispose();
    }
    submitEmail() {
        return __awaiter(this, void 0, void 0, function* () {
            let { step } = this;
            let errors = [];
            runInAction(() => {
                this.loading = true;
            });
            try {
                yield registrationApi.user.createUser({
                    userType: UserType.BUSINESS,
                    email: this.email,
                    password: this.password,
                });
                this.timer.start();
                step = SignupStep.EmailCode;
            }
            catch (e) {
                console.error(e);
                errors = parseApiError(e);
            }
            runInAction(() => {
                this.loading = false;
                this.step = step;
                this.errors = errors;
            });
        });
    }
    submitEmailCode() {
        return __awaiter(this, void 0, void 0, function* () {
            let errors = [];
            let loading = true;
            runInAction(() => {
                this.loading = loading;
            });
            try {
                const { data } = yield registrationApi.user.createUser({
                    userType: UserType.BUSINESS,
                    email: this.email,
                    password: this.password,
                    emailConfirmCode: this.emailCode,
                });
                if (data.access_token && data.refresh_token) {
                    this.auth.login(data.access_token, data.refresh_token);
                    if (this.referral) {
                        yield registrationApi.discounts.redeemDiscount({
                            code: this.referral,
                        });
                    }
                }
                else {
                    errors = [NoTokensError];
                }
            }
            catch (e) {
                console.error(e);
                errors = parseApiError(e);
                loading = false;
            }
            runInAction(() => {
                this.errors = errors;
                this.loading = loading;
            });
        });
    }
    resetEmail() {
        this.step = SignupStep.Email;
        this.password = "";
        this.emailCode = "";
        this.errors = [];
    }
    get passwordValidation() {
        return validatePassword(this.password);
    }
    get isEmailValid() {
        return isEmail(this.email);
    }
    get isEmailCodeValid() {
        return this.emailCode.length > 0;
    }
    get isSignupFormValid() {
        return (this.isEmailValid
            && this.passwordValidation.length
            && this.passwordValidation.lowercase
            && this.passwordValidation.uppercase
            && this.passwordValidation.special
            && this.passwordValidation.digit
            && this.terms);
    }
    get errorsByKey() {
        return groupErrorsByKey(toJS(this.errors));
    }
}
