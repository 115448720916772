import { List, Popover } from "@mui/material";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { HelperLinks, ProfileLinks } from "../../constants";
import { SignOutModal } from "@/features/signout";
import { ROUTES } from "@/routes";
import { BaseIcon, Box } from "@/shared/components";
import { ListItem } from "@/shared/components/List/Item";
import privacy_policy from "@/shared/files/privacy_policy.pdf";
import termsOfUse from "@/shared/files/terms_of_use.pdf";
export const User = observer(() => {
    const navigate = useNavigate();
    const [openModal, setOpenModal] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const handleCloseModal = () => {
        setOpenModal(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { display: "flex", alignItems: "center", gap: "10px", onClick: handleClick, role: "button", bgColor: "general-white", height: "46px", padding: "0 20px", borderRadius: "46px" },
            "Profile Settings",
            React.createElement(BaseIcon, { icon: "DropDown", color: open ? "deep-blue-500" : "black-1200" })),
        React.createElement(Popover, { open: open, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
            }, transformOrigin: {
                vertical: "top",
                horizontal: "right",
            }, slotProps: {
                paper: {
                    sx: {
                        marginTop: "20px",
                    },
                },
            } },
            React.createElement(List, { sx: { width: "100%", maxWidth: 360, minWidth: 300 }, disablePadding: true },
                [...ProfileLinks, ...HelperLinks].map(({ label, link }) => (React.createElement(ListItem, { key: link, onClick: () => {
                        if (link === ROUTES.terms) {
                            window.open(termsOfUse, "_blank");
                            handleClose();
                            return;
                        }
                        if (link === ROUTES.privacy) {
                            window.open(privacy_policy, "_blank");
                            handleClose();
                            return;
                        }
                        navigate(link);
                        handleClose();
                    }, label: label }))),
                React.createElement(Box, { height: "1px", bgColor: "black-100" }),
                React.createElement(ListItem, { onClick: () => {
                        setOpenModal(true);
                        handleClose();
                    }, label: "Sign out", color: "general-red" }))),
        React.createElement(SignOutModal, { isOpen: openModal, onClose: handleCloseModal })));
});
