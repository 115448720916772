var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { makeAutoObservable, runInAction } from "mobx";
import { lastOfCalls } from "@/shared/utils/last-of-calls";
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class DataSync {
    constructor(fn, valueGet = v => v, paramGet = v => v) {
        this.fn = fn;
        this.valueGet = valueGet;
        this.paramGet = paramGet;
        this.loader = 0;
        this.value = undefined;
        this.params = undefined;
        this.error = undefined;
        makeAutoObservable(this, {}, { autoBind: true });
        this.wrappedFn = lastOfCalls(this.fn, 400);
    }
    sync(...args) {
        return __awaiter(this, void 0, void 0, function* () {
            let value;
            let params;
            let error;
            this.wrappedFn.skip();
            runInAction(() => {
                this.loader += 1;
            });
            try {
                const result = yield this.wrappedFn.call(...args);
                if (result.skip) {
                    value = this.value;
                    params = this.params;
                    error = this.error;
                }
                else {
                    value = this.valueGet(result.result);
                    params = this.paramGet(args);
                }
            }
            catch (e) {
                error = e;
                console.error(e);
            }
            runInAction(() => {
                this.loader -= 1;
                this.value = value;
                this.params = params;
                this.error = error;
            });
        });
    }
    reset() {
        this.params = undefined;
        this.value = undefined;
        this.wrappedFn.skip();
    }
    get loading() {
        return this.loader > 0;
    }
}
