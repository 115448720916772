export const sliceAddress = (value, start = 5, end = 3) => {
    if (start === 0) {
        return value.toLowerCase();
    }
    if (value.length <= start + end) {
        return value;
    }
    const startStr = value.substr(0, start).toLowerCase();
    const endStr = value.substr(value.length - end, end).toLowerCase();
    return `${startStr}...${endStr}`;
};
