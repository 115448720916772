var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { Typography } from "../Typography";
import { StyledBox } from "./styled";
const textColor = {
    "red": "general-red",
    "green": "general-green",
    "white": "black-1200",
};
const boxColor = {
    "red": "general-red-transparent-5",
    "green": "general-green-transparent-5",
    "white": "general-white",
};
export const Badge = (_a) => {
    var { children, height = "s", color } = _a, props = __rest(_a, ["children", "height", "color"]);
    return (React.createElement(StyledBox, Object.assign({ padding: "0 10px", width: "max-content", display: "flex", alignItems: "center", borderRadius: "100px" }, props, { "$height": height, bgColor: boxColor[color] }), typeof children === "string"
        ? React.createElement(Typography, { color: textColor[color], type: "text12" }, children)
        : children));
};
