import { useQuery } from "@tanstack/react-query";
import { walletApi } from "./api";
export const useGetBalanceLogReceipt = (id) => useQuery({
    queryKey: ["useGetgetBalanceLogReceipt", id],
    queryFn: () => walletApi.balance.getBalanceLogReceipt(id),
    staleTime: 100000,
    select(data) {
        return data.data;
    },
    gcTime: 100000,
    enabled: !!id,
});
