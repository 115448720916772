var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { Autocomplete } from "./Autocomplete";
import { ListOptionDefault } from "./Option";
const ImplSelect = (_a, ref) => {
    var { value, placeholder, isSearchDisabled, lineClamp } = _a, props = __rest(_a, ["value", "placeholder", "isSearchDisabled", "lineClamp"]);
    const selectedOption = props.options.find((opt) => opt.key === (value || "")) || null;
    return (React.createElement(Autocomplete, Object.assign({ ref: ref, fullWidth: true, value: selectedOption, renderOption: (p, option, state) => React.createElement(ListOptionDefault, Object.assign({ text: option.label }, state, p)), placeholder: placeholder, inputProps: { readOnly: isSearchDisabled } }, props)));
};
export const Select = React.forwardRef(ImplSelect);
