import { styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { generatePath, useNavigate, useSearchParams } from "react-router-dom";
import { PaymentInfoPopup } from "@/pages/PaymentPage/components/PaymentInfoPopup";
import { PaymentTypeItem } from "@/pages/PaymentPage/components/PaymentTypeItem";
import { PaymentParam, PaymentTarget, PaymentType, ROUTES } from "@/routes";
import { Box, Button, Typography } from "@/shared/components";
import { useToggler } from "@/shared/hooks/useToggler";
import bgImg from "@/shared/img/payment-bg.jpg";
import { generateQueryPath } from "@/shared/utils/path";
export const PaymentTypePage = observer(() => {
    const navigate = useNavigate();
    const infoPopup = useToggler();
    const [search] = useSearchParams();
    const target = search.get(PaymentParam.Target);
    const plan = search.get(PaymentParam.Plan);
    const [paymentType, setPaymentType] = React.useState(PaymentType.Crypto);
    return (React.createElement(React.Fragment, null,
        infoPopup.enabled && React.createElement(PaymentInfoPopup, { onClose: infoPopup.off }),
        React.createElement(Bg, null),
        React.createElement(Wrapper, null,
            target === PaymentTarget.Kyb && (React.createElement(Button, { variant: "Gray", icon: "Back", width: "auto", paddingRight: "25px", onClick: () => navigate(generatePath(ROUTES.kybForm)) }, "Back")),
            React.createElement(Typography, { paddingTop: target === PaymentTarget.Kyb ? "60px" : "112px", type: "text32Bold", color: "black-1200", marginBottom: "10px", variant: "h1" }, "Payment"),
            React.createElement(Typography, { type: "text16", color: "black-500", marginBottom: "40px", variant: "body1" }, "Choose your preferred payment method. "),
            React.createElement(Box, { display: "grid", gridAutoFlow: "row", gridAutoRows: "1fr", gap: "10px" },
                React.createElement(PaymentTypeItem, { active: paymentType === PaymentType.Crypto, icon: "PaymentTypeCrypto", title: "Pay with crypto", 
                    // desc="00.00 USDT"
                    onClick: () => setPaymentType(PaymentType.Crypto) }),
                React.createElement(PaymentTypeItem, { active: paymentType === PaymentType.Card, icon: "PaymentTypeCard", title: "Pay with card", 
                    // desc="€00.00"
                    onClick: () => setPaymentType(PaymentType.Card) })),
            React.createElement(Button, { minWidth: 250, marginTop: "100px", onClick: () => {
                    navigate(generateQueryPath(ROUTES.payment, {}, {
                        [PaymentParam.Target]: target,
                        [PaymentParam.Type]: paymentType,
                        [PaymentParam.Plan]: plan,
                    }), { replace: true });
                } }, "Next"))));
});
const Bg = styled("div")(({ theme }) => ({
    position: "fixed",
    top: 0,
    bottom: 0,
    left: "max(calc(((100% - 1240px) / 2) + 560px + min(160px, ((100% - 1240px) / 2))), 600px)",
    right: 0,
    zIndex: -1,
    backgroundImage: `url(${bgImg})`,
    backgroundSize: "620px",
    backgroundPosition: "bottom right",
    backgroundRepeat: "no-repeat",
    backgroundColor: theme.colors["black-1200"],
    [theme.breakpoints.down(1220)]: {
        backgroundPosition: "bottom left",
    },
    [theme.breakpoints.down("md")]: {
        display: "none",
    },
}));
const Wrapper = styled("div")(({ theme }) => ({
    maxWidth: 560,
    [theme.breakpoints.down("md")]: {
        maxWidth: "unset",
    },
}));
