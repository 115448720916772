export const ROUTES = {
    root: "/",
    signin: "/signin",
    passwordReset: "/reset",
    profile: "/profile",
    kyb: "/kyb",
    kybForm: "/kyb/form",
    kybFormId: "/kyb/form/:id/:param?",
    payment: "/payment",
    company: "/company",
    transactions: "/transactions",
    subscription: "/subscription",
    about: "/about",
    terms: "/terms",
    privacy: "/privacy",
    deposit: "/deposit/:type?/:id?",
    transfer: "/transfer/:type",
    bankAccountsList: "/account",
    bankAccountsItem: "/account/:id",
    recipientCreate: "/recipients/new",
    recipients: "/recipients",
    exchange: "/exchange/:type",
    walletList: "/wallet",
    walletItem: "/wallet/:id",
    feesPolicy: "/fees/corporate/",
    checkoutSuccessCard: "/checkout/payment/redirect/success",
    checkoutFailedCard: "/checkout/payment/redirect/failed",
};
export var DepositType;
(function (DepositType) {
    DepositType["Crypto"] = "crypto";
    DepositType["Fiat"] = "fiat";
})(DepositType || (DepositType = {}));
export var ModalType;
(function (ModalType) {
    ModalType["AfterKyb"] = "afterKyb";
})(ModalType || (ModalType = {}));
export var TransferType;
(function (TransferType) {
    TransferType["Account"] = "account";
    TransferType["Recipient"] = "recipient";
    TransferType["Crypto"] = "crypto";
})(TransferType || (TransferType = {}));
export var ExchangeType;
(function (ExchangeType) {
    ExchangeType["Crypto"] = "crypto";
    ExchangeType["Fiat"] = "fiat";
    ExchangeType["FiatFiat"] = "fiat-fiat";
})(ExchangeType || (ExchangeType = {}));
export var PaymentParam;
(function (PaymentParam) {
    PaymentParam["Target"] = "target";
    PaymentParam["Type"] = "type";
    PaymentParam["Plan"] = "plan";
})(PaymentParam || (PaymentParam = {}));
export var PaymentTarget;
(function (PaymentTarget) {
    PaymentTarget["Kyb"] = "kyb";
    PaymentTarget["Subscription"] = "subscription";
})(PaymentTarget || (PaymentTarget = {}));
export var PaymentType;
(function (PaymentType) {
    PaymentType["Crypto"] = "crypto";
    PaymentType["Card"] = "card";
})(PaymentType || (PaymentType = {}));
export var SubscriptionPlan;
(function (SubscriptionPlan) {
    SubscriptionPlan["Personal"] = "personal";
    SubscriptionPlan["Business"] = "business";
    SubscriptionPlan["Pro"] = "pro";
})(SubscriptionPlan || (SubscriptionPlan = {}));
