var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Box, Button, FormControlInput, Typography } from "@/shared/components";
import { PopupBody } from "@/shared/components/Popup/Body";
import { PopupContent } from "@/shared/components/Popup/Content";
import { ResendCodeBtn } from "@/shared/components/ResendCodeBtn";
import { preventDefault } from "@/shared/utils/prevent";
export const VerificationStep = observer(({ description, title, textButton = "Save changes", loading, error, count, onSubmitCode, onResendCode, }) => {
    const [code, setCode] = useState("");
    const handleConfirmCode = () => __awaiter(void 0, void 0, void 0, function* () {
        onSubmitCode(code);
    });
    return (React.createElement(PopupBody, { as: "form", onSubmit: preventDefault(handleConfirmCode) },
        React.createElement(PopupContent, { gap: "30px" },
            React.createElement(Box, { display: "flex", flexDirection: "column", gap: "10px", alignItems: "center" },
                React.createElement(Typography, { type: "text28Bold", textAlign: "center" }, title),
                React.createElement(Typography, { color: "black-500", textAlign: "center" }, description)),
            React.createElement(FormControlInput, { label: "Verification code", type: "text", value: code, onChange: (e) => setCode(e.target.value), disabled: loading, variantError: "alert", error: error, InputProps: {
                    endAdornment: (React.createElement(ResendCodeBtn, { loading: loading, onSubmit: onResendCode, count: count })),
                } })),
        React.createElement(Button, { width: "100%", onClick: handleConfirmCode, disabled: !code || loading, type: "submit" }, textButton)));
});
