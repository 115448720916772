import { observer } from "mobx-react-lite";
import * as React from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { generatePath, useNavigate } from "react-router-dom";
import { FormLayout } from "@/pages/KYBPage/components/FormLayout";
import { FormRow } from "@/pages/KYBPage/components/FormRow";
import { ROUTES } from "@/routes";
import { FormControlInput } from "@/shared/components";
import { FormControlSelect } from "@/shared/components/FormControlSelect";
import { KYBPrimarySourceOfFunds, KYBValues1, KYBValues2, KYBValues3 } from "@/shared/constants/dict";
import { useStoreContext } from "@/shared/hooks/useStore";
import { CompanyInfoStore } from "@/shared/stores/CompanyInfoStore";
import { RequiredFieldMessage } from "@/shared/utils/error";
export const Form4 = observer(() => {
    var _a;
    const navigate = useNavigate();
    const companyInfo = useStoreContext(CompanyInfoStore);
    const form = useForm();
    const submit = form.handleSubmit((formData) => {
        companyInfo.update(formData).then((success) => {
            if (success)
                navigate(generatePath(ROUTES.kybFormId, { id: 5 }));
        });
    });
    React.useEffect(() => {
        form.reset(companyInfo.companyInfo);
    }, [form, companyInfo.companyInfo]);
    const entityType = useWatch({
        control: form.control,
        name: "meta.primarySourceOfFunds",
    });
    return (React.createElement(FormLayout, { onSubmit: submit, onBack: () => navigate(generatePath(ROUTES.kybFormId, { id: 3 })), index: 4, error: (_a = companyInfo.errors[0]) === null || _a === void 0 ? void 0 : _a.description, title: "Expected Account Activity", desc: "Fill in the fields below with your investment information. Please, use Latin letters only.", loading: !companyInfo.ready || companyInfo.loading },
        React.createElement(Controller, { control: form.control, name: "meta.primarySourceOfFunds", rules: {
                required: {
                    value: true,
                    message: RequiredFieldMessage.description,
                },
            }, render: ({ field, fieldState: { error } }) => (React.createElement(FormControlSelect, { name: field.name, onChange: (_, v) => field.onChange(v === null || v === void 0 ? void 0 : v.key), value: field.value, options: KYBPrimarySourceOfFunds, label: "Primary source of funds", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" })) }),
        entityType === "OTHER" && (React.createElement(Controller, { control: form.control, name: "meta.primarySourceOfFundsDescription", rules: {
                required: {
                    value: true,
                    message: RequiredFieldMessage.description,
                },
            }, render: ({ field, fieldState: { error } }) => (React.createElement(FormControlInput, Object.assign({}, field, { disabled: companyInfo.loading || field.disabled, label: "Primary source of funds description", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" }))) })),
        React.createElement(FormRow, null,
            React.createElement(Controller, { control: form.control, name: "meta.totalAssets", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: ({ field, fieldState: { error } }) => (React.createElement(FormControlSelect, { name: field.name, onChange: (_, v) => field.onChange(v === null || v === void 0 ? void 0 : v.key), value: field.value, options: KYBValues1, label: "Total assets", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" })) }),
            React.createElement(Controller, { control: form.control, name: "meta.usdValueOfCrypto", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: ({ field, fieldState: { error } }) => (React.createElement(FormControlSelect, { name: field.name, onChange: (_, v) => field.onChange(v === null || v === void 0 ? void 0 : v.key), value: field.value, options: KYBValues1, label: "USD value of crypto", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" })) })),
        React.createElement(FormRow, null,
            React.createElement(Controller, { control: form.control, name: "meta.usdValueOfFiat", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: ({ field, fieldState: { error } }) => (React.createElement(FormControlSelect, { name: field.name, onChange: (_, v) => field.onChange(v === null || v === void 0 ? void 0 : v.key), value: field.value, options: KYBValues1, label: "USD value of fiat", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" })) }),
            React.createElement(Controller, { control: form.control, name: "meta.monthlyCryptoInvestmentDeposit", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: ({ field, fieldState: { error } }) => (React.createElement(FormControlSelect, { name: field.name, onChange: (_, v) => field.onChange(v === null || v === void 0 ? void 0 : v.key), value: field.value, options: KYBValues2, label: "Monthly crypto investment deposit", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" })) })),
        React.createElement(FormRow, null,
            React.createElement(Controller, { control: form.control, name: "meta.monthlyInvestmentDeposit", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: ({ field, fieldState: { error } }) => (React.createElement(FormControlSelect, { name: field.name, onChange: (_, v) => field.onChange(v === null || v === void 0 ? void 0 : v.key), value: field.value, options: KYBValues2, label: "Monthly investment deposit", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" })) }),
            React.createElement(Controller, { control: form.control, name: "meta.monthlyCryptoDeposits", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: ({ field, fieldState: { error } }) => (React.createElement(FormControlSelect, { name: field.name, onChange: (_, v) => field.onChange(v === null || v === void 0 ? void 0 : v.key), value: field.value, options: KYBValues3, label: "Monthly crypto deposits", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" })) })),
        React.createElement(FormRow, null,
            React.createElement(Controller, { control: form.control, name: "meta.monthlyDeposit", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: ({ field, fieldState: { error } }) => (React.createElement(FormControlSelect, { name: field.name, onChange: (_, v) => field.onChange(v === null || v === void 0 ? void 0 : v.key), value: field.value, options: KYBValues3, label: "Monthly deposit", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" })) }),
            React.createElement(Controller, { control: form.control, name: "meta.monthlyCryptoInvestmentWithdrawal", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: ({ field, fieldState: { error } }) => (React.createElement(FormControlSelect, { name: field.name, onChange: (_, v) => field.onChange(v === null || v === void 0 ? void 0 : v.key), value: field.value, options: KYBValues2, label: "Monthly crypto investment withdrawals", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" })) })),
        React.createElement(FormRow, null,
            React.createElement(Controller, { control: form.control, name: "meta.monthlyInvestmentWithdrawal", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: ({ field, fieldState: { error } }) => (React.createElement(FormControlSelect, { name: field.name, onChange: (_, v) => field.onChange(v === null || v === void 0 ? void 0 : v.key), value: field.value, options: KYBValues2, label: "Monthly investment withdrawals", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" })) }),
            React.createElement(Controller, { control: form.control, name: "meta.monthlyCryptoWithdrawals", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: ({ field, fieldState: { error } }) => (React.createElement(FormControlSelect, { name: field.name, onChange: (_, v) => field.onChange(v === null || v === void 0 ? void 0 : v.key), value: field.value, options: KYBValues3, label: "Monthly crypto withdrawals", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" })) })),
        React.createElement(FormRow, null,
            React.createElement(Controller, { control: form.control, name: "meta.monthlyWithdrawals", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: ({ field, fieldState: { error } }) => (React.createElement(FormControlSelect, { name: field.name, onChange: (_, v) => field.onChange(v === null || v === void 0 ? void 0 : v.key), value: field.value, options: KYBValues3, label: "Monthly withdrawals", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" })) }))));
});
