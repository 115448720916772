import { createTheme } from "@mui/material/styles";
export const color = {
    "black-1000": "var(--black-1000)",
    "black-50": "var(--black-50)",
    "black-100": "var(--black-100)",
    "black-200": "var(--black-200)",
    "black-300": "var(--black-300)",
    "black-400": "var(--black-400)",
    "black-500": "var(--black-500)",
    "black-600": "var(--black-600)",
    "black-1200": "var(--black-1200)",
    "general-white": "var(--general-white)",
    "general-green": "var(--general-green)",
    "deep-blue-500": "var(--deep-blue-500)",
    "deep-blue-500-transparent-2": "var(--deep-blue-500-transparent-2)",
    "deep-blue-500-transparent-5": "var(--deep-blue-500-transparent-5)",
    "deep-blue-500-transparent-10": "var(--deep-blue-500-transparent-10)",
    "hover-link": "var(--hover-link)",
    "general-red": "var(--general-red)",
    "general-orange": "var(--general-orange)",
    "general-yellow": "var(--general-yellow)",
    "black-alert": "var(--black-alert)",
    "general-red-transparent-5": "var(--general-red-transparent-5)",
    "general-green-transparent-5": "var(--general-green-transparent-5)",
    "general-yellow-transparent-5": "var(--general-yellow-transparent-5)",
    "general-red-transparent-10": "var(--general-red-transparent-10)",
    "general-white-transparent-15": "var(--general-white-transparent-15)",
    "general-white-transparent-10": "var(--general-white-transparent-10)",
    "light-blue": "var(--light-blue)",
};
export const typography = {
    text12: {
        fontSize: "var(--text-12-font-size)",
        lineHeight: "var(--text-12-line-height)",
        fontWeight: "var(--text-12-font-weight)",
    },
    text14: {
        fontSize: "var(--text-14-font-size)",
        lineHeight: "var(--text-14-line-height)",
        fontWeight: "var(--text-14-font-weight)",
    },
    text16: {
        fontSize: "var(--text-16-font-size)",
        lineHeight: "var(--text-16-line-height)",
        fontWeight: "var(--text-16-font-weight)",
    },
    text16Bold: {
        fontSize: "var(--text-16-bold-font-size)",
        lineHeight: "var(--text-16-bold-line-height)",
        fontWeight: "var(--text-16-bold-font-weight)",
    },
    text18: {
        fontSize: "var(--text-18-font-size)",
        lineHeight: "var(--text-18-line-height)",
        fontWeight: "var(--text-18-font-weight)",
    },
    text20: {
        fontSize: "var(--text-20-font-size)",
        lineHeight: "var(--text-20-line-height)",
        fontWeight: "var(--text-20-font-weight)",
    },
    text24: {
        fontSize: "var(--text-24-font-size)",
        lineHeight: "var(--text-24-line-height)",
        fontWeight: "var(--text-24-font-weight)",
    },
    text24Bold: {
        fontSize: "var(--text-24-bold-font-size)",
        lineHeight: "var(--text-24-bold-line-height)",
        fontWeight: "var(--text-24-bold-font-weight)",
    },
    text26: {
        fontSize: "var(--text-26-font-size)",
        lineHeight: "var(--text-26-line-height)",
        fontWeight: "var(--text-26-font-weight)",
    },
    text26Bold: {
        fontSize: "var(--text-26-font-size)",
        lineHeight: "var(--text-26-line-height)",
        fontWeight: "var(--text-26-bold-font-weight)",
    },
    text28: {
        fontSize: "var(--text-28-font-size)",
        lineHeight: "var(--text-28-line-height)",
        fontWeight: "var(--text-28-font-weight)",
    },
    text28Bold: {
        fontSize: "var(--text-28-font-size)",
        lineHeight: "var(--text-28-line-height)",
        fontWeight: "var(--text-28-bold-font-weight)",
    },
    text30: {
        fontSize: "var(--text-30-font-size)",
        lineHeight: "var(--text-30-line-height)",
        fontWeight: "var(--text-30-font-weight)",
    },
    text30Bold: {
        fontSize: "var(--text-30-bold-font-size)",
        lineHeight: "var(--text-30-bold-line-height)",
        fontWeight: "var(--text-30-bold-font-weight)",
    },
    text32Bold: {
        fontSize: "var(--text-32-bold-font-size)",
        lineHeight: "var(--text-32-bold-line-height)",
        fontWeight: "var(--text-32-bold-font-weight)",
    },
    text36Bold: {
        fontSize: "var(--text-36-bold-font-size)",
        lineHeight: "var(--text-36-bold-line-height)",
        fontWeight: "var(--text-36-bold-font-weight)",
    },
    text44Bold: {
        fontSize: "var(--text-44-bold-font-size)",
        lineHeight: "var(--text-44-bold-line-height)",
        fontWeight: "var(--text-44-bold-font-weight)",
    },
};
// // Create a theme instance.
export const theme = createTheme({
    colors: color,
    text: typography,
    spacing: 4,
    fontFamily: "Circular",
    breakpoints: {
        values: {
            xs: 0,
            sm: 768,
            md: 1024,
            lg: 1366,
            xl: 1600,
        },
    },
    components: {
        MuiTabs: {
            styleOverrides: {
                flexContainer: {
                    gap: "10px",
                },
                root: {
                    minHeight: "36px",
                },
            },
        },
        MuiTab: {
            defaultProps: {
                disableRipple: true,
                disableTouchRipple: true,
                disableFocusRipple: true,
            },
            styleOverrides: {
                root: {
                    backgroundColor: color["deep-blue-500-transparent-5"],
                    borderRadius: "10px",
                    minHeight: "36px",
                    padding: "0",
                    minWidth: "130px",
                    fontSize: typography.text14.fontSize,
                    color: color["deep-blue-500"],
                    textTransform: "none",
                },
            },
        },
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    h1: "h1",
                    h2: "h2",
                    h3: "h3",
                    h4: "h4",
                    h5: "h5",
                    h6: "h6",
                    span: "span",
                    button: "button",
                },
            },
        },
        MuiCheckbox: {
            defaultProps: {
                disableRipple: true,
                disableTouchRipple: true,
                disableFocusRipple: true,
            },
        },
        MuiPopover: {
            styleOverrides: {
                root: {
                    "& .MuiBackdrop-root": {
                        background: "transparent",
                    },
                },
            },
        },
        MuiModal: {
            styleOverrides: {
                backdrop: {
                    background: "rgba(25, 27, 32, 0.2)",
                },
            },
        },
        MuiRadio: {
            defaultProps: {
                disableRipple: true,
                disableTouchRipple: true,
                disableFocusRipple: true,
            },
        },
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
                disableTouchRipple: true,
            },
        },
        MuiIconButton: {
            defaultProps: {
                disableRipple: true,
                disableTouchRipple: true,
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: color["black-1200"],
                    "&.Mui-error": {
                        color: "var(--general-red)",
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    padding: "0",
                    fontSize: typography.text16.fontSize,
                    fontWeight: typography.text16.fontWeight,
                    lineHeight: typography.text16.lineHeight,
                    color: color["black-1200"],
                    height: "auto",
                },
                root: {
                    borderRadius: "12px",
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                outlined: {
                    color: color["black-500"],
                    "&.Mui-focused:not(.Mui-error)": {
                        color: color["black-1200"],
                    },
                    // "&.MuiFormLabel-filled:not(.Mui-error):not(.Mui-disabled)":
                    //     {
                    //         color: color["black-1200"],
                    //     },
                    // "&:not(.Mui-focused):not(.MuiFormLabel-filled):not(.MuiAutocomplete-hasValue)":
                    //     {
                    //         transform: "translate(20px, 18px) scale(1)",
                    //     },
                    "&.Mui-focused,&.MuiFormLabel-filled": {
                        transform: "translate(20px, -9px) scale(0.75)",
                    },
                    "&.Mui-disabled": {
                        color: color["black-500"],
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: "12px",
                    "&.Mui-focused:not(.Mui-error) .MuiOutlinedInput-notchedOutline": {
                        borderColor: color["black-200"],
                        borderWidth: "1px",
                    },
                    "&:not(.Mui-error):not(.Mui-disabled):hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: color["black-200"],
                        borderWidth: "1px",
                    },
                    "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                        borderColor: color["general-red"],
                        borderWidth: "1px",
                    },
                    "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                        borderColor: color["black-200"],
                    },
                },
                notchedOutline: {
                    padding: "0 20px 0 10px",
                    borderColor: color["black-200"],
                    "&:not(.Mui-disabled):hover": {
                        borderColor: color["black-200"],
                    },
                    "& legend": {
                        fontSize: "12px",
                        "& span": {
                            padding: "0 10px",
                            fontFamily: "Circular",
                        },
                    },
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    padding: "8px",
                    fontSize: typography.text16.fontSize,
                    fontWeight: typography.text16.fontWeight,
                    lineHeight: typography.text16.lineHeight,
                    backgroundColor: color["black-1200"],
                    borderRadius: "8px",
                },
                arrow: {
                    color: color["black-1200"],
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: "8px",
                    padding: "0px",
                    marginTop: "6px",
                    "&.MuiAutocomplete-paper": {
                        boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.08)",
                    },
                    "&.MuiPopover-paper": {
                        boxShadow: "0px 4px 20px 0px #00000014",
                    },
                },
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    "&:hover": {
                        backgroundColor: color["black-50"],
                        "& .ListItemText": {
                            color: color["deep-blue-500"],
                        },
                    },
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    padding: "0",
                },
                noOptions: {
                    padding: "12px 14px",
                },
                listbox: {
                    padding: "0px",
                    "&::-webkit-scrollbar": {
                        display: "none",
                    },
                },
            },
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    boxShadow: "none",
                    border: "none",
                    margin: 0,
                    background: "transparent",
                    "&:before": {
                        display: "none",
                    },
                    "&.Mui-disabled": {
                        background: "transparent",
                    },
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    "&.hasOptionsValue .MuiFormLabel-root": {
                        transform: "translate(20px, -9px) scale(0.75)",
                        color: color["black-1200"],
                    },
                    "&.hasOptionsValue .MuiInputBase-root.MuiAutocomplete-hasValue": {
                        paddingTop: "11px",
                        paddingLeft: "11px",
                        paddingBottom: "11px",
                    },
                    "&.hasOptionsValue .MuiInputBase-root.MuiAutocomplete-hasValue input": {
                        height: "auto",
                    },
                    ".MuiInputLabel-root.MuiFormLabel-filled:not(.Mui-error):not(.Mui-disabled)": {
                        color: color["black-1200"],
                    },
                    "&:not(.hasOptionsValue) .MuiInputLabel-root:not(.Mui-focused):not(.MuiFormLabel-filled):not(.MuiAutocomplete-hasValue)": {
                        transform: "translate(20px, 18px) scale(1)",
                    },
                },
            },
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    margin: 0,
                    "&.Mui-disabled": {
                        opacity: 1,
                    },
                },
                content: {
                    margin: 0,
                },
            },
        },
    },
});
