export const UnknownError = {
    description: "Unknown error",
};
export const NoTokensError = {
    description: "Tokens not founded",
};
export const RequiredFieldMessage = {
    description: "Field is required",
};
export const WrongFormat2Message = {
    description: "Incorrect format, at least 2 words required",
};
export const WrongFormatMessage = {
    description: "Incorrect format",
};
export const ASCIIOnlyFormat2Message = {
    description: "Incorrect format, only latin symbols accepted",
};
export const MfaRequired = {
    description: "Two factor authentication required",
};
export const parseApiError = (e) => {
    var _a, _b, _c, _d;
    if (((_b = (_a = e === null || e === void 0 ? void 0 : e.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.errors) && e.response.data.errors.length > 0) {
        return e.response.data.errors;
    }
    if ((_d = (_c = e === null || e === void 0 ? void 0 : e.response) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.message) {
        return [{ description: e.response.data.message }];
    }
    if (e === null || e === void 0 ? void 0 : e.message) {
        return [{ description: e.message }];
    }
    return [UnknownError];
};
export const groupErrorsByKey = (errors) => errors.reduce((acc, item) => {
    var _a, _b;
    const key = (_a = item.key) !== null && _a !== void 0 ? _a : "unknown";
    const errs = (_b = acc[key]) !== null && _b !== void 0 ? _b : [];
    return Object.assign(Object.assign({}, acc), { [key]: errs.concat(item.description) });
}, {});
