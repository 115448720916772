var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { makeAutoObservable, runInAction } from "mobx";
import { registrationApi } from "@/api/registration";
import { CLIENT_ID } from "@/config";
import { TimerStore } from "@/shared/stores/TimerStore";
import { MfaRequired, parseApiError } from "@/shared/utils/error";
export class MfaStore {
    constructor() {
        this.visible = false;
        this.loading = false;
        this.errors = [];
        this.mfaToken = undefined;
        this.code = undefined;
        this.scope = undefined;
        this.timer = new TimerStore();
        this.authentication = undefined;
        this.challenge = undefined;
        this.resolve = undefined;
        this.reject = undefined;
        makeAutoObservable(this, {}, { autoBind: true });
    }
    reset() {
        var _a;
        this.mfaToken = undefined;
        this.challenge = undefined;
        this.authentication = undefined;
        this.code = undefined;
        this.visible = false;
        this.loading = false;
        this.errors = [];
        this.resolve = undefined;
        (_a = this.reject) === null || _a === void 0 ? void 0 : _a.call(this, new Error(MfaRequired.description));
        this.reject = undefined;
    }
    start(mfaToken, scope) {
        this.reset();
        this.mfaToken = mfaToken;
        this.scope = scope;
        this.visible = true;
        this.createChallenge();
        return new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
        });
    }
    createChallenge() {
        return __awaiter(this, void 0, void 0, function* () {
            let errors = [];
            let authentication;
            let challenge;
            runInAction(() => {
                this.loading = true;
            });
            try {
                if (!this.mfaToken) {
                    throw new Error("MFA token must be defined");
                }
                const authenticationListResp = yield registrationApi.mfa
                    .getListOfTwoFactorAuthentications({
                    onlyActive: true,
                }, {
                    headers: { Authorization: `Bearer ${this.mfaToken}` },
                });
                const authenticationItem = authenticationListResp.data
                    .find(item => "oob_channel" in item && item.oob_channel === "EMAIL");
                if (!authenticationItem) {
                    throw new Error("OOB auth type not founded");
                }
                const challengeResp = yield registrationApi.mfa.createChallenge({
                    authenticator_id: authenticationItem.id,
                    challenge_type: authenticationItem.authenticator_type,
                    client_id: CLIENT_ID,
                    mfa_token: this.mfaToken,
                });
                authentication = authenticationItem;
                challenge = challengeResp.data;
                this.timer.start();
            }
            catch (e) {
                console.error(e);
                errors = parseApiError(e);
            }
            runInAction(() => {
                this.challenge = challenge;
                this.authentication = authentication;
                this.loading = false;
                this.errors = errors;
            });
        });
    }
    submitCode() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            let errors = [];
            let loading = true;
            let { visible } = this;
            runInAction(() => {
                this.loading = loading;
            });
            try {
                if (!this.mfaToken) {
                    throw new Error("MfaToken must be defined");
                }
                if (!this.challenge) {
                    throw new Error("Challenge must be defined");
                }
                if (!this.code) {
                    throw new Error("Code must be defined");
                }
                if (!this.scope) {
                    throw new Error("Scope must be defined");
                }
                const resp = yield registrationApi.auth.getToken({
                    grant_type: "mfa-oob",
                    binding_code: this.code,
                    client_id: CLIENT_ID,
                    mfaToken: this.mfaToken,
                    oobCode: this.challenge.oob_code,
                    scope: this.scope,
                });
                if (resp.data.access_token) {
                    (_a = this.resolve) === null || _a === void 0 ? void 0 : _a.call(this, {
                        accessToken: resp.data.access_token,
                        refreshToken: resp.data.refresh_token,
                    });
                    visible = false;
                }
                else {
                    throw new Error("Access token not founded");
                }
            }
            catch (e) {
                console.error(e);
                errors = parseApiError(e);
                loading = false;
            }
            runInAction(() => {
                this.errors = errors;
                this.loading = loading;
                this.visible = visible;
            });
        });
    }
}
