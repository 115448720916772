import { styled } from "@mui/material";
import * as React from "react";
import { Button } from "@/shared/components";
export const BackBtn = ({ onClick, }) => (React.createElement(Wrapper, { variant: "Gray", width: "auto", icon: "Cross", iconSize: 12, iconPosition: "right", onClick: onClick }, "Continue later"));
const Wrapper = styled(Button) `
    position: absolute;
    top: 0;
    right: 0;
    font-weight: 500;
    display: flex;
    padding-left: 25px;
    padding-right: 25px;
`;
