/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import axios from "axios";
export var ContentType;
(function (ContentType) {
    ContentType["Json"] = "application/json";
    ContentType["FormData"] = "multipart/form-data";
    ContentType["UrlEncoded"] = "application/x-www-form-urlencoded";
    ContentType["Text"] = "text/plain";
})(ContentType || (ContentType = {}));
export class HttpClient {
    constructor(_a = {}) {
        var { securityWorker, secure, format } = _a, axiosConfig = __rest(_a, ["securityWorker", "secure", "format"]);
        this.securityData = null;
        this.setSecurityData = (data) => {
            this.securityData = data;
        };
        this.request = (_b) => __awaiter(this, void 0, void 0, function* () {
            var { secure, path, type, query, format, body } = _b, params = __rest(_b, ["secure", "path", "type", "query", "format", "body"]);
            const secureParams = ((typeof secure === "boolean" ? secure : this.secure)
                && this.securityWorker
                && (yield this.securityWorker(this.securityData)))
                || {};
            const requestParams = this.mergeRequestParams(params, secureParams);
            const responseFormat = format || this.format || undefined;
            if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
                body = this.createFormData(body);
            }
            if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
                body = JSON.stringify(body);
            }
            return this.instance.request(Object.assign(Object.assign({}, requestParams), { headers: Object.assign(Object.assign({}, (requestParams.headers || {})), (type && type !== ContentType.FormData ? { "Content-Type": type } : {})), params: query, responseType: responseFormat, data: body, url: path }));
        });
        this.instance = axios.create(Object.assign(Object.assign({}, axiosConfig), { baseURL: axiosConfig.baseURL || "https://gw.api-stg.evercash.eu/currency" }));
        this.secure = secure;
        this.format = format;
        this.securityWorker = securityWorker;
    }
    mergeRequestParams(params1, params2) {
        const method = params1.method || (params2 && params2.method);
        return Object.assign(Object.assign(Object.assign(Object.assign({}, this.instance.defaults), params1), (params2 || {})), { headers: Object.assign(Object.assign(Object.assign({}, ((method && this.instance.defaults.headers[method.toLowerCase()]) || {})), (params1.headers || {})), ((params2 && params2.headers) || {})) });
    }
    stringifyFormItem(formItem) {
        if (typeof formItem === "object" && formItem !== null) {
            return JSON.stringify(formItem);
        }
        else {
            return `${formItem}`;
        }
    }
    createFormData(input) {
        return Object.keys(input || {}).reduce((formData, key) => {
            const property = input[key];
            const propertyContent = property instanceof Array ? property : [property];
            for (const formItem of propertyContent) {
                const isFileType = formItem instanceof Blob || formItem instanceof File;
                formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
            }
            return formData;
        }, new FormData());
    }
}
/**
 * @title Currency
 * @version 0.0.1-3f0f388a
 * @baseUrl https://gw.api-stg.evercash.eu/currency
 *
 * API for getting currencies, tokens, blockchains and settings.
 */
export class CurrencyApi extends HttpClient {
    constructor() {
        super(...arguments);
        this.admin = {
            /**
             * @description Update token, or create new one if not exist. Must past complete token object. All fields will be overridden.
             *
             * @tags Admin
             * @name AdminUpdateToken
             * @summary Update token
             * @request PUT:/admin/token/{tokenId}
             * @secure
             */
            adminUpdateToken: (tokenId, data, params = {}) => this.request(Object.assign({ path: `/admin/token/${tokenId}`, method: "PUT", body: data, secure: true, type: ContentType.Json, format: "json" }, params)),
            /**
             * No description
             *
             * @tags Admin
             * @name AdminDeleteToken
             * @summary Delete token settings
             * @request DELETE:/admin/token/{tokenId}
             * @secure
             */
            adminDeleteToken: (tokenId, params = {}) => this.request(Object.assign({ path: `/admin/token/${tokenId}`, method: "DELETE", secure: true }, params)),
            /**
             * @description Update token settings, or create new one if not exist. Must past complete token settings object. All fields will be overridden.
             *
             * @tags Admin
             * @name AdminUpdateTokenSettings
             * @summary Update token settings
             * @request PUT:/admin/token/{tokenId}/settings/{partner}/{group}
             * @secure
             */
            adminUpdateTokenSettings: (tokenId, partner, group, data, params = {}) => this.request(Object.assign({ path: `/admin/token/${tokenId}/settings/${partner}/${group}`, method: "PUT", body: data, secure: true, type: ContentType.Json, format: "json" }, params)),
            /**
             * No description
             *
             * @tags Admin
             * @name AdminDeleteTokenSettings
             * @summary Delete token settings
             * @request DELETE:/admin/token/{tokenId}/settings/{partner}/{group}
             * @secure
             */
            adminDeleteTokenSettings: (tokenId, partner, group, params = {}) => this.request(Object.assign({ path: `/admin/token/${tokenId}/settings/${partner}/${group}`, method: "DELETE", secure: true }, params)),
            /**
             * @description Update exchange pair settings, or create new one if not exist. Must past complete exchange pair settings object. All fields will be overridden.
             *
             * @tags Admin
             * @name AdminUpdateExchangePairSettings
             * @summary Update exchange pair
             * @request PUT:/admin/exchange_pair/{exchangePairId}
             * @secure
             */
            adminUpdateExchangePairSettings: (exchangePairId, data, params = {}) => this.request(Object.assign({ path: `/admin/exchange_pair/${exchangePairId}`, method: "PUT", body: data, secure: true, type: ContentType.Json, format: "json" }, params)),
            /**
             * No description
             *
             * @tags Admin
             * @name AdminDeleteTokenSettings1
             * @summary Delete exchange pair settings
             * @request DELETE:/admin/exchange_pair/{exchangePairId}
             * @secure
             */
            adminDeleteTokenSettings1: (exchangePairId, params = {}) => this.request(Object.assign({ path: `/admin/exchange_pair/${exchangePairId}`, method: "DELETE", secure: true }, params)),
            /**
             * @description Update currency, or create new one if not exist. Must past complete currency object.  All fields will be overridden.
             *
             * @tags Admin
             * @name AdminUpdateCurrency
             * @summary Update currency
             * @request PUT:/admin/currency/{slug}
             * @secure
             */
            adminUpdateCurrency: (slug, data, params = {}) => this.request(Object.assign({ path: `/admin/currency/${slug}`, method: "PUT", body: data, secure: true, type: ContentType.Json, format: "json" }, params)),
            /**
             * No description
             *
             * @tags Admin
             * @name AdminDeleteCurrency
             * @summary Delete currency
             * @request DELETE:/admin/currency/{slug}
             * @secure
             */
            adminDeleteCurrency: (slug, params = {}) => this.request(Object.assign({ path: `/admin/currency/${slug}`, method: "DELETE", secure: true }, params)),
            /**
             * @description Update currency settings, or create new one if not exist. Must past complete currency settings object. All fields will be overridden.
             *
             * @tags Admin
             * @name AdminUpdateCurrencySettings
             * @summary Update currency settings
             * @request PUT:/admin/currency/{slug}/settings/{partner}/{group}
             * @secure
             */
            adminUpdateCurrencySettings: (slug, partner, group, data, params = {}) => this.request(Object.assign({ path: `/admin/currency/${slug}/settings/${partner}/${group}`, method: "PUT", body: data, secure: true, type: ContentType.Json, format: "json" }, params)),
            /**
             * No description
             *
             * @tags Admin
             * @name AdminDeleteCurrencySettings
             * @summary Delete currency settings
             * @request DELETE:/admin/currency/{slug}/settings/{partner}/{group}
             * @secure
             */
            adminDeleteCurrencySettings: (slug, partner, group, params = {}) => this.request(Object.assign({ path: `/admin/currency/${slug}/settings/${partner}/${group}`, method: "DELETE", secure: true }, params)),
            /**
             * No description
             *
             * @tags Admin
             * @name AdminListAllToken
             * @summary List all tokens
             * @request GET:/admin/token
             * @secure
             */
            adminListAllToken: (query, params = {}) => this.request(Object.assign({ path: `/admin/token`, method: "GET", query: query, secure: true, format: "json" }, params)),
            /**
             * @description List all exchange pairs settings. Settings are partner and group related. Null currency means match all currency.
             *
             * @tags Admin
             * @name AdminGetAllExchangePairConfigs
             * @summary List all exchange pair
             * @request GET:/admin/exchange_pair
             * @secure
             */
            adminGetAllExchangePairConfigs: (query, params = {}) => this.request(Object.assign({ path: `/admin/exchange_pair`, method: "GET", query: query, secure: true, format: "json" }, params)),
            /**
             * No description
             *
             * @tags Admin
             * @name AdminListAllCurrency
             * @summary List all currencies
             * @request GET:/admin/currency
             * @secure
             */
            adminListAllCurrency: (query, params = {}) => this.request(Object.assign({ path: `/admin/currency`, method: "GET", query: query, secure: true, format: "json" }, params)),
        };
        this.token = {
            /**
             * No description
             *
             * @tags Tokens
             * @name GetAllTokens
             * @request GET:/token
             */
            getAllTokens: (query, params = {}) => this.request(Object.assign({ path: `/token`, method: "GET", query: query, format: "json" }, params)),
            /**
             * No description
             *
             * @tags Tokens
             * @name CreateToken
             * @request POST:/token
             */
            createToken: (data, params = {}) => this.request(Object.assign({ path: `/token`, method: "POST", body: data, type: ContentType.Json, format: "json" }, params)),
            /**
             * No description
             *
             * @tags Tokens
             * @name GetAllTokensShort
             * @request GET:/token/short
             */
            getAllTokensShort: (params = {}) => this.request(Object.assign({ path: `/token/short`, method: "GET", format: "json" }, params)),
        };
        this.preferred = {
            /**
             * @description Returns ordered list of currency slugs
             *
             * @tags preferences-controller
             * @name GetPreferredCurrencies
             * @summary Get preferred currencies for account
             * @request GET:/preferred/{accountId}
             * @secure
             */
            getPreferredCurrencies: (accountId, params = {}) => this.request(Object.assign({ path: `/preferred/${accountId}`, method: "GET", secure: true }, params)),
            /**
             * @description Sets new list of preferred currencies, returns ordered list of currency slugs
             *
             * @tags preferences-controller
             * @name SetPreferredCurrenciesSlug
             * @summary Sets new preferred currencies
             * @request POST:/preferred/{accountId}
             * @secure
             */
            setPreferredCurrenciesSlug: (accountId, data, params = {}) => this.request(Object.assign({ path: `/preferred/${accountId}`, method: "POST", body: data, secure: true, type: ContentType.Json }, params)),
        };
        this.blockchain = {
            /**
             * No description
             *
             * @tags Blockchains
             * @name GetAllBlockchains
             * @request GET:/blockchain
             */
            getAllBlockchains: (params = {}) => this.request(Object.assign({ path: `/blockchain`, method: "GET", format: "json" }, params)),
            /**
             * No description
             *
             * @tags Blockchains
             * @name CreateBlockchain
             * @request POST:/blockchain
             */
            createBlockchain: (data, params = {}) => this.request(Object.assign({ path: `/blockchain`, method: "POST", body: data, type: ContentType.Json, format: "json" }, params)),
        };
        this.rate = {
            /**
             * @description Get rate for exchange pair and amount. Rate is singed and available only specific time. To receive special rate pass to method account or partner code.
             *
             * @tags Rates
             * @name GetRate
             * @summary Get rate for exchange pair
             * @request GET:/rate
             */
            getRate: (query, params = {}) => this.request(Object.assign({ path: `/rate`, method: "GET", query: query, format: "json" }, params)),
        };
        this.instruments = {
            /**
             * No description
             *
             * @tags Rate instruments
             * @name GetPriceByInstrument
             * @request GET:/instruments/{instrument}
             */
            getPriceByInstrument: (instrument, params = {}) => this.request(Object.assign({ path: `/instruments/${instrument}`, method: "GET", format: "json" }, params)),
            /**
             * No description
             *
             * @tags Rate instruments
             * @name GetInstrumentList
             * @request GET:/instruments/
             */
            getInstrumentList: (params = {}) => this.request(Object.assign({ path: `/instruments/`, method: "GET", format: "json" }, params)),
        };
        this.currency = {
            /**
             * No description
             *
             * @tags Currencies
             * @name GetAllCurrencies
             * @request GET:/currency
             */
            getAllCurrencies: (params = {}) => this.request(Object.assign({ path: `/currency`, method: "GET", format: "json" }, params)),
            /**
             * No description
             *
             * @tags Currencies
             * @name GetCurrencyBySlug
             * @request GET:/currency/slug/{slug}
             */
            getCurrencyBySlug: (slug, params = {}) => this.request(Object.assign({ path: `/currency/slug/${slug}`, method: "GET", format: "json" }, params)),
            /**
             * No description
             *
             * @tags Currencies
             * @name GetAllCurrenciesShort
             * @request GET:/currency/short
             */
            getAllCurrenciesShort: (params = {}) => this.request(Object.assign({ path: `/currency/short`, method: "GET", format: "json" }, params)),
            /**
             * No description
             *
             * @tags Currencies
             * @name GetCurrencyByName
             * @request GET:/currency/name/{name}
             */
            getCurrencyByName: (name, params = {}) => this.request(Object.assign({ path: `/currency/name/${name}`, method: "GET", format: "json" }, params)),
        };
    }
}
