import { action } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { PasswordResetPageStore } from "@/pages/PasswordResetPage/stores/PasswordResetPageStore";
import { FormControlInput, Typography } from "@/shared/components";
import { AuthForm } from "@/shared/components/AuthForm";
import { Button } from "@/shared/components/Button";
import { PasswordHint } from "@/shared/components/PasswordHint";
import { useStoreContext } from "@/shared/hooks/useStore";
import { onChange } from "@/shared/utils/input";
export const NewPasswordForm = observer(() => {
    var _a;
    const page = useStoreContext(PasswordResetPageStore);
    return (React.createElement(AuthForm, { onBack: page.reset, onSubmit: page.submitPassword, title: "Reset password", desc: "Create a new password", content: React.createElement(React.Fragment, null,
            React.createElement(FormControlInput, { type: "password", label: "Password", value: page.password, onChange: onChange(action(value => {
                    page.password = value;
                })), disabled: page.loading, variantError: "alert", error: (_a = page.errors[0]) === null || _a === void 0 ? void 0 : _a.description }),
            React.createElement(FormControlInput, { type: "password", label: "Repeat password", value: page.passwordRepeat, onChange: onChange(action(value => {
                    page.passwordRepeat = value;
                })), disabled: page.loading, variantError: "alert" }),
            React.createElement(PasswordHint, { showMatch: true, match: !!page.password && page.password === page.passwordRepeat, digit: page.passwordValidation.digit, length: page.passwordValidation.length, lowercase: page.passwordValidation.lowercase, special: page.passwordValidation.special, uppercase: page.passwordValidation.uppercase })), footer: React.createElement(React.Fragment, null,
            React.createElement(Button, { width: "100%", type: "submit", disabled: page.loading || !page.isPasswordValid }, "Change password"),
            React.createElement(Typography, { type: "text16", color: "black-500", width: "100%", textAlign: "center" },
                "Something wrong? ",
                React.createElement("a", { href: "/" }, "Contact support"))) }));
});
