import { styled } from "@mui/material/styles";
import { DataGrid as DataGridMU } from "@mui/x-data-grid";
export const DataGrid = styled(DataGridMU, {
    shouldForwardProp: (prop) => !prop.startsWith("$"),
})(({ theme }) => ({
    "--unstable_DataGrid-radius": "24px",
    "--DataGrid-overlayHeight": "400px",
    borderColor: theme.colors["black-100"],
    background: theme.colors["general-white"],
    "&.Pagination": {
        borderBottomLeftRadius: "0",
        borderBottomRightRadius: "0",
    },
    "& .MuiDataGrid-withBorderColor": {
        borderColor: theme.colors["black-100"],
    },
    "& .MuiDataGrid-columnSeparator": {
        display: "none",
    },
    "& .MuiDataGrid-cell": {
        fontSize: "16px",
        padding: "0 10px",
        fontFamily: theme.fontFamily,
        position: "relative",
        "&:last-child": {
            paddingRight: "60px",
        },
        "&:first-child": {
            padding: "30px",
        },
        "&:focus, &:focus-within": {
            outline: "solid transparent 0px",
        },
    },
    "& .MuiDataGrid-row:hover, .MuiDataGrid-row.Mui-hovered": {
        borderColor: theme.colors["black-50"],
    },
    "&:not(.Selection) .MuiDataGrid-columnHeader": {
        "&:first-child": {
            paddingLeft: "60px",
        },
    },
    "& .MuiDataGrid-columnHeader": {
        "&.MuiDataGrid-columnHeader--sorted .MuiDataGrid-columnHeaderTitle": {
            color: theme.colors["black-1200"],
        },
        padding: "0 10px",
        "&:last-child": {
            paddingRight: "60px",
        },
        "& .MuiCheckbox-indeterminate": {
            color: theme.colors["deep-blue-500"],
        },
        "& .MuiDataGrid-columnHeaderTitle": {
            fontSize: "14px",
            fontWeight: "400",
            fontFamily: theme.fontFamily,
            color: theme.colors["black-400"],
        },
        "&:focus-within": {
            outline: "solid transparent 0px",
        },
    },
}));
