var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { InputAdornment } from "@mui/material";
import { styled } from "@mui/material/styles";
import { compose, spacing } from "@mui/system";
import classNames from "classnames";
import React, { memo } from "react";
import { BaseIcon } from "../BaseIcon";
import { StyledInput } from "./styled";
export const Input = React.forwardRef((_a, ref) => {
    var { maxLength, inputProps, disabled, value, onChange, size = "l", width = "100%", className, inputRef, containerRef, textAlign, type, InputProps } = _a, props = __rest(_a, ["maxLength", "inputProps", "disabled", "value", "onChange", "size", "width", "className", "inputRef", "containerRef", "textAlign", "type", "InputProps"]);
    const [showPassword, setShowPassword] = React.useState(false);
    const inputPasswordType = showPassword ? "text" : "password";
    const inputType = type === "password" ? inputPasswordType : type;
    const endAdornment = type === "password"
        ? (React.createElement(InputAdornment, { position: "end" },
            React.createElement(BaseIcon, { onClick: () => setShowPassword(p => !p), icon: showPassword ? "ClosedEye" : "Eye" })))
        : InputProps === null || InputProps === void 0 ? void 0 : InputProps.endAdornment;
    return (React.createElement(StyledInput, Object.assign({ ref: containerRef, value: value !== null && value !== void 0 ? value : "", disabled: disabled, onChange: onChange, "$size": size, "$width": width, "$textAlign": textAlign, inputRef: ref, inputProps: Object.assign({ maxLength }, inputProps), className: classNames(className, "BaseInput", {
            "BaseInput-clickable": props.onClick,
            "No-label": !props.label,
        }), type: inputType }, props, { InputProps: Object.assign(Object.assign({}, InputProps), { endAdornment }) })));
});
export const BaseInput = memo(styled(Input, {
    shouldForwardProp: (prop) => !spacing.filterProps.includes(prop),
})(compose(spacing)));
