import { useQueryClient } from "@tanstack/react-query";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { toast } from "react-toastify";
import { useGetVerificationInfo } from "@/api/registration";
import { tokenStorage } from "@/api/tokenStorage";
import { CreateRecipientStore } from "@/features/create-recipient/store/CreateRecipientStore";
import { Toast } from "@/shared/components/Alert";
import { RootInner } from "@/shared/components/Root/Inner";
import { useProvider, useStore } from "@/shared/hooks/useStore";
import { AccountListStore } from "@/shared/stores/AccountListStore";
import { AddressListStore } from "@/shared/stores/AddressListStore";
import { AppVersionStore } from "@/shared/stores/AppVersionStore";
import { AuthStore } from "@/shared/stores/AuthStore";
import { BalanceListStore } from "@/shared/stores/BalanceListStore";
import { BalanceTypeStore } from "@/shared/stores/BalanceTypeStore";
import { CurrencyListStore } from "@/shared/stores/CurrencyListStore";
import { EventsStore } from "@/shared/stores/EventsStore";
import { MenuStore } from "@/shared/stores/MenuStore";
import { MfaStore } from "@/shared/stores/MfaStore";
import { RecipientListStore } from "@/shared/stores/RecipientListStore";
import { TermsStore } from "@/shared/stores/TermsStore";
import { UserCurrencyStore } from "@/shared/stores/UserCurrencyStore";
export const Root = observer(() => {
    var _a;
    const addressList = useStore(AddressListStore);
    const events = useStore(EventsStore);
    const twoFA = useStore(MfaStore);
    const auth = useStore(AuthStore, tokenStorage, twoFA);
    const menu = useStore(MenuStore);
    const createRecipient = useStore(CreateRecipientStore);
    const userCurrency = useStore(UserCurrencyStore);
    const currencyList = useStore(CurrencyListStore);
    const balance = useStore(BalanceTypeStore);
    const AuthProvider = useProvider(AuthStore);
    const MenuProvider = useProvider(MenuStore);
    const UserCurrencyProvider = useProvider(UserCurrencyStore);
    const CurrencyListProvider = useProvider(CurrencyListStore);
    const BalanceProvider = useProvider(BalanceTypeStore);
    const accountList = useStore(AccountListStore, auth, events);
    const AccountListProvider = useProvider(AccountListStore);
    const CreateRecipientProvider = useProvider(CreateRecipientStore);
    const recipientList = useStore(RecipientListStore, auth);
    const RecipientListProvider = useProvider(RecipientListStore);
    const TwoFAProvider = useProvider(MfaStore);
    const EventsProvider = useProvider(EventsStore);
    const BalanceListProvider = useProvider(BalanceListStore);
    const AddressListProvider = useProvider(AddressListStore);
    const balanceList = useStore(BalanceListStore, accountList);
    const verification = useGetVerificationInfo(auth.authorized);
    const appVersion = useStore(AppVersionStore);
    const AppVersionProvider = useProvider(AppVersionStore);
    const TermsProvider = useProvider(TermsStore);
    const terms = useStore(TermsStore, auth);
    const queryClient = useQueryClient();
    React.useEffect(() => () => {
        var _a;
        if (!((_a = auth.userInfo) === null || _a === void 0 ? void 0 : _a.primaryEmail))
            queryClient.clear();
    }, [(_a = auth.userInfo) === null || _a === void 0 ? void 0 : _a.primaryEmail, queryClient]);
    React.useEffect(() => {
        toggleLoader(auth.initialized
            && accountList.initialized
            && (auth.authorized ? verification.isFetched : auth.initialized));
    }, [
        auth.initialized,
        accountList.initialized,
        verification.isFetched,
        auth.authorized,
    ]);
    React.useEffect(() => {
        if (appVersion.updated) {
            toast((props) => (React.createElement(Toast, Object.assign({ type: "default", text: "Our application has been updated. Please reload the page for the latest features." }, props))), {
                autoClose: false,
            });
        }
    }, [appVersion.updated]);
    return (React.createElement(AppVersionProvider, { value: appVersion },
        React.createElement(AddressListProvider, { value: addressList },
            React.createElement(BalanceListProvider, { value: balanceList },
                React.createElement(EventsProvider, { value: events },
                    React.createElement(TwoFAProvider, { value: twoFA },
                        React.createElement(CreateRecipientProvider, { value: createRecipient },
                            React.createElement(RecipientListProvider, { value: recipientList },
                                React.createElement(AccountListProvider, { value: accountList },
                                    React.createElement(BalanceProvider, { value: balance },
                                        React.createElement(CurrencyListProvider, { value: currencyList },
                                            React.createElement(UserCurrencyProvider, { value: userCurrency },
                                                React.createElement(MenuProvider, { value: menu },
                                                    React.createElement(AuthProvider, { value: auth },
                                                        React.createElement(TermsProvider, { value: terms },
                                                            React.createElement(RootInner, null))))))))))))))));
});
function toggleLoader(visible) {
    var _a;
    (_a = document.getElementById("loader")) === null || _a === void 0 ? void 0 : _a.classList.toggle("active", !visible);
}
