import * as React from "react";
import * as ReactDOM from "react-dom";
import styles from "./index.module.scss";
import { blockPage, unblockPage } from "@/shared/utils/page";
export const Overlay = ({ children, }) => {
    React.useEffect(() => {
        blockPage();
        return () => {
            unblockPage();
        };
    }, []);
    return ReactDOM.createPortal(React.createElement("div", { className: styles.overlay }, children), document.body);
};
