import { observer } from "mobx-react-lite";
import * as React from "react";
import { generatePath, Link } from "react-router-dom";
import { IndividualFormStore } from "@/pages/KYBPage/stores/IndividualFormStore";
import { IndividualListStore } from "@/pages/KYBPage/stores/IndividualListStore";
import { ROUTES } from "@/routes";
import { BaseIcon, Box, Typography } from "@/shared/components";
import { Button } from "@/shared/components/Button";
import { IndividualTypes } from "@/shared/constants/dict";
import { useProvider, useStore, useStoreContext } from "@/shared/hooks/useStore";
export const IndividualItem = observer(({ uuid, }) => {
    var _a;
    const individualList = useStoreContext(IndividualListStore);
    const individualForm = useStore(IndividualFormStore, individualList);
    const IndividualFormProvider = useProvider(IndividualFormStore);
    const data = individualList.list.find(item => item.id === uuid);
    const remove = () => individualForm.delete(uuid);
    if (!data) {
        return null;
    }
    return (React.createElement(IndividualFormProvider, { value: individualForm },
        React.createElement(Box, { bgColor: "black-50", padding: "20px 30px", display: "flex", alignItems: "center", justifyContent: "space-between", borderRadius: "22px" },
            React.createElement(Box, { display: "flex", flexDirection: "column", gap: "6px" },
                React.createElement(Typography, { type: "text14", color: "black-500" }, (_a = IndividualTypes.find(item => item.key === data.individualType)) === null || _a === void 0 ? void 0 : _a.label),
                React.createElement(Typography, { type: "text16", fontWeight: 500, display: "flex", alignItems: "center", gap: "10px" },
                    data.firstName,
                    " ",
                    data.lastName,
                    React.createElement(BaseIcon, { icon: "Cross", size: 10, containerSize: 24, bgColor: "black-300", borderRadius: 24, color: "general-white", onClick: individualForm.loading ? undefined : remove, disabled: individualForm.loading }))),
            React.createElement(Button, { width: "auto", size: "s", paddingLeft: "25px", paddingRight: "25px", LinkComponent: Link, disabled: individualForm.loading, to: generatePath(ROUTES.kybFormId, { id: 5, param: uuid }) }, "Edit"))));
});
