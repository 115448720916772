import React from "react";
import { Box, Button, Typography } from "@/shared/components";
import { Popup } from "@/shared/components/Popup";
import { PopupBody } from "@/shared/components/Popup/Body";
import { PopupContent } from "@/shared/components/Popup/Content";
import { PopupHead } from "@/shared/components/Popup/Head";
import { PopupTitle } from "@/shared/components/Popup/Title";
export const ConfirmPopup = ({ onSubmit, onClose, info, isLoading, amount, currency, to, }) => (React.createElement(Popup, { width: "450px", onClose: onClose },
    React.createElement(PopupHead, { onClose: onClose }),
    React.createElement(PopupBody, null,
        React.createElement(PopupTitle, { type: "text30Bold" },
            "You will send ",
            amount,
            " ",
            currency,
            " to",
            " ",
            React.createElement(Typography, { color: "deep-blue-500", type: "text32Bold" }, to !== null && to !== void 0 ? to : "\u200b")),
        React.createElement(PopupContent, { justifyContent: "flex-end" },
            React.createElement(Box, { display: "flex", flexDirection: "column", gap: "30px" },
                React.createElement(Box, { width: "100%", height: "1px", bgColor: "black-100" }),
                React.createElement(Box, { display: "flex", flexDirection: "column", gap: "10px" }, info.map(({ label, value }) => (React.createElement(Box, { key: value, display: "flex", alignItems: "center", justifyContent: "space-between" },
                    React.createElement(Typography, { color: "black-500" }, label),
                    React.createElement(Typography, null, value))))),
                React.createElement(Box, { width: "100%", height: "1px", bgColor: "black-100" }))),
        React.createElement(Button, { width: "100%", onClick: onSubmit, loading: isLoading, disabled: isLoading }, "Continue"))));
