var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo, useRef } from "react";
import { NumericFormat } from "react-number-format";
import { mergeRefs, resolveOnChange } from "../../lib/resolveOnChange";
import { BaseInput } from "../Input";
const ImplNumberInput = React.forwardRef((_a, ref) => {
    var { maxValue = 9999999, precision = 2, value = "", onChange, allowNegative = false, thousandSeparator = " ", mask, format, placeholder = "Enter Value", disabled, onBlur, convertValueToNumber = false } = _a, inputProps = __rest(_a, ["maxValue", "precision", "value", "onChange", "allowNegative", "thousandSeparator", "mask", "format", "placeholder", "disabled", "onBlur", "convertValueToNumber"]);
    const refInput = useRef();
    const isTel = inputProps.type === "tel";
    const withValueLimit = ({ floatValue = 0 }) => isTel || floatValue <= maxValue;
    const handleChange = (values, info) => {
        if (refInput.current) {
            resolveOnChange(refInput.current, info.event, onChange, values.value);
        }
    };
    const handleBlur = (e) => {
        onBlur === null || onBlur === void 0 ? void 0 : onBlur(e);
        if (refInput.current) {
            if (value && value !== parseFloat(`${value}`)) {
                resolveOnChange(refInput.current, e, onChange, `${parseFloat(`${value}`)}`);
            }
        }
    };
    const convertedValue = !!value && !!convertValueToNumber ? Number(value) : value;
    return (React.createElement(NumericFormat
    // TODO: Temporary fix. The "react-number-format" component should be investigated.
    // https://jira.softswiss.net/browse/AB-3282
    , Object.assign({ 
        // TODO: Temporary fix. The "react-number-format" component should be investigated.
        // https://jira.softswiss.net/browse/AB-3282
        value: value === null ? "" : convertedValue, onValueChange: handleChange, customInput: BaseInput, isAllowed: withValueLimit, thousandSeparator: thousandSeparator, decimalScale: precision, allowNegative: allowNegative, allowLeadingZeros: isTel, decimalSeparator: isTel ? undefined : ".", onBlur: handleBlur, getInputRef: mergeRefs(ref, refInput), placeholder: placeholder, disabled: disabled }, inputProps)));
});
export const NumberInput = memo(ImplNumberInput);
