import { formattedTokenAmount } from "@broxus/js-utils";
import { styled } from "@mui/material";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { generatePath, Link, useParams } from "react-router-dom";
import { DepositType, ExchangeType, ROUTES, TransferType } from "@/routes";
import { BaseIcon, Box, Button, Typography } from "@/shared/components";
import { Amount } from "@/shared/components/Amount";
import { Loader } from "@/shared/components/Loader";
import { QRCode } from "@/shared/components/QRCode";
import { SquareBtn } from "@/shared/components/SquareBtn";
import { TokenIcon } from "@/shared/components/TokenIcon";
import { useBack } from "@/shared/hooks/useBack";
import { useCopy } from "@/shared/hooks/useCopy";
import { useMedia } from "@/shared/hooks/useMedia";
import { useStoreContext } from "@/shared/hooks/useStore";
import { AccountListStore } from "@/shared/stores/AccountListStore";
import { AddressListStore } from "@/shared/stores/AddressListStore";
import { BalanceListStore } from "@/shared/stores/BalanceListStore";
import { CurrencyListStore } from "@/shared/stores/CurrencyListStore";
import { generateQueryPath } from "@/shared/utils/path";
import { Transactions } from "@/widgets/Transactions";
export const WalletPage = observer(() => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    const params = useParams();
    const media = useMedia();
    const back = useBack();
    const currencyList = useStoreContext(CurrencyListStore);
    const accountList = useStoreContext(AccountListStore);
    const balanceList = useStoreContext(BalanceListStore);
    const addressList = useStoreContext(AddressListStore);
    const balance = (_b = (_a = balanceList.data) === null || _a === void 0 ? void 0 : _a[params.id]) === null || _b === void 0 ? void 0 : _b.balance;
    const account = (_c = accountList.cryptoById) === null || _c === void 0 ? void 0 : _c[params.id];
    const address = account ? (_d = addressList.data[account.account]) === null || _d === void 0 ? void 0 : _d[account.currency] : undefined;
    const loaded = !!balance && !!account && !!address;
    const blockchain = (account === null || account === void 0 ? void 0 : account.blockchain)
        ? (_e = currencyList.blockchain[account.blockchain.toLowerCase()]) === null || _e === void 0 ? void 0 : _e.name
        : undefined;
    const copy = useCopy((_g = (_f = address === null || address === void 0 ? void 0 : address.first) === null || _f === void 0 ? void 0 : _f.address) !== null && _g !== void 0 ? _g : "");
    React.useEffect(() => {
        balanceList.sync(params.id);
    }, [params.id, balanceList]);
    React.useEffect(() => {
        addressList.sync(account);
    }, [account, addressList]);
    return (React.createElement(Box, { gap: "40px", display: "flex", flexDirection: "column" },
        media.desktop
            ? (React.createElement(Button, { variant: "White", onClick: back, alignSelf: "flex-start", icon: "Back" }, "Back"))
            : (React.createElement(Box, { display: "flex", alignItems: "center", gap: "20px" },
                React.createElement(BaseIcon, { icon: "Back", onClick: back, bgColor: "general-white", containerSize: 45, borderRadius: "12px" }),
                React.createElement(Box, { display: "flex", flexDirection: "column" },
                    React.createElement(Typography, { type: "text16" }, account === null || account === void 0 ? void 0 : account.currency),
                    React.createElement(Typography, { type: "text14", color: "black-400" }, blockchain !== null && blockchain !== void 0 ? blockchain : "\u200b")))),
        React.createElement(Box, { display: "flex", flexDirection: "column", gap: "23px" },
            React.createElement(Box, { display: "flex", flexDirection: "column", gap: "5px" },
                balance
                    ? (React.createElement(Typography, { type: "text36Bold" },
                        formattedTokenAmount(balance),
                        " ", account === null || account === void 0 ? void 0 :
                        account.currency))
                    : React.createElement(Loader, { containerSize: 47, size: 24 }),
                React.createElement(Typography, { type: "text16", color: "black-400", fontWeight: 500 }, balance && (account === null || account === void 0 ? void 0 : account.currency)
                    ? React.createElement(Amount, { from: account.currency, amount: balance })
                    : "\u200b")),
            media.desktop
                ? (React.createElement(Box, { display: "flex", alignItems: "center", justifyContent: "space-between" },
                    React.createElement(Box, { display: "flex", gap: "15px", alignItems: "center" },
                        React.createElement(TokenIcon, { symbol: (account === null || account === void 0 ? void 0 : account.currency) || "", size: 42 }),
                        React.createElement(Box, { display: "flex", flexDirection: "column" },
                            React.createElement(Typography, { type: "text16" }, account === null || account === void 0 ? void 0 : account.currency),
                            React.createElement(Typography, { type: "text14", color: "black-400" }, blockchain !== null && blockchain !== void 0 ? blockchain : "\u200b"))),
                    React.createElement(Box, { display: "flex", gap: "10px" },
                        React.createElement(Button, { icon: "Deposit", size: "s", LinkComponent: Link, to: generatePath(ROUTES.deposit, {
                                type: DepositType.Crypto,
                                id: params.id,
                            }) }, "Add funds"),
                        React.createElement(Button, { variant: "Gray", icon: "Transfer", size: "s", LinkComponent: Link, to: generateQueryPath(ROUTES.transfer, {
                                type: TransferType.Crypto,
                            }, {
                                from: params.id,
                            }) }, "Transfer"),
                        React.createElement(Button, { variant: "Gray", icon: "Exchange", size: "s", LinkComponent: Link, to: generateQueryPath(ROUTES.exchange, {
                                type: ExchangeType.Crypto,
                            }, {
                                from: params.id,
                            }) }, "Exchange"))))
                : (React.createElement(Box, { gap: "30px", display: "flex", alignItems: "center" },
                    React.createElement(SquareBtn, { icon: "Deposit", type: "blue", to: generatePath(ROUTES.deposit, {
                            type: DepositType.Crypto,
                            id: params.id,
                        }) }, "Add funds"),
                    React.createElement(SquareBtn, { icon: "Transfer", to: generateQueryPath(ROUTES.transfer, {
                            type: TransferType.Crypto,
                        }, {
                            from: params.id,
                        }) }, "Transfer"),
                    React.createElement(SquareBtn, { icon: "Exchange", to: generateQueryPath(ROUTES.exchange, {
                            type: ExchangeType.Crypto,
                        }, {
                            from: params.id,
                        }) }, "Exchange")))),
        React.createElement(Box, { height: "1px", bgColor: "black-200", width: "100%" }),
        React.createElement(Box, { position: "relative", bgColor: "general-white", borderRadius: "22px", padding: media.desktop ? "40px 50px" : "30px" },
            !loaded && (React.createElement(Loader, { size: 24, top: "50%", left: "50%", margin: "-12px 0 0 -12px", position: "absolute" })),
            React.createElement(InfoPanel, { loaded: loaded, display: "flex", alignItems: "center", justifyContent: "space-between", gap: media.desktop ? "40px" : "30px", flexDirection: media.desktop ? "row" : "column-reverse" },
                React.createElement(Box, { display: "flex", flexDirection: "column", gap: "30px", width: "100%" },
                    React.createElement(Box, { display: "flex", alignItems: "center", gap: "30px", flexDirection: media.desktop ? "row" : "column" },
                        React.createElement(Box, { gap: "5px", display: "flex", flexDirection: "column", width: media.desktop ? undefined : "100%" },
                            React.createElement(Typography, { type: "text12", color: "black-500" }, "Wallet address"),
                            React.createElement(Typography, { type: "text18", wordBreak: "break-word" }, (_j = (_h = address === null || address === void 0 ? void 0 : address.first) === null || _h === void 0 ? void 0 : _h.address) !== null && _j !== void 0 ? _j : "\u200b")),
                        React.createElement(Button, { size: "s", variant: "LightBlue", width: media.desktop ? undefined : "100%", onClick: copy }, "Copy")),
                    React.createElement(Box, { gap: "5px", display: "flex", alignItems: "center", alignSelf: "flex-start", borderColor: "black-100", borderStyle: "solid", borderWidth: "1px", borderRadius: "22px", padding: "20px", width: media.desktop ? undefined : "100%", boxSizing: "border-box", visibility: (account === null || account === void 0 ? void 0 : account.currency) && blockchain },
                        React.createElement(BaseIcon, { icon: "Info", size: 14, containerSize: 24, color: "deep-blue-500" }),
                        "Only send ", account === null || account === void 0 ? void 0 :
                        account.currency.toUpperCase(),
                        " (",
                        blockchain,
                        ") to this address")),
                React.createElement(Box, { flexShrink: "0", height: media.desktop ? "140px" : "200px" }, ((_k = address === null || address === void 0 ? void 0 : address.first) === null || _k === void 0 ? void 0 : _k.address) && (React.createElement(QRCode, { value: (_l = address.first) === null || _l === void 0 ? void 0 : _l.address, size: media.desktop ? 140 : 200 }))))),
        React.createElement(Box, { display: "flex", flexDirection: "column", gap: "20px", mb: "20px" },
            React.createElement(Typography, { type: "text24", fontWeight: 500 }, "Transactions"),
            React.createElement(Transactions, { accountIds: (account === null || account === void 0 ? void 0 : account.account) ? [account === null || account === void 0 ? void 0 : account.account] : [] }))));
});
const InfoPanel = styled(Box) `
    opacity: ${props => props.loaded ? 1 : 0};
    transition: opacity 0.2s ease;
    pointer-events: ${props => props.loaded ? "unset" : "none"};
`;
