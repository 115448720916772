import { observer } from "mobx-react-lite";
import * as React from "react";
import { Panel } from "@/pages/PaymentPage/components/Panel";
import { PaymentCardFormStore } from "@/pages/PaymentPage/stores/PaymentCardFormStore";
import { Box, Typography } from "@/shared/components";
import { useStoreContext } from "@/shared/hooks/useStore";
import { SubscriptionStore } from "@/shared/stores/SubscriptionStore";
import { preventDefault } from "@/shared/utils/prevent";
export const PaymentFormCardSubmit = observer(() => {
    const form = useStoreContext(PaymentCardFormStore);
    const subscription = useStoreContext(SubscriptionStore);
    return (React.createElement(Panel, { onSubmit: preventDefault(form.submit) },
        React.createElement(Box, { display: "flex", flexDirection: "column", gap: "30px" },
            React.createElement(Box, { display: "flex", justifyContent: "space-between", alignItems: "center" },
                React.createElement(Typography, { type: "text18", fontWeight: 600 }, "Total"),
                React.createElement(Box, { display: "flex", alignItems: "center", gap: "10px" },
                    subscription.oldAmount && (React.createElement(Typography, { type: "text18", fontWeight: 600, color: "black-500", textDecoration: "line-through" }, subscription.oldAmount ? `${subscription.oldAmount} ${subscription.currency}` : null)),
                    React.createElement(Typography, { type: "text18", fontWeight: 600 }, subscription.amount ? `${subscription.amount} ${subscription.currency}` : null))))));
});
