import { styled } from "@mui/material/styles";
import * as React from "react";
import { Box } from "@/shared/components/Box";
import { Autocomplete } from "@/shared/components/Select";
import { ListOptionDefault } from "@/shared/components/Select/Option";
import { TokenIcon } from "@/shared/components/TokenIcon";
import { Typography } from "@/shared/components/Typography";
export const TokenSearch = ({ tokens = [], value, tokenBtns, label, disabled, onChange, }) => {
    var _a;
    const options = tokens.map(item => ({
        key: item.id,
        id: item.id,
        label: item.symbol.toUpperCase(),
        symbol: item.symbol,
        iconUrl: item.iconUrl,
        name: item.name,
    }));
    return (React.createElement(Box, { display: "flex", flexDirection: "column", gap: "10px" },
        React.createElement(Autocomplete
        // TODO: Add search icons
        // TODO: Add selected token icon
        , { 
            // TODO: Add search icons
            // TODO: Add selected token icon
            fullWidth: true, disabled: disabled, label: label !== null && label !== void 0 ? label : "Search coin", options: options, value: value ? (_a = options.find(item => item.id === value)) !== null && _a !== void 0 ? _a : null : null, onChange: (_, option) => {
                onChange(option === null || option === void 0 ? void 0 : option.id);
            }, renderOption: (p, option, state) => (React.createElement(ListOptionDefault, Object.assign({}, state, p, { key: option.id }),
                React.createElement(Box, { display: "flex", alignItems: "center", gap: "10px" },
                    React.createElement(TokenIcon, { symbol: option.symbol, iconUrl: option.iconUrl }),
                    React.createElement(Typography, { type: "text16" }, option.symbol),
                    option.name && (React.createElement(Typography, { type: "text16", color: "black-500" }, option.name))))) }),
        tokenBtns && tokenBtns.length > 0 && (React.createElement(Box, { display: "flex", gap: "10px" }, tokenBtns.map(item => (React.createElement(TokenBtn, { key: item.id, onClick: () => {
                onChange(item.id);
            } },
            React.createElement(TokenIcon, { symbol: item.symbol }),
            React.createElement(Typography, { type: "text14", fontWeight: 500 }, item.symbol))))))));
};
const TokenBtn = styled("button") `
    background: ${props => props.theme.colors["black-50"]};
    padding: 10px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
`;
