var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { loadCheckoutWebComponents } from "@checkout.com/checkout-web-components";
import { comparer, makeAutoObservable, reaction } from "mobx";
import { Reactions } from "@/shared/utils/reactions";
export class PaymentCardFormStore {
    constructor(subscription) {
        this.subscription = subscription;
        this.success = false;
        this.loader = 0;
        this.errors = [];
        this.reactons = new Reactions();
        makeAutoObservable(this, {}, { autoBind: true });
    }
    init() {
        this.reactons.create(reaction(() => this.subscription.session, this.createForm, {
            equals: comparer.structural,
            fireImmediately: true,
        }));
    }
    createForm() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (this.subscription.session) {
                    const paymentSession = JSON.parse(this.subscription.session);
                    const self = this;
                    const checkout = yield loadCheckoutWebComponents({
                        publicKey: "pk_gdmyvwiojnxqibz4vunkd727h4*",
                        environment: "production",
                        locale: "en-GB",
                        paymentSession,
                        onPaymentCompleted: (_component, paymentResponse) => {
                            console.log("Create Payment with PaymentId: ", paymentResponse);
                            self.success = true;
                        },
                        onError: (_component, error) => {
                            const err = JSON.parse(JSON.stringify(error));
                            console.error(err);
                            self.errors = [
                                {
                                    key: "0",
                                    description: err === null || err === void 0 ? void 0 : err.code,
                                },
                            ];
                        },
                    });
                    const flow = checkout.create("flow");
                    flow.mount("#flow-container");
                }
            }
            catch (e) {
                console.error(e);
            }
        });
    }
    // eslint-disable-next-line class-methods-use-this
    submit() { }
    get loading() {
        return this.loader > 0;
    }
}
