import { styled } from "@mui/material/styles";
import { action } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SuccessPopup } from "./Modal";
import { VerificationStatus } from "@/api/registration/types";
import { CheckoutForm } from "@/pages/PaymentPage/components/PaymentFormCard/CheckoutForm";
import { PaymentFormCardSubmit } from "@/pages/PaymentPage/components/PaymentFormCard/Submit";
import { PaymentPromo } from "@/pages/PaymentPage/components/Promo";
import { PaymentCardFormStore } from "@/pages/PaymentPage/stores/PaymentCardFormStore";
import { PaymentPromoStore } from "@/pages/PaymentPage/stores/PaymentPromoStore";
import { VerificationStore } from "@/pages/PaymentPage/stores/VerificationStore";
import { ModalType, PaymentParam, PaymentTarget, ROUTES } from "@/routes";
import { Button } from "@/shared/components";
import { useProvider, useStore, useStoreContext } from "@/shared/hooks/useStore";
import { SubscriptionStore } from "@/shared/stores/SubscriptionStore";
import { generateQueryPath } from "@/shared/utils/path";
import { FailedPopup } from "@/widgets/FailedPopup";
import { UnderReviewPopup } from "@/widgets/UnderReviewPopup";
export const PaymentFormCard = observer(() => {
    var _a, _b, _c, _d;
    const navigate = useNavigate();
    const [search] = useSearchParams();
    const target = search.get(PaymentParam.Target);
    const plan = search.get(PaymentParam.Plan);
    const verification = useStoreContext(VerificationStore);
    const subscription = useStoreContext(SubscriptionStore);
    const promo = useStore(PaymentPromoStore, subscription);
    const form = useStore(PaymentCardFormStore, subscription);
    const FormProvider = useProvider(PaymentCardFormStore);
    const PromoProvider = useProvider(PaymentPromoStore);
    const isKyb = PaymentTarget.Kyb === target;
    React.useEffect(() => {
        subscription.createPayment();
    }, [subscription]);
    return (React.createElement(PromoProvider, { value: promo },
        React.createElement(FormProvider, { value: form },
            ((_a = verification.verification) === null || _a === void 0 ? void 0 : _a.status)
                === VerificationStatus.PENDING && (React.createElement(UnderReviewPopup, { redirectLink: generateQueryPath(ROUTES.root, undefined, isKyb ? { type: ModalType.AfterKyb } : undefined) })),
            ((_b = form.errors) === null || _b === void 0 ? void 0 : _b.length) > 0
                && ((_c = verification.verification) === null || _c === void 0 ? void 0 : _c.status)
                    === VerificationStatus.INIT
                && (React.createElement(FailedPopup, { onClose: action(() => {
                        form.success = false;
                        form.errors = [];
                    }) })),
            form.success
                && ((_d = verification.verification) === null || _d === void 0 ? void 0 : _d.status)
                    === VerificationStatus.INIT
                && (React.createElement(SuccessPopup, { onClose: action(() => {
                        form.success = false;
                        navigate("/");
                    }) })),
            React.createElement(Wrapper, null,
                React.createElement(Button, { variant: "Gray", icon: "Back", width: "auto", paddingRight: "25px", marginBottom: "60px", disabled: form.success, onClick: () => navigate(generateQueryPath(ROUTES.payment, {}, {
                        [PaymentParam.Target]: target,
                        [PaymentParam.Plan]: plan,
                    }), {
                        replace: true,
                    }) }, "Back"),
                React.createElement(CheckoutForm, null)),
            React.createElement(Sidebar, null,
                React.createElement(SidebarInner, null,
                    React.createElement(PaymentPromo, null),
                    React.createElement(PaymentFormCardSubmit, null))))));
});
const Wrapper = styled("div") `
    max-width: 560px;

    ${(props) => props.theme.breakpoints.down("md")} {
        max-width: unset;
    }
`;
const Sidebar = styled("div") `
    position: fixed;
    top: 0;
    bottom: 0;
    left: max(
        calc(((100% - 1240px) / 2) + 560px + min(160px, ((100% - 1240px) / 2))),
        600px
    );
    right: 0;
    z-index: -1;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${(props) => props.theme.colors["black-1200"]};

    ${(props) => props.theme.breakpoints.down("md")} {
        position: static;
        padding: 0;
        margin: 40px 0 0;
        background: none;
    }
`;
const SidebarInner = styled("div") `
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 420px;
    width: 100%;

    ${(props) => props.theme.breakpoints.down("md")} {
        max-width: unset;
    }
`;
