import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
const HeightInput = {
    l: "60px",
    xs: "30px",
};
const HrPaddingInput = {
    l: "20px",
    xs: "5px",
};
const RadiusInput = {
    l: "12px",
    xs: "8px",
};
export const StyledInput = styled(TextField, {
    shouldForwardProp: (prop) => !prop.startsWith("$"),
})(({ theme, multiline, $size, $width, $textAlign = "left", InputProps }) => ({
    // padding: multiline ? "16px" : PaddingtInput[$size],
    // borderRadius: '8px',
    // transition: 'background 0.2s ease',
    // display: 'flex',
    // alignItems: multiline ? 'normal' : 'center',
    // gap: '8px',
    // height: !multiline ? HeightInput[$size] : 'auto',
    width: $width || "auto",
    "& .MuiInputBase-root": {
        borderRadius: RadiusInput[$size],
        paddingRight: (InputProps === null || InputProps === void 0 ? void 0 : InputProps.endAdornment) ? HrPaddingInput[$size] : undefined,
        paddingLeft: (InputProps === null || InputProps === void 0 ? void 0 : InputProps.startAdornment) ? HrPaddingInput[$size] : undefined,
    },
    "& .MuiInputBase-input": {
        textAlign: $textAlign,
        padding: `0 ${HrPaddingInput[$size]}`,
        height: !multiline ? HeightInput[$size] : "auto",
        "&.Mui-disabled": {
            cursor: "not-allowed",
        },
    },
    "&.No-label fieldset": {
        top: "0",
        legend: {
            display: "none",
        },
    },
    "& input": {
        "&:-webkit-autofill": {
            // To remove autocomplete background
            WebkitBoxShadow: `0 0 0 1000px ${theme.colors["general-white"]} inset`,
        },
    },
    // "&.Mui-error:after": {
    //     border: `2px solid ${theme.colors.errorStrong}`,
    // },
    "&.Mui-disabled,&.BaseInput-readOnly": {
        cursor: "not-allowed",
        // ".ClearIcon": {
        //     cursor: "not-allowed",
        // },
        "& input,& textarea": {
            cursor: "not-allowed",
            WebkitTextFillColor: "inherit",
        },
    },
    "&.BaseInput-clickable:not(.Mui-disabled,.BaseInput-readOnly)": {
        cursor: "pointer",
        "input, textarea": {
            cursor: "pointer",
            WebkitTextFillColor: "inherit",
        },
    },
    "input::-webkit-input-placeholder, textarea::-webkit-input-placeholder": {
        color: theme.colors["black-500"],
        opacity: 1,
    },
}));
