import { styled } from "@mui/material";
import * as React from "react";
import { BaseIcon } from "@/shared/components/BaseIcon";
import { Box } from "@/shared/components/Box";
import { Loader } from "@/shared/components/Loader";
export const DropdownInput = ({ onClick, disabled, loading, children, }) => (React.createElement(Wrapper, { disabled: disabled, onClick: onClick },
    React.createElement(Box, { display: "flex", alignItems: "center", gap: "10px" }, children),
    loading
        ? React.createElement(Loader, { size: 18, color: "black-200", opacity: 1 })
        : React.createElement(BaseIcon, { icon: "DropDown", size: 24, p: "0", color: "black-500" })));
const Wrapper = styled("button") `
    width: 100%;
    border: 1px solid ${props => props.theme.colors["black-200"]};
    border-radius: 12px;
    height: 60px;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
`;
