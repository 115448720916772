import { styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { CreateWalletPopup } from "@/features/create-wallet";
import { BaseIcon } from "@/shared/components/BaseIcon";
import { Box } from "@/shared/components/Box";
import { FormControlInput } from "@/shared/components/FormControlInput";
import { Loader } from "@/shared/components/Loader";
import { Popup } from "@/shared/components/Popup";
import { PopupHead } from "@/shared/components/Popup/Head";
import { PopupItem } from "@/shared/components/Popup/Item";
import { TokenItem } from "@/shared/components/TokenPopup/TokenItem";
import { Typography } from "@/shared/components/Typography";
import { useStoreContext } from "@/shared/hooks/useStore";
import { useToggler } from "@/shared/hooks/useToggler";
import { AccountListStore } from "@/shared/stores/AccountListStore";
import { AddressListStore } from "@/shared/stores/AddressListStore";
import { BalanceListStore } from "@/shared/stores/BalanceListStore";
export const TokenPopup = observer(({ selectedId, onSelect, onClose, }) => {
    const popup = useToggler();
    const accounts = useStoreContext(AccountListStore);
    const balanceList = useStoreContext(BalanceListStore);
    const addressList = useStoreContext(AddressListStore);
    const loaded = accounts.crypto.every(item => {
        var _a, _b;
        return (((_a = balanceList.data[item.account]) === null || _a === void 0 ? void 0 : _a.balance)
            && ((_b = addressList.data[item.account]) === null || _b === void 0 ? void 0 : _b[item.currency]));
    });
    React.useEffect(() => {
        balanceList.sync(...accounts.crypto.map(item => item.account));
    }, [accounts.crypto, balanceList]);
    React.useEffect(() => {
        addressList.sync(...accounts.crypto);
    }, [accounts.crypto, addressList]);
    return popup.enabled
        ? (React.createElement(CreateWalletPopup, { onClose: popup.off, onSuccess: popup.off }))
        : (React.createElement(Popup, { onClose: onClose },
            React.createElement(PopupHead, { onClose: onClose }, "Select crypto"),
            React.createElement(Box, { height: "1px", bgColor: "black-50" }),
            React.createElement(Box, { position: "relative" },
                !loaded && (React.createElement(Loader, { size: 24, position: "absolute", top: "50%", left: "50%", margin: "-12px 0 0 -12px", zIndex: "100" })),
                React.createElement(Wrapper, { loaded: loaded },
                    React.createElement(Box, { padding: "20px 25px" },
                        React.createElement(FormControlInput
                        // TODO: Search impl
                        , { 
                            // TODO: Search impl
                            placeholder: "Search", InputProps: {
                                startAdornment: (React.createElement(BaseIcon, { icon: "Search", color: "black-400" })),
                            } })),
                    React.createElement(Box, { overflow: "hidden", borderRadius: "0 0 22px 22px" },
                        React.createElement(List, { loaded: loaded },
                            React.createElement(PopupItem, { onClick: popup.on },
                                React.createElement(BaseIcon, { icon: "Plus", size: 14, containerSize: 36, borderRadius: "36px", bgColor: "light-blue", color: "deep-blue-500" }),
                                React.createElement(Typography, { type: "text14", color: "deep-blue-500" }, "Create new wallet")),
                            accounts.crypto.map(item => {
                                var _a, _b, _c, _d;
                                const address = (_a = addressList.data[item.account]) === null || _a === void 0 ? void 0 : _a[item.currency];
                                return (React.createElement(TokenItem, { address: (_b = address === null || address === void 0 ? void 0 : address.first) === null || _b === void 0 ? void 0 : _b.address, key: item.account, id: item.account, selected: item.account === selectedId, symbol: item.currency, amount: (_d = (_c = balanceList.data) === null || _c === void 0 ? void 0 : _c[item.account]) === null || _d === void 0 ? void 0 : _d.balance, currency: item.currency, onSelect: () => {
                                        onSelect(item.account);
                                    } }));
                            })))))));
});
const Wrapper = styled("div") `
    position: relative;
    opacity: ${props => props.loaded ? 1 : 0};
    transition: opacity 0.2s ease;
    pointer-events: ${props => props.loaded ? "unset" : "none"};
`;
const List = styled("div") `
    overflow: auto;
    max-height: 505px;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
`;
