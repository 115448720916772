import { styled } from "@mui/material";
import * as React from "react";
import { Box, Typography } from "@/shared/components";
export const ExchangeField = ({ input, value, label, select, error, }) => (React.createElement(Box, { display: "grid", gridTemplateColumns: "minmax(0,1fr) minmax(0,max-content)", alignItems: "center", bgColor: "black-50", borderRadius: "22px", padding: "12px 12px 12px 30px", gap: "20px" },
    React.createElement(InputWrapper, { withError: !!error },
        React.createElement(Typography, { type: "text14", color: "black-500" }, label),
        React.createElement(Box, null, input !== null && input !== void 0 ? input : (React.createElement(Typography, { type: "text18", fontWeight: 500 }, value || "\u200B"))),
        React.createElement(ErrorMsg, { "$visible": !!error, type: "text12", color: "general-red", fontWeight: 500, position: "absolute", top: "100%", marginTop: "3px" }, error)),
    React.createElement(Box, null, select)));
const InputWrapper = styled("div") `
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 5px;
    flex-grow: 1;
    transition: transform 0.2s ease;
    transform: ${props => props.withError ? "translateY(-10px)" : "none"};
`;
const ErrorMsg = styled(Typography) `
    opacity: ${props => props.$visible ? 1 : 0};
    transition: opacity 0.2s ease;
`;
