import BN from "bignumber.js";
import React from "react";
import { BaseIcon, Box, Button, Typography } from "@/shared/components";
export const SubscriptionCard = ({ options, title, fee, monthly, discount, onSelect, theme = "white", disabled, }) => (React.createElement(Box, { padding: "30px", display: "flex", flexDirection: "column", gap: "90px", bgColor: theme === "black" ? "black-1200" : "general-white", borderRadius: "22px" },
    React.createElement(Box, { display: "flex", flexDirection: "column", gap: "30px" },
        React.createElement(Box, { display: "flex", flexDirection: "column" },
            React.createElement(Typography, { type: "text24Bold", color: theme === "black" ? "general-white" : "black-1200" }, title)),
        React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "center" },
            React.createElement(Box, { display: "flex", gap: 2, alignItems: "center" },
                React.createElement(Typography, { type: "text36Bold", textDecoration: discount ? "line-through" : "unset", fontWeight: 500, color: discount
                        ? "black-500"
                        : theme === "black"
                            ? "general-white"
                            : "black-1200" },
                    "$",
                    fee),
                discount && (React.createElement(Typography, { type: "text36Bold", color: theme === "black"
                        ? "general-white"
                        : "black-1200" },
                    "$",
                    new BN(fee)
                        .times(new BN(100 - discount).div(100))
                        .toFixed(0)))),
            discount && (React.createElement(Typography, { color: "general-green" },
                discount,
                "% referral discount")),
            React.createElement(Typography, { color: theme === "black" ? "general-white" : "black-1200" }, "one-time setup fee")),
        React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "center" },
            React.createElement(Typography, { color: theme === "black" ? "general-white" : "black-1200" }, "monthly subscription"),
            React.createElement(Box, { display: "flex", gap: 2, alignItems: "center" },
                React.createElement(Typography, { type: "text36Bold", color: theme === "black" ? "general-white" : "black-1200" },
                    "$",
                    monthly)),
            React.createElement(Typography, { color: "general-green" }, "First month FREE"))),
    React.createElement(Box, { display: "flex", flexDirection: "column", gap: "30px" },
        React.createElement(Button, { disabled: disabled || !onSelect, size: "s", variant: theme === "black" ? "White" : "Black", onClick: onSelect }, "Select plan"),
        React.createElement(Box, { display: "flex", flexDirection: "column", gap: "5px" }, options.map((option, index) => (React.createElement(Box, { display: "flex", alignItems: "center", gap: "6px", 
            // eslint-disable-next-line react/no-array-index-key
            key: index },
            React.createElement(BaseIcon, { icon: "Check", color: "black-500", size: 8 }),
            React.createElement(Typography, { color: "black-500", type: "text14" }, option))))))));
