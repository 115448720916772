import { observer } from "mobx-react-lite";
import * as React from "react";
import { generatePath, Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { BackBtn } from "@/pages/KYBPage/components/BackBtn";
import { ExitPopup } from "@/pages/KYBPage/components/ExitPopup";
import { PaymentFormPage } from "@/pages/PaymentPage/PaymentFormPage";
import { PaymentTypePage } from "@/pages/PaymentPage/PaymentTypePage";
import { VerificationStore } from "@/pages/PaymentPage/stores/VerificationStore";
import { parseTarget, parseType } from "@/pages/PaymentPage/utils";
import { PaymentParam, PaymentTarget, ROUTES } from "@/routes";
import { BaseIcon, Box } from "@/shared/components";
import { useBack } from "@/shared/hooks/useBack";
import { useMedia } from "@/shared/hooks/useMedia";
import { useProvider, useStore } from "@/shared/hooks/useStore";
import { useToggler } from "@/shared/hooks/useToggler";
import { CompanyInfoStore } from "@/shared/stores/CompanyInfoStore";
import { SubscriptionStore } from "@/shared/stores/SubscriptionStore";
export const PaymentPage = observer(() => {
    const media = useMedia();
    const navigate = useNavigate();
    const exitPopup = useToggler();
    const back = useBack();
    const [search] = useSearchParams();
    const target = parseTarget(search.get(PaymentParam.Target));
    const type = parseType(search.get(PaymentParam.Type));
    const verification = useStore(VerificationStore);
    const companyInfo = useStore(CompanyInfoStore);
    const CompanyInfoProvider = useProvider(CompanyInfoStore);
    const VerificationProvider = useProvider(VerificationStore);
    const subscription = useStore(SubscriptionStore);
    const SubscriptionsProvider = useProvider(SubscriptionStore);
    if (!target) {
        return React.createElement(Navigate, { replace: true, to: generatePath(ROUTES.root) });
    }
    return (React.createElement(SubscriptionsProvider, { value: subscription },
        React.createElement(VerificationProvider, { value: verification },
            React.createElement(CompanyInfoProvider, { value: companyInfo },
                exitPopup.enabled && (React.createElement(ExitPopup, { onClose: exitPopup.off, onBack: () => navigate(generatePath(ROUTES.root)) })),
                React.createElement(Box, { minHeight: "100vh", bgColor: "general-white", position: "relative" },
                    React.createElement(Box, { paddingLeft: "max(20px, calc((100% - 1240px) / 2))", paddingRight: "max(20px, calc((100% - 1240px) / 2))", paddingTop: media.desktop ? "50px" : "20px", paddingBottom: media.desktop ? "50px" : "20px", margin: "0 auto" },
                        React.createElement(Box, { position: "relative", zIndex: "0" },
                            target === PaymentTarget.Kyb ? React.createElement(BackBtn, { onClick: exitPopup.on }) : (React.createElement(BaseIcon, { icon: "Close", size: 45, bgColor: "black-50", borderRadius: "12px", position: "absolute", top: "0", right: "0", onClick: back })),
                            !type ? React.createElement(PaymentTypePage, null) : React.createElement(PaymentFormPage, null))))))));
});
