import * as React from "react";
import { generatePath, Link } from "react-router-dom";
import { DepositType, ROUTES } from "@/routes";
import { Box, Button, Typography } from "@/shared/components";
import { useMedia } from "@/shared/hooks/useMedia";
export const Header = ({ active, }) => {
    const media = useMedia();
    return (React.createElement(Box, { marginBottom: media.desktop ? "60px" : "30px" },
        React.createElement(Typography, { variant: "h1", type: "text32Bold", margin: "0 0 20px" }, "Add funds"),
        React.createElement(Box, { display: media.desktop ? "flex" : "grid", alignItems: "center", gap: "10px", gridAutoFlow: "column", gridAutoColumns: "minmax(0,1fr)" },
            React.createElement(Button, { replace: true, size: "s", minWidth: "100px", variant: active === DepositType.Fiat ? "Default" : "LightBlue", LinkComponent: active !== DepositType.Fiat ? Link : undefined, to: generatePath(ROUTES.deposit, {
                    type: DepositType.Fiat,
                }) }, "Fiat"),
            React.createElement(Button, { replace: true, size: "s", minWidth: "100px", variant: active === DepositType.Crypto ? "Default" : "LightBlue", LinkComponent: active !== DepositType.Crypto ? Link : undefined, to: generatePath(ROUTES.deposit, {
                    type: DepositType.Crypto,
                }) }, "Crypto"))));
};
