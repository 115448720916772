import { jsPDF } from "jspdf";
export const saveToPDF = (text, filename) => {
    // eslint-disable-next-line new-cap
    const doc = new jsPDF();
    doc.text(text, 10, 10);
    doc.save(filename);
};
export const dataToText = (data, maxWidth) => {
    let text = "";
    const r = maxWidth ? `.{1,${maxWidth}}` : undefined;
    const re = r ? new RegExp(r, "g") : undefined;
    data.forEach(item => {
        item.forEach(value => {
            var _a, _b;
            text += re ? (_b = (_a = value.match(re)) === null || _a === void 0 ? void 0 : _a.join("\n")) !== null && _b !== void 0 ? _b : "" : value;
            text += "\n";
        });
        text += "\n";
    });
    return text;
};
