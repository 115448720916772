import { useMediaQuery } from "@mui/material";
import React from "react";
export const useMedia = () => {
    const desktop = useMediaQuery("(min-width: 1024px)");
    const tablet = useMediaQuery("(max-width: 1023px)");
    const phone = useMediaQuery("(max-width: 767px)");
    return React.useMemo(() => ({
        desktop,
        tablet,
        phone,
    }), [tablet, desktop, phone]);
};
