var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { makeObservable, observable, runInAction } from "mobx";
import { getHash } from "@/shared/utils/get-hash";
export class AppVersionStore {
    constructor() {
        this.updated = false;
        this.interval = undefined;
        makeObservable(this, {
            updated: observable,
        });
    }
    init() {
        if (process.env.PRODUCTION) {
            this.interval = setInterval(this.checkVersion.bind(this), 60000);
        }
    }
    dispose() {
        clearInterval(this.interval);
    }
    checkVersion() {
        return __awaiter(this, void 0, void 0, function* () {
            const version = yield fetch("/").then(r => r.text()).then(getHash);
            if (this.version && this.version !== version) {
                runInAction(() => {
                    this.updated = true;
                });
            }
            this.version = version;
        });
    }
}
