import { useMemo } from "react";
export function useCsv(headers, data) {
    return useMemo(() => {
        let result = `${headers.map((item) => `"${item}"`).join(";")}\n`;
        data.forEach((items) => {
            for (let i = 0; i < items.length; i += 1) {
                result += i !== items.length - 1 ? `${items[i]};` : `${items[i]};\n`;
            }
        });
        return result;
    }, [headers, data]);
}
