import { styled } from "@mui/material";
import * as React from "react";
import btcSvg from "@/shared/img/tokens/btc.svg";
import ethSvg from "@/shared/img/tokens/eth.svg";
import usdcSvg from "@/shared/img/tokens/usdc.svg";
import usdtSvg from "@/shared/img/tokens/usdt.svg";
export const TokenIcon = ({ symbol, size = 20, iconUrl, }) => {
    const url = iconUrl !== null && iconUrl !== void 0 ? iconUrl : (() => {
        switch (symbol === null || symbol === void 0 ? void 0 : symbol.toLowerCase()) {
            case "eth":
                return ethSvg;
            case "btc":
                return btcSvg;
            case "usdt":
                return usdtSvg;
            case "usdc":
                return usdcSvg;
            default:
                return null;
        }
    })();
    return (React.createElement(Img, { size: size, url: url }));
};
const Img = styled("div") `
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    border-radius: 50%;
    background-image: ${props => props.url ? `url(${props.url})` : undefined};
    background-size: cover;
    background-color: ${props => props.theme.colors["black-100"]};
    background-repeat: no-repeat;
`;
