import { styled } from "@mui/material/styles";
import * as React from "react";
import { useDropzone } from "react-dropzone";
import { BaseIcon } from "@/shared/components/BaseIcon";
import { Box } from "@/shared/components/Box";
import { Loader } from "@/shared/components/Loader";
import { Typography } from "@/shared/components/Typography";
import { useMedia } from "@/shared/hooks/useMedia";
import { humanFileSize } from "@/shared/utils/file-size";
import { stopPropagation } from "@/shared/utils/stop-propagation";
export const Upload = ({ disabled, maxFileSize, minFileSize, maxFiles, multiple, label, fileName, loading, error, size, success, onFiles, onRemove, onDownload, }) => {
    const media = useMedia();
    const [localErrors, setLocalErrors] = React.useState([]);
    const errors = error ? [...localErrors, error] : localErrors;
    const { getRootProps, getInputProps } = useDropzone({
        disabled: disabled || loading,
        maxSize: maxFileSize,
        minSize: minFileSize,
        maxFiles,
        multiple,
        onDropAccepted: (e) => {
            setLocalErrors([]);
            onFiles === null || onFiles === void 0 ? void 0 : onFiles(e);
        },
        onDropRejected: (e) => {
            setLocalErrors(e.flatMap(item => item.errors.map(err => `${item.file.name} — ${err.message}`)));
        },
    });
    return (React.createElement(Box, { display: "flex", flexDirection: "column", gap: "20px" },
        React.createElement(Box, { bgColor: errors.length
                ? "general-red-transparent-10"
                : success
                    ? "general-green-transparent-5"
                    : "deep-blue-500-transparent-2", borderRadius: "16px", borderColor: errors.length
                ? "general-red"
                : success
                    ? "general-green"
                    : "deep-blue-500", borderWidth: "1px", borderStyle: "dashed" },
            React.createElement(Inner, Object.assign({ onClick: onDownload }, (onDownload ? undefined : getRootProps()), { disabled: !!(disabled || loading) }),
                React.createElement("input", Object.assign({ type: "file" }, getInputProps(), { disabled: disabled })),
                React.createElement(Box, { padding: "28px 30px", display: "flex", justifyContent: "space-between", alignItems: media.phone ? "flex-start" : "center", flexDirection: media.phone ? "column" : undefined, gap: media.phone ? "15px" : undefined },
                    React.createElement(Typography, { type: "text16", color: errors.length
                            ? "general-red"
                            : success
                                ? "general-green"
                                : "black-500" }, fileName
                        ? (React.createElement(Box, { display: "flex", alignItems: "center", gap: "10px" },
                            React.createElement(BaseIcon, { icon: "Doc", size: 24, color: errors.length > 0
                                    ? "general-red"
                                    : success
                                        ? "general-green"
                                        : "deep-blue-500" }),
                            fileName))
                        : label),
                    loading
                        ? React.createElement(Loader, { size: 24, color: (errors === null || errors === void 0 ? void 0 : errors.length) > 0 ? "general-red" : "deep-blue-500" })
                        : (React.createElement(Box, { display: "flex", alignItems: "center", gap: "10px" },
                            fileName && onRemove && (React.createElement(BaseIcon, { icon: "Cross", color: "general-white", containerSize: 24, size: 10, bgColor: "black-300", borderRadius: "100%", onClick: stopPropagation(onRemove) })),
                            size
                                ? (React.createElement(Typography, { type: "text14", color: success ? "general-green" : "black-500", fontWeight: 500, borderRadius: "24px", height: "24px", padding: "0 10px", bgColor: "general-white" }, humanFileSize(size)))
                                : (React.createElement(Typography, { type: "text14", color: "deep-blue-500" },
                                    "Drag & drop files or",
                                    " ",
                                    React.createElement(Typography, { type: "text14", color: "deep-blue-500", textDecoration: "underline" }, "Browse")))))))),
        errors.length > 0 && (React.createElement(Box, { display: "flex", flexDirection: "column", gap: "10px" }, errors.map(item => (React.createElement(Typography, { type: "text14", fontWeight: 450, color: "general-red", key: item }, item)))))));
};
const Inner = styled("div")(({ disabled }) => ({
    cursor: disabled ? "not-allowed" : "pointer",
}));
