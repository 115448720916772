import { styled } from "@mui/material/styles";
import { action, runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { WalletAddress } from "@/pages/PaymentPage/components/PaymentFormCrypto/WalletAddress";
import { PaymentCryptoFormStore, wallets } from "@/pages/PaymentPage/stores/PaymentCryptoFormStore";
import { Box, Typography } from "@/shared/components";
import { FormControlSelect } from "@/shared/components/FormControlSelect";
import { Autocomplete } from "@/shared/components/Select";
import { ListOptionDefault } from "@/shared/components/Select/Option";
import { TokenIcon } from "@/shared/components/TokenIcon";
import { useStoreContext } from "@/shared/hooks/useStore";
const tokens = ["BTC", "ETH", "USDT", "USDC"];
export const TokenForm = observer(() => {
    var _a, _b, _c;
    const form = useStoreContext(PaymentCryptoFormStore);
    return (React.createElement(Box, { display: "flex", flexDirection: "column", gap: "40px" },
        React.createElement(Typography, { type: "text32Bold" }, "Pay with crypto"),
        React.createElement(Box, { display: "flex", flexDirection: "column", gap: "10px" },
            React.createElement(Autocomplete, { fullWidth: true, disabled: form.success, label: "Search coin", options: wallets, value: (_a = wallets.find(item => item.key === form.walletKey)) !== null && _a !== void 0 ? _a : null, onChange: (_, option) => {
                    runInAction(() => {
                        form.walletKey = option === null || option === void 0 ? void 0 : option.key;
                    });
                }, renderOption: (p, option, state) => (React.createElement(ListOptionDefault, Object.assign({}, state, p, { key: option.key }),
                    React.createElement(Box, { display: "flex", alignItems: "center", gap: "10px" },
                        React.createElement(TokenIcon, { symbol: option.symbol }),
                        React.createElement(Typography, { type: "text16" }, option.symbol),
                        React.createElement(Typography, { type: "text16", color: "black-500" }, option.name)))) }),
            React.createElement(Box, { display: "flex", gap: "10px" }, tokens.map(item => {
                var _a;
                const key = (_a = wallets.find(wallet => wallet.symbol.toLowerCase() === item.toLowerCase())) === null || _a === void 0 ? void 0 : _a.key;
                return key
                    ? (React.createElement(TokenBtn, { key: item, disabled: form.success || form.loading, onClick: action(() => {
                            form.walletKey = key;
                        }) },
                        React.createElement(TokenIcon, { symbol: item }),
                        React.createElement(Typography, { type: "text14", fontWeight: 500 }, item)))
                    : null;
            }))),
        React.createElement(FormControlSelect, { label: "Select network", disabled: !form.walletKey || form.success || form.loading, options: (_c = (_b = wallets.find(item => item.key === form.walletKey)) === null || _b === void 0 ? void 0 : _b.network) !== null && _c !== void 0 ? _c : [], value: form.networkKey, onChange: (_, option) => {
                runInAction(() => {
                    form.networkKey = option === null || option === void 0 ? void 0 : option.key;
                });
            } }),
        form.walletAddress && React.createElement(WalletAddress, { address: form.walletAddress })));
});
const TokenBtn = styled("button") `
    background: ${props => props.theme.colors["black-50"]};
    padding: 10px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
`;
