var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import BigNumber from "bignumber.js";
import { makeAutoObservable, reaction, runInAction } from "mobx";
import { currencyApi } from "@/api/currency/api";
import { walletApi } from "@/api/wallet/api";
import { DataSync } from "@/shared/stores/DataStore";
import { parseApiError } from "@/shared/utils/error";
import { Reactions } from "@/shared/utils/reactions";
export class TransferRecipientFormStore {
    constructor(balanceList, accountList, recipientList, addressList) {
        this.balanceList = balanceList;
        this.accountList = accountList;
        this.recipientList = recipientList;
        this.addressList = addressList;
        this.submitErrors = [];
        this.loading = false;
        this.from = undefined;
        this.to = undefined;
        this.amount = undefined;
        this.message = undefined;
        this.confirmation = false;
        this.result = undefined;
        this.paymentPurpose = undefined;
        this.memo = undefined;
        this.feeData = new DataSync(walletApi.withdraw.createWithdraw.bind(walletApi));
        this.settings = new DataSync(currencyApi.currency.getCurrencyBySlug.bind(currencyApi), d => d.data.settings);
        this.reactions = new Reactions();
        makeAutoObservable(this, {}, { autoBind: true });
    }
    init() {
        this.reactions.create(reaction(() => { var _a; return [(_a = this.fromAcc) === null || _a === void 0 ? void 0 : _a.currency, this.amount, this.toRecipient, this.memo, this.paymentPurpose]; }, () => {
            if (this.fromAcc && this.amount && this.toRecipient && this.paymentPurpose) {
                this.feeData.sync({
                    amount: this.amount,
                    currency: this.fromAcc.currency,
                    type: "COUNTERPARTY",
                    counterPartyId: this.toRecipient.data.id,
                    fromAccount: this.fromAcc.account,
                    meta: {
                        memo: this.memo,
                        paymentPurpose: this.paymentPurpose,
                    },
                }, {
                    autoConversion: true,
                    dryRun: true,
                });
            }
            else {
                this.feeData.reset();
            }
        }, {
            fireImmediately: true,
        }), reaction(() => this.from, () => {
            this.balanceList.sync(this.from);
        }, {
            fireImmediately: true,
        }), reaction(() => this.fromAcc, () => {
            this.addressList.sync(this.fromAcc);
        }, {
            fireImmediately: true,
        }));
    }
    dispose() {
        this.reactions.destroy();
    }
    reset() {
        this.result = undefined;
        this.submitErrors = [];
        this.loading = false;
        this.amount = undefined;
        this.message = undefined;
        this.confirmation = false;
        this.feeData.reset();
        this.settings.reset();
    }
    showConfirmation() {
        this.confirmation = true;
        this.submitErrors = [];
    }
    hideConfirmation() {
        this.confirmation = false;
    }
    hideErrors() {
        this.submitErrors = [];
    }
    submit() {
        return __awaiter(this, void 0, void 0, function* () {
            let errors = [];
            let result;
            runInAction(() => {
                this.loading = true;
            });
            try {
                if (!this.feeData.value) {
                    throw new Error("feeData.value must be defined");
                }
                const resp = yield walletApi.withdraw.createWithdraw(this.feeData.value.data, {
                    autoConversion: true,
                    dryRun: false,
                });
                result = resp.data;
                yield this.balanceList.sync();
            }
            catch (e) {
                errors = parseApiError(e);
                console.error(e);
            }
            runInAction(() => {
                this.loading = false;
                this.submitErrors = errors;
                this.result = result;
                this.confirmation = false;
            });
        });
    }
    get errors() {
        return [
            ...this.submitErrors,
            ...(this.feeData.error ? parseApiError(this.feeData.error) : []),
        ];
    }
    get fromAcc() {
        return this.from ? this.accountList.fiatById[this.from] : undefined;
    }
    get toRecipient() {
        var _a;
        return this.to ? (_a = this.recipientList.list) === null || _a === void 0 ? void 0 : _a.find(item => item.data.id === this.to) : undefined;
    }
    get fromAccAddress() {
        var _a;
        return this.fromAcc ? (_a = this.addressList.data[this.fromAcc.account]) === null || _a === void 0 ? void 0 : _a[this.fromAcc.currency] : undefined;
    }
    get balance() {
        return this.fromAcc ? this.balanceList.data[this.fromAcc.account] : undefined;
    }
    get enough() {
        if (this.balance) {
            if (this.total) {
                return new BigNumber(this.total).lte(this.balance.balance);
            }
            if (this.amount) {
                return new BigNumber(this.amount).lte(this.balance.balance);
            }
        }
        return undefined;
    }
    get valid() {
        return this.enough
            && this.amount
            && new BigNumber(this.amount).gt(0)
            && !new BigNumber(this.amount).isNaN()
            && !!this.to
            && !!this.paymentPurpose;
    }
    get fee() {
        var _a;
        return (_a = this.feeData.value) === null || _a === void 0 ? void 0 : _a.data.feeAmount;
    }
    get total() {
        var _a;
        return ((_a = this.feeData.value) === null || _a === void 0 ? void 0 : _a.data.feeAmount)
            ? new BigNumber(this.feeData.value.data.amount)
                .plus(this.feeData.value.data.feeAmount)
                .toFixed()
            : undefined;
    }
    get ready() {
        return this.feeData.value
            && !this.feeData.loading
            && this.enough
            && this.valid;
    }
}
