var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { RecipientItem } from "../RecipientItem";
import { ROUTES, TransferType } from "@/routes";
import { Box, Button, Typography } from "@/shared/components";
import { Loader } from "@/shared/components/Loader";
import { Popup } from "@/shared/components/Popup";
import { PopupCloseBtn } from "@/shared/components/Popup/CloseBtn";
import { useMedia } from "@/shared/hooks/useMedia";
import { useStoreContext } from "@/shared/hooks/useStore";
import { CurrencyListStore } from "@/shared/stores/CurrencyListStore";
import { RecipientListStore } from "@/shared/stores/RecipientListStore";
import { generateQueryPath } from "@/shared/utils/path";
import { sliceAddress } from "@/shared/utils/slice-address";
export const RecipientList = observer(() => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    const media = useMedia();
    const currencyList = useStoreContext(CurrencyListStore);
    const recipientList = useStoreContext(RecipientListStore);
    const [open, setIsOpen] = useState(false);
    const [detailsId, setDetailsId] = useState("");
    const handleShowDetails = (id) => {
        setDetailsId(id);
        setIsOpen(true);
    };
    const selectedItem = (_a = recipientList.list) === null || _a === void 0 ? void 0 : _a.find((item) => item.data.id === detailsId);
    if (!((_b = recipientList.list) === null || _b === void 0 ? void 0 : _b.length)) {
        return (React.createElement(Box, { height: "320px", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }, recipientList.loading ? React.createElement(Loader, { size: 24 }) : (React.createElement(Typography, { type: "text14", color: "black-400" }, "Soon it will be very crowdy here ;)"))));
    }
    const deleteRec = () => __awaiter(void 0, void 0, void 0, function* () {
        var _m, _o;
        if (!((_m = selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.data) === null || _m === void 0 ? void 0 : _m.id))
            return;
        yield recipientList.deleteParty((_o = selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.data) === null || _o === void 0 ? void 0 : _o.id);
        setIsOpen(false);
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { display: "grid", gridTemplateColumns: media.phone ? "1fr" : "1fr 1fr", gap: "20px" }, (_c = recipientList.list) === null || _c === void 0 ? void 0 : _c.map((item) => (React.createElement(RecipientItem, { key: item.data.id, id: item.data.id, name: item.data.profile.name, status: "status" in item.data ? item.data.status : "", account: item.accountNumber, onClick: handleShowDetails })))),
        open && (React.createElement(Popup, { width: "450px", onClose: () => setIsOpen(false) },
            React.createElement(PopupCloseBtn, { position: "absolute", top: "20px", right: "20px", onClick: () => setIsOpen(false) }),
            React.createElement(Box, { borderRadius: "22px 22px 0 0", padding: "100px 50px 30px", bgColor: "black-50", display: "flex", flexDirection: "column", gap: "34px", width: "100%", boxSizing: "border-box" },
                React.createElement(Box, { display: "flex", flexDirection: "column", gap: "30px", alignItems: "center" },
                    React.createElement(Box, { display: "flex", flexDirection: "column", gap: "12px" },
                        React.createElement(Typography, { type: "text24", textAlign: "center" }, (_d = selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.accountNumber) !== null && _d !== void 0 ? _d : ((selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.blockchainAddress)
                            ? sliceAddress(selectedItem.blockchainAddress)
                            : "\u200b")),
                        React.createElement(Typography, { textAlign: "center" }, selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.data.profile.name)),
                    React.createElement(Box, { display: "flex", gap: 4 },
                        React.createElement(Button, { variant: "Bordered", onClick: deleteRec }, "Delete"),
                        React.createElement(Button, { component: Link, to: generateQueryPath(ROUTES.transfer, {
                                type: (selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.isCrypto)
                                    ? TransferType.Crypto
                                    : TransferType.Recipient,
                            }, {
                                to: detailsId,
                            }) }, "Transfer funds")))),
            React.createElement(Box, { width: "100%", padding: "0 30px 10px", boxSizing: "border-box", height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" },
                React.createElement(Box, { width: "100%" },
                    (selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.isCrypto)
                        ? (React.createElement(React.Fragment, null,
                            React.createElement(Box, { display: "flex", flexDirection: "column", padding: "15px 0" },
                                React.createElement(Typography, { type: "text14", color: "black-400" }, "Blockchain"),
                                React.createElement(Typography, null, selectedItem.blockchain
                                    ? (_e = currencyList.blockchain[selectedItem.blockchain]) === null || _e === void 0 ? void 0 : _e.name
                                    : "\u200b")),
                            React.createElement(Box, { bgColor: "black-50", height: "1px", m: "0 -30px" }),
                            React.createElement(Box, { display: "flex", flexDirection: "column", padding: "15px 0" },
                                React.createElement(Typography, { type: "text14", color: "black-400" }, "Address"),
                                React.createElement(Typography, null, (_f = selectedItem.blockchainAddress) !== null && _f !== void 0 ? _f : "\u200b")),
                            React.createElement(Box, { bgColor: "black-50", height: "1px", m: "0 -30px" }),
                            React.createElement(Box, { display: "flex", flexDirection: "column", padding: "15px 0" },
                                React.createElement(Typography, { type: "text14", color: "black-400" }, "Currency"),
                                React.createElement(Typography, null, (_h = (_g = selectedItem.currency) === null || _g === void 0 ? void 0 : _g.toUpperCase()) !== null && _h !== void 0 ? _h : "\u200b"))))
                        : (React.createElement(React.Fragment, null,
                            React.createElement(Box, { display: "flex", flexDirection: "column", padding: "15px 0" },
                                React.createElement(Typography, { type: "text14", color: "black-400" }, "Country"),
                                React.createElement(Typography, null, (_k = (_j = selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.data.institutionAddress) === null || _j === void 0 ? void 0 : _j.country) !== null && _k !== void 0 ? _k : "—")),
                            React.createElement(Box, { bgColor: "black-50", height: "1px", m: "0 -30px" }),
                            React.createElement(Box, { display: "flex", flexDirection: "column", padding: "15px 0" },
                                React.createElement(Typography, { type: "text14", color: "black-400" }, "Bank name"),
                                React.createElement(Typography, null, (_l = selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.data.institutionName) !== null && _l !== void 0 ? _l : "—")))),
                    (selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.iban) && (React.createElement(React.Fragment, null,
                        React.createElement(Box, { m: "0 -30px", bgColor: "black-50", height: "1px" }),
                        React.createElement(Box, { display: "flex", flexDirection: "column", padding: "15px 0" },
                            React.createElement(Typography, { type: "text14", color: "black-400" }, "IBAN"),
                            React.createElement(Typography, null, selectedItem.iban)))),
                    (selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.swiftBic) && (React.createElement(React.Fragment, null,
                        React.createElement(Box, { m: "0 -30px", bgColor: "black-50", height: "1px" }),
                        React.createElement(Box, { display: "flex", flexDirection: "column", padding: "15px 0" },
                            React.createElement(Typography, { type: "text14", color: "black-400" }, "BIC"),
                            React.createElement(Typography, null, selectedItem.swiftBic))))))))));
});
