var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import BigNumber from "bignumber.js";
import { makeAutoObservable, reaction, runInAction } from "mobx";
import { currencyApi } from "@/api/currency/api";
import { walletApi } from "@/api/wallet/api";
import { DataSync } from "@/shared/stores/DataStore";
import { parseApiError } from "@/shared/utils/error";
import { Reactions } from "@/shared/utils/reactions";
export class TransferAccountFormStore {
    constructor(balanceList, accountList, addressList) {
        this.balanceList = balanceList;
        this.accountList = accountList;
        this.addressList = addressList;
        this.loading = false;
        this.from = undefined;
        this.to = undefined;
        this.amount = undefined;
        this.errors = [];
        this.result = undefined;
        this.confirmation = false;
        this.settings = new DataSync(currencyApi.currency.getCurrencyBySlug.bind(currencyApi), (d) => d.data.settings);
        this.reactions = new Reactions();
        makeAutoObservable(this, {}, { autoBind: true });
    }
    init() {
        this.reactions.create(reaction(() => { var _a; return (_a = this.fromAcc) === null || _a === void 0 ? void 0 : _a.currency; }, () => {
            var _a;
            if ((_a = this.fromAcc) === null || _a === void 0 ? void 0 : _a.currency) {
                this.settings.sync(this.fromAcc.currency.toLowerCase());
            }
            else {
                this.settings.reset();
            }
        }, {
            fireImmediately: true,
        }), reaction(() => [this.from, this.to], () => {
            this.balanceList.sync(this.from, this.to);
        }, {
            fireImmediately: true,
        }), reaction(() => [this.fromAcc, this.toAcc], () => {
            this.addressList.sync(this.fromAcc, this.toAcc);
        }, {
            fireImmediately: true,
        }));
    }
    dispose() {
        this.reactions.destroy();
    }
    reset() {
        this.loading = false;
        this.amount = undefined;
        this.errors = [];
        this.result = undefined;
        this.confirmation = false;
        this.settings.reset();
    }
    showConfirmation() {
        this.confirmation = true;
        this.errors = [];
    }
    hideConfirmation() {
        this.confirmation = false;
    }
    hideErrors() {
        this.errors = [];
    }
    submit() {
        return __awaiter(this, void 0, void 0, function* () {
            let errors = [];
            let result;
            runInAction(() => {
                this.loading = true;
            });
            try {
                if (!this.amount) {
                    throw new Error("amount must be defined");
                }
                if (!this.fromAcc) {
                    throw new Error("fromAcc must be defined");
                }
                if (!this.toAcc) {
                    throw new Error("toAcc must be defined");
                }
                const resp = yield walletApi.transfer.createTransfer({
                    amount: this.amount,
                    fromAccount: this.fromAcc.account,
                    toAccount: this.toAcc.account,
                    currency: this.fromAcc.currency,
                });
                result = resp.data;
                yield this.balanceList.sync(this.from, this.to);
            }
            catch (e) {
                errors = parseApiError(e);
                console.error(e);
            }
            runInAction(() => {
                this.loading = false;
                this.errors = errors;
                this.result = result;
                this.confirmation = false;
            });
        });
    }
    get isExchange() {
        return this.fromAcc && this.toAcc
            ? this.fromAcc.currency !== this.toAcc.currency
            : false;
    }
    get fromAcc() {
        return this.from ? this.accountList.fiatById[this.from] : undefined;
    }
    get toAcc() {
        return this.to ? this.accountList.fiatById[this.to] : undefined;
    }
    get fromBalance() {
        return this.fromAcc
            ? this.balanceList.data[this.fromAcc.account]
            : undefined;
    }
    get toBalance() {
        return this.toAcc
            ? this.balanceList.data[this.toAcc.account]
            : undefined;
    }
    get fromEnough() {
        return this.total && this.fromBalance
            ? new BigNumber(this.total).lte(this.fromBalance.balance)
            : undefined;
    }
    get toAccAddress() {
        var _a;
        return this.toAcc
            ? (_a = this.addressList.data[this.toAcc.account]) === null || _a === void 0 ? void 0 : _a[this.toAcc.currency]
            : undefined;
    }
    get fromAccAddress() {
        var _a;
        return this.fromAcc
            ? (_a = this.addressList.data[this.fromAcc.account]) === null || _a === void 0 ? void 0 : _a[this.fromAcc.currency]
            : undefined;
    }
    get valid() {
        return (this.amount
            && this.toAcc
            && this.fromEnough
            && new BigNumber(this.amount).gt(0));
    }
    get fee() {
        var _a;
        return this.amount && this.settings.value
            ? new BigNumber(this.amount)
                .times((_a = this.settings.value.transferFeePercent) !== null && _a !== void 0 ? _a : "0")
                .dividedBy(100)
                .toFixed()
            : undefined;
    }
    get total() {
        return this.amount && this.fee
            ? new BigNumber(this.amount).plus(this.fee).toFixed()
            : "0.00";
    }
}
