import { styled } from "@mui/material/styles";
export const ContainerIcon = styled("span", {
    shouldForwardProp: (prop) => !prop.startsWith("$")
        && !prop.startsWith("border"),
})(({ theme, $bgColor, borderColor, $borderRadius, $rotate, $color, $opacity, size, height, width, $hoverColor, $hoverOpacity, $hoverBgColor, }) => ({
    position: "relative",
    width: `${width || size}px`,
    height: `${height || size}px`,
    background: $bgColor ? theme.colors[$bgColor] : "transparent",
    border: borderColor ? `1px solid ${theme.colors[borderColor]}` : "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: $borderRadius,
    opacity: $opacity,
    transform: $rotate ? `rotate(${$rotate}deg)` : undefined,
    padding: "0px",
    transition: "opacity 0.2s ease, transform 0.2s ease, background 0.2s ease",
    color: $color ? theme.colors[$color] : undefined,
    boxSizing: "border-box",
    "& svg": {
        currentColor: $color ? theme.colors[$color] : undefined,
    },
    "&[role=button]:not(.Icon-disabled)": Object.assign(Object.assign({ cursor: "pointer" }, (($hoverColor || $hoverOpacity) && {
        "&:hover": {
            color: $hoverColor
                ? theme.colors[$hoverColor]
                : $color
                    ? theme.colors[$color]
                    : undefined,
            opacity: $hoverOpacity || $opacity,
        },
    })), ($hoverBgColor && {
        "&:hover": {
            background: theme.colors[$hoverBgColor],
        },
    })),
    "&.Icon-disabled": {
        cursor: "not-allowed",
    },
}));
