var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { makeAutoObservable, reaction, runInAction } from "mobx";
import { Reactions } from "../utils/reactions";
import { walletApi } from "@/api/wallet/api";
export class BalanceListStore {
    constructor(accountsListStore) {
        this.accountsListStore = accountsListStore;
        this.data = {};
        this.loading = {};
        this.intervalId = undefined;
        this.reactions = new Reactions();
        makeAutoObservable(this, {}, { autoBind: true });
    }
    startBalanceInterval() {
        if (this.intervalId)
            return;
        const intervalId = setInterval(() => {
            const crypto = this.accountsListStore.crypto.map((acc) => acc.account);
            const fiat = this.accountsListStore.fiat.map((acc) => acc.account);
            this.sync(...crypto, ...fiat);
        }, 10 * 1000);
        runInAction(() => {
            this.intervalId = intervalId;
        });
    }
    init() {
        this.reactions.create(reaction(() => [
            ...this.accountsListStore.crypto,
            ...this.accountsListStore.fiat,
        ], () => this.startBalanceInterval(), {
            fireImmediately: true,
        }));
    }
    dispose() {
        this.reactions.destroy();
    }
    sync(...ids) {
        return __awaiter(this, void 0, void 0, function* () {
            const filtered = ids.filter((id) => !!id && !this.loading[id]);
            if (filtered.length > 0) {
                let newData = {};
                this.loading = filtered.reduce((acc, item) => (Object.assign(Object.assign({}, acc), { [item]: true })), Object.assign({}, this.loading));
                try {
                    const resp = yield walletApi.balance.getBalanceList({
                        accountIds: filtered,
                    }, {
                        paramsSerializer: {
                            indexes: null,
                        },
                    });
                    newData = resp.data.reduce((acc, item) => (Object.assign(Object.assign({}, acc), { [item.account]: item })), Object.assign({}, newData));
                }
                catch (e) {
                    console.error(e);
                }
                runInAction(() => {
                    this.data = Object.assign(Object.assign({}, this.data), newData);
                    this.loading = filtered.reduce((acc, item) => (Object.assign(Object.assign({}, acc), { [item]: false })), Object.assign({}, this.loading));
                });
            }
        });
    }
    resync() {
        this.sync(...Object.keys(this.data));
    }
}
