import { styled } from "@mui/material/styles";
export const StyledContainer = styled("div", {
    shouldForwardProp: (prop) => !prop.startsWith("$"),
})(({ $errorPosittion, $width }) => ({
    position: "relative",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    width: $width || "unset",
    ".Alert": Object.assign({ position: $errorPosittion }, ($errorPosittion === "absolute" && {
        bottom: "-8px",
        transform: "translateY(100%)",
        boxSizing: "border-box",
        width: "100%",
        zIndex: 2,
    })),
}));
