import * as React from "react";
import { Box } from "@/shared/components/Box";
import { EmptyItem } from "@/shared/components/EmptyItem";
import { Typography } from "@/shared/components/Typography";
import { useMedia } from "@/shared/hooks/useMedia";
export const EmptyTable = ({ message, }) => {
    const media = useMedia();
    return (React.createElement(Box, { display: "flex", flexDirection: "column", bgColor: "general-white", borderRadius: "22px", padding: media.tablet ? "30px" : "40px", gap: media.tablet ? "30px" : "40px", marginBottom: "40px" },
        React.createElement(Box, { display: "grid", gridTemplateColumns: media.tablet ? "1fr" : "1fr 1fr", gap: "20px" },
            React.createElement(EmptyItem, null),
            React.createElement(EmptyItem, null)),
        React.createElement(Box, { display: "flex", flexDirection: "column", gap: "40px", padding: "25px", alignItems: "center", height: "224px", justifyContent: "center" },
            React.createElement(Typography, { color: "black-400", type: "text14" }, message))));
};
