import * as React from "react";
import { Box, Button, Typography } from "@/shared/components";
import { QRCode } from "@/shared/components/QRCode";
import { useCopy } from "@/shared/hooks/useCopy";
import { useMedia } from "@/shared/hooks/useMedia";
export const WalletAddress = ({ address, }) => {
    const copy = useCopy(address, "Wallet address is successfully copied");
    const media = useMedia();
    return (React.createElement(Box, { display: "flex", flexDirection: media.phone ? "column" : "row", padding: media.desktop ? "30px" : "20px", gap: "30px", borderRadius: "12px", borderStyle: "solid", borderWidth: "1px", borderColor: "black-200" },
        React.createElement(Box, { display: "flex", alignItems: "center", justifyContent: "center", flexShrink: "0" },
            React.createElement(QRCode, { size: 120, value: address })),
        React.createElement(Box, { display: "flex", flexDirection: "column" },
            React.createElement(Typography, { type: "text12", color: "black-500", marginBottom: "5px" }, "Wallet address"),
            React.createElement(Typography, { type: "text18", marginBottom: "30px", wordBreak: "break-all" }, address),
            React.createElement(Button, { variant: "LightBlue", alignSelf: "flex-start", onClick: copy }, "Copy"))));
};
