var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { makeObservable, observable, reaction, runInAction } from "mobx";
import { registrationApi } from "@/api/registration";
import { Reactions } from "../utils/reactions";
export class TermsStore {
    constructor(authStore) {
        this.authStore = authStore;
        this.termsConfirmed = false;
        this.termsUpdated = false;
        this.termsLoading = true;
        this.termsLink = "";
        this.termsVersion = 0;
        this.reactions = new Reactions();
        this.confirmTerms = () => __awaiter(this, void 0, void 0, function* () {
            try {
                const resp = yield registrationApi.user.signTermsAndConditions({
                    signed: true,
                    termsAndConditions: this.termsLink,
                    version: this.termsVersion,
                });
                runInAction(() => {
                    var _a, _b;
                    this.termsUpdated = resp.data.signed;
                    this.termsLink = ((_a = resp.data) === null || _a === void 0 ? void 0 : _a.termsAndConditions) ? (_b = resp.data) === null || _b === void 0 ? void 0 : _b.termsAndConditions : "";
                });
            }
            catch (e) {
                console.log(e);
            }
        });
        makeObservable(this, {
            termsUpdated: observable,
            termsConfirmed: observable,
            termsLink: observable,
            termsLoading: observable,
            termsVersion: observable,
        });
    }
    init() {
        this.reactions.create(reaction(() => this.authStore.authorized, () => {
            if (!this.authStore.authorized)
                return;
            this.checkTerms();
        }, {
            fireImmediately: true,
        }));
    }
    checkTerms() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const resp = yield registrationApi.user.getLatestTermsAndConditions();
                runInAction(() => {
                    var _a, _b;
                    this.termsUpdated = resp.data.signed;
                    this.termsLink = ((_a = resp.data) === null || _a === void 0 ? void 0 : _a.termsAndConditions) ? (_b = resp.data) === null || _b === void 0 ? void 0 : _b.termsAndConditions : "";
                    this.termsVersion = resp.data.version;
                    this.termsLoading = false;
                });
            }
            catch (e) {
                console.log(e);
                this.termsLoading = false;
            }
        });
    }
}
