import { observer } from "mobx-react-lite";
import React from "react";
import { useGetVerificationInfo } from "@/api/registration";
import { ExtraDocsStore } from "@/pages/CompanyInfoPage/store/ExtraDocsStore";
import { Box, Button, Typography } from "@/shared/components";
import { Upload } from "@/shared/components/Upload";
import { useMedia } from "@/shared/hooks/useMedia";
import { useProvider, useStore } from "@/shared/hooks/useStore";
export const UploadDocuments = observer(() => {
    var _a;
    const info = useGetVerificationInfo();
    const extraDocs = useStore(ExtraDocsStore);
    const ExtraDocsProvider = useProvider(ExtraDocsStore);
    const media = useMedia();
    return (React.createElement(ExtraDocsProvider, { value: extraDocs },
        React.createElement(Box, { bgColor: "general-white", borderRadius: "22px", padding: media.phone ? "30px" : "60px", display: "flex", flexDirection: "column" },
            React.createElement(Box, { display: "flex", flexDirection: "column", maxWidth: "720px", gap: "30px" },
                React.createElement(Box, { display: "flex", flexDirection: "column", gap: "10px" },
                    React.createElement(Typography, { type: "text24", fontWeight: 500 }, "Want to add more documents?"),
                    React.createElement(Typography, { color: "black-500" },
                        "Please, make sure that you upload relevant company documents. Allowed formats: pdf, doc, docx, jpeg, png and tiff.\u00A0",
                        React.createElement(Typography, null, "The total weight of files uploaded must not exceed 20 MB."))),
                extraDocs.items.map((item, index) => {
                    var _a, _b, _c;
                    return (React.createElement(Upload
                    // eslint-disable-next-line react/no-array-index-key
                    , { 
                        // eslint-disable-next-line react/no-array-index-key
                        key: index, success: item.success, label: "Additional documents", disabled: extraDocs.loading, error: (_a = item.errors.at(0)) === null || _a === void 0 ? void 0 : _a.description, fileName: (_b = item.file) === null || _b === void 0 ? void 0 : _b.name, loading: extraDocs.loading, multiple: false, onRemove: item.success ? undefined : () => extraDocs.remove(index), size: (_c = item.file) === null || _c === void 0 ? void 0 : _c.size, onFiles: ([file]) => extraDocs.addFile(file, index) }));
                })),
            React.createElement(Box, { mt: "10px", display: "flex", justifyContent: "flex-end", maxWidth: "720px" },
                React.createElement(Typography, { color: "deep-blue-500", onClick: extraDocs.addItem }, "+ Add new document")),
            React.createElement(Button, { width: media.phone ? "100%" : "250px", mt: media.phone ? "30px" : "60px", size: media.phone ? "s" : "m", disabled: !((_a = info.data) === null || _a === void 0 ? void 0 : _a.id)
                    || extraDocs.loading
                    || extraDocs.items.every(item => item.success)
                    || extraDocs.items.filter(item => !item.success && item.file).length === 0, onClick: () => extraDocs.upload(info.data.id) }, "Upload"))));
});
