import { formattedTokenAmount } from "@broxus/js-utils";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { getOperationType } from "@/api/wallet/constants";
import { Box, Button, Typography } from "@/shared/components";
import { useCsv } from "@/shared/hooks/useCsv";
import { useDownload } from "@/shared/hooks/useDownload";
import { useStoreContext } from "@/shared/hooks/useStore";
import { DATE_FORMAT, formatDate } from "@/shared/lib";
import { AccountListStore } from "@/shared/stores/AccountListStore";
import { Transactions } from "@/widgets/Transactions";
export const TransactionPage = observer(() => {
    const accounts = useStoreContext(AccountListStore);
    const [selectedItems, setSelectedItems] = React.useState([]);
    const [allItems, setAllItems] = React.useState([]);
    const ids = accounts.list.map((account) => account.account);
    const header = ["Date", "Operation", "Amount"];
    const items = (selectedItems.length > 0 ? selectedItems : allItems)
        .map((item) => [
        formatDate(item.createdAt || "", DATE_FORMAT.date),
        `${getOperationType(item.reasonType)}, ${item.currency}`,
        item.change ? `${formattedTokenAmount(item.change)} ${item.currency}` : "",
    ]);
    const csv = useCsv(header, items);
    const download = useDownload(csv, "transactions", "text/csv");
    return (React.createElement(Box, { flexDirection: "column", gap: "42px", mt: "30px", mb: "30px", display: "flex" },
        React.createElement(Box, { display: "flex", justifyContent: "space-between", alignItems: "center" },
            React.createElement(Typography, { variant: "h2", type: "text32Bold" }, "Transactions history"),
            React.createElement(Button, { size: "s", variant: "Black", icon: "Download", iconSize: 16, padding: "0 25px", onClick: download }, "Export as CSV")),
        React.createElement(Transactions, { accountIds: ids, onSelectedItems: setSelectedItems, tableProps: { checkboxSelection: true }, onItems: setAllItems })));
});
