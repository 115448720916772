import { styled } from "@mui/material/styles";
import * as React from "react";
export const PopupItem = ({ children, selected, onClick }) => (React.createElement(Wrapper, { selected: selected, onClick: onClick }, children));
const Wrapper = styled("button") `
    padding: 0 25px;
    display: flex;
    align-items: center;
    gap: 15px;
    min-height: 80px;
    text-align: left;
    width: 100%;
    background: ${(props) => props.selected && props.theme.colors["deep-blue-500-transparent-2"]};
    transition: 0.2s ease;

    &:last-child {
        border-radius: 0 0 22px 22px;
    }

    &:hover {
        background: ${(props) => props.theme.colors["deep-blue-500-transparent-2"]};
    }
`;
