/* eslint-disable no-restricted-syntax */
export const resolveOnChange = (target, e, onChange, targetValue) => {
    if (!onChange) {
        return;
    }
    let event = e;
    if ((e === null || e === void 0 ? void 0 : e.type) === "click") {
        const currentTarget = target.cloneNode(true);
        // click clear icon
        event = Object.create(e, {
            target: { value: currentTarget },
            currentTarget: { value: currentTarget },
        });
        currentTarget.value = targetValue !== null && targetValue !== void 0 ? targetValue : "";
        onChange(event);
        return;
    }
    // Trigger by composition event, this means we need force change the input value
    if (targetValue !== undefined) {
        event = Object.create(e, {
            target: { value: target },
            currentTarget: { value: target },
        });
        // eslint-disable-next-line no-param-reassign
        target.value = targetValue;
        onChange(event);
        return;
    }
    onChange(event);
};
export const mergeRefs = (...refs) => {
    const filteredRefs = refs.filter(Boolean);
    if (!filteredRefs.length)
        return null;
    return (inst) => {
        for (const ref of filteredRefs) {
            if (typeof ref === "function") {
                ref(inst);
            }
            else if (ref) {
                ;
                ref.current = inst;
            }
        }
    };
};
