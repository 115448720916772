import { styled } from "@mui/material/styles";
import * as React from "react";
import { BaseIcon } from "@/shared/components/BaseIcon";
import { Box } from "@/shared/components/Box";
import { Loader } from "@/shared/components/Loader";
import { Typography } from "@/shared/components/Typography";
export const AccountSelect = ({ disabled, icon, iconUri, name, desc, descLoading, onClick, }) => (React.createElement(Wrapper, { disabled: disabled, onClick: onClick },
    React.createElement(Box, { display: "flex", gap: "10px", alignItems: "center" }, icon !== null && icon !== void 0 ? icon : (iconUri && React.createElement("img", { src: iconUri, width: 24, alt: "" })),
        (name || desc) && (React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "flex-start" },
            name && (React.createElement(Typography, { type: "text14", fontWeight: 500 }, name)),
            descLoading
                ? React.createElement(Loader, { size: 13, containerSize: 18 })
                : desc
                    ? (React.createElement(Desc, { type: "text12", fontWeight: 500, color: "black-400", lineHeight: "18px" }, desc))
                    : null))),
    React.createElement(BaseIcon, { icon: "DropDown", size: 24, color: "black-500" })));
const Wrapper = styled("button") `
    background: ${props => props.theme.colors["general-white"]};
    border-radius: 16px;
    padding: 8px 20px;
    display: flex;
    align-items: center;
    min-height: 55px;
    gap: 10px;
    justify-content: space-between;
    min-width: 212px;
`;
const Desc = styled(Typography) `
    line-height: 18px;
`;
