import { styled } from "@mui/material/styles";
import * as React from "react";
import { BaseIcon, Box, Typography } from "@/shared/components";
export const PaymentTypeItem = ({ disable, icon, title, active, desc, onClick, }) => (React.createElement(Wrapper, { active: active, onClick: onClick, disabled: disable },
    React.createElement(BaseIcon, { size: 32, icon: icon }),
    React.createElement(Box, { flex: "1", display: "flex", flexDirection: "column" },
        React.createElement(Typography, { type: "text16", fontWeight: 500 }, title),
        React.createElement(Typography, { type: "text14", fontWeight: 500, color: "black-500" }, desc)),
    React.createElement(Box, null,
        React.createElement(Radio, { active: active },
            React.createElement(BaseIcon, { icon: "Radio", size: 8, color: "general-white" })))));
// dprint-ignore
const Radio = styled('div') `
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    box-shadow: ${props => props.active
    ? `inset 0 0 0 2px ${props.theme.colors["deep-blue-500"]}`
    : `inset 0 0 0 2px ${props.theme.colors["black-100"]}`};
    width: 20px;
    height: 20px;
    border-radius: 20px;
    transition: 0.2s ease;
    background: ${props => props.active
    ? props.theme.colors["deep-blue-500"]
    : props.theme.colors["general-white"]};
`;
// dprint-ignore
const Wrapper = styled('button') `
    text-align: left;
    padding: 20px;
    box-shadow: ${props => props.active
    ? `inset 0 0 0 2px ${props.theme.colors["deep-blue-500"]}`
    : `inset 0 0 0 1px ${props.theme.colors["black-100"]}`};
    display: flex;
    border-radius: 12px;
    align-items: center;
    gap: 15px;
    cursor: pointer;
    transition: 0.2s ease;
    opacity: ${props => props.disabled ? "0.5" : "1"}
`;
