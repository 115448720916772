var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { formattedTokenAmount } from "@broxus/js-utils";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useCreateDirectExchange } from "@/api/wallet";
import { DirectExchangeStatus } from "@/api/wallet/types";
import { FailPopup } from "@/features/transfer/components/FailPopup";
import { SuccessPopup } from "@/features/transfer/components/SuccessPopup";
import { ExchangeType } from "@/routes";
import { Box, Button, Typography } from "@/shared/components";
import { Popup } from "@/shared/components/Popup";
import { PopupBody } from "@/shared/components/Popup/Body";
import { PopupContent } from "@/shared/components/Popup/Content";
import { PopupHead } from "@/shared/components/Popup/Head";
import { PopupTitle } from "@/shared/components/Popup/Title";
import { useStoreContext } from "@/shared/hooks/useStore";
import { BalanceListStore } from "@/shared/stores/BalanceListStore";
export const ExchangeModal = ({ isOpen, onClose, data, rateInfo, toAmount, showError, onSuccess, }) => {
    var _a;
    const params = useParams();
    const balances = useStoreContext(BalanceListStore);
    const [isOpenSuccess, setIsOpenSuccess] = React.useState(false);
    const [isOpenError, setIsOpenError] = React.useState(false);
    const [errorText, setErrorText] = React.useState("");
    const { mutateAsync, data: result, isPending: isPendingPreExchange, } = useCreateDirectExchange({
        onSuccess: (dataRes) => {
            var _a, _b;
            if (dataRes.data.status === DirectExchangeStatus.FAILED) {
                setErrorText((_b = (_a = dataRes === null || dataRes === void 0 ? void 0 : dataRes.data) === null || _a === void 0 ? void 0 : _a.note) !== null && _b !== void 0 ? _b : "");
                setIsOpenError(true);
                onClose();
                return;
            }
            onClose();
            setIsOpenSuccess(true);
            onSuccess();
            balances.resync();
        },
        onError: () => {
            setIsOpenError(true);
            onClose();
        },
    });
    const exchange = () => __awaiter(void 0, void 0, void 0, function* () {
        var _b, _c;
        try {
            yield mutateAsync({
                dryRun: false,
                data,
            });
        }
        catch (e) {
            setErrorText((_c = (_b = e === null || e === void 0 ? void 0 : e.response) === null || _b === void 0 ? void 0 : _b.data.message) !== null && _c !== void 0 ? _c : "");
            setIsOpenError(true);
        }
    });
    useEffect(() => {
        if (!showError)
            return;
        setErrorText(showError !== null && showError !== void 0 ? showError : "");
        setIsOpenError(true);
    }, [showError]);
    return (React.createElement(React.Fragment, null,
        isOpen && !isOpenSuccess && !isOpenError
            ? (React.createElement(Popup, { width: "450px", onClose: onClose },
                React.createElement(PopupHead, { onClose: onClose }),
                React.createElement(PopupBody, null,
                    React.createElement(PopupTitle, { type: "text32Bold" },
                        "You will pay ",
                        data.fromAmount,
                        " ",
                        data.fromCurrency,
                        " and get",
                        "  ",
                        React.createElement(Typography, { color: "deep-blue-500", type: "text32Bold" },
                            formattedTokenAmount(toAmount),
                            " ",
                            data.toCurrency)),
                    React.createElement(PopupContent, { justifyContent: "flex-end" },
                        React.createElement(Box, { display: "flex", flexDirection: "column", gap: "30px" },
                            React.createElement(Box, { width: "100%", height: "1px", bgColor: "black-100" }),
                            React.createElement(Box, { display: "flex", flexDirection: "column", gap: "10px" },
                                React.createElement(Box, { display: "flex", alignItems: "center", justifyContent: "space-between" },
                                    React.createElement(Typography, { color: "black-500" }, rateInfo.label),
                                    React.createElement(Typography, null, rateInfo.value)),
                                React.createElement(Box, { display: "flex", alignItems: "center", justifyContent: "space-between" },
                                    React.createElement(Typography, { color: "black-500" }, "Processing time"),
                                    React.createElement(Typography, null, "Defined by bank")),
                                data.feeAmount && data.feeCurrency && (React.createElement(Box, { display: "flex", alignItems: "center", justifyContent: "space-between" },
                                    React.createElement(Typography, { color: "black-500" }, "Fees"),
                                    React.createElement(Typography, null, `${formattedTokenAmount(data.feeAmount)} ${data.feeCurrency.toUpperCase()}`)))),
                            React.createElement(Box, { width: "100%", height: "1px", bgColor: "black-100" }),
                            React.createElement(Typography, { color: "black-500", type: "text12" }, "Please note that the final rate will be fixed, when you confirm the transaction"))),
                    React.createElement(Button, { width: "100%", loading: isPendingPreExchange, disabled: isPendingPreExchange, onClick: exchange }, "Continue"))))
            : null,
        isOpenSuccess && (React.createElement(SuccessPopup, { title: params.type === ExchangeType.Crypto
                ? "You've successfully exchanged the crypto"
                : "You've successfully exchanged your funds", address: (_a = result === null || result === void 0 ? void 0 : result.data) === null || _a === void 0 ? void 0 : _a.id, amount: toAmount, currency: data.toCurrency, createdAt: result === null || result === void 0 ? void 0 : result.data.updatedAt, onClose: () => {
                onClose();
                onSuccess();
                setIsOpenSuccess(false);
            } })),
        isOpenError && (React.createElement(FailPopup, { onSubmit: exchange, message: errorText, onClose: () => {
                onClose();
                setIsOpenError(false);
            } }))));
};
