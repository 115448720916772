import React from "react";
const ctxs = new Map();
const providers = new Map();
export function useStoreContext(Store) {
    const ctx = ctxs.get(Store);
    if (!ctx) {
        throw new Error(`${Store.name} context not found`);
    }
    const result = React.useContext(ctx);
    if (result === undefined) {
        throw new Error(`${Store.name} must be defined`);
    }
    return result;
}
export const useProvider = (Store) => {
    let ctx = ctxs.get(Store);
    if (!ctx) {
        ctx = React.createContext(undefined);
        ctx.displayName = Store.name;
        ctxs.set(Store, ctx);
    }
    const CtxProvider = ctx.Provider;
    let Provider = providers.get(Store);
    if (!Provider) {
        Provider = function ProviderComponent({ children, value }) {
            React.useEffect(() => {
                var _a;
                (_a = value.init) === null || _a === void 0 ? void 0 : _a.call(value);
                return () => {
                    var _a;
                    (_a = value.dispose) === null || _a === void 0 ? void 0 : _a.call(value);
                };
            }, [value]);
            return (React.createElement(CtxProvider, { value: value }, children));
        };
        providers.set(Store, Provider);
    }
    return Provider;
};
export const useStore = (Store, ...deps) => React.useMemo(() => (new Store(...deps)
// eslint-disable-next-line react-hooks/exhaustive-deps
), deps);
