import { Checkbox } from "@mui/material";
import { styled } from "@mui/material/styles";
export const StyledCheckbox = styled(Checkbox, {
    shouldForwardProp: (prop) => !prop.startsWith("$"),
})(({ theme, $isHover }) => (Object.assign({ background: "transparent", borderRadius: "6px", padding: "0", transition: "background 0.2s ease, opacity 0.2s ease", width: "15px", height: "15px", "& svg rect": {
        transition: "all 0.2s ease",
        stroke: theme.colors["deep-blue-500"],
    }, "&.MuiCheckbox-sizeMedium": {
        width: "20px",
        height: "20px",
    }, "&.Mui-disabled": {
        cursor: "not-allowed",
        pointerEvents: "all",
        background: "transparent",
        border: `1px solid ${theme.colors["black-100"]}`,
        "svg path": {
            transition: "all 0.2s ease",
            stroke: theme.colors["black-100"],
        },
    }, "&:hover:not(.Mui-checked,.Mui-disabled)": {
        opacity: "1",
        "svg rect": {
            transition: "all 0.2s ease",
            stroke: theme.colors["deep-blue-500"],
        },
    }, "&.MuiCheckbox-indeterminate:not(.Mui-disabled,.Mui-checked) .SW-Icon": {
        opacity: "0",
        "svg path": {
            stroke: theme.colors["deep-blue-500"],
        },
    }, "&.MuiCheckbox-indeterminate:not(.Mui-disabled,.Mui-checked):hover .SW-Icon": {
        opacity: "1",
    } }, ($isHover && {
    "&:not(.Mui-checked,.Mui-disabled) .SW-Icon": {
        opacity: "1",
        "svg path": {
            stroke: theme.colors["deep-blue-500"],
        },
    },
    "&.Mui-checked:not(.Mui-disabled) .SW-Icon": {
        "svg path": {
            stroke: theme.colors["deep-blue-500"],
        },
    },
}))));
