import { formattedTokenAmount } from "@broxus/js-utils";
import React from "react";
import { generatePath, Link } from "react-router-dom";
import { DepositType, ROUTES } from "@/routes";
import { Box, Button, Typography } from "@/shared/components";
import { BankIcon } from "@/shared/components/BankIcon";
import { Loader } from "@/shared/components/Loader";
import { sliceAddress } from "@/shared/utils/slice-address";
export const Account = React.forwardRef(({ id, currency, name, amount, loading, accNum, isEmpty }, ref) => (React.createElement(Box, { padding: "30px 20px", height: "250px", boxSizing: "border-box", bgColor: "general-white", borderRadius: "22px", display: "flex", flexDirection: "column", justifyContent: "space-between", width: "280px", ref: ref },
    React.createElement(Box, { display: "flex", justifyContent: "space-between", alignItems: "center" },
        React.createElement(Link, { to: generatePath(ROUTES.bankAccountsItem, { id }) },
            React.createElement(BankIcon, { size: 42, symbol: currency })),
        React.createElement(Button, { variant: "Bordered", width: "120px", size: "s", LinkComponent: Link, to: generatePath(ROUTES.deposit, {
                type: DepositType.Fiat,
                id,
            }) }, "Add funds")),
    React.createElement(Box, { display: "flex", flexDirection: "column", gap: "10px" },
        React.createElement(Box, { display: "flex", flexDirection: "column" },
            name && React.createElement(Typography, { type: "text14" }, name),
            React.createElement(Typography, { type: "text14", color: "black-400" }, isEmpty ? "No account" : accNum ? sliceAddress(accNum) : "\u200b")),
        loading
            ? React.createElement(Loader, { size: 24, containerSize: 31 })
            : (React.createElement(Typography, { type: "text24", lineHeight: "1" },
                formattedTokenAmount(amount),
                " ",
                currency))))));
