var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import { BaseIcon } from "@/shared/components/BaseIcon";
export const PopupCloseBtn = (_a) => {
    var { onClick, invert } = _a, props = __rest(_a, ["onClick", "invert"]);
    return (React.createElement(BaseIcon, Object.assign({}, props, { icon: "Cross", size: 12, containerSize: 45, bgColor: invert ? "general-white-transparent-15" : "black-50", color: invert ? "general-white" : undefined, borderRadius: "12px", onClick: onClick })));
};
