import { styled } from "@mui/material/styles";
import { Box } from "@/shared/components";
export const Card = styled(Box)(({ theme }) => ({
    transition: "all 0.3s ease",
    "&:hover": {
        backgroundColor: theme.colors["black-50"],
    },
}));
export const WrapperStatus = styled(Box)(({ theme }) => ({
    position: "absolute",
    right: "60px",
    top: 0,
    textTransform: "lowercase",
    background: theme.colors["black-1000"],
    color: theme.colors["general-white"],
    borderRadius: "8px",
    padding: "6px 12px",
}));
