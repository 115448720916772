var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable no-underscore-dangle */
import { makeAutoObservable, reaction, runInAction } from "mobx";
import { registrationApi } from "@/api/registration";
import { IdDocSubType, IdDocType } from "@/api/registration/types";
import { Reactions } from "@/shared/utils/reactions";
export class IndividualVerificationListStore {
    constructor(individualList) {
        this.individualList = individualList;
        this.syncReady = false;
        this.loading = false;
        this.data = [];
        this.reactions = new Reactions();
        makeAutoObservable(this, {}, { autoBind: true });
    }
    init() {
        this.reactions.create(reaction(() => this.individualList.list, this.sync, {
            fireImmediately: true,
        }));
    }
    dispose() {
        this.data = [];
        this.syncReady = false;
        this.loading = false;
        this.reactions.destroy();
    }
    sync() {
        return __awaiter(this, void 0, void 0, function* () {
            let data = [];
            let ready = true;
            runInAction(() => {
                this.loading = true;
            });
            try {
                data = yield Promise.all(this.individualList.list.map(item => {
                    var _a;
                    const kycId = (_a = item.verificationIds) === null || _a === void 0 ? void 0 : _a[0];
                    return kycId
                        ? registrationApi.verification.getVerificationByUuid(kycId)
                            .then(resp => resp.data)
                        : null;
                }));
            }
            catch (e) {
                ready = false;
                console.error(e);
            }
            runInAction(() => {
                this.data = data;
                this.loading = false;
                this.syncReady = ready;
            });
        });
    }
    get ready() {
        return this.syncReady && this.individualList.ready;
    }
    get success() {
        return this.data.every(item => {
            var _a, _b, _c;
            return (!!item && !!((_a = item.docs) === null || _a === void 0 ? void 0 : _a.find(doc => doc.type === IdDocType.PASSPORT))
                && !!((_b = item.docs) === null || _b === void 0 ? void 0 : _b.find(doc => doc.type === IdDocType.SELFIE))
                && !!((_c = item.docs) === null || _c === void 0 ? void 0 : _c.find(doc => doc.type === IdDocType.OTHER && doc.sub_type === IdDocSubType.PROOF_OF_ADDRESS)));
        });
    }
}
