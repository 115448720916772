var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { makeAutoObservable, runInAction } from "mobx";
import { walletApi } from "@/api/wallet/api";
import { AccountType } from "@/api/wallet/types";
import { EventType } from "@/shared/stores/EventsStore";
import { parseApiError } from "@/shared/utils/error";
export class AccountFormStore {
    constructor(events) {
        this.events = events;
        this.name = undefined;
        this.currency = undefined;
        this.loading = false;
        this.accountId = undefined;
        this.errors = [];
        makeAutoObservable(this, {}, { autoBind: true });
    }
    submit() {
        return __awaiter(this, void 0, void 0, function* () {
            let result = null;
            let errors = [];
            this.loading = true;
            try {
                result = yield walletApi.account.createAccount({
                    accountType: AccountType.LAYER2,
                    shortName: this.name,
                    currency: this.currency,
                    blockchain: "fiat",
                });
                this.events.push({
                    type: EventType.AccountCreated,
                });
            }
            catch (e) {
                console.error(e);
                errors = parseApiError(e);
            }
            runInAction(() => {
                this.loading = false;
                this.errors = errors;
            });
            return result === null || result === void 0 ? void 0 : result.data.account;
        });
    }
    get valid() {
        return !!this.currency;
    }
}
