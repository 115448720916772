import { makeAutoObservable } from "mobx";
export class CreateRecipientStore {
    constructor() {
        this.isOpen = false;
        makeAutoObservable(this, {}, { autoBind: true });
    }
    setIsOpen(isOpen) {
        this.isOpen = isOpen;
    }
}
