import { observer } from "mobx-react-lite";
import * as React from "react";
import { Box, Button, Typography } from "@/shared/components";
import { Popup } from "@/shared/components/Popup";
export const FailedPopup = observer(({ onClose, }) => (React.createElement(Popup, { width: "450px" },
    React.createElement(Box, { bgColor: "black-1200", borderRadius: "20px", padding: "50px", minHeight: "400px", maxHeight: "600px", height: "100vh", boxSizing: "border-box", display: "flex", flexDirection: "column", justifyContent: "space-between", gap: "74px" },
        React.createElement(Box, { flexGrow: "4", display: "flex", flexDirection: "column", justifyContent: "center" },
            React.createElement(Typography, { type: "text28", fontWeight: 500, variant: "h2", textAlign: "center", margin: "0 0 10px", color: "general-white" }, "Something went wrong"),
            React.createElement(Typography, { type: "text14", fontWeight: 500, textAlign: "center", color: "general-white" }, "We couldn't process your request."),
            React.createElement(Typography, { type: "text14", fontWeight: 500, textAlign: "center", color: "general-white" }, "Please try again.")),
        React.createElement(Box, { display: "flex", flexDirection: "column", gap: "20px", alignItems: "center" },
            React.createElement(Button, { variant: "White", width: "100%", onClick: onClose }, "Try again"))))));
