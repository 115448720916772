var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// eslint-disable-next-line max-classes-per-file
import { makeAutoObservable, reaction } from "mobx";
import { walletApi } from "@/api/wallet/api";
import { CounterpartyType, } from "@/api/wallet/types";
import { DataSync } from "@/shared/stores/DataStore";
import { Reactions } from "@/shared/utils/reactions";
class Recipient {
    constructor(data) {
        this.data = data;
    }
    get accountNumber() {
        return "accountNumber" in this.data
            ? this.data.accountNumber
            : undefined;
    }
    get iban() {
        return "iban" in this.data ? this.data.iban : undefined;
    }
    get swiftBic() {
        return "swiftBic" in this.data ? this.data.swiftBic : undefined;
    }
    get blockchainAddress() {
        return "blockchainAddress" in this.data
            ? this.data.blockchainAddress
            : undefined;
    }
    get profileName() {
        return "profile" in this.data ? this.data.profile.name : undefined;
    }
    get blockchain() {
        return "blockchain" in this.data ? this.data.blockchain : undefined;
    }
    get currency() {
        return "currency" in this.data ? this.data.currency : undefined;
    }
    get isCrypto() {
        return this.data.type === CounterpartyType.CRYPTO;
    }
}
export class RecipientListStore {
    constructor(auth) {
        this.auth = auth;
        this.reactions = new Reactions();
        this.counterparty = new DataSync(walletApi.counterparty.getCounterparties.bind(walletApi));
        makeAutoObservable(this, {}, { autoBind: true });
    }
    init() {
        this.reactions.create(reaction(() => this.auth.authorized, this.sync, {
            fireImmediately: true,
        }));
    }
    dispose() {
        this.reactions.destroy();
    }
    sync() {
        if (this.auth.authorized) {
            this.counterparty.sync({
                page: 0,
                size: 1000,
            });
        }
        else {
            this.counterparty.reset();
        }
    }
    deleteParty(id) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.auth.authorized)
                return;
            try {
                yield walletApi.counterparty.deleteCounterparty(id);
                this.sync();
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    get list() {
        var _a, _b;
        const recList = (_b = (_a = this.counterparty.value) === null || _a === void 0 ? void 0 : _a.data.data.map((item) => new Recipient(item))) !== null && _b !== void 0 ? _b : [];
        return (recList === null || recList === void 0 ? void 0 : recList.length) === 0
            ? recList
            : recList.sort((a, b) => {
                var _a, _b;
                if (!a.profileName)
                    return 1;
                if (!b.profileName)
                    return -1;
                return (_a = a.profileName) === null || _a === void 0 ? void 0 : _a.localeCompare((_b = b.profileName) !== null && _b !== void 0 ? _b : "");
            });
    }
    get byId() {
        return this.list
            .filter((item) => !!item.data.id)
            .reduce((acc, item) => (Object.assign(Object.assign({}, acc), { [item.data.id]: item })), {});
    }
    get loading() {
        return this.counterparty.loading;
    }
}
