var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { makeAutoObservable, runInAction } from "mobx";
import { registrationApi } from "@/api/registration";
import { IdDocSubType, IdDocType } from "@/api/registration/types";
import { parseApiError } from "@/shared/utils/error";
export class ExtraDocsStore {
    constructor() {
        this.loading = false;
        this.items = [{
                errors: [],
                success: false,
            }];
        makeAutoObservable(this, {}, { autoBind: true });
    }
    addItem() {
        this.items = [...this.items, {
                errors: [],
                success: false,
            }];
    }
    addFile(file, index) {
        const next = [...this.items];
        next[index] = Object.assign(Object.assign({}, this.items[index]), { file });
        this.items = next;
    }
    remove(index) {
        const next = [...this.items];
        next.splice(index, 1);
        this.items = next.length > 0 ? next : [{
                errors: [],
                success: false,
            }];
    }
    upload(kycId) {
        return __awaiter(this, void 0, void 0, function* () {
            this.loading = true;
            this.items = this.items.filter(item => !!item.file);
            const items = yield Promise.all(this.items
                .filter(item => !item.success && !!item.file)
                .map(item => {
                const request = {
                    type: IdDocType.OTHER,
                    subType: IdDocSubType.OTHER,
                };
                return registrationApi.verification.uploadDocument(kycId, {
                    file: item.file,
                    request: new Blob([JSON.stringify(request)], {
                        type: "application/json",
                    }),
                })
                    .then(() => ({
                    errors: [],
                    file: item.file,
                    success: true,
                }))
                    .catch(e => ({
                    errors: parseApiError(e),
                    file: item.file,
                    success: false,
                }));
            }));
            runInAction(() => {
                this.loading = false;
                this.items = items;
            });
        });
    }
}
