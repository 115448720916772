var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
export const StyledLink = styled(Link, {
    shouldForwardProp: (prop) => !prop.startsWith("$") && prop !== "alignSelf",
})((_a) => {
    var { theme, $textColor = "deep-blue-500", $text, $hoverColor } = _a, props = __rest(_a, ["theme", "$textColor", "$text", "$hoverColor"]);
    return ({
        color: $textColor ? theme.colors[$textColor] : "var(--default-font-color)",
        textDecoration: "none",
        fontSize: $text ? theme.text[$text].fontSize : "var(--default-font-size)",
        fontWeight: $text
            ? theme.text[$text].fontWeight
            : "var(--default-font-weight)",
        lineHeight: $text
            ? theme.text[$text].lineHeight
            : "var(--default-line-height)",
        transition: "color 0.2s ease",
        fontFamily: theme.fontFamily,
        alignSelf: props.alignSelf,
        "&:hover": {
            color: $hoverColor ? theme.colors[$hoverColor] : theme.colors["hover-link"],
        },
    });
});
