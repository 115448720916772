import { styled } from "@mui/material/styles";
export const StyledAutocomplete = styled("div", {
    shouldForwardProp: (prop) => !prop.startsWith("$"),
})(({ $smallList, $maxHeightList, $width }) => ({
    width: $width || "100%",
    "& .MuiInputBase-root.MuiOutlinedInput-root .MuiAutocomplete-endAdornment": {
        right: 0,
    },
    ".MuiAutocomplete-endAdornment": {
        marginRight: "20px",
        padding: "0",
        top: "50%",
        transform: "translateY(-50%)",
        ".MuiButtonBase-root": {
            padding: "0",
            margin: "0",
            "&:hover": {
                background: "transparent",
            },
        },
    },
    ".MuiAutocomplete-option": {
        padding: "8px",
        borderRadius: "8px",
    },
    ".MuiAutocomplete-listbox": {
        overflow: "unset",
        maxHeight: $smallList ? $maxHeightList || "30vh" : $maxHeightList || "40vh",
        "&::-webkit-scrollbar": {
            display: "none",
            position: "relative",
        },
    },
    ".MuiOutlinedInput-root .MuiAutocomplete-input.MuiInputBase-input": {
        padding: "0 0 0 20px",
    },
    ".MuiOutlinedInput-root.MuiInputBase-root": {
        padding: "0px",
    },
    ".Icon": {
        boxSizing: "content-box",
    },
    ".BaseInput-readOnly, .BaseInput-readOnly input": {
        cursor: "auto",
    },
}));
export const StyledContainer = styled("li")(({ theme }) => ({
    "&#ListOptionDefault": {
        width: "100%",
        display: "flex",
        gap: "12px",
        alignItems: "center",
        padding: "15px 20px",
        boxSizing: "border-box",
        transition: "background 0.2s ease",
    },
    "&#ListOptionDefault[aria-selected=\"true\"]": {
        backgroundColor: "transparent",
    },
    "&#ListOptionDefault[aria-disabled=\"true\"]": {
        opacity: "0.4",
        cursor: "not-allowed",
    },
    "&#ListOptionDefault:hover:not([aria-disabled=\"true\"])": {
        backgroundColor: theme.colors["black-50"],
        cursor: "pointer",
        ".option-text": {
            color: theme.colors["deep-blue-500"],
        },
    },
    "&#ListOptionDefault.Mui-focused": {
        backgroundColor: "transparent",
    },
    "&#ListOptionDefault.Mui-focusVisible": {
        ".option-text": {
            color: theme.colors["black-1200"],
        },
    },
}));
export const StyledContent = styled("div")(() => ({
    display: "flex",
    gap: "12px",
    alignItems: "center",
    width: "100%",
}));
