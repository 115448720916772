import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { walletApi } from "./api";
export const useGetHistoryQuery = ({ accountIds, page }) => useQuery({
    queryKey: ["transfers-query", ...accountIds, page],
    queryFn: () => walletApi.balance.getBalanceLog({
        accountIds: accountIds || "",
        page,
        size: 10,
    }, {
        paramsSerializer: {
            indexes: null,
        },
    }),
    staleTime: 100000,
    placeholderData: keepPreviousData,
    gcTime: 100000,
    enabled: !!accountIds.length,
    select(data) {
        return Object.assign(Object.assign({}, data.data), { data: data.data.data });
    },
});
