var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { observer } from "mobx-react-lite";
import * as React from "react";
import { IndividualVerificationStore } from "@/pages/KYBPage/stores/IndividualVerificationStore";
import { UploadStore } from "@/pages/KYBPage/stores/UploadStore";
import { Upload } from "@/shared/components/Upload";
import { useStore, useStoreContext } from "@/shared/hooks/useStore";
export const IndividualDocument = observer(({ label, subType, type, }) => {
    var _a, _b, _c, _d, _e;
    const verification = useStoreContext(IndividualVerificationStore);
    const upload = useStore(UploadStore, verification);
    const doc = (_b = (_a = verification.data) === null || _a === void 0 ? void 0 : _a.docs) === null || _b === void 0 ? void 0 : _b.find(item => item.type === type && item.sub_type === subType);
    const onFiles = (e) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            if (!verification.data) {
                yield verification.createVerification();
            }
            if (verification.errors.length > 0) {
                upload.setErrors(verification.errors);
            }
            else if (verification.data) {
                upload.upload(verification.data.id, e[0], type, subType);
            }
        }
        catch (e) {
            console.error(e);
        }
    });
    return (React.createElement(Upload, { size: doc === null || doc === void 0 ? void 0 : doc.size, label: label, onFiles: onFiles, loading: upload.loading || !verification.initialized, error: (_c = upload.errors[0]) === null || _c === void 0 ? void 0 : _c.description, fileName: doc ? label : undefined, disabled: verification.loading, onDownload: ((_d = verification.data) === null || _d === void 0 ? void 0 : _d.id) && (doc === null || doc === void 0 ? void 0 : doc.id)
            ? () => upload.download(verification.data.id, doc === null || doc === void 0 ? void 0 : doc.id, doc.filename)
            : undefined, onRemove: doc && ((_e = verification.data) === null || _e === void 0 ? void 0 : _e.id)
            ? () => upload.delete(verification.data.id, doc.id)
            : undefined }));
});
