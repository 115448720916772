import { styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Header } from "@/pages/UserPage/components/Header";
import { Menu } from "@/pages/UserPage/components/Menu";
import { SideMenu } from "@/pages/UserPage/components/SideMenu";
import { UserPageStore } from "@/pages/UserPage/stores/UserPageStore";
import { Box } from "@/shared/components";
import { useMedia } from "@/shared/hooks/useMedia";
import { useProvider, useStore, useStoreContext } from "@/shared/hooks/useStore";
import { MenuStore } from "@/shared/stores/MenuStore";
export const UserPage = observer(() => {
    const media = useMedia();
    const page = useStore(UserPageStore);
    const menu = useStoreContext(MenuStore);
    const Provider = useProvider(UserPageStore);
    const location = useLocation();
    React.useEffect(() => {
        menu.close();
    }, [menu, location, location.pathname]);
    return (React.createElement(Provider, { value: page },
        React.createElement(Box, { maxWidth: "1440px", margin: "0 auto" },
            !media.phone && React.createElement(Menu, null),
            React.createElement(Container, null,
                React.createElement(Header, null),
                React.createElement(Outlet, null))),
        menu.visible && media.phone && React.createElement(SideMenu, null)));
});
const Container = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    minHeight: "100vh",
    paddingLeft: "max(90px, calc((100% - 1080px) / 2))",
    paddingRight: "max(15px, calc((100% - 1080px) / 2))",
    [theme.breakpoints.down("sm")]: {
        paddingLeft: 20,
        paddingRight: 20,
    },
}));
