var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { parsePhoneNumber } from "awesome-phonenumber";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { VerificationStep } from "../VerificationStep";
import { PhoneFormStep, PhoneFormStore } from "@/pages/ProfilePage/stores/PhoneFormStore";
import { Box, Button, FormControlInput, Typography } from "@/shared/components";
import { Toast } from "@/shared/components/Alert";
import { Popup } from "@/shared/components/Popup";
import { PopupBody } from "@/shared/components/Popup/Body";
import { PopupContent } from "@/shared/components/Popup/Content";
import { PopupHead } from "@/shared/components/Popup/Head";
import { useStoreContext } from "@/shared/hooks/useStore";
import { AuthStore } from "@/shared/stores/AuthStore";
import { preventDefault } from "@/shared/utils/prevent";
export const SettingsNumber = observer(() => {
    var _a, _b, _c, _d, _e, _f, _g;
    const auth = useStoreContext(AuthStore);
    const store = useStoreContext(PhoneFormStore);
    const [phone, setPhone] = useState("");
    const onSubmitPhone = () => {
        store.submitPhone(phone);
    };
    const onSubmitCode = (code) => __awaiter(void 0, void 0, void 0, function* () {
        store.submitPhoneCode(phone, code).then(success => {
            if (success) {
                toast((props) => React.createElement(Toast, Object.assign({ type: "success", text: "Your phone is successfully changed" }, props)));
            }
        });
    });
    const close = () => {
        store.reset();
        setPhone("");
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { display: "flex", justifyContent: "space-between", alignItems: "center" },
            React.createElement(Box, { display: "flex", flexDirection: "column", gap: "10px" },
                React.createElement(Typography, { type: "text20" }, "Phone number"),
                React.createElement(Typography, { color: "black-500" }, (_b = parsePhoneNumber(`+${(_a = auth.userInfo) === null || _a === void 0 ? void 0 : _a.phone}`).number) === null || _b === void 0 ? void 0 : _b.international)),
            React.createElement(Button, { size: "s", disabled: store.loading, onClick: store.open }, "Change")),
        store.visible && (React.createElement(Popup, { width: "450px", onClose: close },
            React.createElement(PopupHead, { onClose: close }),
            store.step === PhoneFormStep.Code && (React.createElement(VerificationStep, { count: store.timer.count, error: (_d = (_c = store.errors) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.description, loading: store.loading, onResendCode: onSubmitPhone, onSubmitCode: onSubmitCode, title: "Confirm your phone", description: `Enter the code we've sent you by SMS to ${(_e = parsePhoneNumber(`+${phone}`).number) === null || _e === void 0 ? void 0 : _e.international}` })),
            store.step === PhoneFormStep.Form && (React.createElement(PopupBody, { as: "form", onSubmit: preventDefault(onSubmitPhone) },
                React.createElement(PopupContent, { gap: "30px" },
                    React.createElement(Box, { display: "flex", flexDirection: "column", gap: "10px", alignItems: "center" },
                        React.createElement(Typography, { type: "text28Bold" }, "Enter new phone number"),
                        React.createElement(Typography, { color: "black-500", textAlign: "center" }, "We will send you a SMS code. Verify your phone number to even better protect your account.")),
                    React.createElement(FormControlInput, { error: (_g = (_f = store.errors) === null || _f === void 0 ? void 0 : _f[0]) === null || _g === void 0 ? void 0 : _g.description, width: "100%", label: "Phone number", disabled: store.loading, value: phone, onChange: (e) => setPhone(e.target.value) })),
                React.createElement(Button, { width: "100%", onClick: onSubmitPhone, disabled: !phone || store.loading }, "Continue")))))));
});
