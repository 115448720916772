import * as React from "react";
import { toast } from "react-toastify";
import useClipboard from "react-use-clipboard";
import { Toast } from "@/shared/components/Alert";
export const useCopy = (text, success = "Copy success") => {
    const [isCopied, setCopied] = useClipboard(text, { successDuration: 1000 });
    React.useEffect(() => {
        if (isCopied) {
            toast(React.createElement(Toast, { text: success, type: "success" }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCopied]);
    return setCopied;
};
