var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import BigNumber from "bignumber.js";
import { makeAutoObservable, runInAction, when } from "mobx";
import { currencyApi } from "@/api/currency/api";
export class CurrencyListStore {
    constructor() {
        this.list = [];
        this.loading = false;
        makeAutoObservable(this, {}, { autoBind: true });
    }
    init() {
        this.syncAll();
    }
    syncAll() {
        return __awaiter(this, void 0, void 0, function* () {
            let { list } = this;
            runInAction(() => {
                this.loading = true;
            });
            try {
                const r = yield currencyApi.currency.getAllCurrencies();
                list = r.data;
            }
            catch (e) {
                console.error(e);
            }
            runInAction(() => {
                this.loading = false;
                this.list = list;
            });
        });
    }
    convert(from, to, amount) {
        return __awaiter(this, void 0, void 0, function* () {
            yield when(() => !!this.rates, { timeout: 3000 });
            const fromRate = this.rates[from.toLowerCase()];
            const toRate = this.rates[to.toLowerCase()];
            return (fromRate === null || fromRate === void 0 ? void 0 : fromRate.eurPrice) && (toRate === null || toRate === void 0 ? void 0 : toRate.eurPrice)
                ? BigNumber(fromRate.eurPrice).multipliedBy(amount).dividedBy(toRate.eurPrice).toFixed()
                : undefined;
        });
    }
    get rates() {
        return this.list.reduce((acc, item) => {
            if (item.slug) {
                acc[item.slug.toLowerCase()] = item;
            }
            return acc;
        }, {});
    }
    get blockchain() {
        return this.list
            .filter(item => !!item.tokens)
            .flatMap(item => item.tokens)
            .flatMap(item => item === null || item === void 0 ? void 0 : item.blockchain)
            .filter(item => !!(item === null || item === void 0 ? void 0 : item.slug))
            .reduce((acc, item) => (Object.assign(Object.assign({}, acc), { [item.slug.toLowerCase()]: item })), {});
    }
    get blockchainList() {
        return Object.values(this.blockchain).filter(a => !!a);
    }
    get bySlug() {
        return this.list
            .filter(item => !!(item === null || item === void 0 ? void 0 : item.slug))
            .reduce((acc, item) => (Object.assign(Object.assign({}, acc), { [item.slug.toLowerCase()]: item })), {});
    }
}
