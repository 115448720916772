import { styled } from "@mui/material/styles";
import { action } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { FormList } from "@/pages/KYBPage/components/FormList";
import { CompanyVerificationStore } from "@/pages/KYBPage/stores/CompanyVerificationStore";
import { IndividualListStore } from "@/pages/KYBPage/stores/IndividualListStore";
import { IndividualVerificationListStore } from "@/pages/KYBPage/stores/IndividualVerificationListStore";
import { KybFormStore } from "@/pages/KYBPage/stores/KybFormStore";
import { PaymentParam, PaymentTarget, ROUTES } from "@/routes";
import { FormControlCheckbox, Typography } from "@/shared/components";
import { Button } from "@/shared/components/Button";
import { useProvider, useStore, useStoreContext } from "@/shared/hooks/useStore";
import bgImg from "@/shared/img/kyb-bg.jpg";
import { CompanyInfoStore } from "@/shared/stores/CompanyInfoStore";
import { onCheckbox } from "@/shared/utils/input";
import { generateQueryPath } from "@/shared/utils/path";
export const KYBFormPage = observer(() => {
    const navigate = useNavigate();
    const companyInfo = useStoreContext(CompanyInfoStore);
    const individualList = useStoreContext(IndividualListStore);
    const companyVerification = useStoreContext(CompanyVerificationStore);
    const individualVerificationList = useStore(IndividualVerificationListStore, individualList);
    const kybForm = useStore(KybFormStore, companyInfo, individualList, companyVerification, individualVerificationList);
    const KybFormProvider = useProvider(KybFormStore);
    const IndividualVerificationListProvider = useProvider(IndividualVerificationListStore);
    return (React.createElement(IndividualVerificationListProvider, { value: individualVerificationList },
        React.createElement(KybFormProvider, { value: kybForm },
            React.createElement(Bg, null),
            React.createElement(Wrapper, null,
                React.createElement(Typography, { type: "text16", color: "black-1200", fontWeight: 500, height: "52px", padding: "0 25px", bgColor: "black-50", display: "inline-flex", alignItems: "center", borderRadius: "52px", marginBottom: "60px" }, "Verification"),
                React.createElement(Typography, { type: "text32Bold", color: "black-1200", marginBottom: "10px", variant: "h1" }, "Company Qualification"),
                React.createElement(Typography, { type: "text16", color: "black-500", marginBottom: "40px", variant: "body1" }, "Almost there! All that's left to do is to complete with the required information on your business."),
                React.createElement(FormList, null),
                kybForm.success && (React.createElement("div", null,
                    React.createElement(FormControlCheckbox, { checked: kybForm.agree, onChange: onCheckbox(action(value => {
                            kybForm.agree = value;
                        })), label: React.createElement(Typography, { type: "text14", color: "black-500" }, "I certify that all of the above information is complete and true. Supplying false information gives rise to liability laid down in the legislation. I undertake to immediately notify the company in writing of any material changes in the information provided."), marginBottom: "30px" }),
                    React.createElement(Button, { disabled: !kybForm.agree, width: "300px", onClick: () => navigate(generateQueryPath(ROUTES.payment, {}, {
                            [PaymentParam.Target]: PaymentTarget.Kyb,
                        })) }, "Go to payment")))))));
});
const Bg = styled("div")(({ theme }) => ({
    position: "fixed",
    top: 0,
    bottom: 0,
    left: "max(calc(((100% - 1240px) / 2) + 560px + min(160px, ((100% - 1240px) / 2))), 600px)",
    right: 0,
    zIndex: -1,
    backgroundImage: `url(${bgImg})`,
    backgroundSize: "620px",
    backgroundPosition: "bottom right",
    backgroundRepeat: "no-repeat",
    backgroundColor: theme.colors["black-1200"],
    [theme.breakpoints.down(1220)]: {
        backgroundPosition: "bottom left",
    },
    [theme.breakpoints.down("md")]: {
        display: "none",
    },
}));
const Wrapper = styled("div")(({ theme }) => ({
    maxWidth: 560,
    [theme.breakpoints.down("md")]: {
        maxWidth: "unset",
    },
}));
