import React from "react";
export const useToggler = (initialValue = false) => {
    const [enabled, setEnabled] = React.useState(initialValue);
    const toggle = React.useCallback((value) => {
        setEnabled(value);
    }, []);
    const on = React.useCallback(() => {
        setEnabled(true);
    }, []);
    const off = React.useCallback(() => {
        setEnabled(false);
    }, []);
    return React.useMemo(() => ({
        enabled,
        toggle,
        on,
        off,
    }), [toggle, on, off, enabled]);
};
