import { makeAutoObservable, runInAction } from "mobx";
export class TimerStore {
    constructor() {
        this.count = 0;
        makeAutoObservable(this, {}, { autoBind: true });
    }
    start(secs = 60) {
        clearInterval(this.interval);
        this.count = secs;
        this.interval = setInterval(() => {
            runInAction(() => {
                this.count -= 1;
                if (this.count === 0) {
                    clearInterval(this.interval);
                }
            });
        }, 1000);
    }
    dispose() {
        clearInterval(this.interval);
    }
}
