import { observer } from "mobx-react-lite";
import React from "react";
import { Box, Button, FormControlCheckbox, Typography } from "@/shared/components";
import { Dialog } from "@/shared/components/Dialog";
import { useStoreContext } from "@/shared/hooks/useStore";
import { AuthStore } from "@/shared/stores/AuthStore";
import { TermsStore } from "@/shared/stores/TermsStore";
import { onCheckbox } from "@/shared/utils/input";
import { action } from "mobx";
export const SignTermsModal = observer(() => {
    const termsStore = useStoreContext(TermsStore);
    const auth = useStoreContext(AuthStore);
    if (auth.initialized && !auth.authorized)
        return React.createElement(React.Fragment, null);
    if (termsStore.termsLoading)
        return React.createElement(React.Fragment, null);
    return (React.createElement(Dialog, { open: !termsStore.termsUpdated },
        React.createElement(Box, { maxWidth: "780px", width: "100%", display: "flex", flexDirection: "column", boxSizing: "border-box", padding: "40px 50px" },
            React.createElement(Typography, { type: "text24Bold" }, "Terms update"),
            React.createElement(Box, { margin: "20px 0 10px 0" },
                React.createElement(Typography, { type: "text18" }, "We have to notify, our terms and conditions was changed")),
            React.createElement(FormControlCheckbox, { checked: termsStore.termsConfirmed, onChange: onCheckbox(action((value) => {
                    termsStore.termsConfirmed = value;
                })), label: React.createElement(Typography, { type: "text12", color: "black-500" },
                    "By proceeding, you agree to our",
                    " ",
                    React.createElement("a", { target: "_blank", href: termsStore.termsLink }, "Terms of Use")) }),
            React.createElement(Button, { type: "submit", width: "100%", marginTop: "50px", disabled: !termsStore.termsConfirmed, onClick: termsStore.confirmTerms }, "Confirm"))));
});
