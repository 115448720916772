import { BalanceLogReasonType } from "./types";
export const OPERATION_TEXT_TYPE = {
    [BalanceLogReasonType.ORDER]: "Transfer",
    [BalanceLogReasonType.BTC_DEPOSIT]: "Deposit",
    [BalanceLogReasonType.ETH_DEPOSIT]: "Deposit",
    [BalanceLogReasonType.TON_DEPOSIT]: "Deposit",
    [BalanceLogReasonType.BTC_WITHDRAWAL_REQUEST]: "Withdrawal",
    [BalanceLogReasonType.BTC_WITHDRAWAL_REJECT]: "Withdrawal (Return payment)",
    [BalanceLogReasonType.BTC_WITHDRAWAL_FAIL]: "Withdrawal (Return payment)",
    [BalanceLogReasonType.ETH_WITHDRAWAL_REQUEST]: "Withdrawal",
    [BalanceLogReasonType.ETH_WITHDRAWAL_REJECT]: "Withdrawal (Return payment)",
    [BalanceLogReasonType.ETH_WITHDRAWAL_FAIL]: "Withdrawal (Return payment)",
    [BalanceLogReasonType.TON_WITHDRAWAL_REQUEST]: "Withdrawal",
    [BalanceLogReasonType.TON_WITHDRAWAL_REJECT]: "Withdrawal (Return payment)",
    [BalanceLogReasonType.TON_WITHDRAWAL_FAIL]: "Withdrawal (Return payment)",
    [BalanceLogReasonType.FIAT_WITHDRAWAL_REQUEST]: "Withdrawal",
    [BalanceLogReasonType.FIAT_WITHDRAWAL_REJECT]: "Withdrawal (Return payment)",
    [BalanceLogReasonType.DEPOSIT_FEE]: "Deposit",
    [BalanceLogReasonType.WITHDRAWAL_FEE]: "Withdrawal fee",
    [BalanceLogReasonType.REJECTED_WITHDRAWAL_FEE]: "Withdrawal fee (Return payment)",
    [BalanceLogReasonType.FAILED_WITHDRAWAL_FEE]: "Withdrawal fee (Return payment)",
    [BalanceLogReasonType.ORDER_FEE]: "Transfer",
    [BalanceLogReasonType.DIRECT_EXCHANGE_FEE]: "Exchange",
    [BalanceLogReasonType.LOCK_BALANCE]: "Transfer",
    [BalanceLogReasonType.UNLOCK_BALANCE]: "Transfer",
    [BalanceLogReasonType.UNKNOWN]: "Transfer",
    [BalanceLogReasonType.TRANSFER]: "Transfer",
    [BalanceLogReasonType.DEPOSIT]: "Deposit",
    [BalanceLogReasonType.WITHDRAWAL]: "Withdrawal",
    [BalanceLogReasonType.DIRECT_EXCHANGE]: "Exchange",
};
export const getOperationType = (type) => { var _a; return (_a = OPERATION_TEXT_TYPE[type]) !== null && _a !== void 0 ? _a : "Unknown type"; };
