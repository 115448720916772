var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { makeAutoObservable, runInAction } from "mobx";
import { registrationApi } from "@/api/registration";
import { parseApiError } from "@/shared/utils/error";
export class CompanyInfoStore {
    constructor() {
        this.ready = false;
        this.loading = false;
        this.companyInfo = undefined;
        this.errors = [];
        makeAutoObservable(this, {}, { autoBind: true });
    }
    init() {
        this.sync();
    }
    dispose() {
        this.ready = false;
        this.loading = false;
        this.companyInfo = undefined;
        this.errors = [];
    }
    sync() {
        return __awaiter(this, void 0, void 0, function* () {
            let companyInfo;
            let ready = true;
            runInAction(() => {
                this.loading = true;
            });
            try {
                const companyInfoResp = yield registrationApi.user.getCompanyInfo();
                companyInfo = companyInfoResp.data || undefined;
            }
            catch (e) {
                console.error(e);
                ready = false;
            }
            runInAction(() => {
                this.companyInfo = companyInfo;
                this.loading = false;
                this.ready = ready;
            });
        });
    }
    update(data) {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function* () {
            let success = false;
            let { companyInfo } = this;
            let errors = [];
            runInAction(() => {
                this.loading = true;
            });
            try {
                const resp = yield registrationApi.user.updateCompanyInfo(Object.assign(Object.assign(Object.assign({}, companyInfo), data), { meta: Object.assign(Object.assign({}, companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.meta), data === null || data === void 0 ? void 0 : data.meta), registrationNumber: (_b = (_a = data.registrationNumber) !== null && _a !== void 0 ? _a : companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.registrationNumber) !== null && _b !== void 0 ? _b : "", companyName: (_d = (_c = data.companyName) !== null && _c !== void 0 ? _c : companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.companyName) !== null && _d !== void 0 ? _d : "" }));
                companyInfo = resp.data;
                success = true;
            }
            catch (e) {
                console.error(e);
                errors = parseApiError(e);
            }
            runInAction(() => {
                this.errors = errors;
                this.companyInfo = companyInfo;
                this.loading = false;
            });
            return success;
        });
    }
}
