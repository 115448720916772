import { styled } from "@mui/material";
import * as React from "react";
import { generatePath, Link } from "react-router-dom";
import { useGetVerificationInfo } from "@/api/registration";
import { VerificationStatus } from "@/api/registration/types";
import { ROUTES } from "@/routes";
import { Box, Typography } from "@/shared/components";
import { Button } from "@/shared/components/Button";
import { Loader } from "@/shared/components/Loader";
import { useMedia } from "@/shared/hooks/useMedia";
import verification from "@/shared/img/verification.png";
export const CompanyInfoBanner = () => {
    const { data } = useGetVerificationInfo();
    const status = data === null || data === void 0 ? void 0 : data.status;
    const media = useMedia();
    let title = "";
    let description = "";
    let button = "";
    let buttonLink = "";
    if (status === VerificationStatus.INIT) {
        button = "Verify";
        buttonLink = generatePath(ROUTES.subscription);
        title = "Pass verification and get full access";
        description =
            "Unlock full access to our business banking services by completing the company and identity verification process";
    }
    if (status === VerificationStatus.PENDING) {
        title = "Verification is under review";
        description = "We’ve successfully received your request. Our team will contact you";
    }
    if (status === VerificationStatus.PENDINGL2F) {
        title = "Verification is under review";
        description = "We’ve successfully received your request. Our team will contact you";
    }
    if (status === VerificationStatus.REJECT || status === VerificationStatus.RETRY) {
        button = "Resubmit";
        title = "Action needed";
        description = "Some of the documents don't meet our requirements";
        buttonLink = generatePath(ROUTES.kyb);
    }
    return (React.createElement(Container, { phone: media.phone, loading: !status }, status
        ? (React.createElement(Box, { maxWidth: media.phone ? undefined : "440px" },
            React.createElement(Typography, { type: "text28", variant: "h2", color: "general-white", marginBottom: "10px", fontWeight: 700 }, title),
            React.createElement(Typography, { type: "text16", variant: "body1", color: "black-500" }, description),
            (button && buttonLink)
                ? (React.createElement(Button, { variant: "White", mt: media.phone ? "30px" : "40px", width: media.phone ? "100%" : "150px", LinkComponent: Link, to: generatePath(ROUTES.kyb) }, button))
                : null))
        : React.createElement(Loader, { size: 24 })));
};
const Container = styled("div") `
    position: relative;
    display: flex;
    align-items: center;
    min-height: ${props => props.phone ? undefined : "400px"};
    padding: ${props => props.phone ? "30px" : "0 80px"};
    border-radius: 22px;
    justify-content: ${props => props.loading ? "center" : "space-between"};
    flex-direction: ${props => props.phone ? "column" : "row"};
    gap: ${props => props.phone ? "30px" : "0"};
    background: ${props => props.loading ? undefined : props.theme.colors["black-1200"]};
    background-size: contain;
    overflow: hidden;
    padding-bottom: ${props => props.phone ? "230px" : undefined};

    &:after {
        content: '';
        display: ${props => props.loading ? "none" : "block"};
        left: ${props => props.phone ? "0" : "600px"};
        bottom: 0;
        position: absolute;
        height: ${props => props.phone ? "200px" : "400px"};
        width: ${props => props.phone ? "100%" : "400px"};
        background: url(${verification}) center center no-repeat;
        background-size: contain;
    }
`;
