var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { makeAutoObservable, runInAction } from "mobx";
import { registrationApi } from "@/api/registration";
export class VerificationStore {
    constructor() {
        this.verification = undefined;
        this.loader = 0;
        makeAutoObservable(this, {}, { autoBind: true });
    }
    init() {
        this.sync();
    }
    sync() {
        return __awaiter(this, void 0, void 0, function* () {
            let verification;
            runInAction(() => {
                this.loader += 1;
            });
            try {
                const verificationResp = yield registrationApi.verification.getVerificationStatus();
                verification = verificationResp.data;
            }
            catch (e) {
                console.error(e);
            }
            runInAction(() => {
                this.verification = verification;
                this.loader -= 1;
            });
        });
    }
    get loading() {
        return this.loader > 0;
    }
}
