import { styled } from "@mui/material/styles";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { BaseIcon, Box, Typography } from "@/shared/components";
import { Loader } from "@/shared/components/Loader";
export const ListItem = ({ name, success, link, loading, disabled, }) => {
    const navigate = useNavigate();
    return (React.createElement(Wrapper, { disabled: disabled, onClick: disabled ? undefined : () => navigate(link) },
        React.createElement(Typography, { type: "text16", color: "black-1200", fontWeight: 500 }, name),
        React.createElement(Box, { display: "flex", alignItems: "center", gap: "10px", minHeight: "30px" },
            loading ? React.createElement(Loader, { size: 18 }) : (success && (React.createElement(Box, { padding: "5px 10px", borderRadius: "100px", bgColor: "general-green-transparent-5" },
                React.createElement(Typography, { type: "text12", fontWeight: 500, color: "general-green" }, "Completed")))),
            !disabled && (React.createElement(BaseIcon, { icon: "Right", containerSize: 24, size: 12, color: "black-500" })))));
};
// dprint-ignore
const Wrapper = styled("div") `
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: ${props => props.theme.colors["black-50"]};
    border-radius: 22px;
    padding: 18px 30px;
    cursor: ${props => props.disabled ? "default" : "pointer"};

    &:hover {
        background: ${props => props.disabled
    ? props.theme.colors["black-50"]
    : props.theme.colors["black-100"]};
    }
`;
