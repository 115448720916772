import { styled } from "@mui/material";
import * as React from "react";
import { Link } from "react-router-dom";
import { BaseIcon } from "@/shared/components";
export const AccountItem = ({ to, children, }) => (React.createElement(Wrapper, { to: to },
    children,
    React.createElement(BaseIcon, { icon: "Right", size: 12, containerSize: 24, color: "black-1200" })));
const Wrapper = styled(Link) `
    display: flex;
    align-items: center;
    gap: 15px;

    ${props => props.theme.breakpoints.down("md")} {
        padding: 20px;
    }

    ${props => props.theme.breakpoints.up("md")} {
        padding: 20px 30px;
        border: 1px solid ${props => props.theme.colors["black-50"]};
        border-radius: 22px;
    }

    &:hover {
        background: ${props => props.theme.colors["black-50"]};
    }
`;
