/* eslint-disable react/no-array-index-key */
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useParams } from "react-router-dom";
import { Box, Button, Typography } from "@/shared/components";
import { useCopy } from "@/shared/hooks/useCopy";
import { useMedia } from "@/shared/hooks/useMedia";
import { useStoreContext } from "@/shared/hooks/useStore";
import { AccountListStore } from "@/shared/stores/AccountListStore";
import { AddressListStore, EAddressTypes } from "@/shared/stores/AddressListStore";
import { dataToText, saveToPDF } from "@/shared/utils/save-to-pdf";
export const DepositFiatInfo = observer(() => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4;
    const params = useParams();
    const media = useMedia();
    const accountList = useStoreContext(AccountListStore);
    const addressList = useStoreContext(AddressListStore);
    const account = params.id ? accountList.fiatById[params.id] : undefined;
    const address = account
        ? (_a = addressList.data[account === null || account === void 0 ? void 0 : account.account]) === null || _a === void 0 ? void 0 : _a[account === null || account === void 0 ? void 0 : account.currency]
        : undefined;
    const [activeIndex, setActiveIndex] = React.useState(0);
    const activeAddress = address === null || address === void 0 ? void 0 : address.list.at(activeIndex);
    const infoByType = {
        [EAddressTypes.ACH]: [
            ["Beneficiary", (_b = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.accountHolderName) !== null && _b !== void 0 ? _b : "—"],
            ["Bank account number", (_c = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.accountNumber) !== null && _c !== void 0 ? _c : "—"],
            [
                "Account routing number",
                (_d = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.accountRoutingNumber) !== null && _d !== void 0 ? _d : "—",
            ],
            ["Bank name", (_e = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.bankName) !== null && _e !== void 0 ? _e : "—"],
            ["Memo", (_f = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.memo) !== null && _f !== void 0 ? _f : "—"],
        ],
        [EAddressTypes.FEDWIRE]: [
            ["Beneficiary", (_g = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.accountHolderName) !== null && _g !== void 0 ? _g : "—"],
            ["Beneficiary address", (_h = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.accountHolderAddress) !== null && _h !== void 0 ? _h : "—"],
            ["Bank account number", (_j = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.accountNumber) !== null && _j !== void 0 ? _j : "—"],
            [
                "Account routing number",
                (_k = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.accountRoutingNumber) !== null && _k !== void 0 ? _k : "—",
            ],
            ["Bank name", (_l = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.bankName) !== null && _l !== void 0 ? _l : "—"],
            ["Bank address", (_m = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.bankAddress) !== null && _m !== void 0 ? _m : "—"],
            ["Memo", (_o = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.memo) !== null && _o !== void 0 ? _o : "—"],
        ],
        [EAddressTypes.SWIFT]: [
            ["Beneficiary", (_p = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.accountHolderName) !== null && _p !== void 0 ? _p : "—"],
            ["Beneficiary address", (_q = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.accountHolderAddress) !== null && _q !== void 0 ? _q : "—"],
            ["Bank account number", (_r = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.accountNumber) !== null && _r !== void 0 ? _r : "—"],
            ["Bank name", (_s = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.bankName) !== null && _s !== void 0 ? _s : "—"],
            ["Bank address", (_t = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.bankAddress) !== null && _t !== void 0 ? _t : "—"],
            ["Bic", (_u = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.swiftBic) !== null && _u !== void 0 ? _u : "—"],
            ["Memo", (_v = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.memo) !== null && _v !== void 0 ? _v : "—"],
        ],
        [EAddressTypes.EFT]: [
            ["Beneficiary", (_w = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.accountHolderName) !== null && _w !== void 0 ? _w : "—"],
            ["Beneficiary address", (_x = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.accountHolderAddress) !== null && _x !== void 0 ? _x : "—"],
            ["Bank account number", (_y = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.accountNumber) !== null && _y !== void 0 ? _y : "—"],
            ["Bank name", (_z = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.bankName) !== null && _z !== void 0 ? _z : "—"],
            ["Bank address", (_0 = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.bankAddress) !== null && _0 !== void 0 ? _0 : "—"],
            ["Institution number", (_1 = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.institutionNumber) !== null && _1 !== void 0 ? _1 : "—"],
            ["Transit number", (_2 = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.transitNumber) !== null && _2 !== void 0 ? _2 : "—"],
            ["Memo", (_3 = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.memo) !== null && _3 !== void 0 ? _3 : "—"],
        ],
        [EAddressTypes.EMPTY]: [],
    };
    const infoFullData = infoByType[(_4 = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.type) !== null && _4 !== void 0 ? _4 : EAddressTypes.EMPTY];
    const copy = useCopy(dataToText(infoFullData));
    const savePDF = () => {
        saveToPDF(dataToText(infoFullData, 60), "bank.pdf");
    };
    React.useEffect(() => {
        addressList.sync(account);
    }, [account, addressList]);
    const infoEmpty = infoFullData.every((_) => _[1] === "—");
    return (React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "center", gap: "40px" },
        React.createElement(Box, { width: "100%", display: "grid", gridTemplateColumns: media.phone ? "1fr" : "1fr 1fr 1fr", gap: "10px" }, address === null || address === void 0 ? void 0 : address.list.map((item, index) => {
            var _a;
            return (React.createElement(Button, { size: "xs", variant: activeIndex === index ? undefined : "Gray", onClick: () => {
                    setActiveIndex(index);
                } }, (_a = item.type) === null || _a === void 0 ? void 0 : _a.toUpperCase()));
        })),
        !infoEmpty && (React.createElement(React.Fragment, null,
            React.createElement(Box, { display: "flex", flexDirection: "column", gap: "20px", width: "100%" }, infoFullData.map((item, index) => (React.createElement(Box, { display: "flex", flexDirection: "column", key: index },
                React.createElement(Typography, { type: "text14", color: "black-400" }, item[0]),
                React.createElement(Typography, { type: "text16", color: media.desktop
                        ? "general-white"
                        : undefined }, item[1]))))),
            React.createElement(Box, { width: "100%", display: media.desktop ? "grid" : "flex", flexDirection: media.desktop ? undefined : "column", alignItems: media.desktop ? "center" : "stretch", gridAutoFlow: "column", gridAutoColumns: "minmax(0, 1fr)", gap: media.desktop ? "30px" : "10px", whiteSpace: "nowrap" },
                React.createElement(Button, { variant: "LightBlue", size: "s", onClick: copy }, "Copy details"),
                React.createElement(Button, { variant: "LightBlue", size: "s", onClick: savePDF }, "Save details in PDF"))))));
});
