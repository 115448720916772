import { Popover } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import React from "react";
import { generatePath, Link, NavLink } from "react-router-dom";
import { MenuLinks } from "../../constants";
import { ROUTES } from "@/routes";
import { BaseIcon, Box, Typography } from "@/shared/components";
export const Menu = () => (React.createElement(Box, { position: "sticky", top: "0" },
    React.createElement(Box, { position: "absolute", top: "0", left: "0" },
        React.createElement(Container, null,
            React.createElement(Logo, { to: generatePath(ROUTES.root) },
                React.createElement(BaseIcon, { icon: "LogoSmall", size: 36 })),
            React.createElement(List, null, MenuLinks.map((props) => React.createElement(MenuItem, Object.assign({ key: props.label }, props))))))));
const MenuItem = ({ link, icon, label }) => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    return (React.createElement(React.Fragment, null,
        React.createElement(Item, { to: generatePath(link), onMouseEnter: handlePopoverOpen, onMouseLeave: handlePopoverClose },
            React.createElement(BaseIcon, { icon: icon, size: 22 })),
        React.createElement(Popover, { sx: {
                pointerEvents: "none",
            }, open: open, anchorEl: anchorEl, anchorOrigin: {
                vertical: "center",
                horizontal: "right",
            }, transformOrigin: {
                vertical: "center",
                horizontal: "left",
            }, onClose: handlePopoverClose, disableRestoreFocus: true, slotProps: {
                paper: {
                    sx: {
                        background: theme.colors["black-600"],
                        padding: "5px 10px",
                        boxSizing: "border-box",
                        margin: "0 0 0 4px",
                    },
                },
            } },
            React.createElement(Typography, { type: "text12", color: "general-white" }, label))));
};
const Container = styled("div")(() => ({
    width: 76,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    padding: "18px 0",
    gap: 40,
}));
const Logo = styled(Link)(() => ({
    width: 36,
    height: 36,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));
const List = styled("div")(() => ({
    display: "flex",
    alignItems: "center",
    gap: 16,
    flexDirection: "column",
}));
const Item = styled(NavLink)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 46,
    height: 46,
    color: theme.colors["black-300"],
    borderRadius: 12,
    transition: "0.2s ease",
    "&:hover": {
        color: theme.colors["black-400"],
        background: theme.colors["black-50"],
    },
    "&.active": {
        background: theme.colors["deep-blue-500"],
        color: theme.colors["general-white"],
    },
}));
