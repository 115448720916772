import { styled } from "@mui/material";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { generatePath, Link } from "react-router-dom";
import { CreateWalletPopup } from "@/features/create-wallet";
import { BalanceItem } from "@/pages/HomePage/components/Balances/Item";
import { ROUTES } from "@/routes";
import { BaseIcon, Box, Button, Typography } from "@/shared/components";
import { EmptyItem } from "@/shared/components/EmptyItem";
import { Loader } from "@/shared/components/Loader";
import { useMedia } from "@/shared/hooks/useMedia";
import { useStoreContext } from "@/shared/hooks/useStore";
import { AccountListStore } from "@/shared/stores/AccountListStore";
import { AddressListStore } from "@/shared/stores/AddressListStore";
import { BalanceListStore } from "@/shared/stores/BalanceListStore";
export const Balances = observer(() => {
    const { tablet, phone } = useMedia();
    const [isOpen, setIsOpen] = React.useState(false);
    const accounts = useStoreContext(AccountListStore);
    const balanceList = useStoreContext(BalanceListStore);
    const addressList = useStoreContext(AddressListStore);
    const loaded = accounts.crypto.every((item) => { var _a; return (_a = balanceList.data[item.account]) === null || _a === void 0 ? void 0 : _a.balance; });
    React.useEffect(() => {
        addressList.sync(...accounts.crypto);
    }, [balanceList, addressList, accounts.crypto]);
    return (React.createElement(Box, { display: "flex", flexDirection: "column", gap: "20px" },
        React.createElement(Box, { display: "flex", alignItems: "center", justifyContent: "space-between" },
            React.createElement(Typography, { type: "text24" }, "Wallets"),
            React.createElement(Button, { variant: "Gray", LinkComponent: Link, to: generatePath(ROUTES.walletList), size: "s" }, "View all")),
        React.createElement(Box, { display: "flex", flexDirection: "column", bgColor: "general-white", borderRadius: "22px", padding: tablet ? "30px" : "40px", gap: tablet ? "30px" : "40px", marginBottom: "40px" }, !accounts.crypto.length
            ? (React.createElement(React.Fragment, null,
                React.createElement(Box, { display: "grid", gridTemplateColumns: tablet ? "1fr" : "1fr 1fr", gap: "21px" },
                    React.createElement(EmptyItem, null),
                    React.createElement(EmptyItem, null)),
                React.createElement(Box, { display: "flex", flexDirection: "column", gap: "40px", padding: "25px", alignItems: "center" },
                    React.createElement(Typography, { color: "black-400", type: "text14" }, "Invoicing in crypto never was so close to you"),
                    React.createElement(Button, { onClick: () => setIsOpen(true), variant: "Black", width: 250 }, "Create new wallet")),
                isOpen && (React.createElement(CreateWalletPopup, { onClose: () => setIsOpen(false), onSuccess: () => { } }))))
            : (React.createElement(React.Fragment, null,
                React.createElement(Box, { position: "relative" },
                    React.createElement(Grid, { tablet: tablet, visible: loaded }, accounts.crypto.map((account) => {
                        var _a, _b, _c, _d;
                        const address = (_a = addressList.data[account.account]) === null || _a === void 0 ? void 0 : _a[account.currency];
                        return (React.createElement(BalanceItem, { key: account.account, currency: account.currency, id: account.account, amount: (_b = balanceList.data[account.account]) === null || _b === void 0 ? void 0 : _b.balance, address: (_c = address === null || address === void 0 ? void 0 : address.first) === null || _c === void 0 ? void 0 : _c.address, isEmpty: (_d = address === null || address === void 0 ? void 0 : address.first) === null || _d === void 0 ? void 0 : _d.isEmpty }));
                    })),
                    !loaded && (React.createElement(Loader, { position: "absolute", top: "50%", left: "50%", margin: "-12px 0 0 -12px", size: 24 }))),
                React.createElement(Box, { tabIndex: 0, role: "button", display: "flex", gap: "15px", alignItems: "center", alignSelf: "flex-start", onClick: () => setIsOpen(true) },
                    React.createElement(BaseIcon, { icon: "Deposit", size: phone ? 20 : 24, containerSize: phone ? 36 : 42, color: "deep-blue-500", bgColor: "deep-blue-500-transparent-5", borderRadius: "50%" }),
                    React.createElement(Typography, { color: "deep-blue-500" }, "Create new wallet")),
                isOpen && (React.createElement(CreateWalletPopup, { onClose: () => setIsOpen(false), onSuccess: () => setIsOpen(false) })))))));
});
const Grid = styled("div") `
    display: grid;
    grid-template-columns: ${(props) => (props.tablet ? "1fr" : "1fr 1fr")};
    gap: 20px 100px;
    opacity: ${(props) => (props.visible ? 1 : 0)};
    transition: 0.2s ease;
`;
