import * as React from "react";
import { Box, Typography } from "@/shared/components";
import { Button } from "@/shared/components/Button";
import { FormAlert } from "@/shared/components/FormAlert";
import { preventDefault } from "@/shared/utils/prevent";
export const FormLayout = ({ index, title, desc, loading, error, showSubmitBtns = true, disabled, children, onBack, onSubmit, }) => (React.createElement("form", { onSubmit: preventDefault(onSubmit) },
    React.createElement(Box, { maxWidth: "780px" },
        React.createElement(Button, { variant: "Gray", icon: "Back", width: "auto", paddingRight: "25px", marginBottom: "60px", onClick: onBack },
            "Step ",
            index,
            "/6"),
        React.createElement(Typography, { type: "text32Bold", variant: "h1", margin: "0 0 10px" }, title),
        React.createElement(Typography, { type: "text16", color: "black-500", margin: "0 0 40px", variant: "body1" }, desc),
        React.createElement(Box, { display: "flex", flexDirection: "column", gap: "40px" }, children),
        error && React.createElement(FormAlert, { text: error, margin: "40px 0" }),
        showSubmitBtns && (React.createElement(Box, { maxWidth: "250px", margin: error ? "40px 0 0" : "100px 0 0" },
            React.createElement(Button, { type: "submit", disabled: loading || disabled, width: "100%" }, "Next"))))));
